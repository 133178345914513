import { useState, useEffect, FC } from 'react'
import s from './PopUp.module.css'

interface PopUp {
  openPopUp: any
  currentSlide: number
  arrLength: number
  isOpen: boolean
  data: any
}

export const PopUp: FC<PopUp> = ({
  openPopUp,
  currentSlide,
  arrLength,
  isOpen,
  data,
}) => {
  const [nextCurrentSlide, setNextCurrenSlide] = useState(currentSlide)
  // const [newArray] = useState(data.map((el) => el.images.background));

  useEffect(() => {
    setNextCurrenSlide(currentSlide)
  }, [currentSlide])

  //   if (!isOpen) return null;

  const clickNextPopUp = (e: any) => {
    e.stopPropagation()
    if (+nextCurrentSlide + 1 >= arrLength) {
      return
    }
    setNextCurrenSlide(+nextCurrentSlide + 1)
  }

  const clickPreviousPopUp = (e: any) => {
    e.stopPropagation()
    if (nextCurrentSlide <= 0) {
      return
    }
    setNextCurrenSlide(+nextCurrentSlide - 1)
  }

  return (
    <>
      <div className={isOpen ? s.modal__active : s.modal} onClick={openPopUp}>
        <div className={s.close__button} onClick={openPopUp}>
          &nbsp;
        </div>
        <div
          className={isOpen ? s.content__active : s.content}
          style={{
            backgroundImage: `url('${data[nextCurrentSlide]}')`,
          }}
          onClick={e => e.stopPropagation()}
        >
          &nbsp;
        </div>

        <div className={s.navigation}>
          <div className={s.navigation_wrapper}>
            <div
              className={s.navigation_prevBtn}
              onClick={e => clickPreviousPopUp(e)}
            >
              <div className={s.navigation_prevBtn_image}>
                &nbsp;
              </div>
            </div>
            <div className={s.navigation__values}>
              <span>{+nextCurrentSlide + 1}</span>
              <span>/</span>
              <span>{arrLength}</span>
            </div>
            <div
              className={s.navigation_nextBtn}
              onClick={e => clickNextPopUp(e)}
            >
              <div className={s.navigation_nextBtn_image}>
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
