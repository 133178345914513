import React from 'react'
import clsx from 'clsx'
import s from './Edit.module.css'
import defaultEdit from './icons/icon-edit_doc.svg'
import activeEdit from './icons/icon-edit_doc_active.svg'
import { Tooltip } from '../Tooltip'

interface EditType {
	active?: boolean
	className?: string
	classNametooltip?: string
	onClick?: () => void
	onEditClick?: () => void
  removeDoc?: () => void
  removeProject?: () => void
  archiveProject?: () => void
  resumeProject?: () => void
  suspendProject?: () => void
  activateProject?: () => void
	arrSettings?: string[]
  team?: boolean
  teamHandler?: any
  teamPublished?: boolean
  client?: boolean
}

export const Edit: React.FC<EditType> = ({
  active,
  className,
  onClick,
  onEditClick,
  removeDoc,
  removeProject,
  archiveProject,
  resumeProject,
  suspendProject,
  activateProject,
  arrSettings,
  classNametooltip,
  team,
  teamHandler,
  teamPublished,
  client,
}) => (
  <div onClick={onClick} className={clsx(s.edit, className)}>
    {active ? (
      <>
        <img src={defaultEdit} width="30px" height="30px" alt="defaultEdit" />
        <Tooltip
          arrSettings={arrSettings}
          onClick={onEditClick}
          removeDoc={removeDoc}
          removeProject={removeProject}
          archiveProject={archiveProject}
          resumeProject={resumeProject}
          suspendProject={suspendProject}
          activateProject={activateProject}
          className={clsx(s.tooltip, classNametooltip)}
          team={team}
          teamHandler={teamHandler}
          teamPublished={teamPublished}
          client={client}
        />
      </>
    ) : (
      <img src={activeEdit} width="30px" height="30px" alt="activeEdit" />
    )}
  </div>
)
