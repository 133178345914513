import { gql } from '@apollo/client'

export const DELETE_CONTRACT = gql`
  mutation contractDelete($uuid: String!) {
    contractDelete(uuid: $uuid)
  }
`
export const DELETE_ACT = gql`
  mutation deleteAct($uuid: String!) {
    admActDelete(uuid: $uuid)
  }
`
export const DELETE_REPORT = gql`
  mutation deleteReport($uuid: String!) {
    admReportDelete(uuid: $uuid)
  }
`
