import React, { ReactNode } from 'react'
import clsx from 'clsx'
import s from './DropdownButton.module.css'

interface DropdownButtonProps {
  children?: ReactNode
  className?: string
  onClick?: (event: React.MouseEvent) => void
}

export const DropdownButton = ({
  className,
  onClick,
  children,
}: DropdownButtonProps) => (
  <button
    className={clsx(s.dropdown_btn, className)}
    type="button"
    onClick={onClick}
  >
    {children}
  </button>
)
