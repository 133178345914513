/*eslint-disable*/

import { gql } from "@apollo/client";

// Мутация для добавления проекта на странице Добавить проект
export const CREATE_PROJ = gql`
  mutation projectCreate($data: ProjectData!) {
    admProjectCreate(data: $data) {
      uuid
      name
      nameEn
      description
      descriptionEn
      country
      minInvestment
      minInterest
      status
      images
      requisites
      support
      sendStatus
    }
  }
`;
