/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import type { ReactNode } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { useAppSelector } from '../../redux/hook'

interface TypeAuth {
	children: ReactNode
}

export const RequireAuth: React.FC<TypeAuth> = ({ children }) => {
  const location = useLocation()
  const isAuth = useAppSelector(state => Boolean(state.authSlice.token))
  // console.log(isAuth)
  if (isAuth) return <>{children}</>
  return <Navigate to="/login" state={{ from: location }} replace />
}
