import React, { CSSProperties } from 'react'
import clsx from 'clsx'
import { Avatar, Counter } from '../../ui'
import initial from './img/icon-initial.svg'
import read from './img/icon-status-read.svg'
import unread from './img/icon-status-unread.svg'
import doc from './img/icon-doc.svg'
import s from './ChatMessageItem.module.css'
import { compareDates } from '../../references/data'

interface ChatMessageItemProps {
  className?: string
  src?: string
  firstName?: string | undefined
  lastName?: string | undefined
  name?: string | undefined
  createdAt?: any
  statusIcon?: string
  text?: string | undefined
  countUnread?: number
  role?: string | undefined
  messageType?: string | undefined
  style?: CSSProperties | undefined
  onClick?: () => void
  fileName?: string
}

export const ChatMessageItem = ({
  className,
  style,
  src = initial,
  firstName = '',
  lastName = '',
  name = '',
  createdAt = '',
  statusIcon,
  text = 'Мы отправим документ на электронную почту в течение двух минут',
  countUnread = 0,
  role,
  messageType,
  fileName,
  onClick,
}: ChatMessageItemProps) => {
  const currentDate = Date.now()
  const msPerday = 24 * 3600 * 1000

  return (
    <div
      className={clsx(s.chat__item, className)}
      onClick={onClick}
      style={style}
    >
      <div className={s.chat__item_avatar}>
        {src ? (
          <div className={s.checkbox_avatar}>
            <img
              className={s.img}
              src={src}
              alt="avatar"
              width="50"
              height="50"
            />
          </div>
        ) : (
          <Avatar
            className={s.chat__item_noavatar}
            firstName={
              firstName[0] ||
              name?.split(' ').splice(1, 1).reverse().join(' ')[0]
            }
            lastName={
              lastName[0] ||
              name?.split(' ').splice(0, 2).slice(0, 1).join(' ')[0]
            }
          />
        )}
      </div>
      <div className={s.chat__item_info}>
        <div className={s.chat__item_title}>
          <p className={s.chat__item_name}>{`${firstName} ${lastName}`}</p>
          <div style={{ display: 'flex', columnGap: 5, alignItems: 'center' }}>
            <p className={s.chat__item_date}>
              {createdAt ? (
                compareDates(currentDate, createdAt) ? (
                  new Intl.DateTimeFormat('ru', {
                    hour: 'numeric',
                    minute: 'numeric',
                  }).format(Date.parse(createdAt))
                ) : currentDate - Date.parse(createdAt) < msPerday ? (
                  <>Вчера</>
                ) : (
                  new Intl.DateTimeFormat('ru', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  }).format(Date.parse(createdAt))
                )
              ) : null}
            </p>
            {statusIcon && (
              <div className={s.chat__item_status}>
                <img
                  src={statusIcon === 'read' ? read : unread}
                  alt="status"
                  width="20"
                  height="20"
                />
              </div>
            )}
          </div>
        </div>
        <div className={s.chat__item_message}>
          {messageType === 'text' && (
            <p className={s.chat__item_text}>{text}</p>
          )}
          {messageType === 'image' && (
            <img src={text} alt="minImg" width="50" height="50" />
          )}
          {messageType === 'doc' && (
            <div className={s.chat__document}>
              <img src={doc} alt="doc-icon" width="25" height="25" />
              <p className={s.chat__document_text}>{fileName}</p>
            </div>
          )}
          {countUnread > 0 && role !== 'lawyer' && role !== 'manager' ? (
            <Counter theme="warn" count={countUnread} />
          ) : null}
        </div>
      </div>
    </div>
  )
}
