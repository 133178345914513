import React, { FC } from 'react'
import clsx from 'clsx'
import { Avatar } from '../Avatar'
import s from './Checkbox.module.css'

interface CheckBoxProps extends React.HTMLAttributes<HTMLInputElement> {
  id?: string
  name?: string
  label?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  className?: string
  avatar?: boolean
  src?: string
  firstName?: string | any
  lastName?: string | any
  fontSize?: string
  color?: string
  isChecked?: boolean
  logoWidth?: string
  logoHeight?: string
}

export const Checkbox: FC<CheckBoxProps> = ({
  className,
  onChange,
  id,
  name,
  label,
  avatar,
  src,
  firstName,
  lastName,
  fontSize = '18px',
  color = '#000',
  isChecked = false,
  logoWidth = '50px',
  logoHeight = '50px',
  ...rest
}) => (
  <div className={clsx(s.checkbox_wrapper, className)}>
    <div className={s.input_checkbox}>
      <input
        className={s.checkbox}
        type="checkbox"
        id={id}
        name={name}
        onChange={onChange}
        checked={isChecked}
        {...rest}
      />
      <label htmlFor={id} style={{ fontSize, color }}>
        {avatar
          && (src ? (
            <div className={s.checkbox_avatar} style={{ width: logoWidth, height: logoHeight }}>
              <img className={s.img} src={src} alt="avatar" width="50" height="50" />
            </div>
          ) : (
            <Avatar
              className={s.checkbox_noavatar}
              firstName={`${firstName}`[0]}
              lastName={`${lastName}`[0]}
            />
          ))}
        <p className={s.checkbox_label}>{label}</p>
      </label>
    </div>
  </div>
)
