import React, {
  useState,
  useRef,
  useEffect,
  FC,
} from 'react'
import { useQuery } from '@apollo/client'
import { ALL_PROJECTS_GALLERY } from '../../../../apollo/query/allProjects'
import s from './SliderProjects.module.css'

interface SliderProjects {
  width?: number
  isTurnDevice?: boolean
  clientWidth: number
}

export const SliderProjects: FC <SliderProjects> = ({ width, isTurnDevice, clientWidth }) => {
  const projectSliderRef = useRef(null)
  const [positionProject, setPositionProject] = useState(0)
  const [counterPageProject, setCounterPageProject] = useState(1)
  const [slideWidth] = useState(16.98)
  const [slideWidthMediumScreen] = useState(32.422)
  const [slideWidthSmallScreen] = useState(32.9427083333333)
  // const [slideWidthExtraSmallScreen] = useState(89.096)
  const [slideWidthExtraSmallScreen] = useState(85.096)
  const [counterPage, setCounterPage] = useState(1)
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const minSwipeDistance = 50
  const [currentSlide, setCurrentSlide] = useState(0)
  // const [picturePerSlide, setPicturePerSlide] = useState(
  //   width > 1024 ? 5 : width > 376 ? 3 : 1,
  // )
  const [picturePerSlide, setPicturePerSlide] = useState(5)
  const { loading, error, data } = useQuery(ALL_PROJECTS_GALLERY)
  const sliderRef = projectSliderRef as React.RefObject<any>
  // для сброса слайдера при повороте экрана
  useEffect(() => {
    setPositionProject(0)
    setCounterPageProject(1)
  }, [width])

  // clientWidth вместо innerWidth,т.к нет скролл бара
  // useEffect(() => {
  //   setPicturePerSlide(clientWidth > 1025 ? 5 : clientWidth > 376 ? 3 : 1)
  // }, [width])

  const onTouchStart = (e: any) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const [dataSlider, setDataSlider]: any = useState([])

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    // eslint-disable-next-line
    if (isLeftSwipe || isRightSwipe) isLeftSwipe ? nextProjectHandler() : prevHandlerProject()
  }

  useEffect(() => {
    if (!data) return
    setDataSlider([
      ...data.projects.filter((el: any) => el.projectStatusNew !== 'draft' && el.projectStatusNew !== 'archive'),
    ])
  }, [data])
  const nextProjectHandler = () => {
    let slideIncrement
    let slideWidthValue

    if (clientWidth > 1024) {
      slideIncrement = 5
      slideWidthValue = slideWidth
    } else if (clientWidth <= 1024 && clientWidth > 768) {
      slideIncrement = 3
      slideWidthValue = slideWidthMediumScreen
    } else if (clientWidth <= 768 && clientWidth > 376) {
      slideIncrement = 3
      slideWidthValue = slideWidthSmallScreen
    } else {
      slideIncrement = 1
      slideWidthValue = slideWidthExtraSmallScreen
    }
    let temp = 0
    const totalPages = Math.ceil(dataSlider.length / slideIncrement)
    const currentPosition = -(
      slideWidthValue * (dataSlider.length - slideIncrement)
    )

    if (positionProject === currentPosition) {
      setCounterPageProject(totalPages)
      return
    }

    if (counterPageProject < totalPages) {
      setCounterPageProject(counterPageProject + 1)
      setPositionProject((positionProject - slideIncrement * slideWidthValue))
      temp = positionProject - slideIncrement * slideWidthValue
    }
    if (counterPageProject + 1 === totalPages) {
      setPositionProject(
        (positionProject - (dataSlider.length - slideIncrement * counterPageProject) * slideWidthValue),
      )
      temp = positionProject - (dataSlider.length - slideIncrement * counterPageProject) * slideWidthValue
    }
    if (counterPageProject === totalPages) {
      return
    }

    if (counterPageProject + 1 > totalPages) {
      return
    }
    const translateUnit = clientWidth > 1024 ? 'vw' : 'vw'

    // eslint-disable-next-line
    // projectSliderRef.current.childNodes.forEach((el) => (el.style = `transform: translateX(${temp}${translateUnit})`))
    if (sliderRef.current && sliderRef.current.childNodes) {
      sliderRef.current.childNodes.forEach((el: any) => {
        el.style.transform = `translateX(${temp}vw)`
      })
    }
  }

  const prevHandlerProject = () => {
    let slideIncrement
    let slideWidthValue

    if (clientWidth > 1024) {
      slideIncrement = 5
      slideWidthValue = slideWidth
    } else if (clientWidth <= 1024 && clientWidth > 768) {
      slideIncrement = 3
      slideWidthValue = slideWidthMediumScreen
    } else if (clientWidth <= 768 && clientWidth > 376) {
      slideIncrement = 3
      slideWidthValue = slideWidthSmallScreen
    } else {
      slideIncrement = 1
      slideWidthValue = slideWidthExtraSmallScreen
    }
    let temp = 0
    const totalPages = Math.ceil(dataSlider.length / slideIncrement)
    const currentPosition = 0

    if (positionProject === 0) {
      setCounterPageProject(1)
      return
    }
    if (counterPageProject === 1) {
      setPositionProject(0)
    }
    if (positionProject === currentPosition) {
      setCounterPageProject(totalPages)
      return
    }

    setCounterPageProject(counterPageProject - 1)

    if (counterPageProject - 1 === totalPages - 1) {
      setPositionProject(
        (positionProject + (dataSlider.length % slideIncrement === 0
          ? slideIncrement
          : dataSlider.length % slideIncrement) * slideWidthValue),
      )
      temp = positionProject + (dataSlider.length % slideIncrement === 0
        ? slideIncrement
        : dataSlider.length % slideIncrement) * slideWidthValue
    } else {
      setPositionProject((positionProject + slideIncrement * slideWidthValue))
      temp = positionProject + slideIncrement * slideWidthValue
    }

    const translateUnit = clientWidth > 1024 ? 'vw' : 'vw'
    // eslint-disable-next-line
    // projectSliderRef.current.childNodes.forEach((el) => (el.style = `transform: translateX(${temp}${translateUnit})`))
    if (sliderRef.current && sliderRef.current.childNodes) {
      sliderRef.current.childNodes.forEach((el: any) => {
        el.style.transform = `translateX(${temp}vw)`
      })
    }
  }

  return (
    <>
      {data && (
        <>
          <div
            className={s.projectSlider}
            ref={projectSliderRef}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            {/* {data.projects.map((el) => ( */}
            {dataSlider.map((el: any) => (
              // <Link
              <div
                className={s.projectSlider__link}
                key={el.name}
                // to={`/${el.name}`}
              >
                <div
                  className={s.projectSlider__link__project}
                  key={el.id}
                  // onClick={returnToTop}
                >
                  <div
                    className={s.projectSlider__link__project__background}
                    style={{
                      backgroundImage: `url('${el.projectImages.background}')`,
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    className={s.projectSlider__link__project__logo}
                    style={{
                      WebkitMaskImage: `url(${el.projectImages.logo})`,

                      maskImage: `url(${el.projectImages.logo})`,
                    }}
                  >
                    &nbsp;
                  </div>
                </div>
                {/* </Link> */}
              </div>
            ))}
          </div>
        </>
      )}

      <div className={s.navigation}>
        <div className={s.navigation_wrapper}>
          <div className={s.navigation_prevBtn} onClick={prevHandlerProject}>
            <div className={s.navigation_prevBtn_image}>
              &nbsp;
            </div>
          </div>
          <div className={s.navigation__values}>
            <span>{counterPageProject}</span>
            <span id="goToForm">/</span>
            {/* <span>{Math.ceil(data.projects.length / picturePerSlide)}</span> */}
            <span>{Math.ceil(dataSlider.length / picturePerSlide)}</span>
          </div>
          <div className={s.navigation_nextBtn} onClick={nextProjectHandler}>
            <div className={s.navigation_nextBtn_image}>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
