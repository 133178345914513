import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '../../redux/hook'
import { Container } from '../Container'
import { addProjectFields } from '../../redux/slices/projectSlice' // добавил признак для дропдаун меню
import { changeNav, changeIsCreateBroker } from '../../redux/slices/editSiteSlice'
import s from './NativeSelectNavigation.module.css'

interface SelectNavigationType {
  className?: string
  onClick?: any
  onBlur?: () => void
  show?: boolean
  draft?: boolean
  defaultTitle?: string
  pledgeArr?: any
  promotionValue?: string
  projectStatusNew?:string
  selectActProject?: boolean
  navigationSelect?: boolean
  navigationArr?: any
  src?: string
  projectTitleAct?: string
  typeSelect?: boolean
  uniqId?: any
}

export const NativeSelectNavigation: React.FC<SelectNavigationType> = ({
  className,
  onClick,
  onBlur,
  show,
  draft,
  defaultTitle,
  pledgeArr,
  promotionValue,
  projectStatusNew,
  selectActProject,
  navigationSelect,
  navigationArr,
  src,
  projectTitleAct,
  typeSelect,
  uniqId,
}) => {
  const dispatch = useAppDispatch()
  const [value, setValue] = useState<string | number | undefined>(
    defaultTitle || 'Выберите из списка',
  )
  useEffect(() => {
    if (draft) {
      setValue('Выберите из списка')
    }
  }, [draft])

  useEffect(() => {
    if (navigationSelect) {
      setValue('Изменить сайт')
    }
  }, [navigationSelect])

  // навигация по изменению сайта
  const handleClickNav = (e: any) => {
    dispatch(changeNav(+e.target.id))
    dispatch(changeIsCreateBroker(false))
  }

  useEffect(() => {
    if (typeSelect) {
      if (defaultTitle) {
        setValue(defaultTitle !== '' ? defaultTitle : 'Тип')
      } else {
        setValue('Тип')
      }
    }
  }, [typeSelect])

  return (
    <div className={s.wrapper}>
      <button
        className={clsx(
          s.dropdownBtn,
          className,
          s.title_navigationSelect,
          show ? s.active : '',
        )}
        id={uniqId}
        type="button"
        onClick={onClick}
        onBlur={onBlur}
      >
        <img className={s.changeSiteIcon_img_value} src={src} alt="icon" width="22" height="22" />
        {!selectActProject ? (value || pledgeArr?.map((e: any) => (e.active ? `${e.title}, ` : ''))) : !projectTitleAct ? value : projectTitleAct}
      </button>
      <Container
        className={clsx(s.container_navigationSelect, show ? s.visible : '')}
      >
        <ul
          className={
            clsx(s.list_container, s.list_container_status)
          }
        >
          {navigationArr?.map((item: any, index: number) => (
            <Link to={item.path} key={String(index).concat('navigationArr')}>
              <li
                className={clsx(
                  s.list,
                  navigationSelect ? s.list_navigationSelect : '',
                )}
                key={String(item.id).concat('navigation')}
                id={item.id}
                onClick={(e: React.MouseEvent) => handleClickNav(e)}
              >
                {item.title}
              </li>
            </Link>
          ))}
        </ul>
      </Container>
    </div>
  )
}
