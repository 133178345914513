import React, { ButtonHTMLAttributes, FC } from 'react'
import clsx from 'clsx'
import arrow from './img/icon-arrow-down.svg'
import s from './ToggleButton.module.css'

interface ToggleButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  src?: string
  rotate: boolean
  onClick?: any| (() => void)
  iconWidth?: string
  iconHeight?: string
}

export const ToggleButton: FC<ToggleButtonProps> = props => {
  const {
    className,
    src = arrow,
    rotate,
    onClick,
    iconWidth = '25px',
    iconHeight = '25px',
    ...rest
  } = props
  return (
    <button
      className={clsx(s.toggle_btn, className)}
      type="button"
      onClick={onClick}
      {...rest}
    >
      <img
        src={src}
        alt="arrow"
        width={iconWidth}
        height={iconHeight}
        style={
          rotate
            ? { transform: 'rotate(0deg)', transition: 'linear 0.2s' }
            : { transform: 'rotate(180deg)', transition: 'linear 0.2s' }
        }
      />
    </button>
  )
}
