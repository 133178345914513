import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useMutation, useQuery } from '@apollo/client'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  changeSelect,
  addUsers,
  changeSingleSelect,
  resetObj,
  closeOnlySelect,
  statusPDF,
} from '../../redux/slices/selectSlice'
import { closePageAct } from '../../redux/slices/pageSlice'
import { Upload } from '../Upload'
import {
  Container,
  Field,
  Label,
  Panel,
  Select,
  DataPicker,
  ProgressBar,
  Title,
  CloseIcon,
  Button,
  Loader,
  NavProjects,
} from '../../ui'
// import { arr3, dateReplacements, periodArr } from './data'
import {
  CREATE_DOC,
  CREATE_ACT,
  CREATE_REPORT,
} from '../../apollo/mutation/createDoc'
import { ALL_PROJECTS } from '../../apollo/query/allProjects'
import { ALL_BROCKERS } from '../../apollo/query/allBrockers'
import { ALL_CLIENTS, CLIENTS } from '../../apollo/query/allClients'
import { ALL_CONTRACTS, ALL_ACTS } from '../../apollo/query/allContracts'
import { NativeSelect } from '../../ui/nativeSelect'
import iconDraft from './icon-draft.svg'
import iconSent from './icon-sent.svg'
import s from './CreateAct.module.css'
import { addAct, resetAct, statusPDFAct } from '../../redux/slices/actSlice'
import {
  addReport,
  resetReport,
  statusPDFReport,
} from '../../redux/slices/reportSlice'
import { PaymentTable } from '../PaymentTable/PaymentTable'

export const CreateAct = () => {
  const dispatch = useAppDispatch()
  const { act, statusFilePDFAct } = useAppSelector(state => state.actSlice)
  const [actName, setActName] = useState('')
  const [searchValueClientActContracts, setSearchValueClientActContracts] = useState('')
  const [showInputActContracts, setShowInputActContracts] = useState(false)
  const [showSelectClientsActContracts, setShowSelectClientsActContracts] = useState(false)
  const [contractsArr, setContractsArr] = useState<any>([])
  const [draft, setDraft] = useState(false)
  const { data: allClients, refetch } = useQuery(ALL_CLIENTS)
  const [searchValueClientAct, setSearchValueClientAct] = useState('')
  const [showInputAct, setShowInputAct] = useState(false)
  const [showSelectClientsAct, setShowSelectClientsAct] = useState(false)
  const [selectProject, setSelectedProjects] = useState([])
  const [showSelectAct, setShowSelectAct] = useState(false)
  const [sum, setSum] = useState('') // значение из поля "Сумма вложения"
  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [senting, setSenting] = useState(false)
  const [sumFieldAct, setSumFieldAct] = useState(0) // сумма полей (6)
  // const valuesAct = Object.values(act).map(elem => (elem !== '' ? 16.66 : 0)) // проверка объекта на его значения
  const valuesAct = Object.values(act).map(elem => (elem !== '' ? 14.285 : 0)) // проверка объекта на его значения
  // const valuesAct = Object.values(act).map(elem => (elem !== '' ? 12.5 : 0)) // проверка объекта на его значения

  const actNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActName(e.target.value)
    dispatch(addAct({ project: 'number', item: e.target.value })) // debounce?
  }
  const [sendAct, { data: dataAct, loading: loadingAct, error: errorAct }] = useMutation(CREATE_ACT, {
    refetchQueries: [
      {
        query: ALL_ACTS,
        variables: {
          sort: {
            field: 'date',
            direction: 'asc',
          },
          filter: {
            // поменять когда переделают на возможность передавать массив
            projectUUID: undefined,
          },
        },
      },
      {
        query: CLIENTS,
      },
    ],
  })
  const {
    loading,
    data: allContracts,
    refetch: refetchAllContracts,
  } = useQuery(ALL_CONTRACTS, {
    variables: {
      sort: {
        field: 'signedOn',
        direction: 'asc',
      },
      filter: {
        projectsUUID: [],
      },
    },
  })

  const handlerActContract = () => {
    setShowSelectClientsActContracts(!showSelectClientsActContracts)
    setShowInputActContracts(!showInputActContracts)
  }

  // поисковик контрактов
  const searchContractHandler = (e: any) => {
    setContractsArr([
      ...allContracts.contracts.filter((el: any) => el.number.includes(e.target.value.trim())),
    ])
  }

  const handlerAct = () => {
    setShowSelectClientsAct(!showSelectClientsAct)
    setShowInputAct(!showInputAct)
  }

  // передаю номер uuid Клиента для формирования объекта
  const dispatchActUUIDClient = (UUIDClient: string) => {
    dispatch(addAct({ project: 'clientUUID', item: UUIDClient }))
  }

  const selectActHandler = () => {
    setShowSelectAct(!showSelectAct)
  }

  // передаю номер uuid Проекта для формирования объекта
  const dispatchActUUIDProject = (UUIDProject: string) => {
    dispatch(addAct({ project: 'projectUUID', item: UUIDProject }))
  }

  // передаю номер uuid Проекта для формирования объекта
  const dispatchActUUIDContract = (contractNumber: string) => {
    dispatch(addAct({ project: 'contractNumber', item: contractNumber }))
  }

  // страница Акта
  const sumHandlerAct = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sum2 = e.target.value
      .split('')
      .map(i => i.trim())
      .filter(i => i !== '')
      .join('')
    setSum(e.target.value)
    dispatch(addAct({ project: 'sum', item: String(sum2) }))
  }

  const { data: allProjects } = useQuery(ALL_PROJECTS, {
    variables: {
      filter: {
        // status: 'published',
      },
    },
  })

  useEffect(() => {
    setSumFieldAct(valuesAct.reduce((a: any, b: any) => a + b))
    // setSumField(100)
  }, [act]) // сложение значений объекта

  // промежуточный массив для контрактов
  useEffect(() => {
    if (!allContracts) return
    setContractsArr([...allContracts.contracts])
  }, [allContracts])

  // делаем через useEffect, а то будет передаваться пустой массив
  useEffect(() => {
    if (!allProjects) return
    setSelectedProjects(
      allProjects.projects.map((e: any) => ({ ...e, active: false })),
    )
  }, [allProjects])

  const hidePage = () => {
    dispatch(closePageAct())
    dispatch(resetAct())
    dispatch(statusPDF(null))
    dispatch(statusPDFAct(null))
    dispatch(statusPDFReport(null))
  }
  // сохранение черновика договора
  const handleSaveDraftAct = () => {
    setDraft(true)
    // gql mutation
    sendAct({
      variables: {
        data: {
          ...act,
          status: 'draft',
          // file: 'null',
        },
      },
    })
    // setIsContract('')
    // setSum('')
    // setAmount('')
    // setPercent('')
    // dispatch(resetObj())
    dispatch(resetAct())
    if (!loadingAct && errorAct === undefined) {
      setTimeout(() => {
        setDraft(false)
        hidePage()
        // window.location.reload()
      }, 2000)
    } else {
      setTimeout(() => {
        setDraft(false)
      }, 2000)
    }
  }
  // сохранение и публикация договора
  const handleSaveSentAct = () => {
    setSenting(true)
    // gql mutation
    sendAct({
      variables: {
        data: {
          ...act,
          // file: 'null',
          status: 'publish',
        },
      },
    })
    if (!loadingAct || errorAct === undefined) {
      setTimeout(() => {
        hidePage()
        window.location.reload()
      }, 2000)
    } else {
      setTimeout(() => {
        setSenting(true)
      })
    }
  }

  const draftSaved = (
    <>
      <img src={iconDraft} alt="draft" width="17" height="17" />
      <span style={{ marginLeft: '7px' }}>Черновик сохранен</span>
    </>
  )

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: '7px' }}>Сохранено</span>
    </>
  )
  return (
    <Container className={s.container}>
      <CloseIcon onClick={hidePage} />
      <Title className={s.title} title="Добавить документ" />
      <div className={s.wrapper}>
        <div className={s.first__block}>
          <Panel padding className={s.panel}>
            <Title className={s.act_title} title="Информация" />
            <div className={s.act_firstLine}>
              <Label title="Номер документа">
                <Field
                  view="default"
                  placeholder="Номер документа"
                  value={actName}
                  onChange={e => actNameHandler(e)}
                />
              </Label>
              <Label title="Номер договора">
                <Select
                  refetch={refetchAllContracts}
                  setSearchValueClientActContracts={
                    setSearchValueClientActContracts
                  }
                  searchValueClientActContracts={searchValueClientActContracts}
                  setShowInputActContracts={setShowInputActContracts}
                  showInputActContracts={showInputActContracts}
                  setShowSelectClientsActContracts={
                    setShowSelectClientsActContracts
                  }
                  showSelectClientsActContracts={showSelectClientsActContracts}
                  // contracts={allContracts?.contracts}
                  contracts={contractsArr}
                  show={showSelectClientsActContracts}
                  contract
                  onClick={handlerActContract}
                  draft={draft}
                  searchContractHandler={searchContractHandler}
                  dispatchActUUIDContract={dispatchActUUIDContract}
                />
              </Label>
            </div>
            <div className={s.act_secondLine}>
              <Label title="Клиент">
                <Select
                  refetch={refetch}
                  setSearchValueClientAct={setSearchValueClientAct}
                  searchValueClientAct={searchValueClientAct}
                  setShowInputAct={setShowInputAct}
                  showInputAct={showInputAct}
                  setShowSelectClientsAct={setShowSelectClientsAct}
                  showSelectClientsAct={showSelectClientsAct}
                  user={allClients?.clients}
                  show={showSelectClientsAct}
                  act
                  onClick={handlerAct}
                  draft={draft}
                  dispatchActUUIDClient={dispatchActUUIDClient}
                />
              </Label>
              <Label title="Проект">
                <NativeSelect
                  tags
                  pledgeArr={selectProject}
                  selectActProject
                  draft={draft}
                  setShowSelectAct={setShowSelectAct}
                  show={showSelectAct}
                  onClick={() => selectActHandler()}
                  dispatchActUUIDProject={dispatchActUUIDProject}
                />
              </Label>
            </div>
            <div className={s.act_thirdLine}>
              <Label title="Сумма">
                <Field
                  view="default"
                  placeholder="Сумма"
                  value={
                    sum
                      ? `${Number(sum.replace(/\D/g, '')).toLocaleString('ru')}`
                      : sum
                  }
                  currency={!!sum}
                  caretPos={390 - sum.length * 7.8}
                  onChange={e => sumHandlerAct(e)}
                />
              </Label>
              <Label
                title="Дата подписания"
                onClick={() => dispatch(closeOnlySelect())}
              >
                <DataPicker
                  className={s.data__picker}
                  startDate={startDate}
                  onChange={date => {
                    setStartDate(date)
                    if (date) {
                      dispatch(
                        addAct({
                          project: 'date',
                          item: date.toISOString(),
                        }),
                      )
                    }
                  }}
                />
              </Label>
            </div>
          </Panel>
          <Upload
            title="Загрузить файл"
            titleStatus="Акт"
            text="Формат файла: .pdf"
            application="application/pdf"
            subText1="Загрузить с компьютера"
            subText2="или перетащите сюда"
            status={statusFilePDFAct}
            uploadId="pdf"
          />
          <Panel className={s.btns__panel}>
            <Button
              onClick={handleSaveDraftAct}
              // disabled={Math.ceil(sumFieldAct) !== 100}
              disabled={Math.ceil(sumFieldAct) < 80}
              className={clsx(s.btns__panel_draft)}
              theme="outline"
            >
              {!draft ? (
                'Сохранить как черновик'
              ) : loadingAct ? (
                <Loader />
              ) : !loadingAct && errorAct ? (
                'Ошибка'
              ) : (
                draftSaved
              )}
            </Button>
            <Button
              onClick={handleSaveSentAct}
              disabled={Math.ceil(sumFieldAct) !== 100}
              className={s.btns__panel_save}
              theme="dark"
            >
              {!senting ? (
                'Сохранить и опубликовать'
              ) : loadingAct ? (
                <Loader />
              ) : !loadingAct && errorAct ? (
                'Ошибка'
              ) : (
                sentSaved
              )}
            </Button>
          </Panel>
        </div>
        <Panel className={s.panel__progress} padding>
          <ProgressBar value={Math.ceil(sumFieldAct)} />
        </Panel>
      </div>
    </Container>
  )
}
