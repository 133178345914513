import { gql } from '@apollo/client'

export const CHAT_JOIN = gql`
  subscription ChatJoin($token: String!) {
    chatChannelJoined(token: $token) {
      id
      title {
        investor {
          lastName
          firstName
          middleName
          photo
          profile {
            uuid
            login
            role
          }
        }
        broker {
          name
          photo
          profile {
            uuid
            login
            role
          }
        }
        superuser {
          name
          photo
          profile {
            uuid
            login
            role
          }
        }
      }
      lastMessage {
        text
        createdAt
        status
        messageType
        fileName
        profile {
          uuid
        }
      }
      countUnread
      members {
        client {
          firstName
          lastName
          photo
        }
        broker {
          photo
          name
        }
        lawyer {
          photo
          name
        }
        manager {
          photo
          name
        }
        superuser {
          photo
          name
        }
      }
    }
  }
`
