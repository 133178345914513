import React, {
  useState,
  useRef,
  useEffect,
  FC,
} from 'react'
import { PopUp } from '../PopUp/PopUp'
import s from './SliderGallery.module.css'

interface SliderGallery {
  width: number
  project?: any
  arrGallery?: any
  clientWidth: number
}

export const SliderGallery: FC<SliderGallery> = ({
  width,
  project,
  arrGallery,
  // isTurnDevice,
  clientWidth,
}) => {
  const slider = useRef(null)
  const [position, setPosition] = useState(0)
  const [slideWidth] = useState(16.98)
  const [slideWidthMediumScreen] = useState(32.422)
  const [slideWidthSmallScreen] = useState(32.9427083333333)
  const [slideWidthExtraSmallScreen] = useState(89.096)
  const [counterPage, setCounterPage] = useState(1)
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const minSwipeDistance = 50
  const [currentSlide, setCurrentSlide] = useState(0)
  const [picturePerSlide, setPicturePerSlide] = useState(width > 1024 ? 5 : width > 376 ? 3 : 1)
  const [isOpen, setIsOpen] = useState(false)
  const sliderRef = slider as React.RefObject<any>

  useEffect(() => {
    setPosition(0)
    setCounterPage(1)
    // if (isTurnDevice) {
    //   // eslint-disable-next-line
    //   slider.current.childNodes.forEach((el: any) => (el.style = `transform: translateX(${0}px`))
    // }
  }, [width])

  useEffect(() => {
    // setPicturePerSlide(clientWidth > 1024 ? 5 : clientWidth > 376 ? 3 : 1)
    setPicturePerSlide(5)
  }, [width])

  const onTouchStart = (e: any) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    // eslint-disable-next-line
    if (isLeftSwipe || isRightSwipe) isLeftSwipe ? nextHandler() : prevHandler()
  }

  const nextHandler = () => {
    if (clientWidth > 1024) {
      if (arrGallery.length < 5) return
      if (position === -(slideWidth * (arrGallery.length - 5))) {
        setCounterPage(Math.ceil(arrGallery.length / 5))
        return
      }
      setCounterPage(counterPage + 1)
      // eslint-disable-next-line
      counterPage + 1 === Math.ceil(arrGallery.length / 5)
        // ? setPosition((position -= (arrGallery.length - 5 * counterPage) * slideWidth))
        // : setPosition((position -= 5 * slideWidth))
        ? setPosition((position - (arrGallery.length - 5 * counterPage) * slideWidth))
        : setPosition((position - 5 * slideWidth))

      let temp = 0

      // eslint-disable-next-line
        counterPage + 1 === Math.ceil(arrGallery.length / 5) 
        ? temp = position - (arrGallery.length - 5 * counterPage) * slideWidth
        : temp = position - 5 * slideWidth
      // eslint-disable-next-line
      // slider.current.childNodes.forEach((el) => (el.style = `transform: translateX(${position}vw)`))
      // eslint-disable-next-line
      // slider.current.childNodes.forEach((el: any) => (el.style = `transform: translateX(${temp}vw)`))
      if (sliderRef.current && sliderRef.current.childNodes) {
        sliderRef.current.childNodes.forEach((el: any) => {
          el.style.transform = `translateX(${temp}vw)`
        })
      }
    }
  }

  const prevHandler = () => {
    if (clientWidth > 1024) {
      if (position === 0) {
        setCounterPage(1)
        return
      }
      if (counterPage === 1) {
        setPosition(0)
      }
      let temp = 0
      if (counterPage === Math.ceil(arrGallery.length / 5)) {
        // setPosition((position = position + (arrGallery.length % 5 === 0 ? 5 : arrGallery.length % 5) * slideWidth))
        setPosition((position + (arrGallery.length % 5 === 0 ? 5 : arrGallery.length % 5) * slideWidth))
        setCounterPage(counterPage - 1)
        // let temp = 0
        temp = (position + (arrGallery.length % 5 === 0 ? 5 : arrGallery.length % 5) * slideWidth)
      } else {
        // setPosition((position = position + 5 * slideWidth))
        setPosition((position + 5 * slideWidth))
        setCounterPage(counterPage - 1)
        // let temp = 0
        temp = position + 5 * slideWidth
      }
      //  // eslint-disable-next-line
      // slider.current.childNodes.forEach((el) => (el.style = `transform: translateX(${position}vw)`))
      // eslint-disable-next-line
      //  slider.current.childNodes.forEach((el: any) => (el.style = `transform: translateX(${temp}vw)`))
      if (sliderRef.current && sliderRef.current.childNodes) {
        sliderRef.current.childNodes.forEach((el: any) => {
          el.style.transform = `translateX(${temp}vw)`
        })
      }
    }
  }

  const openPopUp = (e: any) => {
    setIsOpen(!isOpen)
    setCurrentSlide(e.target.id)
  }

  return (
    <>
      {/* {isGallery && ( */}
      {true && (
        <div className={s.slider} ref={slider} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
          {arrGallery.map((el: any, index: any) => (
            <div className={s.slider__image__wrapper} key={`arrGallery_${index}`}>
              <div
                id={index}
                className={s.slider__image}
                key={`arrGallery_${index}`}
                onClick={index => openPopUp(index)}
                style={{
                  backgroundImage: `url('${el}')`,
                }}
              >
                &nbsp;
              </div>
            </div>
          ))}
        </div>
      )}

      <div className={s.navigation}>
        <div className={s.navigation_wrapper}>
          <div className={s.navigation_prevBtn} onClick={prevHandler}>
            <div className={s.navigation_prevBtn_image}>
              &nbsp;
            </div>
          </div>
          <div className={s.navigation__values}>
            <span>{counterPage}</span>
            {/* <span id="goToForm">/</span> */}
            <span>/</span>
            <span>{Math.ceil(arrGallery.length / picturePerSlide)}</span>
          </div>
          <div className={s.navigation_nextBtn} onClick={nextHandler}>
            <div className={s.navigation_nextBtn_image}>
                &nbsp;
            </div>
          </div>
        </div>
      </div>

      <PopUp openPopUp={openPopUp} data={arrGallery} currentSlide={currentSlide} arrLength={arrGallery.length} isOpen={isOpen} />
    </>
  )
}
