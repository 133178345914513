import React from 'react'
import clsx from 'clsx'
import {
  Field, Label, Panel, Textarea, Title,
} from '../../ui'
import { UploadStories } from '../../components/UploadStories'
import infoIcon from './img/info.svg'
import addIcon from './img/add-icon.svg'
import deleteIcon from './img/delete-icon.svg'
import s from './StoriesSlide.module.css'

// interface temporaryProps {
//   addSlide:
//   slide:
//   changeField:
//   deleteItemSlide:
//   colorsSlide:
//   changeColorSlide:
// }

export const StoriesSlide: React.FC<any> = ({
  addSlide,
  slide,
  changeField,
  deleteItemSlide,
  colorsSlide,
  changeColorSlide,
  indexSlide,
}) => (
  <Panel padding>
    <div className={s.blockTitle}>
      <Title title={`Слайд ${slide.titleSlide}`} />
      <div className={s.titleInfoBlock}>
        <img src={infoIcon} alt="infoIcon" />
        <p className={s.titleInfo}>
          Заполните необходимые поля или загрузите готовое изображение
        </p>
      </div>
    </div>
    <div className={s.blockInput}>
      <div className={s.inputRadioBlock}>
        <p className={s.inputRadioTitle}>Расположение заголовка и текста:</p>
        <div className={s.radio_btn_wrapper}>
          <Label className={s.radioBtnBlock}>
            <input
              className={s.radioItem}
              id={`upper + ${slide.id}`}
              type="radio"
              value="сверху"
              checked={slide.positionTitle === 'сверху'}
              onChange={e => changeField(e, slide.id, 'positionTitle')}
            />
            <label htmlFor={`upper + ${slide.id}`}>Сверху</label>
          </Label>
          <Label className={s.radioBtnBlock}>
            <input
              className={s.radioItem}
              id={`down + ${slide.id}`}
              type="radio"
              value="снизу"
              checked={slide.positionTitle === 'снизу'}
              onChange={e => changeField(e, slide.id, 'positionTitle')}
            />
            <label htmlFor={`down + ${slide.id}`}>
              Снизу
            </label>
          </Label>
        </div>
      </div>
      <Label>
        Заголовок
        <Field
          maxLength={35}
          className={s.title}
          view="default"
          value={slide.title}
          onChange={e => changeField(e, slide.id, 'title')}
        />
      </Label>
      <Label>
        Текст
        <Textarea
          className={s.description}
          value={slide.text}
          onChange={e => changeField(e, slide.id, 'text')}
          rows={5}
          maxLength={250}
        />
      </Label>
      <div className={s.blockInputBtn}>
        <Label>
          Текст кнопки
          <Field
            maxLength={25}
            placeholder="25 символов максимум включая пробелы"
            view="default"
            value={slide.textBtn}
            onChange={e => changeField(e, slide.id, 'textBtn')}
          />
        </Label>
        <Label>
          Ссылка кнопки
          <Field
            placeholder="https://"
            view="default"
            value={slide.linkBtn}
            onChange={e => changeField(e, slide.id, 'linkBtn')}
          />
        </Label>
      </div>
    </div>
    <h2 className={s.chooseTitle}>Загрузить изображение</h2>
    <div style={{ display: 'flex' }}>
      <div style={{ flexBasis: '392px', marginRight: '107px' }}>
        <UploadStories
          style={{ padding: '0px' }}
          // className={}
          selectedFile={indexSlide === slide.id}
          slideId={slide.id}
          // title="Загрузить файл"
          titleStatus="Файл"
          text="Максимальный размер фото 8 Мб. Формат: png. Размер: 430 х 932 px"
          application=".png"
          subText1="Загрузить с компьютера"
          subText2="или перетащите сюда"
          // status={statusFilePDF}
          uploadId={slide.titleSlide}
          classNameSubText={s.btnSubText}
        />
      </div>
      <div style={{ flexBasis: '50%', marginBottom: '18px' }}>
        <div style={{ marginBottom: '10px' }}>
          <p className={s.colorTitle}>Или задайте цвет фона</p>
          <p className={s.colorSubTitle}>
            Убедитесь, что текст хорошо читается
          </p>
        </div>
        <div>
          <div>
            {slide?.colors?.map((elem: any, i: number) => (
              <div key={i} className={s.blockColors}>
                {elem.defaultColors?.map((color: any) => (
                  <div
                    key={color.id}
                    className={
                      color.active ? s.colorItemActive : s.blockColorItem
                    }
                    onClick={() => changeColorSlide(
                      color.id,
                      slide.id,
                      color.color,
                      'defaultColors',
                      i,
                    )}
                  >
                    <div
                      className={clsx(s.colorItem)}
                      style={{ backgroundColor: color.color }}
                    />
                  </div>
                ))}
                {elem.gradientColors?.map((color: any) => (
                  <div
                    key={color.id}
                    className={
                      color.active ? s.colorItemActive : s.blockColorItem
                    }
                    onClick={() => changeColorSlide(
                      color.id,
                      slide.id,
                      color.color,
                      'gradientColors',
                      i,
                    )}
                  >
                    <div
                      className={clsx(s.colorItem)}
                      style={{ background: color.color }}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className={s.slideBlockBtn}>
      <div className={s.slideBtnItem} onClick={addSlide}>
        <img src={addIcon} alt="addIcon" />
        <p className={s.titleInfo}>Добавить слайд</p>
      </div>
      <div
        className={s.slideBtnItemDel}
        onClick={() => deleteItemSlide(slide.id)}
      >
        <img src={deleteIcon} alt="deleteIcon" />
        <p className={s.titleInfo}>Удалить</p>
      </div>
    </div>
  </Panel>
)
