/* eslint-disable guard-for-in */
import React from 'react'
import * as tus from 'tus-js-client'
import clsx from 'clsx'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { addUsers, statusXML, statusPDF } from '../../redux/slices/selectSlice'
import { addAct, resetAct, statusPDFAct } from '../../redux/slices/actSlice'
import { addReport, statusPDFReport } from '../../redux/slices/reportSlice'
import { Panel, InputFile, Loader } from '../../ui'
import iconSuccess from './success.svg'
import s from './Upload.module.css'

interface UploadProps {
  application?: string
  title: string
  text: string
  subText1: string
  subText2: string
  status?: string | null
  titleStatus?: string
  uploadId?: string
  fileUrl?: string
}

export const Upload: React.FC<UploadProps> = ({
  application,
  title,
  text,
  subText1,
  subText2,
  status = '',
  titleStatus = '',
  uploadId,
  fileUrl,
}) => {
  const dispatch = useAppDispatch()
  const { information, statusFileXML, statusFilePDF } = useAppSelector(
    state => state.selectSlice,
  )
  const { act } = useAppSelector(state => state.actSlice)
  const { report } = useAppSelector(state => state.reportSlice)

  const upLoad = (e: any, titleStatus: string): void => {
    const file = e.target.files

    for (const item in file) {
      if (typeof file[item] === 'object') {
        const fileType = file[item].name.slice(-4)

        const upload = new tus.Upload(file[item], {
          endpoint: process.env.REACT_APP_API_URL,
          retryDelays: [100, 300, 500, 1000, 3000],
          metadata: {
            filename: file[item].name,
            filetype: file[item].type,
          },
          onProgress(bytesUploaded: number, bytesTotal: number) {
            if (fileType === '.csv') {
              dispatch(statusXML('pending'))
            } else if (fileType === '.pdf') {
              dispatch(statusPDF('pending'))
              dispatch(statusPDFAct('pending'))
            }
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
            console.log(bytesUploaded, bytesTotal, `${percentage}%`)
          },
          onSuccess() {
            if (fileType === '.csv' && titleStatus.includes('График')) {
              dispatch(
                addUsers({
                  project: 'calendarFileName',
                  item: upload.url?.replace(
                    `${process.env.REACT_APP_API_URL}`,
                    '',
                  ),
                }),
              )
              dispatch(statusXML('success'))
            } else if (fileType === '.pdf' && titleStatus.includes('Файл')) {
              dispatch(
                addUsers({
                  project: 'contractFileName',
                  item: upload.url?.replace(
                    `${process.env.REACT_APP_API_URL}`,
                    '',
                  ),
                }),
              )
              dispatch(statusPDF('success'))
            } else if (fileType === '.pdf' && titleStatus.includes('Акт')) {
              // dispatch(
              //   addAct({
              //     project: 'file',
              //     // item: upload.url?.replace(
              //     //   `${process.env.REACT_APP_API_URL}`,
              //     //   '',
              //     // ),
              //     item: upload.url,
              //   }),
              // )
              dispatch(
                addAct({
                  project: 'file',
                  item: upload.url?.replace(
                    `${process.env.REACT_APP_API_URL}`,
                    '',
                  ),
                  // item: upload.url,
                }),
              )
              dispatch(statusPDFAct('success'))
            } else if (fileType === '.pdf' && titleStatus.includes('Отчет')) {
              // dispatch(
              //   addReport({
              //     project: 'file',
              //     // item: upload.url?.replace(
              //     //   `${process.env.REACT_APP_API_URL}`,
              //     //   '',
              //     // ),
              //     item: upload.url,
              //   }),
              // )
              dispatch(
                addReport({
                  project: 'file',
                  item: upload.url?.replace(
                    `${process.env.REACT_APP_API_URL}`,
                    '',
                  ),
                }),
              )
              dispatch(statusPDFReport('success'))
            }
            console.log(
              'Download %s from %s',
              upload.url?.replace(`${process.env.REACT_APP_API_URL}`, ''),
              file[item].name.slice(-4),
            )
          },
          onError(error: any) {
            if (fileType === '.csv') {
              dispatch(statusXML('error'))
            } else if (fileType === '.pdf') {
              dispatch(statusPDF('error'))
            }
            console.log(`Failed because: ${error}`)
          },
        })
        // Check if there are any previous uploads to continue.
        upload.findPreviousUploads().then((previousUploads: string | any[]) => {
          // Found previous uploads so we select the first one.
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0])
          }
          // Start the upload
          upload.start()
        })
      }
    }
  }

  if (
    status === 'success'
    || (fileUrl !== ''
      && fileUrl !== undefined
      && fileUrl !== `${process.env.REACT_APP_API_URL}`)
  ) {
    return (
      <Panel className={s.upload} padding>
        <p className={s.title}>{title}</p>
        <p className={s.description}>{text}</p>
        <Panel className={s.upload__file_success}>
          <p className={s.title__success}>
            {/* {titleStatus} */}
            Файл &nbsp;
            {titleStatus === 'Файл' && (
              <span>
                Договор №
                {information.number}
              </span>
            )}
            {titleStatus === 'Акт' && (
              <span>
                Акт №
                {act.number}
              </span>
            )}
            {titleStatus === 'Отчет' && (
              <span>
                Отчет №
                {report.name}
              </span>
            )}
            <br />
            успешно загружен
          </p>
          <div className={s.img__success}>
            <img
              src={iconSuccess}
              alt="iconSuccess"
              width="33px"
              height="33px"
            />
          </div>
          <InputFile
            className={s.wrapper__succes}
            classNameSpan={s.span__success}
            uploadId={uploadId}
            onChange={e => upLoad(e, titleStatus)}
            application={application}
            subText1="Загрузить другой файл"
          />
        </Panel>
      </Panel>
    )
  }

  if (status === 'pending') {
    return (
      <Panel className={s.upload} padding>
        <Panel className={clsx(s.upload__file_success, s.upload__file_pending)}>
          <Loader />
        </Panel>
      </Panel>
    )
  }

  if (status === 'error') {
    return (
      <Panel className={s.upload} padding>
        <Panel className={clsx(s.upload__file_success, s.upload__file_error)}>
          <p>Ошибка попробуйте позже</p>
          <InputFile
            uploadId={uploadId}
            className={s.wrapper__succes}
            classNameSpan={s.span__success}
            onChange={e => upLoad(e, titleStatus)}
            application={application}
            subText1="Загрузить другой файл"
          />
        </Panel>
      </Panel>
    )
  }

  return (
    <Panel className={s.upload} padding>
      <p className={s.title}>{title}</p>
      <p className={s.description}>{text}</p>
      <Panel className={s.upload__file}>
        <InputFile
          uploadId={uploadId}
          onChange={e => upLoad(e, titleStatus)}
          application={application}
          subText1={subText1}
          subText2={subText2}
        />
      </Panel>
    </Panel>
  )
}
