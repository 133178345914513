import { FC } from 'react'
import s from './Banner.module.css'
import { Section } from '../Section/Section'

interface Banner {
  project?: any
  logo?: string
  bannerApp?: string
  bannerSite?: string
  background?: string
}

export const Banner: FC<Banner> = ({
  project,
  logo,
  bannerApp,
  bannerSite,
  background,
}) => (
  <>
    <Section className={s.section__margin}>
      <div className={s.back__wrapper}>
        <div className={s.back__img}>&nbsp;</div>
        <div className={s.back__link}>
          Назад
        </div>
      </div>
      <div className={s.banner__wholeWrapper}>
        <div
          className={s.banner__wrapper}
          style={{
            backgroundImage: `url('${bannerSite}')`,
          }}
        >
          <div
            className={s.projectSlider__link__project__logo}
            style={{
              WebkitMaskImage: `url('${logo}')`,
              maskImage: `url('${logo}')`,
            }}
          >
              &nbsp;
          </div>
        </div>
      </div>
    </Section>
  </>
)
