/*eslint-disable*/
import React, { useState } from "react";
import clsx from "clsx";
import { Label, Panel, Textarea } from "../../ui";
import trash from "./img/icon-trash.svg";
import drag from "./img/drag.svg";
import s from "./StageItem.module.css";

interface StageItemType {
  item?: any;
  dragStartHandler?: (e: React.DragEvent<HTMLDivElement>, item: any) => void;
  dragLeaveHandler?: (e: React.DragEvent<HTMLDivElement>) => void;
  dragEndHandler?: (e: React.DragEvent<HTMLDivElement>) => void;
  dragOverHandler?: (e: React.DragEvent<HTMLDivElement>) => void;
  dropHandler?: (e: React.DragEvent<HTMLDivElement>, item: any) => void;
  deleteItem?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  changeStageTitle?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  changeStageTitleEn?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  changeStageDescription?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  changeStageDescriptionEn?: (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  remove?: boolean;
  id?: string;
}

export const StageItem: React.FC<StageItemType> = ({
  item,
  dragStartHandler,
  dragLeaveHandler,
  dragEndHandler,
  dragOverHandler,
  dropHandler,
  changeStageTitle,
  changeStageTitleEn,
  changeStageDescription,
  changeStageDescriptionEn,
  remove,
  id,
  deleteItem,
}) => {
  const [show, setShow] = useState(false);
  return (
    <div
      className={s.wrapper}
      draggable={!show}
      onDragStart={(e: React.DragEvent<HTMLDivElement>) =>
        dragStartHandler?.(e, item)
      }
      onDragLeave={(e: React.DragEvent<HTMLDivElement>) =>
        dragLeaveHandler?.(e)
      }
      onDragEnd={(e: React.DragEvent<HTMLDivElement>) => dragEndHandler?.(e)}
      onDragOver={(e: React.DragEvent<HTMLDivElement>) => dragOverHandler?.(e)}
      onDrop={(e: React.DragEvent<HTMLDivElement>) => dropHandler?.(e, item)}
    >
      <div className={remove ? s.stage : s.stage__conditions} id={id}>
        <img
          className={s.stage__drag}
          src={drag}
          alt="sent"
          width="9"
          height="15"
        />
        <div className={s.stage__number}>
          {item.order < 10 ? "0" : ""}
          {item.order}
        </div>
        <div className={s.stage__innerWrapper}>
          <div className={s.stage__text}>{item.title}</div>
          <button
            className={clsx(s.dropdownBtn, show ? s.active : "")}
            type="button"
            onClick={() => setShow(!show)}
          >
            &nbsp;
          </button>
        </div>
      </div>
      {show && (
        <Panel className={remove ? s.form__panel : s.form__panel_conditions}>
          <Label title="Заголовок">
            <div className={s.input__wrapper}>
              <Textarea
                className={s.input}
                value={item.title}
                id={id}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  changeStageTitle?.(event)
                }
                rows={3}
                // maxLength={350}
              />
              <Textarea
                className={s.input}
                value={item.titleEn}
                id={id}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  changeStageTitleEn?.(event)
                }
                rows={3}
                eng
                // maxLength={350}
              />
            </div>
          </Label>
          <Label title="Описание" className={s.form__subtitle}>
            <div className={s.input__wrapper}>
              <Textarea
                className={s.input}
                id={id}
                value={item.description}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  changeStageDescription?.(event)
                }
                rows={5}
                // maxLength={350}
              />
              <Textarea
                className={s.input}
                id={id}
                value={item.descriptionEn}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  changeStageDescriptionEn?.(event)
                }
                rows={5}
                eng
                // maxLength={350}
              />
            </div>
          </Label>
        </Panel>
      )}
      {remove && (
        <button
          className={s.remove__button}
          type="button"
          onClick={(e) => deleteItem?.(e)}
        >
          <img src={trash} alt="sent" width="20" height="19" id={id} />
        </button>
      )}
    </div>
  );
};
