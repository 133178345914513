import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  changeColorDesign,
  changeFirstFieldDynamic,
  changeStatusSelectDynamicPromotion,
  editChangeColorDesign,
  editChangeFieldDynamic,
  resetPromotionDynamicObj,
} from '../../redux/slices/promotionDynamicSlice'
import { resetPromotionObj } from '../../redux/slices/promotionSlice'
import {
  Button,
  Loader,
  Panel,
  ProgressBar,
  StoriesInfo,
  Title,
  ToggleButton,
} from '../../ui'
import { PromotionDynamicDesign } from '../../ui/PromotionDynamicDesign'
import { PROMOTION_DYNAMICS } from '../../apollo/query/promotion/dynamics'
import { UPDATE_DYNAMIC_PROMOTION } from '../../apollo/mutation/promotion/promotionUpdate'
import fullFill from './img/icon-fillfull.svg'
import empty from './img/icon-empty.svg'
import arrow from './img/icon-arrow-up.svg'
import s from './EditDynamicPromotion.module.css'

interface EditDynamicPromotioProps {
  uuid?: string
}

export const EditDynamicPromotion: React.FC<EditDynamicPromotioProps> = ({
  uuid,
}) => {
  const navigate = useNavigate()

  const { promotionDynamicObj, colorsDynamicMiniature, statusSelect } = useAppSelector(state => state.promotionDynamicSlice)
  const dispatch = useAppDispatch()

  const {
    data: dataDynamic,
    loading: loadingDynamic,
    error: errorDynamic,
  } = useQuery(PROMOTION_DYNAMICS, {
    variables: {
      filter: {
        uuid,
      },
    },
  })
  const [updateDynamicPromotion, { data, loading, error }] = useMutation(
    UPDATE_DYNAMIC_PROMOTION,
    {
      refetchQueries: [
        {
          query: PROMOTION_DYNAMICS,
        },
        {
          query: PROMOTION_DYNAMICS,
          variables: {
            filter: {
              uuid,
            },
          },
        },
        {
          query: PROMOTION_DYNAMICS,
          variables: {
            filter: {
              status: 'active',
            },
          },
        },
        {
          query: PROMOTION_DYNAMICS,
          variables: {
            filter: {
              status: 'draft',
            },
          },
        },
        {
          query: PROMOTION_DYNAMICS,
          variables: {
            filter: {
              status: 'archive',
            },
          },
        },
      ],
    }
  )

  const [isLoading, setIsLoading] = useState(true)

  const [sumField, setSumField] = useState(0)
  const {
    color,
    image,
    header,
    text,
    gradientColor,
    gradientDeg,
    gradientLocation,
    __typename,
    ...promDynamicObj
  } = promotionDynamicObj

  const arr = Object.values(promDynamicObj)

  useEffect(() => {
    setSumField(arr.map(e => (e ? 20 : 0)).reduce((a: any, b: any) => a + b))
  }, [promotionDynamicObj])

  const [collapse, setCollapse] = useState(false)

  useEffect(() => {
    if (dataDynamic?.promotionDynamics[0]) {
      for (const key in dataDynamic?.promotionDynamics[0]) {
        if (key !== 'uuid') {
          if (key === 'color') {
            dispatch(
              editChangeColorDesign(
                dataDynamic?.promotionDynamics[0][key].includes('linear')
                  ? {
                    index: 1,
                    nameGroup: 'gradientColors',
                    color: dataDynamic?.promotionDynamics[0][key],
                  }
                  : {
                    index: 0,
                    nameGroup: 'defaultColors',
                    color: dataDynamic?.promotionDynamics[0][key],
                  }
              )
            )
          }
          dispatch(
            editChangeFieldDynamic({
              nameField: key,
              event: dataDynamic?.promotionDynamics[0][key],
            })
          )
        }
      }
      setIsLoading(false)
    }
  }, [dataDynamic])

  if (isLoading) {
    return (
      <div className={s.loading}>
        <Loader />
      </div>
    )
  }

  return (
    <div>
      <div className={s.wrapper}>
        <StoriesInfo
          promotion="dynamic"
          statusSelect={statusSelect}
          name={promotionDynamicObj.name}
          status={promotionDynamicObj.status}
          startDate={new Date(Date.parse(promotionDynamicObj?.startedAt))}
          endDate={new Date(Date.parse(promotionDynamicObj?.endedAt))}
          changeField={(e: any, nameField: string) => dispatch(changeFirstFieldDynamic({ event: e, nameField }))}
          changeStatus={() => dispatch(changeStatusSelectDynamicPromotion())}
        />
        <PromotionDynamicDesign
          title={promotionDynamicObj.header}
          text={promotionDynamicObj.text}
          changeField={(e: any, nameField: string) => dispatch(changeFirstFieldDynamic({ event: e, nameField }))}
          colorsDesign={colorsDynamicMiniature}
          changeColorDesign={(
            colorId: number,
            color: string,
            nameGroup: string,
            index: number
          ) => dispatch(
            changeColorDesign({
              colorId,
              color,
              nameGroup,
              index,
            })
          )}
        />
        <Button
          disabled={
            Math.ceil(
              sumField + (image || color || header || text ? 20 : 0)
            ) !== 100
          }
          theme={
            Math.ceil(
              sumField + (image || color || header || text ? 20 : 0)
            ) === 100
              ? 'dark'
              : 'secondary'
          }
          onClick={() => {
            const { status, __typename, ...dynamicObj } = promotionDynamicObj
            updateDynamicPromotion({
              variables: {
                uuid,
                data: {
                  ...dynamicObj,
                  status:
                    status === 'Активный'
                      ? 'active'
                      : status === 'Черновик'
                        ? 'draft'
                        : 'archive',
                },
              },
            })
            setTimeout(() => {
              navigate('/promotion', { replace: true })
              dispatch(resetPromotionObj())
              dispatch(resetPromotionDynamicObj())
            }, 1500)
          }}
        >
          Сохранить
        </Button>
      </div>
      <div className={s.panel__progress}>
        <Panel padding>
          <ProgressBar
            className={s.project_create}
            value={Math.ceil(
              sumField + (image || color || header || text ? 20 : 0)
            )}
          />
          <div className={s.project_indicator}>
            <img
              src={
                promotionDynamicObj.endedAt
                && promotionDynamicObj.startedAt
                && promotionDynamicObj.name
                && promotionDynamicObj.status
                  ? fullFill
                  : empty
              }
              alt="empty"
            />
            <p>Информация</p>
          </div>
          <div className={s.project_indicator}>
            <img
              src={header || text || color || image ? fullFill : empty}
              alt="empty"
            />
            <p>Дизайн</p>
          </div>
        </Panel>
        <Panel
          padding
          className={clsx(s.promotion_select, {
            [s.promotion_select_active]: collapse,
          })}
        >
          <div className={s.promotion_select_header}>
            <Title className={s.dynamic_title} title="Превью" />
            <ToggleButton
              className={s.promotion_select_btn}
              src={arrow}
              rotate={collapse}
              iconWidth="18px"
              iconHeight="18px"
              onClick={() => setCollapse(!collapse)}
            />
          </div>
          <Panel
            className={s.promotion_item_dynamic}
            style={{ background: `${color}` }}
            padding
          >
            <div
              className={s.dynamic_info}
              style={color ? { color: '#fff' } : { color: '#000' }}
            >
              <p className={s.dynamic_header}>{promotionDynamicObj.header}</p>
              <p className={s.dynamic_text}>{promotionDynamicObj.text}</p>
            </div>
            {image && (
              <div className={s.dynamic_logo}>
                <img
                  src={image}
                  alt={image ? 'logo' : ''}
                  width="80"
                  height="80"
                />
              </div>
            )}
          </Panel>
        </Panel>
      </div>
    </div>
  )
}
