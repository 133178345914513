import React, {
  ButtonHTMLAttributes,
  FC,
  MouseEventHandler,
  ReactNode,
} from 'react'
import clsx from 'clsx'
import { Panel } from '../Panel'
import s from './ActionButton.module.css'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  className?: string
  src?: string
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
}

export const ActionButton: FC<ButtonProps> = ({
  className,
  src,
  children,
  onClick,
  ...rest
}) => (
  <Panel className={s.action__panel}>
    <button
      className={clsx(s.action, className)}
      type="button"
      onClick={onClick}
      {...rest}
    >
      <img src={src} alt="" width="70" height="70" />
      <p>{children}</p>
    </button>
  </Panel>
)
