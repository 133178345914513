/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UsersType {
	projectUUID: string
	clientUUID: string
	brokerUUID: string
	number: string
	investSum: string
	withdrawalCount: string | number
	pcYearly: string | number
	withdrawalDay: string | number
	signedOn: string
	expireOn: string
	calendarFileName: string
	contractFileName: string
	currency: string
	taxStatus: boolean
	paymentSchedule: string
}

interface selectSlice {
	selNum: number | null
	information: UsersType
	singleSelect: boolean
	statusFileXML: string | null
	statusFilePDF: string | null
}

const initialState: selectSlice = {
  selNum: null, // какой select выбран
  information: {
    number: '',
    projectUUID: '',
    clientUUID: '',
    brokerUUID: '',
    investSum: '',
    withdrawalCount: '',
    pcYearly: '',
    withdrawalDay: '',
    signedOn: new Date().toISOString(),
    expireOn: '',
    contractFileName: '',
    calendarFileName: '',
    currency: '',
    taxStatus: true,
    paymentSchedule: '',
  },
  singleSelect: false,
  statusFileXML: null,
  statusFilePDF: null,
}

export const selectSlice = createSlice({
  name: 'select',
  initialState,
  reducers: {
    changeSelect(state, action: PayloadAction<number | null>) {
      if (state.selNum === action.payload) {
        state.selNum = null
      } else {
        state.selNum = action.payload
      }
    },
    addUsers(state, action) {
      state.information = {
        ...state.information,
        [action.payload.project]: action.payload.item,
      }
      // console.log(state.information)
    },
    changeSingleSelect(state) {
      state.singleSelect = !state.singleSelect
    },
    resetObj(state) {
      state.information = {
        number: '',
        projectUUID: '',
        clientUUID: '',
        brokerUUID: '',
        investSum: '',
        withdrawalCount: '',
        pcYearly: '',
        withdrawalDay: '',
        signedOn: new Date().toISOString(),
        expireOn: '',
        contractFileName: '',
        calendarFileName: '',
        currency: '',
        taxStatus: true,
        paymentSchedule: '',
      }
      state.selNum = null
      state.statusFileXML = null
      state.statusFilePDF = null
    },
    closeSelect(state) {
      state.selNum = null
      state.statusFileXML = null
      state.statusFilePDF = null
    },
    closeOnlySelect(state) {
      state.selNum = null
    },
    statusXML(state, action) {
      state.statusFileXML = action.payload
    },
    statusPDF(state, action) {
      state.statusFilePDF = action.payload
    },
  },
})

export const {
  changeSelect,
  addUsers,
  changeSingleSelect,
  resetObj,
  closeSelect,
  statusXML,
  statusPDF,
  closeOnlySelect,
} = selectSlice.actions

export default selectSlice.reducer
