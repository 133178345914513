import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { Panel } from '../Panel'
import s from './PromotionsDropdown.module.css'

interface PromotionsDropdownProps {
  children?: ReactNode
  className?: string
}

export const PromotionsDropdown = ({
  children,
  className,
}: PromotionsDropdownProps) => (
  <div className={clsx(s.dropdown, className)}>
    <Panel className={s.dropdown_panel}>
      {children}
    </Panel>
  </div>
)
