import { gql } from '@apollo/client'

export const GET_ALL_FAQ = gql`
  query allDataFaq {
    allDataFaq {
      id
      uuid
      title
      titleEn
      decription
      descriptionEn
      orderNum
    }
  }
`
