import type { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import {
  Customers,
  Documents,
  Home,
  Login,
  Projects,
  Promotion,
  NotFound,
  EditDocument,
  EditClientPage,
  EditProject,
  EditPromotion,
  EditSite,
} from './pages'

import { RequireAuth } from './features/RequireAuth/RequireAuth'

const withAuth = (jsx: ReactNode) => <RequireAuth children={jsx} />

export const routes = [
  { path: '/', element: <Navigate to="login" replace /> },
  { path: '/home', element: withAuth(<Home />) },
  { path: '/documents', element: withAuth(<Documents />) },
  { path: '/documents/edit/:id', element: withAuth(<EditDocument />) },
  { path: '/projects/edit/:id', element: withAuth(<EditProject />) },
  { path: '/login', element: <Login /> },
  { path: '/clients', element: withAuth(<Customers />) },
  { path: '/clients/edit/:id', element: withAuth(<EditClientPage />) },
  { path: '/projects', element: withAuth(<Projects />) },
  { path: '/promotion', element: withAuth(<Promotion />) },
  { path: '/promotion/edit/:slug/:id', element: withAuth(<EditPromotion />) },
  { path: '/editsite', element: withAuth(<EditSite />) },
  { path: '/editsite/edit/:id', element: withAuth(<EditSite />) },
  { path: '/*', element: <NotFound /> },
]
