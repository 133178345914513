/* eslint-disable */

import React, { useRef, useState } from "react";
import clsx from "clsx";
import s from "./Field.module.css";

interface viewType {
  search: string;
  default: string;
}

const views: viewType = {
  search: s.containerSearch,
  default: s.containerDefault,
};

interface InputProps {
  type?: string;
  placeholder?: string | any;
  maxLength?: number;
  icon?: boolean;
  currency?: boolean;
  view?: keyof typeof views;
  // onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, id?: any) => void;
  onClick?: (event: React.MouseEvent) => void;
  value?: any;
  [attr: string]: any;
  eye?: boolean;
  error?: boolean;
  className?: string;
  caretPos?: number;
  id?: any;
  telegram?: boolean;
  remove?: boolean;
  percent?: boolean;
  mln?: boolean;
  mld?: boolean;
  eng?: boolean;
}

export const Field: React.FC<InputProps> = ({
  type = "text",
  placeholder,
  placeholderColor = "red",
  icon,
  view,
  onChange,
  onClick,
  value,
  error,
  eye,
  className,
  currency = false,
  caretPos,
  maxLength = 16,
  id,
  telegram,
  remove,
  percent,
  mln,
  mld,
  eng,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isShow, setIsShow] = useState(false);

  const showEye = (): void => {
    if (ref.current?.getAttribute("type") === "password") {
      ref?.current?.setAttribute("type", "text");
      setIsShow(true);
    } else {
      ref?.current?.setAttribute("type", "password");
      setIsShow(false);
    }
  };

  return (
    <div className={clsx(s.container, view ? views[view] : null, className)}>
      <input
        ref={eye ? ref : null}
        className={
          !telegram
            ? clsx(
                s.input,
                placeholderColor === "grey" ? s.input_grey : "",
                view ? s.inputAnother : "",
                eng ? s.inputEng : "",
                error ? s.error : ""
              )
            : clsx(
                s.input,
                s.telegram,
                view ? s.inputAnother : "",
                error ? s.error : ""
              )
        }
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
        id={id}
        {...rest}
      />
      {currency && (
        <span className={s.currency} style={{ right: `${caretPos}px` }}>
          ₽
        </span>
      )}
      {percent && (
        <span
          className={s.currency}
          style={{ right: `${caretPos}px`, top: "25px" }}
        >
          %
        </span>
      )}
      {mln && (
        <span
          className={s.currency}
          style={{ right: `${caretPos}px`, top: "25px" }}
        >
          млн ₽
        </span>
      )}
      {mld && (
        <span
          className={s.currency}
          style={{ right: `${caretPos}px`, top: "25px" }}
        >
          млрд ₽
        </span>
      )}
      {icon && <div className={s.icon} />}
      {eye && (
        <p
          onClick={showEye}
          className={clsx(s.showPassword, isShow ? s.hidePassword : "")}
        />
      )}
      {remove && (
        <button className={s.trash} onClick={onClick} type="button">
          {}
        </button>
      )}
      {eng && <span className={s.engIcon} />}
    </div>
  );
};
