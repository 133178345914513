import React, { FC } from 'react'
// import { useQuery } from "@apollo/client";
import { Section } from '../../Section/Section'
// import { GET_MEDIAENTERPRNEUR } from "../../../apolo/query/query_business";
import s from './TitileSection.module.css'

interface TitleSection {
  clientWidth: number
}

export const TitleSection: FC<TitleSection> = ({ clientWidth }) => (
  <Section className={s.section_wrapper}>
    <div className={s.firstLine}>
      <h2 className={s.title}>Ваш бизнес</h2>
      {/* <p className={s.title_text}>{data?.mediaEntrepreneur?.coverTitle}</p> */}
    </div>
    {clientWidth > 375 && (
      <div className={s.secondLine}>
        <h2 className={s.title}>в наших руках</h2>
      </div>
    )}
    {clientWidth < 376 && (
      <>
        <div className={s.secondLine}>
          <h2 className={s.title}>в наших</h2>
        </div>
        <div className={s.thirdLine}>
          <h2 className={s.title}>руках</h2>
        </div>
      </>
    )}
  </Section>
)
