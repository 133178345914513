import { ButtonHTMLAttributes, FC } from 'react'
import clsx from 'clsx'
import cross from './icon-cross.svg'
import s from './CloseIcon.module.css'

interface IconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  width?: number
  height?: number
}

export const CloseIcon: FC<IconProps> = props => {
  const {
    className, width = 20, height = 20, ...rest
  } = props

  return (
    <button className={clsx(s.cross, className)} type="button" {...rest}>
      <img src={cross} alt="cross" width={width} height={height} />
    </button>
  )
}
