import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { useAppDispatch } from '../../redux/hook'
import { DELETE_INVTERVIEW, DELETE_MEDIAREPORT } from '../../apollo/mutation/editSite/mediaPageUpdate'
import { GET_ALL_INTERVIEWS, GET_ALL_MEDIAREPORTS } from '../../apollo/query/editSite/mediaPage'
import {
  Title,
  Container,
  Panel,
  Label,
  Field,
  Textarea,
  Button,
  Loader,
  AddButton,
  Edit,
  Modal,
} from '../../ui'
import s from './VideoItem.module.css'
import { changeIsCreateBroker, changeNav } from '../../redux/slices/editSiteSlice'

interface VideoFrameProps {
	item?: any
  index?: number
  videoReport?: boolean
}

export const VideoItem: React.FC<VideoFrameProps> = ({ item, index, videoReport }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isModal, setIsModal] = useState(false)
  const [active, setActive] = useState(false)

  const [deleteInterview, { data: deleteInterviewData }] = useMutation(DELETE_INVTERVIEW, {
    refetchQueries: [
      {
        query: GET_ALL_INTERVIEWS,
      },
    ],
  })

  const [deleteMediaReport, { data: deleteReportData }] = useMutation(DELETE_MEDIAREPORT, {
    refetchQueries: [
      {
        query: GET_ALL_MEDIAREPORTS,
      },
    ],
  })

  const removeHandler = () => {
    setIsModal(!isModal)
  }

  const getStringAfterEqualSigns = (url: string) => {
    const equalSignsCount = url.split('=').length - 1
    if (equalSignsCount === 1) {
      return url.split('=')[1]
    }
    if (equalSignsCount === 2) {
      const parts = url.split('=')
      const parts1 = parts[1].split('&')
      return parts1[0]
    }
    if (equalSignsCount === 3) {
      const parts = url.split('=')
      const parts2 = parts[1].split('&')
      return parts2[0]
    }
    return ''
  }

  return (
    <div>
      <div className={s.grid_item_wrapper} key={item.uuid.concat(index)}>
        <div className={s.grid_item} key={item.uuid} id={item.uuid}>
          <Edit
            className={s.grid_item_edit}
            active={active}
            onClick={() => setActive(!active)}
            onEditClick={() => {
              dispatch(changeNav(videoReport ? 8 : 6))
              navigate(`/editsite/edit/${item.uuid}`)
            }}
            removeDoc={removeHandler}
          />
          <div className={s.grid_item_published}>
            <iframe
              className={s.grid_item_iframe}
              width="260"
              height="190"
              src={`https://www.youtube.com/embed/${getStringAfterEqualSigns(item.video)}?start=1.5&controls=0`}
              allowFullScreen
              title={item?.title}
            >
              1
            </iframe>
          </div>
        </div>
        {isModal && (
          <Modal
            contractNumber={item?.title}
            background="rgb(250 250 250 / 0.3)"
            question="Вы действительно хотите удалить видео"
          >
            {!videoReport && (
              <Button
                className={(s.table__btn)}
                theme="dark"
                onClick={(() => {
                  deleteInterview({
                    variables: {
                    // uuid: `${contractId}`,
                      uuid: item.uuid,
                    },
                  })
                  setIsModal(false)
                })}
              >
                Удалить
              </Button>
            )}
            {videoReport && (
              <Button
                className={(s.table__btn)}
                theme="dark"
                onClick={(() => {
                  deleteMediaReport({
                    variables: {
                    // uuid: `${contractId}`,
                      uuid: item.uuid,
                    },
                  })
                  setIsModal(false)
                })}
              >
                Удалить
              </Button>
            )}
            <Button
              className={s.table__btn}
              theme="outline"
              onClick={() => {
              // dispatch(hideModal())
              // setContractId('')
                setIsModal(false)
              }}
            >
              Отмена
            </Button>
          </Modal>
        )}
      </div>
      <span className={s.grid_item_caption}>{item.title}</span>
    </div>
  )
}
