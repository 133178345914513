import React, { useState, useEffect, FC } from 'react'
import close from '../../../assets/close_icon.svg'
import s from './TeamPopup.module.css'

interface TeamPopup {
  width: number
  setIsOpenPopup?: (arg: any) => void
  useData?: any
  popUpTeam?: any
  setPreviewToggler?: (arg: any) => void
  broker?: any
}

export const TeamPopup: FC<TeamPopup> = ({
  useData, width, setIsOpenPopup, popUpTeam, setPreviewToggler, broker,
}) => {
  const [checkedNumbers, setChecedNumbers] = useState(true)
  const [checkedBiografy, setChecedBiografy] = useState(false)
  const [currentSlider, setCurrenrSlider] = useState(0)
  const [isClose, setIsClose] = useState(false)
  // Разблокировка скрола.
  // useEffect(() => {
  //   isClose && (document.body.style.overflow = 'unset')
  // }, [isClose])

  // разблокировка и закртие

  const popUpCloseHandler = () => {
    setIsClose(true)
    document.body.style.overflow = 'unset'
    setIsOpenPopup?.(false)
  }

  const isChecedNumbers = () => {
    setChecedNumbers(true)
    setChecedBiografy(false)
  }
  const isChecedBiografy = () => {
    setChecedNumbers(false)
    setChecedBiografy(true)
  }

  const goForward = () => {
    let tmp = currentSlider
    if (tmp === 10) return
    tmp += 1
    setCurrenrSlider(tmp)
  }

  const goBack = () => {
    let tmp = currentSlider
    if (tmp === 0) return
    tmp -= 1
    setCurrenrSlider(tmp)
  }

  if (isClose) return <div />

  return (
    <div className={s.wrapper} onClick={e => e.stopPropagation()}>
      <button className={s.close} type="button" onClick={() => setPreviewToggler?.(false)}>
        <img src={close} alt="" />
      </button>
      <div className={s.team__popup}>
        <div className={s.team__sliders}>
          <div className={s.team__slide} key={broker?.uuid}>
            <div className={s.popup__img_wrapper}>
              <div
                className={s.popup__img}
                style={{
                  backgroundImage: `url('${broker?.photo}')`,
                }}
              >
                <div className={s.popup_description}>
                  <p className={s.popup__title}>
                    {broker?.personRole}
                  </p>
                  <hr className={s.popup__hr} />
                  <p className={s.popup__name}>
                    {broker?.firstname}
                  </p>
                </div>
              </div>
            </div>
            <div className={s.popup__info}>
              <button
                type="button"
                className={
                  checkedNumbers
                    ? s.popup__numbers_checked
                    : s.popup__numbers_unchecked
                }
                onClick={() => isChecedNumbers()}
              >
                Цифры
              </button>
              <span> / </span>
              <button
                type="button"
                className={
                  checkedBiografy
                    ? s.popup__biografy_checked
                    : s.popup__biografy_unchecked
                }
                onClick={() => isChecedBiografy()}
              >
                Биография
              </button>
              <div className={s.popup__info_texts}>
                <div
                  className={
                    checkedNumbers
                      ? s.popup__numbers_block_checked
                      : s.popup__numbers_block_unchecked
                  }
                >
                  <div className={s.popup_numbers__flex_box}>
                    {/* {popUpTeam[currentSlider]?.numbers.map((elem: any, index: number) => ( */}
                    {broker?.numbers.map((elem: any, index: number) => (
                      <div
                        className={s.popup__numbers_block}
                        key={elem?.uuid?.concat(String(index))}
                      >
                        <div
                          style={{ display: 'flex' }}
                          className={s.popup__numbers_big_element_wrapper}
                          key={'popup__numbers_big_element_wrapper'.concat(
                            String(index),
                          )}
                        >
                          <p className={s.popup__numbers_big_element}>
                            {elem?.value}
                          </p>
                          {elem?.type !== 'Ед. изм.' && (
                            <p className={s.popup__numbers_big_element_type}>
                              {elem?.type}
                            </p>
                          )}
                        </div>
                        <p className={s.popup__numbers_small_element}>
                          {elem?.description}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className={
                    checkedBiografy
                      ? s.popup__biografy_block_checked
                      : s.popup__biografy_block_unchecked
                  }
                >
                  <div className={s.popup__biografy_block}>
                    <p className={s.popup__biografy_title}>
                      {broker?.slogan}
                    </p>
                    <div className={s.outer_biografy_wrapper}>
                      <div className={s.inner_biografy_wrapper}>
                        {broker?.bio
                          ?.split('\n')
                          .map((elem: any, index: number) => (
                            <p
                              className={s.popup__biografy_text}
                              key={'popup__biografy_text'.concat(String(index))}
                            >
                              {elem}
                            </p>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={s.slider__navigation}>
                <div
                  className={s.slider__navigation_goback_arrow}
                // onClick={() => goBack()}
                >
                &nbsp;
                </div>
                <p>
                  {/* {currentSlider + 1} */}
                  1
                  /
                  {/* {useData.length} */}
                  1
                </p>
                <div
                  className={s.slider__navigation_goforward_arrow}
                // onClick={() => goBack()}
                >
                &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
