/* eslint-disable react/jsx-curly-newline */
import { useState } from 'react'
import clsx from 'clsx'
import { useAppDispatch } from '../../redux/hook'
import { useGetRole } from '../../hooks/useGetRole'
import { openChatMessageList } from '../../redux/slices/pageSlice'
import { ChatMessageItem } from '../ChatMessageItem'
import { ChatMessageItemGroup } from '../ChatMessageItemGroup'
import s from './ChatMessagesActive.module.css'

interface ChatMessagesActiveProps {
  className?: string
  chatUsersJoind?: any[]
}

export const ChatMessagesActive = ({
  className,
  chatUsersJoind,
}: ChatMessagesActiveProps) => {
  const [enabled, setEnabled] = useState<number | null>(null)

  const dispatch = useAppDispatch()

  const changeBackGroundColorHandler = (index: number) => {
    if (enabled === index) {
      setEnabled(null)
    } else {
      setEnabled(index)
    }
  }

  return (
    <div className={clsx(s.messages__wrapper, className)}>
      {chatUsersJoind?.map((elem: any, index: number) => (
        <div
          key={index}
          style={{
            background: enabled === index ? '#D3DFFF' : '#fafafa',
          }}
          onClick={() => {
            changeBackGroundColorHandler(index)
            dispatch(
              openChatMessageList({
                id: elem?.id,
                title:
                  (elem?.title?.investor?.firstName &&
                    `${elem?.title?.investor?.firstName} ${elem?.title?.investor?.lastName}`) ||
                  elem?.title?.superuser?.name
                    ?.split(' ')
                    .splice(0, 2)
                    .reverse()
                    .join(' ') ||
                  elem?.title?.broker?.name
                    ?.split(' ')
                    .splice(0, 2)
                    .reverse()
                    .join(' '),
                photo:
                  elem?.title?.investor?.photo ||
                  elem?.title?.superuser?.photo ||
                  elem?.title?.broker?.photo,
                members: elem?.members?.length,
                brocker: elem?.members?.length > 2 && {
                  title: elem?.title?.broker?.name
                    ?.split(' ')
                    .splice(0, 2)
                    .reverse()
                    .join(' '),
                  photo: elem?.title?.broker?.photo,
                },
              })
            )
          }}
        >
          {elem?.members?.length >= 3 ? (
            <ChatMessageItemGroup
              src={elem?.title?.superuser?.photo || elem?.title?.broker?.photo}
              name={
                elem?.title?.superuser?.name
                  ?.split(' ')
                  .splice(0, 2)
                  .reverse()
                  .join(' ') ||
                elem?.title?.broker?.name
                  ?.split(' ')
                  .splice(0, 2)
                  .reverse()
                  .join(' ')
              }
              srcInvestor={elem?.title?.investor?.photo}
              firstName={elem?.title?.investor?.firstName}
              middleName={elem?.title?.investor?.middleName}
              lastName={elem?.title?.investor?.lastName}
              createdAt={elem?.lastMessage?.createdAt}
              statusIcon={elem?.lastMessage?.status}
            />
          ) : (
            <ChatMessageItem
              src={
                elem?.title?.investor?.photo ||
                elem?.title?.superuser?.photo ||
                elem?.title?.broker?.photo
              }
              firstName={
                elem?.title?.investor?.firstName ||
                elem?.title?.superuser?.name
                  ?.split(' ')
                  .splice(0, 2)
                  .reverse()
                  .join(' ') ||
                elem?.title?.broker?.name
                  ?.split(' ')
                  .splice(0, 2)
                  .reverse()
                  .join(' ')
              }
              lastName={elem?.title?.investor?.lastName}
              name={elem?.title?.broker?.name || elem?.title?.superuser?.name}
              countUnread={elem?.countUnread}
              createdAt={elem?.lastMessage?.createdAt}
              text={elem?.lastMessage?.text}
              statusIcon={elem?.lastMessage?.status}
              role={elem?.lastMessage?.profile?.role}
              messageType={elem?.lastMessage?.messageType}
              fileName={elem?.lastMessage?.fileName}
            />
          )}
        </div>
      ))}
    </div>
  )
}
