import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import {
  GET_ALL_INTERVIEWS,
  GET_ALL_MEDIAREPORTS,
  GET_ALL_OUTLETS,
  GET_ALL_COMMENTS,
} from '../../apollo/query/editSite/mediaPage'
import { VideoItem } from '../VideoItem/VideoItem'
import { MediaItem } from '../MediaItem/MediaItem'
import { useAppDispatch } from '../../redux/hook'
import {
  Title,
  Container,
  Panel,
  AddButton,
} from '../../ui'
import preview from './img/icon-eye.png'
import iconSent from './img/icon-sent.svg'
import iconGallery from './img/icon-gallery.svg'
import s from './EditMediaPage.module.css'
import { changeNav } from '../../redux/slices/editSiteSlice'

export const EdiMediaPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [senting, setSenting] = useState(false)
  const [obj, setObj] = useState<any>({})
  const { data: dataInterviews } = useQuery(GET_ALL_INTERVIEWS)
  const { data: dataMediaReports } = useQuery(GET_ALL_MEDIAREPORTS)
  const { data: dataOutlets } = useQuery(GET_ALL_OUTLETS)
  const { data: dataComments } = useQuery(GET_ALL_COMMENTS)

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: '7px' }}>Сохранено</span>
    </>
  )
  return (
    <Container className={s.container}>
      {/* <CloseIcon onClick={closePage} /> */}
      <Title title="Страница Медиа" />
      <div className={s.wrapper}>
        <div className={s.wrapper_section}>
          <Panel className={s.info__panel_team} padding>
            <div className={s.title__panel_wrapper}>
              <Title className={s.title__panel} title="Интервью" />
              <AddButton className={s.addButton} onClick={() => dispatch(changeNav(5))}>
                Добавить видео
              </AddButton>
            </div>
            <div className={s.grid_container}>
              {dataInterviews?.mediaInterviews?.map((item: any, index: number) => (
                <VideoItem key={index} item={item} index={index} />
              ))}
            </div>
          </Panel>
        </div>
        <div className={s.wrapper_section}>
          <Panel className={s.info__panel_team} padding>
            <div className={s.title__panel_wrapper}>
              <Title className={s.title__panel} title="Статьи" />
              <AddButton className={s.addButton} onClick={() => dispatch(changeNav(9))}>
                Добавить статью
              </AddButton>
            </div>
            <div className={s.grid_container}>
              {dataOutlets?.mediaOutlets?.map((item: any, index: number) => (
                <MediaItem key={index} item={item} index={index} />
              ))}
            </div>
          </Panel>
        </div>
        <div className={s.wrapper_section}>
          <Panel className={s.info__panel_team} padding>
            <div className={s.title__panel_wrapper}>
              <Title className={s.title__panel} title="Контент с мероприятий" />
              <AddButton className={s.addButton} onClick={() => dispatch(changeNav(7))}>
                Добавить видео
              </AddButton>
            </div>
            <div className={s.grid_container}>
              {dataMediaReports?.mediaReports?.map((item: any, index: number) => (
                <VideoItem key={index} item={item} index={index} videoReport />
              ))}
            </div>
          </Panel>
        </div>
        <div className={s.wrapper_section}>
          <Panel className={s.info__panel_team} padding>
            <div className={s.title__panel_wrapper}>
              <Title className={s.title__panel} title="Отзывы" />
              <AddButton className={s.addButton} onClick={() => dispatch(changeNav(10))}>
                Добавить отзыв
              </AddButton>
            </div>
            <div className={s.grid_container}>
              {dataComments?.mediaReviewsText?.map((item: any, index: number) => (
                <MediaItem key={index} item={item} index={index} avatar />
              ))}
            </div>
          </Panel>
        </div>
        {/* <Panel className={s.btns__panel}>
          <Button
            onClick={() => console.log('click')}
            disabled
            className={s.btns__panel_preview}
            icon
            src={preview}
          >
            Предпросмотр
          </Button>

          <Button
            onClick={handleSaveSent}
            // disabled={Math.ceil(sumFieldReport) !== 100}
            className={s.btns__panel_save}
            theme="dark"
          >
            {!senting ? (
              'Сохранить и опубликовать'
            ) : loadingUpdateMainPage ? (
              <Loader />
            ) : !loadingUpdateMainPage && errorUpdateMainPage ? (
              'Ошибка'
            ) : (
              sentSaved
            )}
          </Button>
        </Panel> */}
      </div>
    </Container>
  )
}
