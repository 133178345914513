import { gql } from '@apollo/client'

// мутация на изменение статуса сторис
export const PROMOTION_STORY_CHANGE_STATUS = gql`
  mutation ChangeStoryStatus($uuid: String!, $data: StoryDataUpdate!) {
    admStoryUpdate(uuid: $uuid, data: $data) {
      uuid
      status
    }
  }
`
// мутация на удаление сторис
export const PROMOTION_STORY_DELETE = gql`
  mutation DeleteStory($uuid: String!) {
    admStoryDelete(uuid: $uuid)
  }
`
// мутация на изменение статуса динамической акции
export const PROMOTION_DYNAMIC_CHANGE_STATUS = gql`
  mutation ChangeDynamicStatus(
    $uuid: String!
    $data: PromotionDynamicDataUpdate
  ) {
    admPromotionDynamicUpdate(uuid: $uuid, data: $data) {
      uuid
      status
    }
  }
`
// мутация на удаление динамической акции
export const PROMOTION_DYNAMIC_DELETE = gql`
  mutation DeleteDynamic($uuid: String!) {
    admPromotionDynamicDelete(uuid: $uuid)
  }
`

// мутация на обновление данных в stories
export const UPDATE_STORIES_PROMOTION = gql`
  mutation admStoryUpdate($uuid: String!, $data: StoryDataUpdate!) {
    admStoryUpdate(uuid: $uuid, data: $data) {
      uuid
      name
      status
      startedAt
      endedAt
      header
      image
      color
      slides
      gradientColor
      gradientDeg
      gradientLocation
    }
  }
`

// мутация на обновление данных динамической акции
export const UPDATE_DYNAMIC_PROMOTION = gql`
  mutation admPromotionDynamicUpdate(
    $uuid: String!
    $data: PromotionDynamicDataUpdate
  ) {
    admPromotionDynamicUpdate(uuid: $uuid, data: $data) {
      uuid
      name
      status
      startedAt
      endedAt
      header
      text
      image
      color
      gradientColor
      gradientDeg
      gradientLocation
    }
  }
`
