import React, { ReactNode } from 'react'
import clsx from 'clsx'
import s from './Ring.module.css'

interface RingType {
	className?: string
  children: ReactNode
}

export const Ring: React.FC<RingType> = ({ className, children }) => (
  <div className={clsx(s.block, className)}>
    <div className={s.title}>
      {children}
    </div>
  </div>
)
