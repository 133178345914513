import { configureStore } from '@reduxjs/toolkit'
import tableSlice from './slices/tableSlice'
import selectSlice from './slices/selectSlice'
import pageSlice from './slices/pageSlice'
import authSlice from './slices/authSlice'
import projectSlice from './slices/projectSlice'
import clientSlice from './slices/clientSlice'
import promotionSlice from './slices/promotionSlice'
import promotionDynamicSlice from './slices/promotionDynamicSlice'
import actSlice from './slices/actSlice'
import reportSlice from './slices/reportSlice'
import editSiteSlice from './slices/editSiteSlice'
import chatSlice from './slices/chatSlice'

const store = configureStore({
  reducer: {
    tableSlice,
    selectSlice,
    pageSlice,
    authSlice,
    projectSlice,
    clientSlice,
    promotionSlice,
    promotionDynamicSlice,
    actSlice,
    reportSlice,
    editSiteSlice,
    chatSlice,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
