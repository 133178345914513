/* eslint-disable */

/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-mixed-spaces-and-tabs */

import { ChangeEvent, useEffect, useState } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { useAppSelector, useAppDispatch } from "../../redux/hook";
import {
  changeName,
  hideModal,
  showModal,
  chooseEditDoc,
} from "../../redux/slices/tableSlice";
import {
  openPage,
  openPageAct,
  openPageReport,
} from "../../redux/slices/pageSlice";
import {
  ALL_CONTRACTS,
  ALL_ACTS,
  ALL_REPORTS,
} from "../../apollo/query/allContracts";
import {
  DELETE_CONTRACT,
  DELETE_ACT,
  DELETE_REPORT,
} from "../../apollo/mutation/deleteContract";
import { ALL_PROJECTS } from "../../apollo/query/allProjects";
import {
  Container,
  Field,
  Modal,
  SortingInput,
  NavProjects,
  ErrorMessage,
  Loader,
} from "../../ui";
import { User } from "../../ui/User";
import { Button } from "../../ui/Button";
import { data, dataReport } from "./data";
import iconDirection from "./icons/directions.svg";
import s from "./Table.module.css";
import { ALL_BROCKERS } from "../../apollo/query/allBrockers";

export const Table = () => {
  const [arrow, setArrow] = useState(false);
  const [editActive, setEditActive] = useState<number | null>(null);
  const [contractId, setContractId] = useState("");
  const [value, setValue] = useState<string>("");
  const [navValue, setNavValue] = useState(0);
  const [actId, setActId] = useState("");
  const [reportId, setReportId] = useState("");
  const [actNameForDisplay, setActNameForDisplay] = useState("");
  const [reportNameForDisplay, setReportNameForDisplay] = useState("");
  const [directionContracts, setDirectionContracts] = useState("asc");
  const [directionActs, setDirectionActs] = useState("asc");
  const [activeProjectItem, setActiveProjectItem] = useState<any>([]);
  const [activeProjectItemAct, setActiveProjectItemAct] = useState<any>([]);
  const [activeProjectItemReport, setActiveProjectItemReport] = useState<any>(
    []
  );
  const [selectedProjectsUUID, setSelectedProjectsUUID] = useState<string[]>(
    []
  );
  const [selectedProjectsUUIDAct, setSelectedProjectsUUIDAct] = useState<
    string[]
  >([]);
  const [selectedProjectsUUIDReport, setSelectedProjectsUUIDReport] = useState<
    string[]
  >([]);
  const [selectedProjects, setSelectedProjects] = useState<any>([]);

  const { isModal, contractNumber } = useAppSelector(
    (state) => state.tableSlice
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const dataNavTitle = ["Договор", "Документ", "Отчет"];

  const { data: projectsData } = useQuery(ALL_PROJECTS);
  const [limit, setLimit] = useState<number | null>(10);
  const [filterNumberContract, setFilterNumberContract] = useState<any>(null);

  const [brokerList, setBrokerList] = useState<string[]>([]);
  const [selectedBrockerFilter, setSelectedBrockerFilter] = useState<any>({
    uuid: null,
    name: "",
  });

  const { data: allBrockers, error: errorBrocker } = useQuery(ALL_BROCKERS);

  const {
    loading,
    data: allContracts,
    error: errorAllContracts,
    fetchMore,
  } = useQuery(ALL_CONTRACTS, {
    variables: {
      sort: {
        field: "signedOn",
        direction: directionContracts,
      },
      filter: {
        projectsUUID: selectedProjectsUUID,
        brokerUUID: selectedBrockerFilter.uuid,
        // number: filterNumberContract === '' ? null : filterNumberContract,
      },
      // slice: {
      //   offset: 0,
      //   limit,
      // },
    },
  });

  useEffect(() => {
    if (!allBrockers) return;
    setBrokerList(
      allBrockers.brokers.map((el: any) => ({
        ...el,
        active: false,
        title: el.name,
      }))
    );
  }, [allBrockers]);

  const [contractsArray, setContractsArray] = useState<any[]>([]);
  useEffect(() => {
    if (allContracts?.contracts) {
      setContractsArray(allContracts?.contracts);
    } else {
      setContractsArray([]);
    }
  }, [allContracts]);

  const [maxAgregator, setMaxAgregator] = useState<any>([]);

  const handleScroll = (e: any) => {
    if (e.target.scrollTop !== 0) {
      if (
        e.target.scrollTop <
          e.target.scrollHeight - e.target.clientHeight + 10 &&
        e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight - 10
      ) {
        const maxTemp = e.target.scrollHeight - e.target.clientHeight;
        if (!maxAgregator.includes(maxTemp)) {
          setLimit(limit && limit + 10);
        }
        setMaxAgregator([
          ...maxAgregator,
          e.target.scrollHeight - e.target.clientHeight,
        ]);
      }
    }
  };

  const {
    data: allActs,
    loading: loadingAllActs,
    error: errorAllActs,
  } = useQuery(ALL_ACTS, {
    variables: {
      sort: {
        field: "date",
        direction: directionActs,
      },
      filter: {
        // projectsUUID: selectedProjectsUUID,
        // поменять когда переделают на возможность передавать массив
        projectUUID: selectedProjectsUUIDAct[0],
        brokerUUID: selectedBrockerFilter.uuid,
      },
    },
  });

  const {
    data: allReports,
    loading: loadingAllReports,
    error: errorAllReports,
  } = useQuery(ALL_REPORTS, {
    variables: {
      filter: {
        // поменять когда переделают на возможность передавать массив
        projectUUID: selectedProjectsUUIDReport[0],
      },
    },
  });

  const [isDeleteContract, { data: isDelete }] = useMutation(DELETE_CONTRACT, {
    refetchQueries: [
      {
        query: ALL_CONTRACTS,
        variables: {
          sort: {
            field: "signedOn",
            direction: "asc",
          },
          filter: {
            // поменять когда переделают на возможность передавать массив
            projectsUUID: [],
            number: null,
          },
          // slice: {
          //   offset: 0,
          //   limit,
          // },
        },
      },
    ],
  });

  const [deleteAct, { data: deleteActData }] = useMutation(DELETE_ACT, {
    refetchQueries: [
      {
        query: ALL_ACTS,
        variables: {
          sort: {
            field: "date",
            direction: "asc",
          },
          filter: {
            // поменять когда переделают на возможность передавать массив
            projectUUID: undefined,
          },
        },
      },
    ],
  });
  const [deleteReport, { data: deleteReportData }] = useMutation(
    DELETE_REPORT,
    {
      refetchQueries: [
        {
          query: ALL_REPORTS,
          variables: {
            filter: {
              // поменять когда переделают на возможность передавать массив
              projectUUID: selectedProjectsUUIDReport[0],
            },
          },
        },
      ],
    }
  );

  useEffect(() => {
    if (projectsData?.projects) {
      const tempArr = projectsData?.projects.map((elem: any) => ({
        ...elem,
        active: false,
      }));
      setActiveProjectItem([
        ...tempArr.filter((el: any) => el.projectStatusNew !== "draft"),
      ]);
      setActiveProjectItemAct([
        ...tempArr.filter((el: any) => el.projectStatusNew !== "draft"),
      ]);
      setActiveProjectItemReport([
        ...tempArr.filter((el: any) => el.projectStatusNew !== "draft"),
      ]);
    }
  }, [projectsData]);

  const click = (index: number, doc: string, uuid: string) => {
    dispatch(changeName(doc));
    setContractId(uuid);
    if (index === editActive) {
      setEditActive(null);
    } else {
      setEditActive(index);
    }
  };

  const clickAct = (index: number, name: string, uuid: string) => {
    setActId(uuid);
    setActNameForDisplay(name);
    if (index === editActive) {
      setEditActive(null);
    } else {
      setEditActive(index);
    }
  };

  const clickReport = (index: number, name: string, uuid: string) => {
    setReportNameForDisplay(name);
    setReportId(uuid);
    if (index === editActive) {
      setEditActive(null);
    } else {
      setEditActive(index);
    }
  };

  const showPage = (e: any) => {
    if (e.target.innerText === "Загрузить договор") dispatch(openPage());
    if (e.target.innerText === "Загрузить документ") dispatch(openPageAct());
    if (e.target.innerText === "Загрузить отчет") dispatch(openPageReport());
  };

  // Поиск по номеру договора, имени клиента, проекту

  let searchFiltered = contractsArray?.filter(
    (item: any) =>
      item?.number?.toLowerCase().includes(value.toLowerCase().trim()) ||
      item?.client?.firstName
        ?.toLowerCase()
        .includes(value.toLowerCase().trim()) ||
      item?.client?.middleName
        ?.toLowerCase()
        .includes(value.toLowerCase().trim()) ||
      item?.client?.lastName
        ?.toLowerCase()
        .includes(value.toLowerCase().trim()) ||
      item?.broker?.name?.toLowerCase().includes(value.toLowerCase().trim()) ||
      item?.project?.name?.toLowerCase().includes(value.toLowerCase().trim())
  );

  if (navValue === 1) {
    searchFiltered = allActs?.acts?.filter(
      (item: any) =>
        item?.number?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.client?.firstName
          ?.toLowerCase()
          .includes(value?.toLowerCase().trim()) ||
        item?.client?.middleName
          ?.toLowerCase()
          .includes(value.toLowerCase().trim()) ||
        item?.client?.lastName
          ?.toLowerCase()
          .includes(value.toLowerCase().trim()) ||
        item?.contract?.broker?.name
          .toLowerCase()
          .includes(value.toLowerCase().trim()) ||
        item?.project?.name?.toLowerCase().includes(value.toLowerCase().trim())
    );
  }

  console.log("allActs?.acts>>", allActs?.acts);

  if (navValue === 2) {
    searchFiltered = allReports?.reports.filter(
      (item: any) =>
        item?.name?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.project?.name?.toLowerCase().includes(value.toLowerCase().trim())
    );
  }

  const deleteActHandler = () => {
    deleteAct({
      variables: {
        uuid: `${actId}`,
      },
    });
    setActId("");
    dispatch(hideModal());
    // window.location.reload()
    setTimeout(() => {
      // window.location.reload()
    }, 1000);
  };

  const deleteReportHandler = () => {
    deleteReport({
      variables: {
        uuid: `${reportId}`,
      },
    });
    setReportId("");
    dispatch(hideModal());
  };

  // выбор документа при переходе в редактирование
  useEffect(() => {
    if (navValue === 0) dispatch(chooseEditDoc(0));
    if (navValue === 1) dispatch(chooseEditDoc(1));
    if (navValue === 2) dispatch(chooseEditDoc(2));
    setSelectedBrockerFilter({
      uuid: null,
      name: "",
    });
    setArrow(false);
  }, [navValue]);

  // сортировка по дате
  const handleSort = () => {
    if (navValue === 0) {
      setDirectionContracts(directionContracts === "asc" ? "desc" : "asc");
    }
    if (navValue === 1) {
      setDirectionActs(directionActs === "asc" ? "desc" : "asc");
    }
  };

  const searchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value.trim());
    setFilterNumberContract(event.target.value.trim());
    setLimit(null);
  };

  return (
    <Container className={s.container}>
      <div className={s.navWrapper}>
        <NavProjects
          className={s.promotion_type}
          setNavValue={setNavValue}
          navValue={navValue}
          dataNavTitle={dataNavTitle}
        />
        <Button
          className={s.btn_download}
          theme="icon"
          icon
          onClick={(e) => showPage(e)}
        >
          {navValue === 0
            ? "Загрузить договор"
            : navValue === 1
            ? "Загрузить документ"
            : "Загрузить отчет"}
        </Button>
      </div>
      <div className={s.navTabl}>
        <Field
          icon
          placeholder="Поиск"
          placeholderColor="grey"
          view="search"
          type="text"
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            searchHandler(event)
          }
        />
        <div className={s.navTabl__inputs}>
          {navValue === 0 && (
            <SortingInput
              arrow={arrow}
              onClick={() => setArrow(!arrow)}
              onBlur={() => setArrow(false)}
              projects={projectsData?.projects}
              activeProjectItem={activeProjectItem}
              setActiveProjectItem={setActiveProjectItem}
              setSelectedProjects={setSelectedProjects}
              setSelectedProjectsUUID={setSelectedProjectsUUID}
              isOpen={arrow}
              directionContracts={directionContracts}
              setDirectionContracts={setDirectionContracts}
              handleSort={handleSort}
              navValue={navValue}
              directionActs={directionActs}
              selectedBrockerFilter={selectedBrockerFilter}
              setSelectedBrockerFilter={setSelectedBrockerFilter}
              brokerList={brokerList}
            />
          )}
          {navValue === 1 && (
            <SortingInput
              arrow={arrow}
              onClick={() => setArrow(!arrow)}
              onBlur={() => setArrow(false)}
              projects={projectsData?.projects}
              activeProjectItem={activeProjectItemAct}
              setActiveProjectItem={setActiveProjectItemAct}
              setSelectedProjects={setSelectedProjects}
              setSelectedProjectsUUID={setSelectedProjectsUUIDAct}
              isOpen={arrow}
              directionContracts={directionContracts}
              setDirectionContracts={setDirectionContracts}
              handleSort={handleSort}
              navValue={navValue}
              directionActs={directionActs}
              selectedBrockerFilter={selectedBrockerFilter}
              setSelectedBrockerFilter={setSelectedBrockerFilter}
              brokerList={brokerList}
            />
          )}
          {navValue === 2 && (
            <SortingInput
              arrow={arrow}
              onClick={() => setArrow(!arrow)}
              onBlur={() => setArrow(false)}
              projects={projectsData?.projects}
              activeProjectItem={activeProjectItemReport}
              setActiveProjectItem={setActiveProjectItemReport}
              setSelectedProjects={setSelectedProjects}
              setSelectedProjectsUUID={setSelectedProjectsUUIDReport}
              isOpen={arrow}
              directionContracts={directionContracts}
              setDirectionContracts={setDirectionContracts}
              handleSort={handleSort}
              navValue={navValue}
              directionActs={directionActs}
              selectedBrockerFilter={selectedBrockerFilter}
              setSelectedBrockerFilter={setSelectedBrockerFilter}
              brokerList={brokerList}
            />
          )}
        </div>
      </div>
      <div className={s.table}>
        <div className={clsx(s.tableItems, s.firstString)}>
          {navValue !== 2
            ? data?.titles.map((title) => (
                <div key={title} className={s.tableTitle}>
                  {title}
                  {title === "Дата" && (navValue === 0 || navValue === 1) && (
                    <button
                      className={s.button_direction}
                      onClick={handleSort}
                      type="button"
                    >
                      <img
                        className={s.button_direction_img}
                        src={iconDirection}
                        alt="draft"
                        width="17"
                        height="17"
                      />
                    </button>
                  )}
                </div>
              ))
            : dataReport?.titles.map((title) => (
                <div key={title} className={s.tableTitle}>
                  {title}
                </div>
              ))}
        </div>
        {errorAllContracts ? (
          <div
            style={{
              position: "relative",
              left: "50%",
              transform: "translate(-20%)",
              top: "50%",
            }}
          >
            <ErrorMessage>Ошибка: {errorAllContracts?.message}</ErrorMessage>
          </div>
        ) : (
          <div className={s.table__users}>
            {searchFiltered?.map((user: any, index: number) => (
              <User
                active={editActive === index}
                editActiveHandle={
                  navValue === 0
                    ? () => click(index, user.number, user.uuid)
                    : navValue === 1
                    ? () => clickAct(index, user.number, user.uuid)
                    : () => clickReport(index, user.name, user.uuid)
                }
                onEditClick={() => navigate(`/documents/edit/${user.uuid}`)}
                key={index}
                user={user}
                act={navValue === 1}
                report={navValue === 2}
                removeDoc={() => dispatch(showModal())}
              />
            ))}
          </div>
        )}
        {loading && (
          <div className={s.loader}>
            <Loader />
          </div>
        )}
      </div>
      {isModal && (
        <Modal
          contractNumber={
            navValue === 0
              ? contractNumber
              : navValue === 1
              ? actNameForDisplay
              : reportNameForDisplay
          }
          background="rgb(250 250 250 / 0.8)"
          question={
            navValue === 0
              ? "Вы действительно хотите удалить документ Договор № "
              : navValue === 1
              ? "Вы действительно хотите удалить документ Акт № "
              : "Вы действительно хотите удалить документ Отчет № "
          }
        >
          <Button
            className={clsx(s.table__btn)}
            theme="dark"
            onClick={
              navValue === 0
                ? () => {
                    isDeleteContract({
                      variables: {
                        uuid: `${contractId}`,
                      },
                    });
                    setContractId("");
                    dispatch(hideModal());
                    setTimeout(() => {
                      // window.location.reload()
                    }, 1000);
                  }
                : navValue === 1
                ? () => deleteActHandler()
                : () => deleteReportHandler()
            }
          >
            Удалить
          </Button>
          <Button
            className={s.table__btn}
            theme="outline"
            onClick={() => {
              dispatch(hideModal());
              setContractId("");
            }}
          >
            Отмена
          </Button>
        </Modal>
      )}
    </Container>
  );
};
