import arrows from './img/icon-arrow.svg'

export const headerList = [
  {
    title: 'Фамилия',
    icon: 'arrows',
    role: 'marketer',
  },
  {
    title: 'Проект',
  },
  {
    title: 'Договор до',
    icon: 'arrows',
  },
  {
    title: 'Активные инвестиции',
    icon: 'arrows',
  },
  {
    title: 'Всего инвестиций',
    icon: 'arrows',
  },
  {
    title: 'Брокер',
    icon: 'arrows',
    role: 'marketer',
  },
]
