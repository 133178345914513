import React, { InputHTMLAttributes, useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  Title,
} from '..'
import { ToggleButton } from '../ToggleButton'
import toggle from './icon-arrow_down_grey.svg'
import sort from './icon-arrow_up&down_grey.svg'
import check from './icon-check.svg'
import s from './SortingInput.module.css'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  onClick?: () => void;
  placeholder?: string;
  src?: string;
  arrow?: boolean;
  projects?: any;
  activeProjectItem?: any;
  setActiveProjectItem?: (arg: any) => void;
  activeProject?: (arg: string) => void;
  setSelectedProjects?: (arg: any) => void;
  setSelectedProjectsUUID?: (arg: any) => void;
  isOpen?: boolean;
  directionContracts?: string;
  setDirectionContracts?: (arg: string) => void;
  handleSort?: () => void;
  navValue?: number;
  directionActs?: string;
  selectedBrockerFilter?: any
              setSelectedBrockerFilter?: any
              brokerList?: any
};

export const SortingInput = ({
  className,
  onClick,
  placeholder = 'Сортировать по',
  src = sort,
  arrow = false,
  projects,
  activeProjectItem,
  setActiveProjectItem,
  setSelectedProjectsUUID,
  setSelectedProjects,
  isOpen,
  directionContracts,
  setDirectionContracts,
  handleSort,
  navValue,
  directionActs,
  selectedBrockerFilter,
  setSelectedBrockerFilter,
  brokerList,
  ...rest
}: Props) => {
  const [collapseProjects, setCollapseProjects] = useState<boolean>(false)
  const [collapseBrockers, setCollapseBrockers] = useState(false)
  const makeCollapse = (title: any) => {
    if (title === 'Проекты') {
      setCollapseProjects(!collapseProjects)
    } else {
      setCollapseBrockers(!collapseBrockers)
    }
    // console.log('e.target.id', e.target)
    // if (e.target.id === 'Проекты') { setCollapseProjects(!collapseProjects) }
    // if (e.target.id === 'По брокеру') { setCollapseBrockers(!collapseBrockers) }
    // setCollapseProjects(!collapseProjects)
  }

  let tempArr: any = []
  const activeProject = (uuid: string) => {
    setActiveProjectItem?.(
      activeProjectItem?.map((elem: any) => {
        if (uuid === elem.uuid) {
          elem.active = !elem.active
        }
        return elem
      }),
    )
    tempArr = activeProjectItem.filter((el: any) => el.active === true)
    setSelectedProjectsUUID?.(tempArr.map((el: any) => el.uuid))
  }

  const selectedBrocker = (uuid: string, name: string) => {
    if (uuid === selectedBrockerFilter.uuid) {
      setSelectedBrockerFilter({ uuid: null, name: '' })
    } else {
      setSelectedBrockerFilter({ uuid, name })
      setCollapseBrockers(!collapseBrockers)
    }
  }

  useEffect(() => {
    setActiveProjectItem?.(
      activeProjectItem.map((el: any) => ({ ...el, active: false })),
    )
    setSelectedProjectsUUID?.([])
  }, [navValue])

  return (
    <div className={clsx(s.sorting, className)}>
      <button className={s.sorting__input} onClick={onClick} type="button">
        <div className={s.sorting__doubleArrows}>
          <img src={src} alt="sorting" width="18" height="18" />
        </div>
        {placeholder}
        <div
          className={clsx(
            s.sorting__arrowDown,
            arrow ? s.sorting__arrowUp : '',
          )}
        >
          <img src={toggle} alt="" />
        </div>
      </button>
      {isOpen && (
        <ul className={clsx(s.wrapper)}>
          {navValue !== 2 && (
            <li className={clsx(s.filterDocs_select)}>
              <button
                className={s.filterDocs_select_header_btn}
                type="button"
                onClick={handleSort}
              >
                <Title
                  className={s.filterDocs_select_title}
                  title="Дата по возрастанию"
                />
                {directionContracts === 'asc' && navValue === 0 && (
                  <img
                    className={s.filterDocs_select_img}
                    src={check}
                    alt="check"
                  />
                )}
                {directionActs === 'asc' && navValue === 1 && (
                  <img
                    className={s.filterDocs_select_img}
                    src={check}
                    alt="check"
                  />
                )}
              </button>
            </li>
          )}
          {navValue !== 2 && (
            <li className={clsx(s.filterDocs_select)}>
              <button
                className={s.filterDocs_select_header_btn}
                type="button"
                onClick={handleSort}
              >
                <Title
                  className={s.filterDocs_select_title}
                  title="Дата по убыванию"
                />
                {directionContracts === 'desc' && navValue === 0 && (
                  <img
                    className={s.filterDocs_select_img}
                    src={check}
                    alt="check"
                  />
                )}
                {directionActs === 'desc' && navValue === 1 && (
                  <img
                    className={s.filterDocs_select_img}
                    src={check}
                    alt="check"
                  />
                )}
              </button>
            </li>
          )}
          <li
            className={clsx(s.filterDocs_select, {
              [s.filterDocs_select_active]: collapseProjects,
            })}
          >
            <div
              className={s.filterDocs_select_header}
              id="Проекты"
              onClick={(e: any) => makeCollapse('Проекты')}
            >
              <Title className={s.filterDocs_select_title} title="Проекты" />
              <ToggleButton
                className={s.filterDocs_select_btn}
                // src={arrow}
                src={toggle}
                rotate={collapseProjects}
                iconWidth="18px"
                iconHeight="18px"
                // onClick={(e: any) => makeCollapse(e)}
              />
            </div>
            <div className={s.block__projects_items}>
              {activeProjectItem?.map((item: any) => (
                <div
                  key={item.uuid}
                  className={clsx(
                    s.block__projects_item,
                    item.active === true && s.active,
                  )}
                  onClick={() => activeProject(item.uuid)}
                >
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
          </li>
          <li
            style={{ paddingLeft: 0, paddingRight: 0 }}
            className={clsx(s.filterDocs_select, {
              [s.filterDocs_select_active]: collapseBrockers,
            })}
            onClick={(e: any) => {
              makeCollapse('По брокеру')
            }}
          >
            <div
              style={{ padding: '0 30px' }}
              className={s.filterDocs_select_header}
              id="По брокеру"
            >
              <Title
                className={s.filterDocs_select_title}
                title={selectedBrockerFilter?.name ? `По брокеру: ${selectedBrockerFilter.name}` : 'По брокеру'}
              />
              <ToggleButton
                className={s.filterDocs_select_btn}
                src={toggle}
                rotate={collapseBrockers}
                iconWidth="18px"
                iconHeight="18px"
                // onClick={(e: any) => makeCollapse(e)}
              />
            </div>
            <div className={s.block__project_name}>
              {brokerList?.map(
                (elem: any, i: number) => (
                  <p
                    onClick={() => selectedBrocker(elem.uuid, elem.name)}
                    className={clsx(
                      s.list, s.list_currency, elem.name === selectedBrockerFilter?.name && s.list_active
                    )}
                    key={i}
                  >
                    {elem.name}
                  </p>
                )
              )}
            </div>
          </li>
        </ul>
      )}
    </div>
  )
}
