export const pledge = [
  {
    id: 1,
    active: false,
    title: 'Поручительство основателя',
  },
  {
    id: 2,
    active: false,
    title: 'Поручительство головной компании',
  },
  {
    id: 3,
    active: false,
    title: 'Поручительство партнеров',
  },
  {
    id: 4,
    active: false,
    title: 'Залог оборудования',
  },
  {
    id: 5,
    active: false,
    title: 'Залог товара',
  },
  {
    id: 6,
    active: false,
    title: 'Залог недвижимости',
  },
  {
    id: 7,
    active: false,
    title: 'Банковские гарантии',
  },
  {
    id: 8,
    active: false,
    title: 'Неустойка',
  },
]

export const status = [
  {
    id: 1,
    active: false,
    title: 'Активный',
  },
  {
    id: 2,
    active: false,
    title: 'Приостановленный',
  },
  {
    id: 3,
    active: false,
    title: 'Черновик',
  },
  {
    id: 4,
    active: false,
    title: 'Архив',
  },
]

export const investForm = [
  {
    id: 1,
    active: false,
    title: 'Процентный заём',
  },
  {
    id: 2,
    active: false,
    title: 'Конвертируемый заем',
  },
  {
    id: 3,
    active: false,
    title: 'Проектное финансирование',
  },
  {
    id: 4,
    active: false,
    title: 'Продажа доли в бизнесе',
  },
  {
    id: 5,
    active: false,
    title: 'Продажа акций',
  },
  {
    id: 6,
    active: false,
    title: 'Облигации',
  },
]

export const pledgeItem = [
  {
    description: 'Основатель верит в проект и готов отвечать лично, если что-то пойдёт не так',
    id: 1,
    title: 'Поручительство основателя',
    uuid: 'c0d2b986-e51c-43a4-afbe-cc8bc559ae73',
    active: false,
  },
  {
    description: 'Отвечает не изолированное юрлицо, а гораздо большая бизнес-структура: холдинг, компания или группа компаний',
    id: 2,
    title: 'Поручительство головной компании',
    uuid: 'e3041cdd-f0f9-4fad-8818-94064ebae165',
    active: false,
  },
  {
    description: 'Ключевые бизнес-партнёры берут на себя часть обязательств перед инвестором',
    id: 3,
    title: 'Поручительство партнёров',
    uuid: '8b61f0f6-c6a9-4966-be10-5ff72a78136b',
    active: false,
  },
  {
    description: 'Компания оформляет в залог оборудование, которое будет реализовано с молотка, если возникнет задолженность',
    id: 4,
    title: 'Залог оборудования',
    uuid: '0a615f57-86c1-4ec4-9a61-4da0b7a56cec',
    active: false,
  },
  {
    description: 'Часть товара в обороте компании. Если возникает задолженность, этот товар выходит из оборота — и продаётся с молотка',
    id: 5,
    title: 'Залог товара',
    uuid: '1d8ca08a-bec3-4ece-aac6-6543064253c5',
    active: false,
  },
  {
    description: 'Работает как ипотека — недвижимость в залоге будет реализована с молотка, если возникнет задолженность',
    id: 6,
    title: 'Залог недвижимости',
    uuid: '5a109fd3-11e6-43f7-a9e3-20606bb91f07',
    active: false,
  },
  {
    description: 'Независимая банковская гарантия. Если основной должник не вернул долг — деньги инвестору перечисляет банк',
    id: 7,
    title: 'Банковские гарантии',
    uuid: 'a3ecf95b-479e-412c-85f1-961d287efe38',
    active: false,
  },
  {
    description: 'Если заёмщик не выполняет условия договора, ему придётся платить штраф или пени. Штраф — это фиксированная сумма. Пени — процент от долга за каждый день просрочки',
    id: 8,
    title: 'Неустойка',
    uuid: 'ee370e05-ec4f-4417-9b19-44f3ae9bd46e',
    active: false,
  },
]
