import { gql } from '@apollo/client'

export const CHAT_MESSAGES_CREATED = gql`
  subscription chatMessageCreated($chatChannelId: Int!, $token: String!) {
    chatMessageCreated(chatChannelId: $chatChannelId, token: $token) {
      id
      text
      messageType
      status
      createdAt
      chatChannel {
        id
        createdAt
        members {
          role
          uuid
        }
      }
      profile {
        role
        uuid
        client {
          firstName
          lastName
          photo
        }
        broker {
          photo
          name
        }
        lawyer {
          photo
          name
        }
        manager {
          photo
          name
        }
      }
      fileName
    }
  }
`
