/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable wrap-iife */
import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useAppSelector } from '../../redux/hook'
import { useGetRole } from '../../hooks/useGetRole'
import {
  CreatePromotion,
  PromotionDynamic,
  PromotionNotifications,
  PromotionStories,
} from '../../components'
import { Layout } from '../../features/Layouts'
import { Title } from '../../ui'
import { dataNavTitle } from './data'
import {
  admin,
  lawyer,
  manager,
  marketer,
  technical1,
  technical2,
} from '../../references/data'
import s from './Promotion.module.css'
import { UserProfile } from '../../components/UserProfile/UserProfile'

export const Promotion = () => {
  const { isCreatePromotion } = useAppSelector(state => state.pageSlice)
  const [navValuePromotions, setNavValuePromotions] = useState<number>(0)
  const { isRole, location, navigate } = useGetRole()

  useEffect(() => {
    ;(function redirect() {
      if (isRole === technical1 && location.pathname === '/promotion') {
        navigate('/clients', { replace: true })
      } else if (isRole === technical2 && location.pathname === '/promotion') {
        navigate('/documents', { replace: true })
      } else if (isRole === lawyer && location.pathname === '/promotion') {
        navigate('/home', { replace: true })
      } else if (isRole === manager && location.pathname === '/promotion') {
        navigate('/home', { replace: true })
      }
    })()
  }, [location, isRole])

  return (
    <Layout>
      {(isRole === admin || isRole === marketer) && (
        <>
          <div className={s.header}>
            <div className={s.title}>
              <Title className={clsx(s.promotion__title)} title="Акции" />
            </div>
            <UserProfile isRole={isRole} navigate={navigate} />
          </div>

          {(!isCreatePromotion && (
            <div className={s.promotion_items}>
              <PromotionStories
                title="Сторис"
                dataNavTitle={dataNavTitle}
                setNavValuePromotions={setNavValuePromotions}
              />
              <PromotionDynamic
                title="Динамические акции"
                dataNavTitle={dataNavTitle}
                setNavValuePromotions={setNavValuePromotions}
              />
              {isRole === admin && (
                <PromotionNotifications title="Рассылка акций" />
              )}
            </div>
          )) || (
            <CreatePromotion
              setNavValuePromotions={setNavValuePromotions}
              navValuePromotions={navValuePromotions}
            />
          )}
        </>
      )}
    </Layout>
  )
}
