import { FC } from 'react'
import { Section } from '../Section/Section'
import { SectionTitle } from '../SectionTitle/SectionTitle'
import s from './InfoList.module.css'

interface InfoList {
  project?: any
  pledgeArrForState?: any
}

export const InfoList: FC <InfoList> = ({ project, pledgeArrForState }) => (
  <Section className={s.section__margin__bottom}>
    <SectionTitle text="Данные" />
    <ul className={s.info__list}>
      <li className={s.info__requisites_item}>
        <div className={s.info__requisites_title}>Название:</div>
        <div className={s.info__requisites_value}>
          {project?.name}
        </div>
      </li>
      <li className={s.info__requisites_item}>
        <div className={s.info__requisites_title}>Юрлицо:</div>
        <div className={s.info__requisites__value_wrapper}>
          <div className={s.info__requisites_value}>
            {project?.requisites?.name}
          </div>
          <div className={s.info__requisites_value}>
            <span>ОГРН </span>
            {project?.requisites?.OGRN}
          </div>
          <div className={s.info__requisites_value}>
            <span>ИНН </span>
            {project?.requisites?.INN}
          </div>
        </div>
      </li>
      <li className={s.info__requisites_item}>
        <div className={s.info__requisites_title}>Адрес:</div>
        <div className={s.info__requisites_wrapper}>
          <div className={s.info__requisites_value}>
            {project?.requisites?.legal_address}
          </div>
        </div>
      </li>
      <li className={s.info__requisites_item}>
        <div className={s.info__requisites_title}>
          Место ведения деятельности:
        </div>
        <div className={s.info__requisites_value}>
          {project?.requisites?.address}
        </div>
      </li>
      <li className={s.info__requisites_item}>
        <div className={s.info__requisites_title}>
          Обеспечение по проектам:
        </div>
        <div className={s.info__requisites_wrapper}>
          {pledgeArrForState.map((el: any) => (
            <div className={s.info__requisites_value} key={el.uuid}>
              {el.name}
            </div>
          ))}
        </div>
      </li>
      <li className={s.info__requisites_item}>
        <div className={s.info__requisites_title}>Договоры по проекту:</div>
        <div className={s.info__requisites_wrapper}>
          <button className={s.info__callManager} type="button" onClick={() => null}>
            Запросить у менеджера
          </button>
        </div>
      </li>
    </ul>
  </Section>
)
