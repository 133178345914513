import { useAppDispatch } from '../../redux/hook'
import { closePromotion } from '../../redux/slices/pageSlice'
import { resetPromotionObj } from '../../redux/slices/promotionSlice'
import { resetPromotionDynamicObj } from '../../redux/slices/promotionDynamicSlice'
import { StoriesPromotion, DynamicCreatePromotion } from '../../components'
import {
  CloseIcon, Container, NavProjects, Title
} from '../../ui'
import s from './CreatePromotion.module.css'

interface CreatePromotionProps {
  setNavValuePromotions: (num: number) => void
  navValuePromotions: number
}

export const CreatePromotion = ({
  setNavValuePromotions,
  navValuePromotions,
}: CreatePromotionProps) => {
  const dispatch = useAppDispatch()
  const closeCreatePromotion = () => {
    dispatch(closePromotion())
    dispatch(resetPromotionObj())
    dispatch(resetPromotionDynamicObj())
  }
  return (
    <Container className={s.container}>
      <Title className={s.title} title="Добавить акцию" />
      <CloseIcon onClick={closeCreatePromotion} />
      <div className={s.wrapper}>
        <NavProjects
          className={s.create_promotion}
          setNavValue={setNavValuePromotions}
          navValue={navValuePromotions}
          dataNavTitle={['Сторис', 'Динамическая акция']}
        />
      </div>
      {navValuePromotions === 0 ? (
        <StoriesPromotion />
      ) : (
        <DynamicCreatePromotion />
      )}
    </Container>
  )
}
