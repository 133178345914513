import React, { use, useEffect, useState } from 'react'
import clsx from 'clsx'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { addAct, resetAct, statusPDFAct } from '../../redux/slices/actSlice'
import {
  changeSelect,
  addUsers,
  changeSingleSelect,
  resetObj,
  closeSelect,
  closeOnlySelect,
  statusPDF,
} from '../../redux/slices/selectSlice'
import { ALL_PROJECTS } from '../../apollo/query/allProjects'
import { ALL_BROCKERS } from '../../apollo/query/allBrockers'
import { ALL_CLIENTS } from '../../apollo/query/allClients'
import { GET_CONTRACT, GET_ACT, GET_REPORT } from '../../apollo/query/getContract'
import { ALL_CONTRACTS } from '../../apollo/query/allContracts'
import { CONTRACT_UPDATE, ACT_UPDATE, REPORT_UPDATE } from '../../apollo/mutation/contractUpdate'
import { NativeSelect } from '../../ui/nativeSelect'
import { Upload } from '../../components/Upload'
import {
  Container,
  Field,
  Label,
  Panel,
  Select,
  DataPicker,
  ProgressBar,
  Title,
  CloseIcon,
  Button,
  Loader,
  NavProjects,
} from '../../ui'
// import { arr3, dateReplacements, periodArr } from './data'
import iconSent from './icon-sent.svg'
import iconDraft from './icon-draft.svg'
import s from './EditReport.module.css'
import { addReport, resetReport, statusPDFReport } from '../../redux/slices/reportSlice'
import { closePageReport, openPageReport, closePage } from '../../redux/slices/pageSlice'

interface EditReportProps {
	params?: string
}

export const EditReport: React.FC<EditReportProps> = ({ params }) => {
  const { report, statusFilePDFReport } = useAppSelector(state => state.reportSlice)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [selectProject, setSelectedProjects] = useState([])
  const [senting, setSenting] = useState(false)
  const [draft, setDraft] = useState(false)
  const [sumFieldReport, setSumFieldReport] = useState(0) // сумма полей (6)
  const [projectTitleReport, setProjectTitleReport] = useState('')
  const [reportName, setReportName] = useState('')
  const valuesReport = Object.values(report).map(elem => (elem !== '' ? 33.33 : 0)) // проверка объекта на его значения

  // запрос на репорт
  const { data: reportData, loading: loadingGetReport } = useQuery(
    GET_REPORT,
    {
      variables: {
        uuid: params,
      },
    },
  )

  const [
    updateReport,
    {
      data: updateReportData,
      loading: loadingUpdateReport,
      error: errorUpdateReport,
    },
  ] = useMutation(REPORT_UPDATE)

  const { data: allProjects } = useQuery(ALL_PROJECTS, {
    variables: {
      filter: {
        // status: 'published',
      },
    },
  })

  useEffect(() => {
    if (reportData) {
      const objGetReport: any = {
        projectUUID: reportData.report.project.uuid,
        name: reportData.report.name,
        file: reportData.report.file,
      }
      for (const key in objGetReport) {
        dispatch(addReport({ project: key, item: objGetReport[key] }))
      }
    }
  }, [reportData])

  // принимаем акт
  useEffect(() => {
    if (reportData?.report) {
      setProjectTitleReport(reportData?.report.project.name)
      setReportName(reportData?.report.name)
    }
  }, [reportData])

  const [showSelectReport, setShowSelectReport] = useState(false)
  const selectReportHandler = () => {
    setShowSelectReport(!showSelectReport)
  }
  // передаю номер uuid Проекта для формирования объекта
  const dispatchReportUUIDProject = (UUIDProject: string) => {
    dispatch(addReport({ project: 'projectUUID', item: UUIDProject }))
  }
  const actReportHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReportName(e.target.value)
    dispatch(addReport({ project: 'name', item: e.target.value })) // debounce?
  }

  const hidePage = () => {
    navigate('/documents', { replace: true })
    dispatch(resetReport())
    dispatch(statusPDF(null))
    dispatch(statusPDFAct(null))
    dispatch(statusPDFReport(null))
  }

  useEffect(() => {
    setSumFieldReport(valuesReport.reduce((a: any, b: any) => a + b))
    // setSumField(100)
  }, [report]) // сложение значений объекта

  useEffect(() => {
    if (!allProjects) return
    setSelectedProjects(allProjects.projects.map((e:any) => ({ ...e, active: false })))
  }, [allProjects])

  if (loadingGetReport) {
    <div>
      Loading...
    </div>
  }

  // сохранение черновика договора
  const handleSaveDraftReport = () => {
    setDraft(true)
    // gql mutation
    updateReport({
      variables: {
        uuid: params,
        data: {
          ...report,
          status: 'draft',
          // file: 'null',
        },
      },
    })
    // setIsContract('')
    // setSum('')
    // setAmount('')
    // setPercent('')
    // dispatch(resetObj())
    dispatch(resetReport())
    if (!loadingUpdateReport && errorUpdateReport === undefined) {
      setTimeout(() => {
        setDraft(false)
        hidePage()
      }, 2000)
    } else {
      setTimeout(() => {
        setDraft(false)
      }, 2000)
    }
  }

  // сохранение и публикация договора
  const handleSaveSentReport = () => {
    setSenting(true)
    // gql mutation
    updateReport({
      variables: {
        uuid: params,
        data: {
          ...report,
          // file: 'null',
          status: 'publish',
        },
      },
    })
    if (!loadingUpdateReport || errorUpdateReport === undefined) {
      setTimeout(() => {
        hidePage()
      }, 2000)
    } else {
      setTimeout(() => {
        setSenting(true)
      })
    }
  }

  const draftSaved = (
    <>
      <img src={iconDraft} alt="draft" width="17" height="17" />
      <span style={{ marginLeft: '7px' }}>Черновик сохранен</span>
    </>
  )

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: '7px' }}>Сохранено</span>
    </>
  )

  return (
    <Container className={s.container}>
      <CloseIcon onClick={hidePage} />
      <Title className={s.title} title="Редактировать отчет" />
      <div className={s.wrapper}>
        <div className={s.first__block}>
          <Panel padding className={s.panel}>
            <Title className={s.report_title} title="Информация" />
            <div className={s.report_firstLine}>
              {/* <Label
                  title="Клиент"
                >
                  <Select
                    refetch={refetch}
                    setSearchValueClientReport={setSearchValueClientReport}
                    searchValueClientReport={searchValueClientReport}
                    setShowInputReport={setShowInputReport}
                    showInputReport={showInputReport}
                    dispatchReportUUIDClient={dispatchReportUUIDClient}
                    onClick={handlerReport}
                    setShowSelectClientsReport={setShowSelectClientsReport}
                    showSelectClientsReport={showSelectClientReport}
                    show={showSelectClientReport}
                    report
                    user={allClients?.clients}
                    draft={draft}
                  />
                </Label> */}
              <Label title="Номер акта">
                <Field
                  view="default"
                  placeholder="Номер акта"
                  value={reportName}
                  onChange={e => actReportHandler(e)}
                />
              </Label>
              <Label
                title="Проект"
              >
                <NativeSelect
                  tags
                  pledgeArr={selectProject}
                  selectActProject
                  draft={draft}
                  setShowSelectAct={setShowSelectReport}
                  show={showSelectReport}
                  onClick={() => selectReportHandler()}
                  dispatchActUUIDProject={dispatchReportUUIDProject}
                  projectTitleAct={projectTitleReport}
                  setProjectTitleAct={setProjectTitleReport}
                />
              </Label>
            </div>
          </Panel>
          <Upload
            title="Загрузить файл"
            titleStatus="Отчет"
            text="Формат файла: .pdf"
            application="application/pdf"
            subText1="Загрузить с компьютера"
            subText2="или перетащите сюда"
            status={statusFilePDFReport}
            uploadId="pdf"
            fileUrl={reportData?.report.file}
          />
          <Panel className={s.btns__panel}>
            <Button
              onClick={handleSaveDraftReport}
              // disabled={Math.ceil(sumFieldReport) !== 100}
              disabled={Math.ceil(sumFieldReport) < 60}
              className={clsx(s.btns__panel_draft)}
              theme="outline"
            >
              {!draft ? (
                'Сохранить как черновик'
              ) : loadingUpdateReport ? (
                <Loader />
              ) : !loadingUpdateReport && errorUpdateReport ? (
                'Ошибка'
              ) : (
                draftSaved
              )}
            </Button>
            <Button
              onClick={handleSaveSentReport}
              disabled={Math.ceil(sumFieldReport) !== 100}
              className={s.btns__panel_save}
              theme="dark"
            >
              {!senting ? (
                'Сохранить и опубликовать'
              ) : loadingUpdateReport ? (
                <Loader />
              ) : !loadingUpdateReport && errorUpdateReport ? (
                'Ошибка'
              ) : (
                sentSaved
              )}
            </Button>
          </Panel>
        </div>
        <Panel className={s.panel__progress} padding>
          <ProgressBar value={Math.ceil(sumFieldReport)} />
        </Panel>
      </div>
    </Container>
  )
}
