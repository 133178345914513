import { gql } from '@apollo/client'

export const PROMOTION_DYNAMICS = gql`
  query promotionDynamics($filter: PromotionFilter) {
    promotionDynamics(filter: $filter) {
      uuid
      name
      status
      startedAt
      endedAt
      header
      text
      image
      color
      gradientColor
  gradientDeg
  gradientLocation
    }
  }
`
