import React, { FC } from 'react'
import { FirstScreen } from './firstScreen/FirstScreen'
import { AboutUs } from './aboutUs/AboutUs'
import { NumbersSite } from './numbers/NumbersSite'
import { LastScreen } from '../lastScreen/LastScreen'
import { Projects } from './projects/Projects'
import { TeamSection } from './teamSection/TeamSection'
import close from '../assets/close_icon.svg'
import s from './Home.module.css'

interface HomePage {
  width: number
  setPreviewToggler?: (arg: any) => void
  dataArray: any
  data: any
}

export const HomePage: FC<HomePage> = ({
  width,
  setPreviewToggler,
  data,
  dataArray,
}) => (
  <div className={s.wrapper} onClick={e => e.stopPropagation()}>
    <button className="close" type="button" onClick={() => setPreviewToggler?.(false)}>
      <img src={close} alt="" />
    </button>
    <div id="main">
      <FirstScreen width={width} data={data} />
    </div>
    <div id="about_us">
      <AboutUs data={data} />
    </div>
    <div id="projects">
      <Projects width={width} />
    </div>
    <div id="numbers">
      <NumbersSite dataArray={dataArray} />
    </div>
    <div id="team">
      <TeamSection width={width} />
    </div>
    <div id="form">
      <LastScreen data={data} />
    </div>
  </div>
)
