import React from 'react'
import clsx from 'clsx'
import s from './NavProjects.module.css'

interface NavProjType {
	navValue?: number
	setNavValue?: ((e: number) => void) | any
	className?: string
	dataNavTitle?: string[]
}

export const NavProjects: React.FC<NavProjType> = ({
  navValue,
  setNavValue,
  className,
  dataNavTitle = ['Все', 'Активные', 'Черновики', 'Приостановленные', 'Архив'],
}) => (
  <div className={clsx(s.navItemsBlock, className)}>
    {dataNavTitle.map((e: string, i: number) => (
      <div
        className={clsx(s.navItem, navValue === i && s.navItemActive)}
        onClick={() => setNavValue(i)}
        key={i}
      >
        {e}
      </div>
    ))}
  </div>
)
