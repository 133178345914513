import { gql } from '@apollo/client'

export const PROJECT_UPDATE = gql`
  mutation projectUpdate($uuid: String!, $data: ProjectDataUpdate!) {
    admProjectUpdate(uuid: $uuid, data: $data) {
      id
      uuid
      name
      country
      fullDescription
      fullDescriptionEn
      description
      descriptionEn
      minInvestment
      minInterest
      status
      images
      requisites
      support
      sendStatus
    }
  }
`
