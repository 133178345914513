import React from 'react'
import { Panel } from '../Panel'
import s from './MiniatureUI.module.css'

interface MiniatureProps {
	image: string
	color: string
	header: string
}

export const MiniatureUI: React.FC<MiniatureProps> = ({
  image,
  color,
  header,
}) => (
  <Panel
    className={s.promotion_item}
    style={
      image
        ? {
          background: `url(${image}) center/cover no-repeat`,
					  }
        : { background: `${color}` }
    }
  >
    <p className={s.promotion_header}>{header}</p>
  </Panel>
)
