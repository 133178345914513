/*eslint-disable*/
import { gql } from "@apollo/client";

export const GET_ALL_INTERVIEWS = gql`
  query getMediaInterviews {
    mediaInterviews {
      id
      uuid
      video
      title
      titleEn
      decription
      descriptionEn
      datemarker
    }
  }
`;

export const GET_ALL_MEDIAREPORTS = gql`
  query getMediaReports {
    mediaReports {
      id
      uuid
      video
      title
      titleEn
      datemarker
    }
  }
`;

export const GET_ALL_OUTLETS = gql`
  query getMediaOutlets {
    mediaOutlets {
      id
      uuid
      picture
      title
      titleEn
      decription
      descriptionEn
      sourse
      sourceEn
      datemarker
      link
    }
  }
`;
export const GET_OUTLET = gql`
  query getMediaOutlet($uuid: String!) {
    mediaOutlet(uuid: $uuid) {
      id
      uuid
      picture
      title
      titleEn
      decription
      descriptionEn
      sourse
      sourceEn
      datemarker
      link
    }
  }
`;

export const GET_ALL_COMMENTS = gql`
  query getMediaReviewsText {
    mediaReviewsText {
      id
      uuid
      choose
      avatar
      datemarker
      firstname
      firstnameEn
      lastname
      lastnameEn
      sourse
      link
      comment
      commentEn
      review
    }
  }
`;
export const GET_INTERVIEW = gql`
  query getMediaInterview($uuid: String!) {
    mediaInterview(uuid: $uuid) {
      id
      uuid
      video
      title
      titleEn
      decription
      descriptionEn
      datemarker
    }
  }
`;

export const GET_MEDIAREPORT = gql`
  query getMediaReport($uuid: String!) {
    mediaReport(uuid: $uuid) {
      id
      uuid
      video
      title
      titleEn
      datemarker
    }
  }
`;

export const GET_TEXTREVIEW = gql`
  query getMediaReviewsText($uuid: String!) {
    mediaReviewText(uuid: $uuid) {
      id
      uuid
      choose
      avatar
      firstname
      firstnameEn
      lastname
      lastnameEn
      sourse
      link
      comment
      commentEn
      review
      datemarker
    }
  }
`;
