/* eslint-disable arrow-body-style */
export const currentDate = new Date()
export const year = currentDate.getFullYear()
export const month = currentDate.getMonth()
export const day = currentDate.getDate()
export const hour = currentDate.getHours()
export const minutes = currentDate.getMinutes()
export const seconds = currentDate.getSeconds()
export const ms = currentDate.getMilliseconds()

// current date UTC

export const actualDate = new Date(
  Date.UTC(year, month, day, hour, minutes, seconds, ms)
).toISOString()

// Users roles
export const admin = 'admin'
export const technical1 = 'technical1'
export const technical2 = 'technical2'
export const marketer = 'marketer'
export const lawyer = 'lawyer'
export const manager = 'manager'

// для сравнения дат и вывода в чате времени, Вчера или даты
export const compareDates = (currDay, backDay) => {
  return (
    Intl.DateTimeFormat('ru-Ru', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }).format(new Date(currDay)) ===
    Intl.DateTimeFormat('ru-Ru', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }).format(new Date(backDay))
  )
}
