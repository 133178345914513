import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UsersType {
	projectUUID: string
  name: string
  file: string
}

interface reportSlice {
	report: UsersType
	statusFilePDFReport: string | null
}

const initialState: reportSlice = {
  report: {
    projectUUID: '',
    name: '',
    file: '',
  },
  statusFilePDFReport: null,
}

export const reportSlice = createSlice({
  name: 'select',
  initialState,
  reducers: {
    addReport(state, action) {
      state.report = {
        ...state.report,
        [action.payload.project]: action.payload.item,
      }
    },
    resetReport(state) {
      state.report = {
        projectUUID: '',
        name: '',
        file: '',
      }
      state.statusFilePDFReport = null
    },
    closeSelect(state) {
      state.statusFilePDFReport = null
    },
    statusPDFReport(state, action) {
      state.statusFilePDFReport = action.payload
    },
  },
})

export const {
  addReport,
  resetReport,
  closeSelect,
  statusPDFReport,
} = reportSlice.actions

export default reportSlice.reducer
