import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UsersType {
  photo: string
}

interface editSiteSlice {
	mainPage: UsersType
	statusFilePDFReport: string | null
  nav: number
  isCreateBroker: boolean
  leftText: string
  leftTextEn: string
}

const initialState: editSiteSlice = {
  mainPage: {
    photo: '',
  },
  statusFilePDFReport: null,
  nav: 1,
  isCreateBroker: false,
  leftText: '',
  leftTextEn: '',
}

export const editSiteSlice = createSlice({
  name: 'select',
  initialState,
  reducers: {
    editMainPage(state, action) {
      state.mainPage = {
        ...state.mainPage,
        [action.payload.project]: action.payload.item,
      }
    },
    resetMainPage(state) {
      state.mainPage = {
        photo: '',
      }
      state.statusFilePDFReport = null
    },
    changeNav(state, action) {
      state.nav = action.payload
    },
    changeIsCreateBroker(state, action) {
      state.isCreateBroker = action.payload
    },
    changeLeftText(state, action) {
      state.leftText = action.payload
    },
    changeLeftTextEn(state, action) {
      state.leftTextEn = action.payload
    }
  },
})

export const {
  editMainPage,
  resetMainPage,
  changeNav,
  changeIsCreateBroker,
  changeLeftText,
  changeLeftTextEn
} = editSiteSlice.actions

export default editSiteSlice.reducer
