import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react'
import clsx from 'clsx'
import add from './img/icon-plus.svg'
import s from './AddButton.module.css'

interface AddButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  className?: string
  src?: string
}

export const AddButton: FC<AddButtonProps> = props => {
  const {
    className, children, src = add, ...rest
  } = props
  return (
    <button className={clsx(s.btn_add, className)} type="button" {...rest}>
      <span>
        <img src={src} alt="" width="25" height="25" />
      </span>
      {children}
    </button>
  )
}
