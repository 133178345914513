export const arr3 = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
]

export const dateReplacements: any = {
  '01.03.2024': '29.02.2024',
  '02.03.2025': '28.02.2025',
  '01.03.2025': '28.02.2025',
  '02.03.2026': '28.02.2026',
  '01.03.2026': '28.02.2026',
  '02.03.2027': '28.02.2027',
  '01.03.2027': '28.02.2027',
  '01.03.2028': '29.02.2028',
  '02.03.2029': '28.02.2029',
  '01.03.2029': '28.02.2029',
  '02.03.2030': '28.02.2030',
  '01.03.2030': '28.02.2030',
  '02.03.2031': '28.02.2031',
  '01.03.2031': '28.02.2031',
  '01.03.2032': '29.02.2032',
  '02.03.2033': '28.02.2033',
  '01.03.2033': '28.02.2033',
  '02.03.2034': '28.02.2034',
  '01.03.2034': '28.02.2034',
  '02.03.2035': '28.02.2035',
  '01.03.2035': '28.02.2035',
  '01.03.2036': '29.02.2036',
  '02.03.2037': '28.02.2037',
  '01.03.2037': '28.02.2037',
  '02.03.2038': '28.02.2038',
  '01.03.2038': '28.02.2038',
  '02.03.2039': '28.02.2039',
  '01.03.2039': '28.02.2039',
  '01.03.2040': '29.02.2040',
  '02.03.2041': '28.02.2041',
  '01.03.2041': '28.02.2041',
  '02.03.2042': '28.02.2042',
  '01.03.2042': '28.02.2042',
  '02.03.2043': '28.02.2043',
  '01.03.2043': '28.02.2043',
  '01.03.2044': '29.02.2044',
  '02.03.2045': '28.02.2045',
  '01.03.2045': '28.02.2045',
  '02.03.2046': '28.02.2046',
  '01.03.2046': '28.02.2046',
  '02.03.2047': '28.02.2047',
  '01.03.2047': '28.02.2047',
  '01.03.2048': '29.02.2048',
  '02.03.2049': '28.02.2049',
  '01.03.2049': '28.02.2049',
  '02.03.2050': '28.02.2050',
}

export const periodArr = [
  {
    id: 1,
    title: '12',
  },
  {
    id: 2,
    title: '18',
  },
  {
    id: 3,
    title: '24',
  },
  {
    id: 4,
    title: '30',
  },
  {
    id: 5,
    title: '36',
  },
  {
    id: 6,
    title: '42',
  },
  {
    id: 7,
    title: '48',
  },
]
