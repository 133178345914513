import React, { useState, ButtonHTMLAttributes, ChangeEvent } from 'react'
import clsx from 'clsx'
import {
  Button,
  Loader,
  Title,
  Field,
} from '..'
import { ToggleButton } from '../ToggleButton'
import arrow from './img/icon-arrow-up.svg'
import s from './FilterClients.module.css'
import { projectsArrItem } from '../../components/ClientsList'
import { NativeSelect } from '../nativeSelect'

interface FilterProps {
  className?: string
  filter3m?: (event: React.MouseEvent<HTMLButtonElement>) => void
  isButton3mActive?: boolean
  isButton2mActive?: boolean
  isButton1mActive?: boolean
  applyFilter?: (event: React.MouseEvent<HTMLButtonElement>) => void
  activeProjectItem?: projectsArrItem | any
  setActiveProjectItem?: (arg: any) => void
  setSelectedProjects?: (arg: string) => void
  filterByInvestmentFromActive?: (e: ChangeEvent<HTMLInputElement>) => void
  filterByInvestmentToActive?: (e: ChangeEvent<HTMLInputElement>) => void
  filterByInvestmentFrom?: (e: ChangeEvent<HTMLInputElement>) => void
  filterByInvestmentTo?: (e: ChangeEvent<HTMLInputElement>) => void
  filterByCountFromState?: number | string
  filterByCountToState?: number | string
  setFilterByCountFromState?: (arg: string) => void
  setFilterByCountToState?: (arg: string) => void
  filterByCountFromStateActive?: number | string
  filterByCountToStateActive?: number | string
  setFilterByCountFromStateActive?: (arg: string) => void
  setFilterByCountToStateActive?: (arg: string) => void
  fromSumValue?: number | string | any
  toSumValue?: number | string
  fromSumValueActive?: number | string
  toSumValueActive?: number | string
  setSelectedProjectsUUID?: (arg: string[]) => void
  loaderFilter?: boolean
  brokerList?: any
  selectedBrockerFilter?: any
              setSelectedBrockerFilter?: any
}
export const FilterClients: React.FC<FilterProps> = ({
  className,
  filter3m,
  isButton3mActive,
  isButton2mActive,
  isButton1mActive,
  applyFilter,
  activeProjectItem,
  setActiveProjectItem,
  setSelectedProjects,
  fromSumValue,
  toSumValue,
  fromSumValueActive,
  toSumValueActive,
  filterByInvestmentFrom,
  filterByInvestmentTo,
  filterByInvestmentFromActive,
  filterByInvestmentToActive,
  filterByCountFromState,
  filterByCountToState,
  setFilterByCountFromState,
  setFilterByCountToState,
  filterByCountFromStateActive,
  filterByCountToStateActive,
  setFilterByCountFromStateActive,
  setFilterByCountToStateActive,
  setSelectedProjectsUUID,
  loaderFilter,
  brokerList,
  selectedBrockerFilter,
  setSelectedBrockerFilter
}) => {
  const [collapseTerm, setCollapseTerm] = useState<boolean>(false)
  const [collapseType, setCollapseType] = useState<boolean>(false)
  const [collapseInvestSum, setCollapseInvestSum] = useState<boolean>(false)
  const [collapseActive, setCollapseActive] = useState<boolean>(false)
  const [collapseProjects, setCollapseProjects] = useState<boolean>(false)
  const [collapseAmountProjects, setCollapseAmountProjects] = useState<boolean>(false)
  const [collapseBrockers, setCollapseBrockers] = useState(false)

  // console.log('brokerList', brokerList)

  const makeCollapse = (e: any) => {
    if (e.target.parentElement.parentElement.id === 'Срок окончания договора') { setCollapseTerm(!collapseTerm) }
    if (e.target.parentElement.parentElement.id === 'Тип договора') { setCollapseType(!collapseType) }
    if (e.target.parentElement.parentElement.id === 'Сумма инвестиций') { setCollapseInvestSum(!collapseInvestSum) }
    if (e.target.parentElement.parentElement.id === 'По действующим договорам') { setCollapseActive(!collapseActive) }
    if (e.target.parentElement.parentElement.id === 'Проекты') { setCollapseProjects(!collapseProjects) }
    if (e.target.parentElement.parentElement.id === 'Количество проектов') { setCollapseAmountProjects(!collapseAmountProjects) }
    if (e.target.parentElement.parentElement.id === 'По брокеру') { setCollapseBrockers(!collapseBrockers) }
  }
  let tempArr: any = []
  const activeProject = (uuid: string) => {
    setActiveProjectItem?.(
      activeProjectItem?.map((elem: any) => {
        if (uuid === elem.uuid) {
          elem.active = !elem.active
        }
        return elem
      }),
    )
    tempArr = activeProjectItem.filter((el: any) => el.active === true)
    setSelectedProjects?.(tempArr.map((el: any) => el.name))
    setSelectedProjectsUUID?.(tempArr.map((el: any) => el.uuid))
  }

  const selectedBrocker = (uuid: string, name: string) => {
    if (uuid === selectedBrockerFilter.uuid) {
      setSelectedBrockerFilter({ uuid: null, name: '' })
    } else {
      setSelectedBrockerFilter({ uuid, name })
      setCollapseBrockers(!collapseBrockers)
    }
  }

  return (
    <ul className={clsx(s.wrapper, className)}>
      <li
        className={clsx(s.filterDocs_select_first, {
          [s.filterDocs_select_active]: collapseTerm,
        })}
      >
        <div
          className={s.filterDocs_select_header}
          id="Срок окончания договора"
        >
          <Title
            className={s.filterDocs_select_title}
            title="Срок окончания договора"
          />
          <ToggleButton
            className={s.filterDocs_select_btn}
            src={arrow}
            rotate={collapseTerm}
            iconWidth="18px"
            iconHeight="18px"
            onClick={(e: any) => makeCollapse(e)}
          />
        </div>
        <div className={s.button_wpapper}>
          <button
            className={clsx(
              s.button_variant,
              isButton3mActive ? s.button1_active : s.button1,
            )}
            onClick={e => filter3m?.(e)}
            id="3m"
            type="button"
          >
            3 мес
          </button>
          <button
            className={clsx(
              s.button_variant,
              isButton2mActive ? s.button2_active : s.button2,
            )}
            id="2m"
            onClick={e => filter3m?.(e)}
            type="button"
          >
            2 мес
          </button>
          <button
            className={clsx(
              s.button_variant,
              isButton1mActive ? s.button3_active : s.button3,
            )}
            id="1m"
            onClick={e => filter3m?.(e)}
            type="button"
          >
            1 мес
          </button>
        </div>
      </li>
      <li
        className={clsx(s.filterDocs_select, {
          [s.filterDocs_select_active]: collapseInvestSum,
        })}
      >
        <div className={s.filterDocs_select_header} id="Сумма инвестиций">
          <Title
            className={s.filterDocs_select_title}
            title="Сумма инвестиций"
          />
          <ToggleButton
            className={s.filterDocs_select_btn}
            src={arrow}
            rotate={collapseInvestSum}
            iconWidth="18px"
            iconHeight="18px"
            onClick={(e: any) => makeCollapse(e)}
          />
        </div>
        <p className={s.block__sum_subtitle}>За все время</p>
        <div className={s.block__sum_items}>
          <input
            placeholder="От..."
            className={s.block__sum_input}
            onChange={e => filterByInvestmentFrom?.(e)}
            id="fromSumValue"
            value={
              fromSumValue
                ? `${Number(fromSumValue.replace(/\D/g, '')).toLocaleString('ru')}`
                : fromSumValue
            }
          />
          <input
            placeholder="До..."
            className={s.block__sum_input}
            value={
              toSumValue
                ? `${Number(String(toSumValue).replace(/\D/g, '')).toLocaleString('ru')}`
                : toSumValue
            }
            onChange={e => filterByInvestmentTo?.(e)}
            id="toSumValue"
          />
        </div>
        <p className={s.block__sum_subtitle}>По действующим договорам</p>
        <div className={s.block__sum_items}>
          <input
            placeholder="От..."
            className={s.block__sum_input}
            value={
              fromSumValueActive
                ? `${Number(String(fromSumValueActive).replace(/\D/g, '')).toLocaleString('ru')}`
                : fromSumValueActive
            }
            onChange={e => filterByInvestmentFromActive?.(e)}
            id="fromSumValueActive"
          />
          <input
            placeholder="До..."
            className={s.block__sum_input}
            value={
              toSumValueActive
                ? `${Number(String(toSumValueActive).replace(/\D/g, '')).toLocaleString('ru')}`
                : toSumValueActive
            }
            onChange={e => filterByInvestmentToActive?.(e)}
            id="toSumValueActive"
          />
        </div>
      </li>
      <li
        className={clsx(s.filterDocs_select, {
          [s.filterDocs_select_active]: collapseProjects,
        })}
      >
        <div className={s.filterDocs_select_header} id="Проекты">
          <Title className={s.filterDocs_select_title} title="Проекты" />
          <ToggleButton
            className={s.filterDocs_select_btn}
            src={arrow}
            rotate={collapseProjects}
            iconWidth="18px"
            iconHeight="18px"
            onClick={(e: any) => makeCollapse(e)}
          />
        </div>
        <div className={s.block__project_name}>
          {activeProjectItem?.map(
            (elem: any, i: number) => elem.active && (
              <span key={i}>
                  &nbsp;
                {`${elem.name},`}
                  &nbsp;
              </span>
            ),
          )}
        </div>
        <div className={s.block__projects_items}>
          {activeProjectItem?.map((item: any) => (
            <div
              key={item.uuid}
              className={clsx(
                s.block__projects_item,
                item.active === true && s.active,
              )}
              onClick={() => activeProject(item.uuid)}
            >
              <p>{item.name}</p>
            </div>
          ))}
        </div>
      </li>
      <li
        className={clsx(s.filterDocs_select, {
          [s.filterDocs_select_active]: collapseAmountProjects,
        })}
      >
        <div className={s.filterDocs_select_header} id="Количество проектов">
          <Title
            className={s.filterDocs_select_title}
            title="Количество проектов"
          />
          <ToggleButton
            className={s.filterDocs_select_btn}
            src={arrow}
            rotate={collapseAmountProjects}
            iconWidth="18px"
            iconHeight="18px"
            onClick={(e: any) => makeCollapse(e)}
          />
        </div>
        <p className={s.block__sum_subtitle}>За все время</p>
        <div className={s.block__sum_items}>
          <input
            placeholder="От..."
            className={s.block__sum_input}
            value={filterByCountFromState}
            onChange={e => setFilterByCountFromState?.(e.target.value.trim())}
          />
          <input
            placeholder="До..."
            className={s.block__sum_input}
            value={filterByCountToState}
            onChange={e => setFilterByCountToState?.(e.target.value.trim())}
          />
        </div>
        <p className={s.block__sum_subtitle}>По действующим договорам</p>
        <div className={s.block__sum_items}>
          <input
            placeholder="От..."
            className={s.block__sum_input}
            value={filterByCountFromStateActive}
            onChange={e => setFilterByCountFromStateActive?.(e.target.value.trim())}
          />
          <input
            placeholder="До..."
            className={s.block__sum_input}
            value={filterByCountToStateActive}
            onChange={e => setFilterByCountToStateActive?.(e.target.value.trim())}
          />
        </div>
      </li>
      <li
        style={{ paddingLeft: 0, paddingRight: 0 }}
        className={clsx(s.filterDocs_select, {
          [s.filterDocs_select_active]: collapseBrockers,
        })}
      >
        <div
          style={{ padding: '0 30px' }}
          className={s.filterDocs_select_header}
          id="По брокеру"
        >
          <Title
            className={s.filterDocs_select_title}
            title={selectedBrockerFilter?.name ? `По брокеру: ${selectedBrockerFilter.name}` : 'По брокеру'}
          />
          <ToggleButton
            className={s.filterDocs_select_btn}
            src={arrow}
            rotate={collapseBrockers}
            iconWidth="18px"
            iconHeight="18px"
            onClick={(e: any) => makeCollapse(e)}
          />
        </div>
        <div className={s.block__project_name}>
          {brokerList?.map(
            (elem: any, i: number) => (
              <p
                onClick={() => selectedBrocker(elem.uuid, elem.name)}
                className={clsx(
                  s.list, s.list_currency, elem.name === selectedBrockerFilter?.name && s.list_active
                )}
                key={i}
              >
                {elem.name}
              </p>
            )
          )}
        </div>
      </li>
      <Button theme="outline" className={s.button} onClick={applyFilter}>
        {loaderFilter ? <Loader className={s.loader} /> : <span>Применить фильтр</span>}
      </Button>
    </ul>
  )
}
