import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const useGetRole = () => {
  const [isRole, setIsRole] = useState<string | null>('')
  const [profileUUID, setProfileUUID] = useState<string | null>(null)
  const [token, setToken] = useState<string | null>(null)

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    setIsRole(() => localStorage.getItem('role'))
    setProfileUUID(() => localStorage.getItem('profileUUID'))
    setToken(() => localStorage.getItem('token'))
  }, [isRole, profileUUID, token])

  return {
    isRole, profileUUID, token, location, navigate
  }
}
