import React from 'react'
import { Link } from 'react-router-dom'
import s from './NotFound.module.css'

export const NotFound = () => (
  <div className={s.wrapper}>
    <div className={s.wrapper__top}>
      <div className={s.no__page}>
        <div className={s.text__wrapper}>
          <div className={s.text__backgound_up}>
            <span className={s.text__noPage}>нет такой</span>
          </div>
          <div className={s.text__backgound_down}>
            <span className={s.text__noPage}>страницы</span>
          </div>
        </div>
      </div>
      <div className={s.number}>
        <span className={s.number__text}>404</span>
      </div>
    </div>
    <div className={s.wrapper__bottom}>
      <div className={s.wrapper__link}>
        <Link className={s.link__arrow} to="/">
          <div className={s.link__arrow}>&nbsp;</div>
        </Link>
        <Link className={s.link__text} to="/">
          на главную
        </Link>
      </div>
    </div>
  </div>
)
