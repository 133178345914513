import React, { useState, useEffect, FC } from 'react'
import clsx from 'clsx'
import { useQuery } from '@apollo/client'
import { GET_NUMBERS } from '../../../../apollo/query/editSite/mainPage'
import s from './NumbersSite.module.css'

interface NumbersSite {
  dataArray: any
}

export const NumbersSite: FC<NumbersSite> = ({ dataArray }) => {
  const [currentSlider, setCurrenrSlider] = useState(0)
  const [numbers, setNumbers] = useState<any>([])
  const {
    data: numbersData,
    loading: numbersLoading,
    error: numbersError,
  } = useQuery(GET_NUMBERS, {
    variables: {
      filter: {},
    },
  })

  const removeQuotes = (inputString: any) => {
    if (inputString === undefined) return null
    return inputString.replace(/"/g, '')
  }

  useEffect(() => {
    if (!dataArray) return
    const temp = dataArray?.length - 1
    const tempArr = dataArray
    setNumbers(tempArr)
    setCurrenrSlider(temp)
  }, [dataArray])

  const goOld = () => {
    let tmp = currentSlider
    if (tmp === 0) return
    tmp -= 1
    setCurrenrSlider(tmp)
  }

  const goNext = () => {
    let tmp = currentSlider
    // if (tmp === numbersData?.mediaNumbers?.length - 1) return
    if (tmp === dataArray.length - 1) return
    tmp += 1
    setCurrenrSlider(tmp)
  }

  return (
    <div className={s.numbers__content_block}>
      <div className={s.numbers__title_flex}>
        <p className={s.numbers__title}>Сколько</p>
        <p className={clsx(s.numbers__title, s.numbers__title_give)}>вложили</p>
        <p className={clsx(s.numbers__title, s.numbers__title_and)}>и</p>
        <p className={clsx(s.numbers__title, s.numbers__title_take)}>заработали</p>
        <p className={clsx(s.numbers__title, s.numbers__title_our)}>наши</p>
        <p className={clsx(s.numbers__title, s.numbers__title_clients)}>клиенты</p>
        <p className={s.numbers__title}>в</p>
        <div
          className={s.numbers__navigation_goback_arrow}
          onClick={() => goOld()}
        >
          &nbsp;
        </div>
        <div className={s.numbers__title_year}>
          {/* {numbers[currentSlider]?.year} */}
          {dataArray[currentSlider]?.year}
        </div>
        <div
          className={s.numbers__navigation_goforward_arrow}
          onClick={() => goNext()}
        >
          &nbsp;
        </div>
        <p className={s.numbers__title}>году:</p>
      </div>
      <div className={s.numbers__flex_box}>
        <div className={s.numbers__flex_box_1v}>
          <div className={s.numbers__flex_block}>
            <div className={s.numbers__big_numbers_wrapper}>
              <p className={s.numbers__big_numbers}>
                {removeQuotes(dataArray[currentSlider]?.investorProfit?.number)}
              </p>
              {dataArray[currentSlider]?.investorProfit?.type !== 'Ед. изм.' && (
                <p
                  className={
                    dataArray[currentSlider]?.investorProfit?.type !== '%'
                      ? s.numbers__big_numbers
                      : s.numbers__big_numbers_empty
                  }
                >
                  {dataArray[currentSlider]?.investorProfit?.type}
                </p>
              )}
            </div>
            <p className={s.numbers__small_description}>прибыль инвесторов</p>
          </div>
          <div className={s.numbers__flex_block}>
            <div className={s.numbers__big_numbers_wrapper}>
              <p className={s.numbers__big_numbers}>
                {removeQuotes(dataArray[currentSlider]?.investorInvested?.number)}
              </p>
              {dataArray[currentSlider]?.investorInvested?.type
              !== 'Ед. изм.' && (
                <p
                  className={
                    dataArray[currentSlider]?.investorInvested?.type
                    !== '%'
                      ? s.numbers__big_numbers
                      : s.numbers__big_numbers_empty
                  }
                >
                  {dataArray[currentSlider]?.investorInvested?.type}
                </p>
              )}
            </div>
            <p className={s.numbers__small_description}>Вложили инвесторы</p>
          </div>
        </div>
        <div className={s.numbers__flex_box_2v}>
          <div className={s.numbers__flex_block}>
            <div className={s.numbers__big_numbers_wrapper}>
              <p className={s.numbers__big_numbers}>
                {removeQuotes(dataArray[currentSlider]?.returnOnInvestment?.number)}
              </p>
              {dataArray[currentSlider]?.returnOnInvestment?.type
              !== 'Ед. изм.' && (
                <p
                  className={
                    dataArray[currentSlider]?.returnOnInvestment?.type !== '%'
                      ? s.numbers__big_numbers
                      : s.numbers__big_numbers_empty
                  }
                >
                  {dataArray[currentSlider]?.returnOnInvestment?.type}
                </p>
              )}
            </div>
            <p className={s.numbers__small_description}>
              прибыльность инвестиций
            </p>
          </div>
          <div className={s.numbers__flex_block}>
            <div className={s.numbers__big_numbers_wrapper}>
              <p className={s.numbers__big_numbers}>
                {removeQuotes(dataArray[currentSlider]?.transactionsCompleted?.number)}
              </p>
              {dataArray[currentSlider]?.transactionsCompleted?.type
              !== 'Ед. изм.' && (
                <p
                  className={
                    dataArray[currentSlider]?.returnOnInvestment?.type !== '%'
                      ? s.numbers__big_numbers
                      : s.numbers__big_numbers_empty
                  }
                >
                  {dataArray[currentSlider]?.transactionsCompleted?.type}
                </p>
              )}
            </div>
            <p className={s.numbers__small_description}>СДЕЛОК ЗАВЕРШЕНО</p>
          </div>
        </div>
        <div className={s.numbers__flex_box_3v}>
          <div className={s.numbers__flex_block}>
            <div className={s.numbers__big_numbers_wrapper}>
              <p className={s.numbers__big_numbers}>
                {removeQuotes(dataArray[currentSlider]?.reinvest?.number)}
              </p>
              {dataArray[currentSlider]?.reinvest?.type !== 'Ед. изм.' && (
                <p
                  className={
                    dataArray[currentSlider]?.reinvest?.type !== '%'
                      ? s.numbers__big_numbers
                      : s.numbers__big_numbers_empty
                  }
                >
                  {dataArray[currentSlider]?.reinvest?.type}
                </p>
              )}
            </div>
            <p className={s.numbers__small_description}>инвестируют повторно</p>
          </div>
          <div className={s.numbers__flex_block}>
            <div className={s.numbers__big_numbers_wrapper}>
              <p className={s.numbers__big_numbers}>
                {removeQuotes(dataArray[currentSlider]?.averageInvestorCheck?.number)}
              </p>
              {dataArray[currentSlider]?.averageInvestorCheck?.type
              !== 'Ед. изм.' && (
                <p
                  className={
                    dataArray[currentSlider]?.averageInvestorCheck?.type !== '%'
                      ? s.numbers__big_numbers
                      : s.numbers__big_numbers_empty
                  }
                >
                  {dataArray[currentSlider]?.averageInvestorCheck?.type}
                </p>
              )}
            </div>
            <p className={s.numbers__small_description}>СРЕДНИЙ ЧЕК ИНВЕСТОРА</p>
          </div>
        </div>
      </div>
    </div>
  )
}
