import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useMutation } from '@apollo/client'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  addSlide,
  changeColorSlide,
  changeFieldState,
  changeFirstField,
  deleteSlide,
  changeColorMiniature,
  changeStatusSelectPromotionSlide,
  resetPromotionObj,
} from '../../redux/slices/promotionSlice'
import { closePromotion } from '../../redux/slices/pageSlice'
import { resetPromotionDynamicObj } from '../../redux/slices/promotionDynamicSlice'
import { PROMOTION_STORY_CREATE } from '../../apollo/mutation/promotion/promotionCreate'
import { STORIES } from '../../apollo/query/promotion/stories'
import {
  StoriesSlide,
  StoriesMiniature,
  StoriesInfo,
  Button,
  Panel,
  ProgressBar,
  Title,
  ToggleButton,
  SliderUI,
  MiniatureUI,
} from '../../ui'
import fullFill from './img/icon-fillfull.svg'
import empty from './img/icon-empty.svg'
import arrow from './img/icon-arrow-up.svg'
import s from './StoriesPromotion.module.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const StoriesPromotion = () => {
  const [createStories, { data, loading, error }] = useMutation(
    PROMOTION_STORY_CREATE,
    {
      refetchQueries: [
        { query: STORIES },
        {
          query: STORIES,
          variables: {
            filter: {
              status: 'active',
            },
          },
        },
        {
          query: STORIES,
          variables: {
            filter: {
              status: 'draft',
            },
          },
        },
        {
          query: STORIES,
          variables: {
            filter: {
              status: 'archive',
            },
          },
        },
      ],
    }
  )
  const { promotionObj, colorsMiniature, statusSelect } = useAppSelector(
    state => state.promotionSlice
  )
  const dispatch = useAppDispatch()

  const [sumField, setSumField] = useState(0)
  const {
    color,
    image,
    gradientColor,
    gradientDeg,
    header,
    gradientLocation,
    ...promDynamicObj
  } = promotionObj
  const arr = Object.values(promDynamicObj)
  useEffect(() => {
    setSumField(
      arr.map(e => (e ? 16.6 : 0)).reduce((a: any, b: any) => a + b)
    )
  }, [promotionObj])

  const [collapse, setCollapse] = useState(false)
  const [collapseMiniature, setCollapseMiniature] = useState(false)

  const [btn, setBtn] = useState(1)

  const slideRef = useRef<any>(null)

  const next = () => {
    setBtn(prev => (prev >= promotionObj?.slides?.length ? 1 : prev + 1))
    slideRef?.current?.slickNext()
  }
  const previous = () => {
    slideRef?.current?.slickPrev()
    setBtn(prev => (prev <= 1 ? promotionObj?.slides?.length : prev - 1))
  }

  return (
    <div className={s.main_wrapper}>
      <div className={s.wrapper}>
        <StoriesInfo
          promotion="stories"
          statusSelect={statusSelect}
          name={promotionObj?.name}
          status={promotionObj?.status}
          startDate={new Date(Date.parse(promotionObj?.startedAt))}
          endDate={new Date(Date.parse(promotionObj?.endedAt))}
          changeField={(e: any, nameField: string) => dispatch(changeFirstField({ event: e, nameField }))}
          changeStatus={() => dispatch(changeStatusSelectPromotionSlide())}
        />
        <StoriesMiniature
          colorsMiniature={colorsMiniature}
          title={promotionObj?.header}
          // colorMiniature={promotionObj.colorMiniature}
          // image={promotionObj.imageMiniature}
          changeColorMiniature={(
            colorId: number,
            color: string,
            nameGroup: string,
            index: number
          ) => dispatch(
            changeColorMiniature({
              colorId,
              color,
              nameGroup,
              index,
            })
          )}
          changeField={(e: any, nameField: string) => dispatch(changeFirstField({ event: e, nameField }))}
        />
        {promotionObj?.slides.map((elem, i) => (
          <StoriesSlide
            // colorsSlide={colorsSlide}
            key={i}
            indexSlide={i}
            slide={elem}
            addSlide={() => dispatch(addSlide())}
            changeField={(e: any, id: number, nameField: string) => dispatch(
              changeFieldState({ event: e.target.value, id, nameField })
            )}
            deleteItemSlide={(id: number) => dispatch(deleteSlide(id))}
            changeColorSlide={(
              colorId: number,
              slideId: number,
              color: string,
              nameGroup: string,
              index: number
            ) => dispatch(
              changeColorSlide({
                colorId,
                slideId,
                color,
                nameGroup,
                index,
              })
            )}
          />
        ))}
        <Button
          disabled={
            Math.ceil(sumField + (image || color || header ? 16.6 : 0)) !== 100
          }
          theme={
            Math.ceil(sumField + (image || color || header ? 16.6 : 0)) !== 100
              ? 'secondary'
              : 'dark'
          }
          onClick={() => {
            const { status, slides, ...storiesObj } = promotionObj
            createStories({
              variables: {
                data: {
                  ...storiesObj,
                  status:
                    status === 'Активный'
                      ? 'active'
                      : status === 'Черновик'
                        ? 'draft'
                        : 'archive',
                  slides: JSON.stringify(slides),
                },
              },
            })
            setTimeout(() => {
              dispatch(closePromotion())
              dispatch(resetPromotionObj())
              dispatch(resetPromotionDynamicObj())
            }, 1500)
          }}
        >
          Сохранить
        </Button>
      </div>
      <div className={s.panel__progress}>
        <Panel padding>
          <ProgressBar
            className={s.project_create}
            value={Math.ceil(sumField + (image || color || header ? 16.6 : 0))}
          />
          <div className={s.project_indicator}>
            <img
              src={
                promotionObj.endedAt
                && promotionObj.startedAt
                && promotionObj.name
                && promotionObj.status
                  ? fullFill
                  : empty
              }
              alt="empty"
            />
            <p className={s.project_status_item}>Информация</p>
          </div>
          <div className={s.project_indicator}>
            <img
              src={promotionObj.header || color || image ? fullFill : empty}
              alt="empty"
            />
            <p className={s.project_status_item}>Превью</p>
          </div>
          <div className={s.project_indicator}>
            <img src={promotionObj.slides ? fullFill : empty} alt="empty" />
            <p className={s.project_status_item}>Контент</p>
          </div>
        </Panel>
        <Panel
          padding
          className={clsx(s.promotion_select, {
            [s.promotion_select_active]: collapseMiniature,
          })}
        >
          <div className={s.promotion_select_header}>
            <Title className={s.dynamic_title} title="Миниатюра" />
            <ToggleButton
              className={s.promotion_select_btn}
              src={arrow}
              rotate={collapseMiniature}
              iconWidth="18px"
              iconHeight="18px"
              onClick={() => setCollapseMiniature(!collapseMiniature)}
            />
          </div>
          <MiniatureUI
            image={image!}
            color={color!}
            header={promotionObj.header}
          />
        </Panel>
        <Panel
          padding
          className={clsx(s.promotion_select, {
            [s.promotion_select_active]: collapse,
          })}
        >
          <div className={s.promotion_select_header}>
            <Title className={s.dynamic_title} title="Слайд" />
            <ToggleButton
              className={s.promotion_select_btn}
              src={arrow}
              rotate={collapse}
              iconWidth="18px"
              iconHeight="18px"
              onClick={() => setCollapse(!collapse)}
            />
          </div>
          <SliderUI
            slides={promotionObj?.slides}
            slideRef={slideRef}
            next={next}
            previous={previous}
            btn={btn}
          />
        </Panel>
      </div>
    </div>
  )
}
