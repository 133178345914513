import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { addInfoOrgField } from '../../redux/slices/clientSlice'
import {
  Field, Label, Panel, ProgressBar, Title
} from '../../ui'
import fullFill from './img/icon-fillfull.svg'
import empty from './img/icon-empty.svg'
import s from './ClientInfoOrg.module.css'

interface ClientInfoOrgProps {
  name: string
  setName: ((i: string) => void) | any
  innOrg: string
  setInnOrg: ((i: string) => void) | any
  address: string
  setAddress: ((i: string) => void) | any
  rs: string
  setRs: ((i: string) => void) | any
  ks: string
  setKs: ((i: string) => void) | any
  bank: string
  setBank: ((i: string) => void) | any
  bik: string
  setBik: ((i: string) => void) | any
}

export const ClientInfoOrg = ({
  name,
  setName,
  innOrg,
  setInnOrg,
  address,
  setAddress,
  rs,
  setRs,
  ks,
  setKs,
  bank,
  setBank,
  bik,
  setBik,
}: ClientInfoOrgProps) => {
  const dispatch = useAppDispatch()
  const { clientInfo } = useAppSelector(state => state.clientSlice)
  const [sumField, setSumField] = useState(0)

  useEffect(() => {
    setSumField(
      Object.values(clientInfo?.infoOrg)
        .map(e => (e ? 14.2 : 0))
        .reduce((a: any, b: any) => a + b)
    )
  }, [clientInfo])

  return (
    <div>
      <Panel padding>
        <Title title="Данные" className={s.title} />
        <Label title="ИП">
          <Field
            view="default"
            value={name}
            maxLength={200}
            onChange={(e: any) => {
              setName(e.target.value)
              dispatch(
                addInfoOrgField({
                  fieldName: 'name',
                  item: e.target.value,
                })
              )
            }}
          />
        </Label>
        <Label title="ИНН" className={s.middleBlock}>
          <Field
            view="default"
            value={innOrg}
            maxLength={12}
            onChange={(e: any) => {
              setInnOrg(e.target.value.replace(/\D/g, ''))
              dispatch(
                addInfoOrgField({
                  fieldName: 'inn',
                  item: e.target.value,
                })
              )
            }}
          />
        </Label>
        <Label title="Зарегистрирован">
          <Field
            view="default"
            value={address}
            maxLength={200}
            onChange={(e: any) => {
              setAddress(e.target.value)
              dispatch(
                addInfoOrgField({
                  fieldName: 'address',
                  item: e.target.value,
                })
              )
            }}
          />
        </Label>
      </Panel>
      <Panel padding className={s.req}>
        <Title title="Реквизиты" className={s.title} />
        <div className={clsx(s.blockReq, s.firstBlock)}>
          <Label title="Счет">
            <Field
              view="default"
              value={rs}
              maxLength={20}
              onChange={(e: any) => {
                setRs(e.target.value.replace(/\D/g, ''))
                dispatch(
                  addInfoOrgField({
                    fieldName: 'rs',
                    item: e.target.value,
                  })
                )
              }}
            />
          </Label>
          <Label title="Кор. счет">
            <Field
              view="default"
              value={ks}
              maxLength={20}
              onChange={(e: any) => {
                setKs(e.target.value.replace(/\D/g, ''))
                dispatch(
                  addInfoOrgField({
                    fieldName: 'ks',
                    item: e.target.value,
                  })
                )
              }}
            />
          </Label>
        </div>
        <div className={s.blockReq}>
          <Label title="Банк">
            <Field
              type="text"
              view="default"
              value={bank}
              maxLength={150}
              onChange={(e: any) => {
                setBank(e.target.value)
                dispatch(
                  addInfoOrgField({
                    fieldName: 'bank',
                    item: e.target.value,
                  })
                )
              }}
            />
          </Label>
          <Label title="БИК">
            <Field
              view="default"
              value={bik}
              maxLength={9}
              onChange={(e: any) => {
                setBik(e.target.value.replace(/\D/g, ''))
                dispatch(
                  addInfoOrgField({
                    fieldName: 'bik',
                    item: e.target.value,
                  })
                )
              }}
            />
          </Label>
        </div>
      </Panel>

      <Panel className={s.panel__progress} padding>
        <ProgressBar className={s.project_create} value={Math.ceil(sumField)} />
        <div className={s.project_indicator}>
          <img src={name && innOrg && address ? fullFill : empty} alt="empty" />
          <p>Данные</p>
        </div>
        <div className={s.project_indicator}>
          <img src={rs && ks && bank && bik ? fullFill : empty} alt="empty" />
          <p>Реквизиты</p>
        </div>
      </Panel>
    </div>
  )
}
