import { gql } from '@apollo/client'

export const GET_CHAT_MESSAGES = gql`
  query getChatMessages($chatChannelId: Int!) {
    chatMessages(chatChannelId: $chatChannelId) {
      id
      text
      messageType
      status
      createdAt
      chatChannel {
        id
        createdAt
        members {
          role
          uuid
        }
      }
      profile {
        role
        uuid
        client {
          firstName
          lastName
          photo
        }
        broker {
          photo
          name
        }
        lawyer {
          photo
          name
        }
        manager {
          photo
          name
        }
        superuser {
          photo
          name
        }
      }
      fileName
    }
  }
`

export const GET_CHAT_MESSAGES_LAWYER = gql`
  query chatMessageALawyer($chatChannelId: Int!) {
    chatMessageALawyer(chatChannelId: $chatChannelId) {
      id
      text
      messageType
      status
      createdAt
      chatChannel {
        id
        createdAt
        members {
          role
          uuid
        }
      }
      profile {
        role
        uuid
        client {
          firstName
          lastName
          photo
        }
        broker {
          photo
          name
        }
        lawyer {
          photo
          name
        }
        manager {
          photo
          name
        }
        superuser {
          photo
          name
        }
      }
      fileName
    }
  }
`
