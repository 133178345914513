/* eslint-disable react/jsx-curly-newline */
import React, {
  FC, useEffect, useRef, useState
} from 'react'
import { useMutation } from '@apollo/client'
import { Panel } from '../../ui'
import { UploadChat } from '../UploadChat'
import plus from './img/icon-plus.svg'
import cross from './img/icon-cross.svg'
import exitChat from './img/icon-chat-exit-blue.svg'
import exitChatWhite from './img/icon-chat-exit-white.svg'
import sent from './img/icon-sent.svg'
import folder from './img/icon-folder.svg'
import photo from './img/icon-photo.svg'
import s from './ChatCreateText.module.css'
import { POST_CHAT_MESSAGES } from '../../apollo/mutation/chats/postChatMessages'
import { GET_CHAT_MESSAGES } from '../../apollo/query/chats/chatMessages'

export const ChatCreateText: FC<any> = ({
  postMessage,
  onChatExit,
  isRole,
  chatChannelId,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [isIconWhite, setIsIconWhite] = useState<boolean>(false)
  const [value, setValue] = useState<string>('')
  const [
    postMessageHandler,
    {
      data: postMessageDate,
      loading: postMessageLoading,
      error: postMessageError,
    },
  ] = useMutation(POST_CHAT_MESSAGES, {
    refetchQueries: [
      {
        query: GET_CHAT_MESSAGES,
        variables: {
          chatChannelId,
        },
      },
    ],
  })

  const exitButton = useRef<HTMLDivElement>(null)

  useEffect(() => {
    exitButton.current?.addEventListener('mousemove', () =>
      setIsIconWhite(true)
    )
    exitButton.current?.addEventListener('mouseout', () =>
      setIsIconWhite(false)
    )
  }, [exitButton])

  const handleToggleBtn = () => setOpen(!open)

  const handlerFile = (messageType: string, text: any, fileName: string) => {
    postMessageHandler({
      variables: {
        text,
        chatChannelId,
        messageType,
        fileName,
      },
    })
  }

  return (
    <div className={s.chat__text} style={{ height: open ? '470px' : '100px' }}>
      <div className={s.chat__text_bottom}>
        <div className={s.chat__btn} onClick={handleToggleBtn}>
          <img src={open ? cross : plus} alt="plus" width="50" height="50" />
        </div>
        <div className={s.chat__text_wrapper}>
          <input
            className={s.chat__text_input}
            type="text"
            onChange={e => {
              setValue(e.target.value)
            }}
            value={value}
            maxLength={250}
            placeholder="Сообщение..."
          />
          {value && (
            <div
              className={s.chat__text_sent}
              onClick={() => {
                postMessage(value)
                setValue('')
              }}
            >
              <img src={sent} alt="sent" width="50" height="50" />
            </div>
          )}
        </div>
      </div>
      <div className={s.chat_upload}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            columnGap: 30,
          }}
        >
          <UploadChat
            src={folder}
            text="Файл"
            handlerFile={(messageType: string, text: any, fileName: string) =>
              handlerFile(messageType, text, fileName)
            }
            uploadId="doc"
          />
          <UploadChat
            src={photo}
            text="Фото"
            application="image/*"
            handlerFile={(messageType: string, text: any, fileName: string) =>
              handlerFile(messageType, text, fileName)
            }
            uploadId="image"
          />
        </div>
        {isRole !== 'manager' && (
          <div ref={exitButton} style={{ cursor: 'pointer' }}>
            <Panel className={s.chat_exit}>
              <button type="button" onClick={onChatExit}>
                <img
                  src={isIconWhite ? exitChatWhite : exitChat}
                  alt="exitChat"
                  width="35"
                  height="35"
                />
                <span>Покинуть чат</span>
              </button>
            </Panel>
          </div>
        )}
      </div>
    </div>
  )
}
