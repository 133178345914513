/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useNavigate, useLocation } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch } from '../../redux/hook'
import { logIn } from '../../redux/slices/authSlice'
import { AUTH } from '../../apollo/query/authorize'
import {
  Title, Field, Button, Logo, Loader
} from '../../ui'
import s from './Login.module.css'

export const Login = () => {
  const dispatch = useAppDispatch()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      login: '',
      password: '',
    },
  })

  const [getAuthorize, { data, loading, error }] = useLazyQuery(AUTH)

  const location = useLocation()
  const navigate = useNavigate()

  const fromPage = location.state?.from?.pathname || '/home'
  const toPageTech1 = '/clients'
  const toPageTech2 = '/documents'
  const toPageMarketer = '/promotion'

  // Запись токена в localStorage
  useEffect(() => {
    if (
      data?.authorize?.profile?.role === 'admin' ||
      data?.authorize?.profile?.role === 'lawyer' ||
      data?.authorize?.profile?.role === 'manager'
    ) {
      localStorage.setItem('token', `${data?.authorize?.accessToken}`)
      localStorage.setItem('role', `${data?.authorize?.profile?.role}`)
      localStorage.setItem('profileUUID', `${data?.authorize?.profile?.uuid}`)
      dispatch(logIn(localStorage.getItem('token')))
      navigate(fromPage, { replace: true })
    } else if (data?.authorize?.profile?.role === 'technical1') {
      localStorage.setItem('token', `${data?.authorize?.accessToken}`)
      localStorage.setItem('role', `${data?.authorize?.profile?.role}`)
      dispatch(logIn(localStorage.getItem('token')))
      navigate(toPageTech1, { replace: true })
    } else if (data?.authorize?.profile?.role === 'technical2') {
      localStorage.setItem('token', `${data?.authorize?.accessToken}`)
      localStorage.setItem('role', `${data?.authorize?.profile?.role}`)
      dispatch(logIn(localStorage.getItem('token')))
      navigate(toPageTech2, { replace: true })
    } else if (data?.authorize?.profile?.role === 'marketer') {
      localStorage.setItem('token', `${data?.authorize?.accessToken}`)
      localStorage.setItem('role', `${data?.authorize?.profile?.role}`)
      dispatch(logIn(localStorage.getItem('token')))
      navigate(toPageMarketer, { replace: true })
    }
  }, [data])

  const onSubmit = (data: any) => {
    getAuthorize({
      variables: {
        ...data,
      },
    })
    reset()
  }

  return (
    <div className={s.mainBlock}>
      <Logo className={s.logo} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.block}>
          <Title className={s.login__title} title="Вход" />

          <div className={s.inputBlock}>
            <Controller
              control={control}
              name="login"
              rules={{
                required: 'Поле обязательно',
                minLength: {
                  value: 3,
                  message: 'Минимум 3 символа',
                },
              }}
              render={({ field, fieldState }) => (
                <Field
                  {...field}
                  placeholder={
                    errors?.login?.message ? errors?.login?.message : 'Логин'
                  }
                  value={field.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    field.onChange(e.target.value.trimStart())
                  }}
                  ref={null}
                  errors={fieldState.error}
                  error={!!errors?.login?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              rules={{
                required: 'Поле обязательно',
              }}
              render={({ field, fieldState }) => (
                <Field
                  // eslint-disable-next-line react/jsx-boolean-value
                  eye={true}
                  type="password"
                  {...field}
                  placeholder={
                    errors?.password?.message
                      ? errors?.password?.message
                      : 'Пароль'
                  }
                  value={field.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    field.onChange(e.target.value.trimStart())
                  }}
                  ref={null}
                  errors={fieldState.error}
                  error={!!errors?.password?.message}
                />
              )}
            />
          </div>
          <Button
            className={s.btn}
            type="submit"
            theme={!isValid ? 'secondary' : 'dark'}
            disabled={!isValid}
          >
            {loading ? (
              <Loader className={s.loader} />
            ) : error ? (
              'Ошибка'
            ) : (
              'Войти'
            )}
          </Button>
        </div>
      </form>
    </div>
  )
}
