import React, { InputHTMLAttributes, useEffect, useRef } from 'react'
import clsx from 'clsx'
import s from './InputFile.module.css'

type inputFileProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string
  application?: string
  subText1?: string
  subText2?: string
  classNameSpan?: string
  classNameSubText?: string
  uploadId?: string
  label?: boolean
  src?: string
  text?: string
  onChange?: (e: any, text: string) => void
}

export const InputFile = ({
  className,
  application,
  subText1,
  subText2,
  classNameSpan,
  classNameSubText,
  uploadId,
  label = true,
  src,
  text = '',
  onChange,
  ...rest
}: inputFileProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const spanRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    const handleMouseMove = () => {
      // spanRef.current?.classList.add('hovered')
      if (ref.current) {
        ref.current.style.zIndex = '0'
      }
    }
    const handleMouseOut = () => {
      // spanRef.current?.classList.remove('hovered')
      if (ref.current) {
        ref.current.style.zIndex = '2'
      }
    }
    if (ref?.current) {
      ref.current.addEventListener('mousemove', handleMouseMove)
      ref.current.addEventListener('mouseout', handleMouseOut)
    }
    if (spanRef?.current) {
      spanRef.current.addEventListener('mousemove', handleMouseMove)
    }
  }, [ref, spanRef])

  return (
    <div className={clsx(s.upload__wrapper, className)}>
      <input
        ref={ref}
        className={s.input}
        type="file"
        id={uploadId}
        accept={application}
        onChange={onChange}
        {...rest}
      />
      {label && (
        <label className={clsx(s.notice, classNameSpan)} htmlFor={uploadId}>
          <span className={classNameSubText} ref={spanRef}>
            {subText1}
          </span>
          <span>{subText2}</span>
        </label>
      )}
      {src && (
        <div style={{ marginTop: 19, cursor: 'pointer' }}>
          <img src={src} alt={src} width="35" height="35" />
          <p
            style={{
              marginTop: 5,
              fontSize: 14,
              fontWeight: 700,
            }}
          >
            {text}
          </p>
        </div>
      )}
    </div>
  )
}
