import { gql } from '@apollo/client'

export const POST_CHAT_MESSAGES = gql`
  mutation postChatMessages(
    $text: String!
    $chatChannelId: Int!
    $messageType: ChatMessageType!
    $fileName: String
  ) {
    chatPostMessage(
      text: $text
      chatChannelId: $chatChannelId
      messageType: $messageType
      fileName: $fileName
    )
  }
`
