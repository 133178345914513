import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  changeSelect,
  addUsers,
  changeSingleSelect,
} from '../../redux/slices/selectSlice'
import { Container } from '../Container'
import s from './Select.module.css'

interface SelectType {
	className?: string
	onClick?: () => void
	onBlur?: () => void
	show?: boolean
	tags?: boolean
	user?: any[]
	draft?: boolean
	setSearchValueClient?: any
	searchValueClient?: null | any
	setShowInput?: any
	showInput?: null | any
	defaultTitle?: string
	refetch?: any
  act?:boolean
  setSearchValueClientAct?: any
  searchValueClientAct?: null | any
  setShowInputAct?:any
  showInputAct?: null | any
  setShowSelectClientsAct?: any
  showSelectClientsAct?: boolean
  contract?:boolean
  contracts?: any
  setSearchValueClientActContracts?: any
  searchValueClientActContracts?: any
  setShowInputActContracts?: any
  showInputActContracts?: boolean
  setShowSelectClientsActContracts?: any
  showSelectClientsActContracts?: boolean
  searchContractHandler?: any
  dispatchActUUIDClient?: any
  dispatchActUUIDContract?: any
  report?: boolean
  showInputReport?: boolean
  setShowInputReport?: (arg: boolean) => void
  setShowSelectClientsReport?: (arg: boolean) => void
  showSelectClientsReport?: boolean
  setSearchValueClientReport?: (arg: string) => void
  searchValueClientReport?: string
  dispatchReportUUIDClient?: (arg: string) => void
  firstName?: any
}

export const Select: React.FC<SelectType> = ({
  className,
  onClick,
  onBlur,
  show,
  user,
  tags = false,
  draft,
  setSearchValueClient,
  searchValueClient,
  setShowInput,
  showInput,
  defaultTitle,
  refetch,
  act,
  setSearchValueClientAct,
  searchValueClientAct,
  setShowInputAct,
  showInputAct,
  setShowSelectClientsAct,
  showSelectClientsAct,
  contract,
  contracts,
  setSearchValueClientActContracts,
  searchValueClientActContracts,
  setShowInputActContracts,
  showInputActContracts,
  setShowSelectClientsActContracts,
  showSelectClientsActContracts,
  searchContractHandler,
  dispatchActUUIDClient,
  dispatchActUUIDContract,
  report,
  showInputReport,
  setShowInputReport,
  showSelectClientsReport,
  setShowSelectClientsReport,
  setSearchValueClientReport,
  searchValueClientReport,
  dispatchReportUUIDClient,
  firstName,
}) => {
  const dispatch = useAppDispatch()
  const { selNum, information, singleSelect } = useAppSelector(
    state => state.selectSlice,
  )
  const [value, setValue] = useState<string | number>(
    defaultTitle || 'Выберите из списка',
  )
  const inputRef = useRef<HTMLInputElement | any>()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [showInput])

  useEffect(() => {
    if (draft) {
      setValue('Выберите из списка')
    }
  }, [draft])

  useEffect(() => {
    if (act) {
      if (defaultTitle) {
        setValue(defaultTitle)
      }
    }
  }, [defaultTitle])

  useEffect(() => {
    if (contract) {
      if (defaultTitle) {
        setValue(defaultTitle)
      }
    }
  }, [defaultTitle])

  const useItemSelect = (item: string | number, name: string | number) => {
    setValue(name)
    if (singleSelect) {
      const item = name
      dispatch(changeSingleSelect())
      dispatch(addUsers({ project: 'withdrawalDay', item }))
    } else {
      dispatch(
        addUsers(
          selNum === 1
            ? { project: 'projectUUID', item }
            : selNum === 2
              ? { project: 'clientUUID', item }
              : selNum === 3
                ? { project: 'brokerUUID', item }
                : null,
        ),
      )
      dispatch(changeSelect(selNum))
    }
  }

  const useItemSelectAct = (uuidClient: string | number, name: string | number) => {
    setValue(name)
    setShowSelectClientsAct(!showSelectClientsAct)
    dispatchActUUIDClient(uuidClient)
  }

  const useItemSelectReport = (uuidClient: string, name: string | number) => {
    setValue(name)
    if (setShowSelectClientsReport !== undefined) {
      setShowSelectClientsReport(!showSelectClientsReport)
    }
    // if (dispatchReportUUIDClient) dispatchReportUUIDClient(uuidClient)
    dispatchReportUUIDClient?.(uuidClient)
  }

  const useItemSelectActContracts = (uuidContract: string | number, number: string | number) => {
    setValue(number)
    setShowSelectClientsActContracts(!setShowSelectClientsActContracts)
    dispatchActUUIDContract(number)
  }

  return (
    <>
      {!act && !contract && !report && (
        <div>
          {showInput ? (
            <div className={s.activeInputContainer}>
              <input
                ref={inputRef}
                value={searchValueClient}
                onChange={e => {
                  setSearchValueClient(e.target.value.trimStart())
                  refetch({
                    filter: {
                      lastName: e.target.value.trimStart(),
                    },
                  })
                }}
                className={clsx(s.dropdownBtn, show && user ? s.active : '')}
              />
              <div className={s.activeInput} onClick={onClick} />
            </div>
          ) : (
            <button
              className={clsx(s.dropdownBtn, show && user ? s.active : '')}
              type="button"
              onClick={onClick}
              onBlur={onBlur}
            >
              {value}
            </button>
          )}
          {user ? (
            <Container
              className={clsx(s.container, className, show ? s.visible : '')}
            >
              <ul className={clsx(s.list_container, tags ? s.tags_container : '')}>
                {user?.map((item, index) => (
                  <li
                    className={clsx(s.list, tags ? s.tags : '')}
                    onClick={() => {
                      useItemSelect(
                        item.uuid,
                        item.lastName
                          ? `${item.lastName} ${item.firstName} ${item.middleName}`
                          : item.name
                            ? item.name
                            : index + 1,
                      )
                      if (item.lastName) {
                        setShowInput(false)
                        setSearchValueClient('')
                      }
                    }}
                    key={index}
                  >
                    {item.lastName
                      ? `${item.lastName} ${item.firstName} ${item.middleName}`
                      : item.name
                        ? item.name
                        : index + 1}
                  </li>
                ))}
              </ul>
            </Container>
          ) : null}
        </div>
      )}
      {act && (
        <>
          {showInputAct ? (
            <div className={s.activeInputContainer}>
              <input
                ref={inputRef}
                value={searchValueClientAct}
                onChange={e => {
                  setSearchValueClientAct(e.target.value.trimStart())
                  refetch({
                    filter: {
                      lastName: e.target.value.trimStart(),
                    },
                  })
                }}
                className={clsx(s.dropdownBtn, show && user ? s.active : '')}
              />
              <div className={s.activeInput} onClick={onClick} />
            </div>
          ) : (
            <button
              className={clsx(s.dropdownBtn, show && user ? s.active : '')}
              type="button"
              onClick={onClick}
              onBlur={onBlur}
            >
              {value}
            </button>
          )}
          <Container className={clsx(s.container, className, show ? s.visible : '')}>
            <ul className={clsx(s.list_container)}>
              {user?.map((item, index) => (
                <li
                  className={clsx(s.list)}
                  onClick={() => {
                    useItemSelectAct(
                      item.uuid,
                      item.lastName
                        ? `${item.lastName} ${item.firstName} ${item.middleName}`
                        : item.name
                          ? item.name
                          : index + 1,
                    )
                    if (item.lastName) {
                      setShowInputAct(false)
                      setSearchValueClientAct('')
                    }
                  }}
                  key={index}
                >
                  {item.lastName
                    ? `${item.lastName} ${item.firstName} ${item.middleName}`
                    : item.name
                      ? item.name
                      : index + 1}
                </li>
              ))}
            </ul>
          </Container>
        </>
      )}
      {report && (
        <>
          {showInputReport ? (
            <div className={s.activeInputContainer}>
              <input
                ref={inputRef}
                value={searchValueClientReport}
                onChange={e => {
                  if (setSearchValueClientReport !== undefined) {
                    setSearchValueClientReport(e.target.value.trimStart())
                  }
                  refetch({
                    filter: {
                      lastName: e.target.value.trimStart(),
                    },
                  })
                }}
                className={clsx(s.dropdownBtn, show && user ? s.active : '')}
              />
              <div className={s.activeInput} onClick={onClick} />
            </div>
          ) : (
            <button
              className={clsx(s.dropdownBtn, show && user ? s.active : '')}
              type="button"
              onClick={onClick}
              onBlur={onBlur}
            >
              {value}
            </button>
          )}
          <Container className={clsx(s.container, className, show ? s.visible : '')}>
            <ul className={clsx(s.list_container)}>
              {user?.map((item, index) => (
                <li
                  className={clsx(s.list)}
                  onClick={() => {
                    useItemSelectReport(
                      item.uuid,
                      item.lastName
                        ? `${item.lastName} ${item.firstName} ${item.middleName}`
                        : item.name
                          ? item.name
                          : index + 1,
                    )
                    if (item.lastName) {
                      if (setShowInputReport !== undefined) {
                        setShowInputReport(false)
                      }
                      if (setSearchValueClientReport) setSearchValueClientReport('')
                    }
                  }}
                  key={index}
                >
                  {item.lastName
                    ? `${item.lastName} ${item.firstName} ${item.middleName}`
                    : item.name
                      ? item.name
                      : index + 1}
                </li>
              ))}
            </ul>
          </Container>
        </>
      )}
      {contract && (
        <>
          {showInputActContracts ? (
            <div className={s.activeInputContainer}>
              <input
                ref={inputRef}
                value={searchValueClientAct}
                // onChange={e => {
                //   setSearchValueClientActContracts(e.target.value.trimStart())
                //   // refetch({
                //   //   filter: {
                //   //     lastName: e.target.value.trimStart(),
                //   //   },
                //   // })
                // }}
                onChange={(e:any) => searchContractHandler(e)}
                className={clsx(s.dropdownBtn, show && user ? s.active : '')}
              />
              <div className={s.activeInput} onClick={onClick} />
            </div>
          ) : (
            <button
              className={clsx(s.dropdownBtn, show && user ? s.active : '')}
              type="button"
              onClick={onClick}
              onBlur={onBlur}
            >
              {value}
            </button>
          )}
          <Container className={clsx(s.container, className, show ? s.visible : '')}>
            <ul className={clsx(s.list_container)}>
              {contracts?.map((item:any, index:number) => (
                <li
                  className={clsx(s.list)}
                  onClick={() => {
                    useItemSelectActContracts(
                      item.uuid,
                      item.number,
                      // ? `${item.lastName} ${item.firstName} ${item.middleName}`
                      // : item.name
                      //   ? item.name
                      //   : index + 1,
                    )
                    if (item.number) {
                      setShowInputActContracts(false)
                      setSearchValueClientActContracts('')
                    }
                  }}
                  key={index}
                >
                  {/* {item.lastName
                    ? `${item.lastName} ${item.firstName} ${item.middleName}`
                    : item.name
                      ? item.name
                      : index + 1} */}
                  {item.number}
                </li>
              ))}
            </ul>
          </Container>
        </>
      )}
    </>
  )
}
