import type { ButtonHTMLAttributes, FC } from 'react'
import clsx from 'clsx'
import s from './NavButton.module.css'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  disabled?: boolean
  active?: boolean
}

export const NavButton: FC<ButtonProps> = props => {
  const {
    className, children, disabled, active, ...rest
  } = props

  return (
    <button
      className={clsx(s.btn, className, { [s.active]: active })}
      disabled={disabled}
      type="button"
      {...rest}
    >
      {children}
    </button>
  )
}
