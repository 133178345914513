/* eslint-disable */

import React, { ChangeEvent, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import {
  Title,
  Container,
  Panel,
  Label,
  Field,
  Textarea,
  Button,
  Loader,
  AddButton,
  CloseIcon,
  DataPicker,
  ProgressBar,
} from "../../ui";
import { UploadEditPages } from "../UploadEditPages";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
  changeIsCreateBroker,
  changeNav,
} from "../../redux/slices/editSiteSlice";
import { CREATE_REVIEW } from "../../apollo/mutation/editSite/mediaPageUpdate";
import { GET_ALL_COMMENTS } from "../../apollo/query/editSite/mediaPage";
import iconSent from "./img/icon-sent.svg";
import fullFill from "./img/icon-fillfull.svg";
import empty from "./img/icon-empty.svg";
import s from "./CreateReview.module.css";

export const CreateReview = () => {
  const dispatch = useAppDispatch();
  const [sumField, setSumField] = useState(0);
  const [senting, setSenting] = useState(false);
  const [obj, setObj] = useState<any>({
    avatar: "",
    firstname: "",
    firstnameEn: "",
    lastname: "",
    lastnameEn: "",
    sourse: "",

    link: "",
    comment: "",
    commentEn: "",
    datemarker: "",
    review: "",
  });

  // расчет прогрессбара
  useEffect(() => {
    const tempObj = {
      avatar: obj?.avatar,
      firstname: obj?.firstname.trim() && obj?.firstnameEn.trim(),
      lastname: obj?.lastname.trim() && obj?.lastnameEn.trim(),
      sourse: obj?.sourse.trim(),
      link: obj?.link.trim(),
      comment: obj?.comment.trim() && obj?.commentEn.trim(),
      datemarker: obj?.datemarker,
      // review: obj?.review.trim(),
    };
    const values = Object.values(tempObj).map((elem) =>
      elem !== "" ? 14.28 : 0
    );
    setSumField(values.reduce((a: any, b: any) => a + b));
    // setSumField(100)
  }, [obj]); // сложение значений объекта

  const [
    createReview,
    {
      data: createReviewData,
      loading: loadingReviewOutlet,
      error: errorReviewOutlet,
    },
  ] = useMutation(CREATE_REVIEW, {
    refetchQueries: [
      {
        query: GET_ALL_COMMENTS,
        variables: {
          filter: {},
        },
      },
    ],
  });

  const changeFirstname = (event: ChangeEvent<HTMLInputElement>) => {
    setObj({ ...obj, firstname: event.target.value });
  };

  const changeFirstnameEn = (event: ChangeEvent<HTMLInputElement>) => {
    setObj({ ...obj, firstnameEn: event.target.value });
  };

  const changeLastname = (event: ChangeEvent<HTMLInputElement>) => {
    setObj({ ...obj, lastname: event.target.value });
  };

  const changeLastnameEn = (event: ChangeEvent<HTMLInputElement>) => {
    setObj({ ...obj, lastnameEn: event.target.value });
  };
  const changeComment = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setObj({ ...obj, comment: event.target.value });
  };

  const changeCommentEn = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setObj({ ...obj, commentEn: event.target.value });
  };
  const changeDate = (date: any) => {
    setObj({ ...obj, datemarker: date });
  };
  const changeSource = (event: ChangeEvent<HTMLInputElement>) => {
    setObj({ ...obj, sourse: event.target.value });
  };

  const changeLink = (event: ChangeEvent<HTMLInputElement>) => {
    setObj({ ...obj, link: event.target.value });
  };
  const changeAvatar = (url: string) => {
    setObj({ ...obj, avatar: url });
  };

  const closePage = () => {
    dispatch(changeIsCreateBroker(false));
    dispatch(changeNav(4));
    setObj({
      avatar: "",
      firstname: "",
      firstnameEn: "",
      lastname: "",
      lastnameEn: "",
      sourse: "",
      link: "",
      comment: "",
      commentEn: "",
      datemarker: "",
      review: "",
    });
  };

  const handleSaveSent = () => {
    setSenting(true);
    createReview({
      variables: {
        data: {
          ...obj,
          datemarker: obj.datemarker.toISOString(),
        },
      },
    });
    if (!loadingReviewOutlet || errorReviewOutlet === undefined) {
      setTimeout(() => {
        closePage();
      }, 2000);
    } else {
      setTimeout(() => {
        closePage();
      });
    }
  };

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: "7px" }}>Сохранено</span>
    </>
  );
  return (
    <Container className={s.container}>
      <CloseIcon onClick={closePage} />
      {/* <CloseIcon onClick={() => console.log()} /> */}
      <Title title="Добавить отзыв" />
      <div className={s.wrapper}>
        <div className={s.first_block}>
          <Panel className={s.info__panel} padding>
            <Label title="Имя">
              <div className={s.input__wrapper}>
                <Field
                  className={s.broker_name}
                  value={obj.title}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeFirstname?.(event)
                  }
                  rows={5}
                  maxLength={100}
                />
                <Field
                  className={s.broker_name}
                  value={obj.titleEn}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeFirstnameEn?.(event)
                  }
                  rows={5}
                  maxLength={100}
                  eng
                />{" "}
              </div>
            </Label>
            <Label title="Фамилия">
              <div className={s.input__wrapper}>
                <Field
                  className={s.broker_name}
                  value={obj.title}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeLastname?.(event)
                  }
                  rows={5}
                  maxLength={100}
                />
                <Field
                  className={s.broker_name}
                  value={obj.titleEn}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeLastnameEn?.(event)
                  }
                  rows={5}
                  maxLength={100}
                  eng
                />
              </div>
            </Label>

            <div className={s.input__wrapper}>
              <Label
                title="Дата публикации"
                // onClick={() => dispatch(closeOnlySelect())}
              >
                <DataPicker
                  className={s.data__picker}
                  startDate={obj.datemarker}
                  onChange={(date) => changeDate?.(date)}
                />
              </Label>
              <Label title="Источник">
                <Field
                  className={s.youTube_link}
                  // placeholder="59 симв. макс. включая пробелы"
                  value={obj.source}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeSource?.(event)
                  }
                  rows={5}
                  maxLength={100}
                />
              </Label>
            </div>

            <Label title="Ссылка">
              <Field
                className={s.link}
                // placeholder="59 симв. макс. включая пробелы"
                value={obj.link}
                view="default"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  changeLink?.(event)
                }
                rows={5}
                maxLength={100}
              />
            </Label>

            <Label title="Текст отзыва">
              <div className={s.input__wrapper}>
                <Textarea
                  className={s.description}
                  value={obj.decription}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    changeComment?.(event)
                  }
                  rows={5}
                  // maxLength={350}
                />
                <Textarea
                  className={s.description}
                  value={obj.descriptionEn}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    changeCommentEn?.(event)
                  }
                  rows={5}
                  // maxLength={350}
                  eng
                />
              </div>
            </Label>
            <div className={s.uploads}>
              <UploadEditPages
                title="Загрузить фото"
                titleStatus="Отзыв"
                text=""
                text2="Максимальный размер фото 8 Мб. Форматы: jpeg, jpg, png. Оптимальный размер: 296 х 220 px"
                // application=".webp"
                subText1="Загрузить с компьютера"
                subText2="или перетащите сюда"
                uploadId="Review"
                changeAvatar={changeAvatar}
                avatar={obj.avatar}
              />
            </div>
          </Panel>
        </div>
        <Panel className={s.btns__panel}>
          <Button
            onClick={handleSaveSent}
            className={
              Math.ceil(sumField) === 100
                ? s.btns__panel_save
                : s.btns__panel_save_disabled
            }
            // className={
            //   !obj.firstname || !obj.comment || !obj.sourse || !obj.datemarker
            //     ? s.btns__panel_save_disabled
            //     : s.btns__panel_save
            // }
            theme="dark"
            disabled={Math.ceil(sumField) < 100}
            // disabled={
            //   !obj.firstname || !obj.comment || !obj.sourse || !obj.datemarker
            // }
            // disabled={!broker?.firstname}
          >
            {!senting ? (
              "Сохранить и закрыть"
            ) : loadingReviewOutlet ? (
              <Loader />
            ) : !loadingReviewOutlet && errorReviewOutlet ? (
              "Ошибка"
            ) : (
              sentSaved
            )}
          </Button>
        </Panel>
      </div>
      <Panel className={s.panel__progress} padding>
        <ProgressBar
          className={s.project_create}
          value={Math.ceil(sumField)}
          // value={100}
        />
        <div className={s.project_indicator}>
          <img
            src={
              obj.firstname.trim() && obj.firstnameEn.trim() ? fullFill : empty
            }
            alt="empty"
          />
          <p>Имя</p>
        </div>
        <div className={s.project_indicator}>
          <img
            src={
              obj.lastname.trim() && obj.lastnameEn.trim() ? fullFill : empty
            }
            alt="empty"
          />
          <p>Фамилия</p>
        </div>
        <div className={s.project_indicator}>
          <img
            src={obj.comment.trim() && obj.commentEn.trim() ? fullFill : empty}
            alt="empty"
          />
          <p>Текст отзыва</p>
        </div>
        <div className={s.project_indicator}>
          <img src={obj.datemarker ? fullFill : empty} alt="empty" />
          <p>Дата публикации</p>
        </div>
        <div className={s.project_indicator}>
          <img src={obj.sourse.trim() ? fullFill : empty} alt="empty" />
          <p>Источник</p>
        </div>
        <div className={s.project_indicator}>
          <img src={obj.link.trim() ? fullFill : empty} alt="empty" />
          <p>Ссылка</p>
        </div>
        <div className={s.project_indicator}>
          <img src={obj.avatar ? fullFill : empty} alt="empty" />
          <p>Фото</p>
        </div>
      </Panel>
    </Container>
  );
};
