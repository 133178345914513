/* eslint-disable */

import React, { ChangeEvent, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import {
  Title,
  Container,
  Panel,
  Label,
  Field,
  Textarea,
  Button,
  Loader,
  CloseIcon,
  ProgressBar,
  DataPicker,
} from "../../ui";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
  changeIsCreateBroker,
  changeNav,
} from "../../redux/slices/editSiteSlice";
import { GET_ALL_MEDIAREPORTS } from "../../apollo/query/editSite/mediaPage";
import { CREATE_MEDIAREPORT } from "../../apollo/mutation/editSite/mediaPageUpdate";
import iconSent from "./img/icon-sent.svg";
import iconDraft from "./img/icon-draft.svg";
import fullFill from "./img/icon-fillfull.svg";
import empty from "./img/icon-empty.svg";
import s from "./CreateVideoReport.module.css";

export const CreateVideoReport = () => {
  const dispatch = useAppDispatch();
  const [sumField, setSumField] = useState(0);
  const [senting, setSenting] = useState(false);
  const [obj, setObj] = useState<any>({
    video: "",
    title: "",
    titleEn: "",
    datemarker: "",
  });
  const [
    createReview,
    {
      data: createReviewData,
      loading: loadingReviewData,
      error: errorReviewData,
    },
  ] = useMutation(CREATE_MEDIAREPORT, {
    refetchQueries: [
      {
        query: GET_ALL_MEDIAREPORTS,
        variables: {
          filter: {},
        },
      },
    ],
  });

  // расчет прогрессбара
  useEffect(() => {
    const tempObj = {
      video: obj.video.trim(),
      title: obj.title.trim() && obj.titleEn.trim(),
      datemarker: obj.datemarker,
    };
    const values = Object.values(tempObj).map((elem) =>
      elem !== "" ? 33.33333 : 0
    );
    setSumField(values.reduce((a: any, b: any) => a + b));
    // setSumField(100)
  }, [obj]); // сложение значений объекта

  const changeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setObj({ ...obj, title: event.target.value });
  };

  const changeTitleEn = (event: ChangeEvent<HTMLInputElement>) => {
    setObj({ ...obj, titleEn: event.target.value });
  };

  const changeDate = (date: any) => {
    setObj({ ...obj, datemarker: date });
  };
  const changeVideo = (event: ChangeEvent<HTMLInputElement>) => {
    setObj({ ...obj, video: event.target.value });
  };

  const closePage = () => {
    dispatch(changeIsCreateBroker(false));
    dispatch(changeNav(4));
    setObj({
      video: "",
      title: "",
      titleEn: "",
      datemarker: "",
    });
  };

  const handleSaveSent = () => {
    setSenting(true);
    createReview({
      variables: {
        data: {
          ...obj,
          datemarker: obj.datemarker.toISOString(),
        },
      },
    });
    if (!loadingReviewData || errorReviewData === undefined) {
      setTimeout(() => {
        closePage();
      }, 2000);
    } else {
      setTimeout(() => {
        closePage();
      });
    }
  };

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: "7px" }}>Сохранено</span>
    </>
  );

  return (
    <Container className={s.container}>
      <CloseIcon onClick={closePage} />
      <Title title="Добавить видео" />
      <div className={s.wrapper}>
        <div className={s.first_block}>
          <Panel className={s.info__panel} padding>
            <Label title="Название">
              <div className={s.input__wrapper}>
                <Field
                  className={s.title}
                  // placeholder="59 симв. макс. включая пробелы"
                  value={obj.title}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeTitle?.(event)
                  }
                  rows={5}
                  maxLength={100}
                />
                <Field
                  className={s.title}
                  // placeholder="59 симв. макс. включая пробелы"
                  value={obj.titleEn}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeTitleEn?.(event)
                  }
                  rows={5}
                  maxLength={100}
                  eng
                />
              </div>
            </Label>
            <div className={s.line}>
              <Label
                title="Дата публикации"
                // onClick={() => dispatch(closeOnlySelect())}
              >
                <DataPicker
                  className={s.data__picker}
                  startDate={obj.datemarker}
                  onChange={(date) => changeDate?.(date)}
                />
              </Label>
              <Label title="Ссылка на видео (Youtube)">
                <Field
                  className={s.youTube_link}
                  // placeholder="59 симв. макс. включая пробелы"
                  value={obj.video}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeVideo?.(event)
                  }
                  rows={5}
                  maxLength={100}
                />
              </Label>
            </div>
          </Panel>
        </div>
        <Panel className={s.btns__panel}>
          <Button
            onClick={handleSaveSent}
            className={
              Math.ceil(sumField) === 100
                ? s.btns__panel_save
                : s.btns__panel_save_disabled
            }
            // className={
            //   obj.title && obj.video && obj.datemarker
            //     ? s.btns__panel_save
            //     : s.btns__panel_save_disabled
            // }
            theme="dark"
            disabled={Math.ceil(sumField) < 100}
            // disabled={!obj.title.trim() && !obj.video.trim() && !obj.datemarker}
          >
            {!senting ? (
              // 'Сохранить и опубликовать'
              "Сохранить и закрыть"
            ) : loadingReviewData ? (
              <Loader />
            ) : !loadingReviewData && errorReviewData ? (
              "Ошибка"
            ) : (
              sentSaved
            )}
          </Button>
        </Panel>
      </div>
      <Panel className={s.panel__progress} padding>
        <ProgressBar
          className={s.project_create}
          value={Math.ceil(sumField)}
          // value={100}
        />
        <div className={s.project_indicator}>
          <img
            src={obj.title.trim() && obj.titleEn.trim() ? fullFill : empty}
            alt="empty"
          />
          <p>Название</p>
        </div>
        <div className={s.project_indicator}>
          <img src={obj.datemarker ? fullFill : empty} alt="empty" />
          <p>Дата публикации</p>
        </div>
        <div className={s.project_indicator}>
          <img src={obj.video.trim() ? fullFill : empty} alt="empty" />
          <p>Ссылка на видео (Youtube)</p>
        </div>
      </Panel>
    </Container>
  );
};
