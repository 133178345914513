import React, { useState, useEffect, FC } from 'react'
// import { useQuery } from "@apollo/client";
// import { GET_ALL_CONDITIONS } from "../../../apolo/query/query_business";
import { Section } from '../../Section/Section'
import { MediaSectionTitle } from '../../MediaSectionTItle/MediaSectionTitle'
// import { demandList } from "./demandList";
import { DemandItem } from './demandItem/DemandItem'
import s from './DemandListSection.module.css'

interface DemandListSection {
  clientWidth: number
  mediaEntrepreneur?: any
  conditionList?: any
}

export const DemandListSection: FC<DemandListSection> = ({ clientWidth, mediaEntrepreneur, conditionList }) => {
  const [demandList, setDemandList]: any = useState([])
  const [firstLine, setFirstLine]: any = useState([])
  const [secondLine, setSecondLine]: any = useState([])
  const [firstLine1024, setFirstLine1024]: any = useState([])
  const [secondLine1024, setSecondLine1024]: any = useState([])
  const [thirdLine1024, setThirdLine1024]: any = useState([])
  useEffect(() => {
    if (!conditionList) return
    const temp = [...conditionList]
    const sortedArr = [...temp.sort((a: any, b: any) => a.order - b.order)]
    setFirstLine([...sortedArr.slice(0, 3)])
    setSecondLine([...sortedArr.slice(3, 6)])
    setFirstLine1024([...sortedArr.slice(0, 2)])
    setSecondLine1024([...sortedArr.slice(2, 4)])
    setThirdLine1024([...sortedArr.slice(4, 6)])
    setDemandList([...sortedArr])
  }, [conditionList])
  return (
    <Section className={s.wrapper}>
      <div className={s.topline_wrapper}>
        <MediaSectionTitle
          className={s.sectionTitle}
          text1="С какими"
          text2="проектами"
          text3="Мы работаем"
        />
        <p className={s.topline_text}>{mediaEntrepreneur?.projectsTitle}</p>
      </div>
      <div className={s.demand_wrapper}>
        <ol>
          {/* <div className={s.grid_container}></div> */}
          {clientWidth > 1025 && (
            <div className={s.demand_lineBlock}>
              {firstLine.map((el: any, index: number) => (
                <DemandItem
                  el={el}
                  index={index}
                  key={String(index).concat('firstLine')}
                />
              ))}
            </div>
          )}
          {clientWidth > 375 && clientWidth < 1025 && (
            <div className={s.demand_lineBlock}>
              {firstLine1024.map((el: any, index: number) => (
                <DemandItem
                  el={el}
                  index={index}
                  key={String(index).concat('firstLine')}
                />
              ))}
            </div>
          )}
          {clientWidth > 1025 && (
            <div className={s.demand_lineBlock}>
              {secondLine.map((el: any, index: number) => (
                <DemandItem
                  el={el}
                  index={index}
                  key={String(index).concat('secondLine')}
                />
              ))}
            </div>
          )}
          {clientWidth > 375 && clientWidth < 1025 && (
            <div className={s.demand_lineBlock}>
              {secondLine1024.map((el: any, index: number) => (
                <DemandItem
                  el={el}
                  index={index}
                  key={String(index).concat('secondLine')}
                />
              ))}
            </div>
          )}
          {clientWidth > 375 && clientWidth < 1025 && (
            <div className={s.demand_lineBlock}>
              {thirdLine1024.map((el: any, index: number) => (
                <DemandItem
                  el={el}
                  index={index}
                  key={String(index).concat('secondLine')}
                />
              ))}
            </div>
          )}
          {clientWidth < 376 && (
            <div className={s.demand_lineBlock}>
              {demandList.map((el: any, index: number) => (
                <DemandItem
                  el={el}
                  index={index}
                  key={String(index).concat('secondLine')}
                  small
                />
              ))}
            </div>
          )}
        </ol>
      </div>
    </Section>
  )
}
