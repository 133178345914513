import React from 'react'
import s from './TitleFaq.module.css'

export const Title = () => (
  <>
    <h2 className={s.big_faq}>FAQ</h2>
    <div className={s.title_description}>
      <p className={s.title_description__first_line}>В этом разделе</p>
      <p className={s.title_description__second_line}>вы найдете ответы</p>
      <p className={s.title_description__third_line}>
        на часто задаваемые вопросы
      </p>
    </div>
  </>
)
