import React, { useState, useEffect, FC } from 'react'
import { useQuery } from '@apollo/client'
import { GET_ALL_INVESTFORMS } from '../../../../apollo/query/allProjects'
import { Section } from '../Section/Section'
import { SectionTitle } from '../SectionTitle/SectionTitle'
import s from './Purpose.module.css'

interface Purpose {
  project?: any
}

export const Purpose: FC<Purpose> = ({ project }) => {
  const {
    loading: investFormLoading,
    error: investFormError,
    data: investFormData,
  } = useQuery(GET_ALL_INVESTFORMS)
  const [investForms, setInvstforms] = useState(project.investForms === '' ? [] : JSON.parse(project.investForms))
  const [descriptionArray, setDescriptionArray] = useState([])
  const [booleanArr, setBooleanArr] = useState([])

  useEffect(() => {
    // if (!data) return // возвращаем false пока не придут данные
    if (!investFormData) return

    const tempArr: any = [] // вспомогательный массив для рендера названия и описания
    const booleanArrHelper: any = [] // вспомогательный массив для количества объектов с описанием

    const makeDescriptionArray = (nameOfForm: string) => {
      // investFormData.investForms.forEach((element) => {
      investFormData.investForms.forEach((element: any) => {
        // перебор инвестформа из запроса сервера
        if (element.name === nameOfForm) {
          // если название инвестии совпадает с названием инвестии из проекта
          tempArr.push({
            // формируем вспомогательный массив с описанием
            name: element.name,
            description: element.description,
          })
          booleanArrHelper.push(false) // формируем вспомогательный массив для видимости описания, только количество элементов
        }
        setDescriptionArray(tempArr) // обновляем состояние для описание
        setBooleanArr(booleanArrHelper) //  обновляем состояние для признака видимости описания
      })
    }

    if (investForms.length === 0) {
      makeDescriptionArray('Процентный заём')
    } else {
      // project.investForms.forEach((el) => {
      investForms.forEach((el: any) => {
        makeDescriptionArray(el.name)
      })
    }
  }, [investFormData])

  const toggleDescription = (e: any, i: number) => {
    const newArr: any = booleanArr.map((el, index) => (index === i ? !el : el))
    setBooleanArr(newArr)
  }

  return (
    <Section className={s.section__margin__bottom}>
      <SectionTitle text="Цели" />
      <div className={s.info__list}>
        {/* {data.projects[0].target !== "" && ( */}
        <div className={s.info__requisites_item}>
          <div className={s.info__requisites_title}>Название:</div>
          <div className={s.info__requisites_value}>
            {project?.target}
          </div>
        </div>
        {/* // )} */}
        <div className={s.info__requisites_item}>
          <div className={s.info__requisites_title}>
            Форма привлечения инвестиций:
          </div>
          <ul className={s.invest__form__wrapper}>
            {descriptionArray.map((el: any, i: number) => (
              <li
                className={
                  booleanArr[i] ? s.invest__item__active : s.invest__item
                }
                key={i}
              >
                <div className={s.invest__item__title}>
                  <span className={s.info__requisites_value}>{el.name}</span>
                  <div
                    className={booleanArr[i] ? s.x__img : s.x__img__hide}
                    onClick={e => toggleDescription(e, i)}
                  >
                    &nbsp;
                  </div>
                </div>
                <p className={s.description}>
                  {el.description}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Section>
  )
}
