import clsx from 'clsx'
import s from './Title.module.css'

type Props = {
    className?: string
    title: string
}

export const Title = ({ className, title }: Props) => (
  <h2 className={clsx(s.title, className)}>
    {title}
  </h2>
)
