import { gql } from '@apollo/client'

export const CREATE_CLIENT = gql`
  mutation ClientCreate($profileUUID: String!, $data: ClientData!) {
    clientCreate(profileUUID: $profileUUID, data: $data) {
      uuid
      photo
      firstName
      middleName
      lastName
      phone
      birthDay
      info {
        email
        iis
        inn
      }
      infoOrg {
        name
        inn
        address
        rs
        ks
        bank
        bik
      }
      contracts {
        uuid
        expireOn
        investSum
        project {
          uuid
          name
        }
      }
    }
  }
`

export const CREATE_PROFILE = gql`
  mutation AdmProfileCreate($data: ProfileData!) {
    admProfileCreate(data: $data) {
      uuid
    }
  }
`
