import React, { FC, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { NavigateFunction } from 'react-router-dom'
import s from './UserProfile.module.css'
import { Dropdown } from '../../ui'
import logout from '../../pages/Home/icons/icon-logo-logout.svg'
import toggleArrow from '../../pages/Home/icons/icon-toggleArrow.svg'
import { logOut } from '../../redux/slices/authSlice'
import { useAppDispatch } from '../../redux/hook'

interface UserProfileProps {
  isRole: string
  navigate: NavigateFunction
}

export const UserProfile: FC<UserProfileProps> = ({ isRole, navigate }) => {
  const [dropdown, setDropdown] = useState<boolean>(false)
  const client = useApolloClient()
  const dispatch = useAppDispatch()

  const exit = () => {
    dispatch(logOut(localStorage.clear()))
  }
  return (
    <>
      <button
        className={s.btn}
        onClick={() => setDropdown(!dropdown)}
        type="button"
      >
        <div>
          {isRole === 'lawyer' && (
            <span className={s.isRole}>Правовой советник</span>
          )}
          {isRole === 'manager' && <span className={s.isRole}>Менеджер</span>}
          {isRole === 'admin' && (
            <span className={s.isRole}>Администратор</span>
          )}
          {isRole === 'technical1' && (
            <span className={s.isRole}>Технический 1</span>
          )}
          {isRole === 'technical2' && (
            <span className={s.isRole}>Технический 2</span>
          )}
          {isRole === 'marketer' && (
            <span className={s.isRole}>Маркетолог</span>
          )}
          <img src={logout} alt="logout" />
          <img
            src={toggleArrow}
            alt="upDown"
            width="11"
            style={
              dropdown
                ? { transform: 'rotateX(0)' }
                : { transform: 'rotateX(180deg)' }
            }
          />
        </div>
      </button>
      {dropdown && (
        <Dropdown
          className={s.dropdown}
          content="Выйти"
          onClick={async () => {
            exit()
            await client.clearStore()
            navigate('/home', { replace: true })
          }}
        />
      )}
    </>
  )
}
