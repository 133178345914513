import { FC } from 'react'

interface Container {
  children?: any
  className?: string
  id?: string
}

export const Container: FC<Container> = ({ children, className, id }) => (
  <main className={className} id={id}>
    {children}
  </main>
)
