import React, { useState, FC } from 'react'
import clsx from 'clsx'
// import { useQuery } from "@apollo/client";
// import { GET_MAINPAGE } from "../../../apolo/query/query_mainPage";
import s from './AboutUs.module.css'

interface AboutUs {
  data?: any
}

export const AboutUs: FC<AboutUs> = ({ data }) => {
  // const { data } = useQuery(GET_MAINPAGE)
  const [mousePosition, setMousePosition] = useState('')

  const listenMousePosition = (event: any) => {
    // setMousePosition(event.target.className)
    setMousePosition(event.target.id)
  }

  let goUp = ''
  const goUpBlackLines = (mousePosition: string) => {
    if (mousePosition === 'animation__text_first_part') {
      goUp = 'up'
      return goUp
    }
    return null
  }
  goUpBlackLines(mousePosition)

  return (
    <div className={s.about__us_main}>
      <div className={s.animation__text_block}>
        <div className={s.cube}>&nbsp;</div>
        <div>
          <div className={s.animation__text} onMouseOver={listenMousePosition} onFocus={listenMousePosition}>
            <p
              className={
                goUp !== 'up'
                  ? s.animation__text_first_part
                  : clsx(s.animation__text_first_part, s.animation__text_first_part_white)
              }
              id="animation__text_first_part"
            >
              Инвестору
              <br />
              сложно найти
              <br />
              прибыльные проекты,
            </p>
            <p
              className={
                goUp !== 'up'
                  ? s.animation__text_second_part
                  : clsx(s.animation__text_second_part, s.animation__text_second_part_black)
              }
            >
              еще сложнее определить,
              <br />
              можно ли доверять
              <br />
              {' '}
              собственнику бизнеса
            </p>
          </div>
          <div className={s.animation__black_lines}>
            <div
              className={
                goUp !== 'up'
                  ? s.animation__1th_black_line
                  : clsx(s.animation__1th_black_line, s.animation__1th_black_line_go_upp)
              }
            >
              &nbsp;
            </div>
            <div
              className={
                goUp !== 'up'
                  ? s.animation__2th_black_line
                  : clsx(s.animation__2th_black_line, s.animation__2th_black_line_go_upp)
              }
            >
              &nbsp;
            </div>
            <div
              className={
                goUp !== 'up'
                  ? s.animation__3th_black_line
                  : clsx(s.animation__3th_black_line, s.animation__3th_black_line_go_upp)
              }
            >
              &nbsp;
            </div>
          </div>
        </div>
      </div>
      <div className={s.right__part}>
        <div
          className={s.conversation__image}
          style={{ backgroundImage: `url('${data?.photoAbout}')` }}
        >
          &nbsp;
        </div>
        <p className={s.decoration_marks}>“</p>
        <div className={s.about_us__text}>
          <div className={s.text_paragraph_query}>
            {data?.about}
            <div className={s.second_screen__yellow_mark_first}>&nbsp;</div>
            <div className={s.second_screen__yellow_mark_second}>&nbsp;</div>
            <div className={s.second_screen__yellow_mark_third}>&nbsp;</div>
            <div className={s.second_screen__yellow_mark_fourth}>&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  )
}
