/*eslint-disable*/
import { createSlice } from "@reduxjs/toolkit";
import { bool } from "prop-types";

interface requisitesType {
  name: string;
  INN: string;
  OGRN: string;
  address: string;
  addressEn: string;
  legal_addressEn: string;
  legal_address: string;
}

interface supportType {
  deposit: any;
}

interface imagesType {
  logo: "";
  grey_logo: "";
  background: "";
}

interface ProjectType {
  name: string;
  nameEn: string;
  description: string;
  descriptionEn: string;
  country: string;
  minInvestment: number | string;
  minInterest: number | string;
  requisites: requisitesType | any;
  support: any;
  images: imagesType | any;
  projectImages: any; // новая галерея
  projectStatusNew: string;
  fullDescription: string;
  fullDescriptionEn: string;
  url: string;
  socialNetworks: string;
  target: string;
  targetEn: string;
  income: number | string;
  investTresholds: any;
  investForms: any;
}

interface ProjectSlice {
  projectInfo: ProjectType;
  statusFilePng: string | null;
  statusFileWebp: string | null;
  statusFilePngBanner: string | null; // для баннера
  statusFileWebpBackground: string | null; // для фона
  statusFileGallery: string | null; // для галереи
  pledgeSelect: boolean;
  statusSelect: boolean; // признак для открытия закрытия дропдаун меню
  investFormSelect: boolean; // признак для тегов с формами инвестиций
  countriesSelect: boolean; // признак для открытия закрытия дропдаун меню со странами
  nameSrcWebp: string;
  galleryArr: any;
}

const initialState: ProjectSlice = {
  projectInfo: {
    name: "",
    nameEn: "",
    description: "",
    descriptionEn: "",
    fullDescription: "",
    fullDescriptionEn: "",
    country: "",
    minInvestment: "",
    minInterest: "",
    images: {
      logo: "",
      grey_logo: "",
      background: "",
    },
    requisites: {
      name: "",
      INN: "",
      OGRN: "",
      address: "",
      addressEn: "",
      legal_address: "",
      legal_addressEn: "",
    },
    support: "",
    projectImages: {
      id: "",
      uuid: "",
      bannerSite: "",
      bannerApp: "",
      background: "",
      logo: "",
      gallery: "",
    },
    projectStatusNew: "",
    url: "",
    socialNetworks: "",
    target: "",
    targetEn: "",
    income: "",
    investTresholds: "",
    investForms: "",
  },
  statusFilePng: null,
  statusFileWebp: null,
  statusFilePngBanner: null, // для баннура
  statusFileWebpBackground: null, // для фона
  statusFileGallery: null, // для галерии
  pledgeSelect: false,
  statusSelect: false, // признак для открытия закрытия дропдаун меню
  investFormSelect: false, // признак для тегов с формами инвестиций
  countriesSelect: false, // признак для открытия закрытия дропдаун меню со странами
  nameSrcWebp: "",
  galleryArr: [],
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    addProjectFields(state, action) {
      state.projectInfo = {
        ...state.projectInfo,
        [action.payload.fieldName]: action.payload.item,
      };
    },
    addRequisitesFields(state, action) {
      state.projectInfo.requisites = {
        ...state.projectInfo.requisites,
        [action.payload.fieldName]: action.payload.item,
      };
    },
    addSupportFields(state, action) {
      state.projectInfo.support =
        action.payload.length > 0 ? JSON.stringify(action.payload) : "";
      // state.projectInfo.support =				action.payload.length > 0 ? JSON.stringify(action.payload) : JSON.stringify('')
    },
    addInvestFormFields(state, action) {
      state.projectInfo.investForms =
        action.payload.length > 0 ? JSON.stringify(action.payload) : "";
    },
    addInvestTresholdsFields(state, action) {
      state.projectInfo.investTresholds =
        action.payload.length > 0 ? JSON.stringify(action.payload) : "";
    },
    addImagesFields(state, action) {
      state.projectInfo.images = {
        ...state.projectInfo.images,
        [action.payload.fieldName]: action.payload.item,
      };
    },
    addProjectImagesFields(state, action) {
      // для галереи
      state.projectInfo.projectImages = {
        ...state.projectInfo.projectImages,
        [action.payload.fieldName]: action.payload.item,
      };
    },
    resetProjectFields(state) {
      state.projectInfo = {
        name: "", // надо
        nameEn: "",
        description: "", // короткое
        descriptionEn: "",
        fullDescription: "", // полное
        fullDescriptionEn: "",
        minInvestment: "", // УДАЛИТЬ
        minInterest: "", // УДАЛИТЬ
        country: "",
        requisites: {
          // надо
          name: "", // надо
          INN: "", // надо
          OGRN: "", // надо
          address: "", // надо
          addressEn: "",
          legal_address: "", // надо
          legal_addressEn: "",
        },
        support: "",
        images: {
          logo: "",
          grey_logo: "",
          background: "",
        },
        projectImages: {
          // галерея
          id: "",
          uuid: "",
          bannerSite: "",
          bannerApp: "",
          background: "",
          logo: "",
          gallery: "",
        },
        projectStatusNew: "", // надо
        url: "", // надо
        socialNetworks: "", // надо
        target: "", // надо
        targetEn: "",
        income: "", // надо
        investTresholds: "", // надо JSON
        investForms: "", // надо JSON, логика NativeSelect
      };
      state.statusFilePng = null;
      state.statusFileWebp = null;
      state.statusFilePngBanner = null; // для 3ей картинки
      state.statusFileWebpBackground = null; // для 4ой картинки
      state.statusFileGallery = null; // для галереи
      state.pledgeSelect = false;
    },
    statusPng(state, action) {
      state.statusFilePng = action.payload;
    },
    statusWebp(state, action) {
      state.statusFileWebp = action.payload;
    },
    // функция статуса третей картинки для tus
    statusFilePngBanner(state, action) {
      state.statusFilePngBanner = action.payload;
    },
    // функция статуса третей картинки для tus
    statusPngBannerEditProject(state, action) {
      state.statusFilePngBanner = action.payload;
    },
    // функция статуса четвертой картинки для tus
    statusFileWebpBackground(state, action) {
      state.statusFileWebpBackground = action.payload;
    },
    statusFileWebpBackgroundrEditProject(state, action) {
      state.statusFileWebpBackground = action.payload;
    },
    // функция статуса галереи для tus
    statusFileGallery(state, action) {
      state.statusFileGallery = action.payload;
    },
    statusGalleryEditProject(state, action) {
      state.statusFileGallery = action.payload;
    },
    changePledgeSelect(state) {
      state.pledgeSelect = !state.pledgeSelect;
    },
    // функция изменяю признак статусСелекта для открытия-закрытия дропдаун меню
    changeStatusSelect(state) {
      state.statusSelect = !state.statusSelect;
    },
    // функция изменяю признак  Селекта для открытия-закрытия дропдаун меню тего ивест форм
    changeInvestFormSelect(state) {
      state.investFormSelect = !state.investFormSelect;
    },
    // функция изменяю признак Селекта с выбором стран для открытия-закрытия дропдаун меню стран
    changeCountriesSelect(state) {
      state.countriesSelect = !state.countriesSelect;
    },
    closeCountriesSelect(state) {
      state.countriesSelect = false;
    },
    addSrc(state, action) {
      state.nameSrcWebp = action.payload;
    },
    addSrcGalleryArr(state, action) {
      state.galleryArr = [...state.galleryArr, action.payload];
    },
  },
});

export const {
  addProjectFields,
  resetProjectFields,
  addRequisitesFields,
  addSupportFields,
  addImagesFields,
  statusPng,
  statusWebp,
  changePledgeSelect,
  changeStatusSelect, // для дропдаун меню
  changeInvestFormSelect, // для дропдаун меню тегов инвест форм
  changeCountriesSelect, // для дропдаун меню со странами
  closeCountriesSelect, // для того, что бы закрыть меню со странами при сохранении
  addProjectImagesFields, // для галери при создании проекта
  addInvestTresholdsFields, // для диапазонов инвестирования
  addInvestFormFields, // для выбора форм через теги
  statusFilePngBanner, // для третей картинки в UploadProjects
  statusFileWebpBackground, // статус для 4ой картинки в UploadProjects
  statusFileGallery, // для галереи
  addSrc,
  addSrcGalleryArr,
  statusPngBannerEditProject,
  statusFileWebpBackgroundrEditProject,
  statusGalleryEditProject,
} = projectSlice.actions;

export default projectSlice.reducer;
