import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_CLIENT } from '../../apollo/mutation/updateClient'
import { CLIENTS } from '../../apollo/query/allClients'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  addClientFields,
  resetClientFields,
} from '../../redux/slices/clientSlice'
import {
  Button,
  ClientInfo,
  ClientInfoOrg,
  CloseIcon,
  Container,
  Loader,
  NavProjects,
  Title,
} from '../../ui'
import iconSent from './img/icon-sent.svg'
import s from './EditClient.module.css'

interface EditClientProps {
  params?: string
}

export const EditClient: React.FC<EditClientProps> = ({ params }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { clientInfo } = useAppSelector(state => state.clientSlice)
  const dataNavTitle = ['Физ. лицо', 'ИП']
  const [navValue, setNavValue] = useState(0)
  const [senting, setSenting] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [birthDay, setBirthDay] = useState<Date | null>(new Date())
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [iis, setIis] = useState('')
  const [inn, setInn] = useState('')

  const [name, setName] = useState('')
  const [innOrg, setInnOrg] = useState('')
  const [address, setAddress] = useState('')
  const [rs, setRs] = useState('')
  const [ks, setKs] = useState('')
  const [bank, setBank] = useState('')
  const [bik, setBik] = useState('')

  const {
    data: dataClients,
    loading: loadingClients,
    error: errorClients,
  } = useQuery(CLIENTS, {
    variables: {
      filter: {
        uuids: params,
      },
    },
  })

  const [
    updateClient,
    { data: dataUpdate, loading: loadingUpdate, error: errorUpdate },
  ] = useMutation(UPDATE_CLIENT, {
    refetchQueries: [
      {
        query: CLIENTS,
        variables: {
          sort: {
            field: 'lastName',
            direction: 'asc',
          },
        },
      },
      {
        query: CLIENTS,
        variables: {
          filter: {
            uuids: params,
          },
        },
      },
    ],
  })

  useEffect(() => {
    if (dataClients) {
      setFirstName(dataClients?.clients[0].firstName)
      setLastName(dataClients?.clients[0].lastName)
      setMiddleName(dataClients?.clients[0].middleName)
      setBirthDay(new Date(Date.parse(dataClients?.clients[0]?.birthDay)))
      setPhone(dataClients?.clients[0].phone)
      setEmail(dataClients?.clients[0]?.info?.email)
      setIis(dataClients?.clients[0]?.info?.iis)
      setInn(dataClients?.clients[0]?.info?.inn)
      setName(dataClients?.clients[0]?.infoOrg?.name)
      setInnOrg(dataClients?.clients[0]?.infoOrg?.inn)
      setAddress(dataClients?.clients[0]?.infoOrg?.address)
      setRs(dataClients?.clients[0]?.infoOrg?.rs)
      setKs(dataClients?.clients[0]?.infoOrg?.ks)
      setBank(dataClients?.clients[0]?.infoOrg?.bank)
      setBik(dataClients?.clients[0]?.infoOrg?.bik)
      const objGetClient: any = {
        firstName: dataClients?.clients[0]?.firstName,
        lastName: dataClients?.clients[0]?.lastName,
        middleName: dataClients?.clients[0]?.middleName,
        photo: dataClients?.clients[0].photo,
        birthDay: dataClients?.clients[0]?.birthDay,
        phone: dataClients?.clients[0]?.phone,
        info: {
          email: dataClients?.clients[0]?.info?.email,
          iis: dataClients?.clients[0]?.info?.iis,
          inn: dataClients?.clients[0]?.info?.inn,
        },
        infoOrg: {
          name: dataClients?.clients[0]?.infoOrg?.name,
          inn: dataClients?.clients[0]?.infoOrg?.inn,
          address: dataClients?.clients[0]?.infoOrg?.address,
          rs: dataClients?.clients[0]?.infoOrg?.rs,
          ks: dataClients?.clients[0]?.infoOrg?.ks,
          bank: dataClients?.clients[0]?.infoOrg?.bank,
          bik: dataClients?.clients[0]?.infoOrg?.bik,
        },
      }
      for (const key in objGetClient) {
        dispatch(addClientFields({ fieldName: key, item: objGetClient[key] }))
      }
    }
  }, [dataClients])

  const closePage = () => {
    navigate('/clients', { replace: true })
    dispatch(resetClientFields())
  }

  useEffect(() => {
    if (dataUpdate) {
      setSenting(true)
      setTimeout(() => {
        closePage()
        setSenting(false)
      }, 3000)
    }
  }, [dataUpdate])

  const handleSaveBtn = () => {
    updateClient({
      variables: {
        uuid: params,
        data: {
          ...clientInfo,
        },
      },
    })
  }

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: '7px' }}>Сохранено</span>
    </>
  )

  return (
    <Container className={s.container}>
      <CloseIcon onClick={closePage} />
      <Title title="Редактирование клиента" />
      <div>
        <div className={s.wrapper}>
          <NavProjects
            className={s.nav}
            setNavValue={setNavValue}
            navValue={navValue}
            dataNavTitle={dataNavTitle}
          />
          {navValue === 0 ? (
            <ClientInfo
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              middleName={middleName}
              setMiddleName={setMiddleName}
              birthDay={birthDay}
              setBirthDay={setBirthDay}
              phone={phone}
              setPhone={setPhone}
              email={email}
              setEmail={setEmail}
              iis={iis}
              setIis={setIis}
              inn={inn}
              setInn={setInn}
            />
          ) : (
            <ClientInfoOrg
              name={name}
              setName={setName}
              innOrg={innOrg}
              setInnOrg={setInnOrg}
              address={address}
              setAddress={setAddress}
              rs={rs}
              setRs={setRs}
              ks={ks}
              setKs={setKs}
              bank={bank}
              setBank={setBank}
              bik={bik}
              setBik={setBik}
            />
          )}
        </div>
        <Button
          onClick={handleSaveBtn}
          className={s.btn}
          theme={
            !(firstName && lastName && middleName && phone)
              ? 'secondary'
              : 'dark'
          }
          disabled={!(firstName && lastName && middleName && phone)}
        >
          {loadingUpdate ? (
            <Loader />
          ) : errorUpdate ? (
            'Ошибка'
          ) : senting ? (
            sentSaved
          ) : (
            'Внести в базу данных'
          )}
        </Button>
      </div>
    </Container>
  )
}
