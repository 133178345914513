import { gql } from '@apollo/client'

export const ADM_STORIES_PUSH = gql`
  mutation admStorySend(
    $profilesUUID: [String!]!
    $promotionStoriesUUID: [String!]!
    $actualDate: Time!
  ) {
    admStorySend(
      profilesUUID: $profilesUUID
      promotionStoriesUUID: $promotionStoriesUUID
      actualDate: $actualDate
    )
  }
`
export const ADM_DYNAMICS_PUSH = gql`
  mutation admDynamicSend(
    $profilesUUID: [String!]!
    $promotionDynamicUUID: [String!]!
    $actualDate: Time!
  ) {
    admPromotionDynamicSend(
      profilesUUID: $profilesUUID
      promotionDynamicUUID: $promotionDynamicUUID
      actualDate: $actualDate
    )
  }
`
