/*eslint-disable*/
import React, { ChangeEvent, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Title,
  Container,
  Panel,
  Label,
  Field,
  Textarea,
  Button,
  Loader,
  AddButton,
} from "../../ui";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
  changeIsCreateBroker,
  changeNav,
} from "../../redux/slices/editSiteSlice";
import { GET_FOOTER } from "../../apollo/query/editSite/footerPage";
import { UPDATE_FOOTER } from "../../apollo/mutation/editSite/footerPage";
import preview from "./img/icon-eye.png";
import iconSent from "./img/icon-sent.svg";
import iconYoutube from "./img/icon_youtube.svg";
import iconTelegram from "./img/icon_telegram.svg";
import iconInstagram from "./img/icon_instagram.svg";
import iconVK from "./img/icon_vk.svg";
import fullFill from "./img/icon-fillfull.svg";
import empty from "./img/icon-empty.svg";
import s from "./EditFooter.module.css";
import { Portal } from "../../ui/Portal/Portal";
import { Footer } from "../preview/footer/Footer";

export const EditFooter = () => {
  const dispatch = useAppDispatch();
  const [senting, setSenting] = useState(false);
  const [footer, setFooter] = useState({
    socnetYoutube: "",
    socnetTelegram: "",
    socnetInstagram: "",
    socnetVK: "",
    contactsAddress: "",
    contactsAddressEn: "",
    contactsMail: "",
    contactsPhone: "",
    textHeader: "",
    textHeaderEn: "",
    textText: "",
    textTextEn: "",
  });
  const {
    data: footerData,
    loading: footerLoading,
    error: footerError,
  } = useQuery(GET_FOOTER);
  const [previewToggler, setPreviewToggler] = useState(false);

  useEffect(() => {
    if (!footerData) return;
    setFooter({ ...footerData?.footer });
  }, [footerData]);

  const [
    updateFooter,
    { data: updateFooterData, loading: loadingFooter, error: errorFooter },
  ] = useMutation(UPDATE_FOOTER, {
    refetchQueries: [
      {
        query: GET_FOOTER,
        variables: {
          filter: {},
        },
      },
    ],
  });

  const changeYouTubeLink = (event: ChangeEvent<HTMLInputElement>) =>
    setFooter({ ...footer, socnetYoutube: event.target.value });
  const changeTelegramLink = (event: ChangeEvent<HTMLInputElement>) =>
    setFooter({ ...footer, socnetTelegram: event.target.value });
  const changeInstagramLink = (event: ChangeEvent<HTMLInputElement>) =>
    setFooter({ ...footer, socnetInstagram: event.target.value });
  const changeVKLink = (event: ChangeEvent<HTMLInputElement>) =>
    setFooter({ ...footer, socnetVK: event.target.value });

  const changeTextHeader = (event: ChangeEvent<HTMLInputElement>) =>
    setFooter({ ...footer, textHeader: event.target.value });
  const changeTextHeaderEn = (event: ChangeEvent<HTMLInputElement>) =>
    setFooter({ ...footer, textHeaderEn: event.target.value });

  const changeTextText = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setFooter({ ...footer, textText: event.target.value });
  const changeTextTextEn = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setFooter({ ...footer, textTextEn: event.target.value });

  const changeContactsAddress = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setFooter({ ...footer, contactsAddress: event.target.value });

  const changeContactsMail = (event: ChangeEvent<HTMLInputElement>) =>
    setFooter({ ...footer, contactsMail: event.target.value });
  const changeContactsAddressEn = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setFooter({ ...footer, contactsAddressEn: event.target.value });
  const changeContactsPhone = (event: ChangeEvent<HTMLInputElement>) =>
    setFooter({ ...footer, contactsPhone: event.target.value });

  const closePage = () => {
    dispatch(changeIsCreateBroker(false));
    dispatch(changeNav(1));
    setFooter({
      socnetYoutube: "",
      socnetTelegram: "",
      socnetInstagram: "",
      socnetVK: "",
      contactsAddress: "",
      contactsAddressEn: "",
      contactsMail: "",
      contactsPhone: "",
      textHeader: "",
      textHeaderEn: "",
      textText: "",
      textTextEn: "",
    });
  };

  const handleSaveSent = () => {
    setSenting(true);
    updateFooter({
      variables: {
        data: {
          ...footer,
        },
      },
    });
    if (!loadingFooter || errorFooter === undefined) {
      setTimeout(() => {
        closePage();
      }, 2000);
    } else {
      setTimeout(() => {
        closePage();
      });
    }
  };

  // Блок скрола.
  useEffect(() => {
    // previewToggler && (document.body.style.overflow = 'hidden')
    if (previewToggler) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [previewToggler]);

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: "7px" }}>Сохранено</span>
    </>
  );

  return (
    <Container className={s.container}>
      <Title title="Футер" />
      <div className={s.wrapper}>
        <div className={s.first_block}>
          <Panel className={s.info__panel} padding>
            <Title className={s.panel__title} title="Соц. сети" />
            <Label title="YouTube">
              <div className={s.social__link__wrapper}>
                <Field
                  className={s.social__link}
                  view="default"
                  value={footer.socnetYoutube}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeYouTubeLink?.(event)
                  }
                  maxLength={100}
                  telegram
                />
                <img
                  className={s.social__link_icon}
                  src={iconYoutube}
                  alt="youtube"
                  width="25"
                  height="25"
                />
              </div>
            </Label>
            <Label title="Telegram">
              <div className={s.social__link__wrapper}>
                <Field
                  className={s.social__link}
                  view="default"
                  value={footer.socnetTelegram}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeTelegramLink?.(event)
                  }
                  maxLength={100}
                  telegram
                />
                <img
                  className={s.social__link_icon}
                  src={iconTelegram}
                  alt="telegram"
                  width="25"
                  height="25"
                />
              </div>
            </Label>
            <Label title="Instagram">
              <div className={s.social__link__wrapper}>
                <Field
                  className={s.social__link}
                  view="default"
                  value={footer.socnetInstagram}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeInstagramLink?.(event)
                  }
                  maxLength={100}
                  telegram
                />
                <img
                  className={s.social__link_icon}
                  src={iconInstagram}
                  alt="instagram"
                  width="25"
                  height="25"
                />
              </div>
            </Label>
            <Label title="Vkontakte">
              <div className={s.social__link__wrapper}>
                <Field
                  className={s.social__link}
                  view="default"
                  value={footer.socnetVK}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeVKLink?.(event)
                  }
                  maxLength={100}
                  telegram
                />
                <img
                  className={s.social__link_icon}
                  src={iconVK}
                  alt="instagram"
                  width="25"
                  height="25"
                />
              </div>
            </Label>
          </Panel>
          <Panel className={s.info__panel} padding>
            <Title className={s.panel__title} title="Контакты" />
            <Label title="Адрес">
              <div className={s.input__wrapper}>
                {/* <Field
                  className={s.contact__info}
                  view="default"
                  value={footer.contactsAddress}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeContactsAddress?.(event)
                  }
                  maxLength={100}
                />
                <Field
                  className={s.contact__info}
                  view="default"
                  value={footer.contactsAddressEn}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeContactsAddressEn?.(event)
                  }
                  maxLength={100}
                  eng
                /> */}
                <Textarea
                  className={s.contact__info}
                  value={footer.contactsAddress}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    changeContactsAddress?.(event)
                  }
                  maxLength={100}
                  rows={3}
                />
                <Textarea
                  className={s.contact__info}
                  value={footer.contactsAddressEn}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    changeContactsAddressEn?.(event)
                  }
                  maxLength={100}
                  rows={3}
                  eng
                />
              </div>
            </Label>
            <Label title="Телефон">
              <Field
                className={s.social__link}
                view="default"
                value={footer.contactsPhone}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  changeContactsPhone?.(event)
                }
                maxLength={100}
              />
            </Label>
            <Label title="Почта">
              <Field
                className={s.social__link}
                view="default"
                value={footer.contactsMail}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  changeContactsMail?.(event)
                }
                maxLength={100}
              />
            </Label>
          </Panel>
          <Panel className={s.info__panel} padding>
            <Title className={s.panel__title} title="Текст" />
            <Label title="Заголовок">
              <div className={s.input__wrapper}>
                <Field
                  className={s.text__header}
                  view="default"
                  value={footer.textHeader}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeTextHeader?.(event)
                  }
                  maxLength={100}
                />
                <Field
                  className={s.text__header}
                  view="default"
                  value={footer.textHeaderEn}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeTextHeaderEn?.(event)
                  }
                  maxLength={100}
                  eng
                />
              </div>
            </Label>
            <Label title="Текст">
              <div className={s.input__wrapper}>
                <Textarea
                  className={s.text__text}
                  value={footer.textText}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    changeTextText?.(event)
                  }
                  // maxLength={350}
                  rows={5}
                />
                <Textarea
                  className={s.text__text}
                  value={footer.textTextEn}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    changeTextTextEn?.(event)
                  }
                  // maxLength={350}
                  rows={5}
                  eng
                />
              </div>
            </Label>
          </Panel>
        </div>
        <Panel className={s.btns__panel}>
          <Button
            onClick={() => setPreviewToggler(true)}
            // disabled
            className={s.btns__panel_preview}
            icon
            src={preview}
          >
            Предпросмотр
          </Button>
          <Button
            onClick={handleSaveSent}
            className={s.btns__panel_save}
            // className={Math.ceil(sumField) === 100 ? s.btns__panel_save : s.btns__panel_save_disabled}
            theme="dark"
            // disabled={Math.ceil(sumField) < 100}
            // disabled={!broker?.firstname}
          >
            {!senting ? (
              "Сохранить и опубликовать"
            ) : footerLoading ? (
              <Loader />
            ) : !footerLoading && errorFooter ? (
              "Ошибка"
            ) : (
              sentSaved
            )}
          </Button>
        </Panel>
      </div>
      {previewToggler && (
        <Portal>
          <div className={s.preview}>
            <Footer setPreviewToggler={setPreviewToggler} footer={footer} />
          </div>
        </Portal>
      )}
    </Container>
  );
};
