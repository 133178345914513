import React, { createContext, useState } from 'react'

export const AppContext = createContext()

export const UseAppContext = ({ children }) => {
  const [isCheck, setIsCheck] = useState([])
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isStory, setIsStory] = useState([])
  const [isCheckStories, setIsCheckStories] = useState(false)
  const [isDynamic, setIsDynamic] = useState([])
  const [isCheckDynamics, setIsCheckDynamics] = useState(false)
  const [isCountUnread, setCountUnread] = useState(null)

  // функция для выбора всех чекбоксов инвесторов для рассылки
  const handleSelectAll = arr => {
    setIsCheckAll(!isCheckAll)
    setIsCheck(arr?.map(elem => elem?.profile?.uuid))
    if (isCheckAll) {
      setIsCheck([])
    }
  }

  // функция для выбора одного чекбокса инвестора для рассылки
  const handleClick = e => {
    const { id, checked } = e.target
    setIsCheck([...isCheck, id])
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id))
    }
  }

  // функция для выбора всех чекбоксов сторис для рассылки
  const handleSelectStories = arr => {
    setIsCheckStories(!isCheckStories)
    setIsStory(arr?.map(elem => elem?.uuid))
    if (isCheckStories) {
      setIsStory([])
    }
  }

  // функция для выбора одного чекбокса сторис для рассылки
  const handleSelectStory = e => {
    const { id, checked } = e.target
    setIsStory([...isStory, id])
    if (!checked) {
      setIsStory(isStory.filter(item => item !== id))
    }
  }

  // функция для выбора всех чекбоксов динамических акций для рассылки
  const handleSelectDynamics = arr => {
    setIsCheckDynamics(!isCheckDynamics)
    setIsDynamic(arr?.map(elem => elem?.uuid))
    if (isCheckDynamics) {
      setIsDynamic([])
    }
  }

  // функция для выбора одного чекбокса  динамической акции для рассылки
  const handleSelectDynamic = e => {
    const { id, checked } = e.target
    setIsDynamic([...isDynamic, id])
    if (!checked) {
      setIsDynamic(isDynamic.filter(item => item !== id))
    }
  }

  return (
    <AppContext.Provider
      value={{
        isCheck,
        setIsCheck,
        isCheckAll,
        setIsCheckAll,
        handleSelectAll,
        handleClick,
        isStory,
        setIsStory,
        isCheckStories,
        setIsCheckStories,
        handleSelectStories,
        handleSelectStory,
        isDynamic,
        setIsDynamic,
        isCheckDynamics,
        setIsCheckDynamics,
        handleSelectDynamics,
        handleSelectDynamic,
        isCountUnread,
        setCountUnread,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
