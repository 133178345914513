import React, {
  ChangeEvent,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import clsx from 'clsx'
import { useQuery } from '@apollo/client'
import { AppContext } from '../../features/Context/useAppContext'
import {
  Checkbox,
  Container,
  Field,
  ProjectPanel,
  SortingInput,
  FilterClients,
  ErrorMessage,
} from '../../ui'
import { CLIENTS } from '../../apollo/query/allClients'
import { ALL_PROJECTS } from '../../apollo/query/allProjects'
import filter from './img/icon-filter.svg'
import s from './PromotionInvestorsList.module.css'
import { projectsArrItem } from '../ClientsList'

export const PromotionInvestorsList = () => {
  const [value, setValue] = useState<string>('')
  const [arrow, setArrow] = useState<boolean>(false)
  const {
    isCheck, isCheckAll, setIsCheckAll, handleSelectAll, handleClick
  } = useContext(AppContext)
  const [isButton3mActive, setIsButton3mActive] = useState<boolean>(false)
  const [isButton2mActive, setIsButton2mActive] = useState<boolean>(false)
  const [isButton1mActive, setIsButton1mActive] = useState<boolean>(false)
  const [activeProjectItem, setActiveProjectItem] = useState<
    projectsArrItem | any
  >([])
  const [fromSumValueNumber, setFromSumValueNumber] = useState<any>('')
  const [toSumValueNumber, setToSumValueNumber] = useState<any>('')
  const [totalInvestSumFromActiveNumber, setTotalInvestSumFromActiveNumber] = useState<any>('')
  const [totalInvestSumToActiveNumber, setTotalInvestSumToActiveNumber] = useState<any>('')
  const [fromSumValue, setFromSumValue] = useState<number | string>('')
  const [toSumValue, setToSumValue] = useState<number | string>('')
  const [fromSumValueActive, setFromSumValueActive] = useState<number | string>('')
  const [toSumValueActive, setToSumValueActive] = useState<number | string>('')
  const [filterByCountToState, setFilterByCountToState] = useState<number | string>('')
  const [filterByCountFromStateActive, setFilterByCountFromStateActive] = useState<number | string>('')
  const [filterByCountToStateActive, setFilterByCountToStateActive] = useState<number | string>('')
  const [filterByCountFromState, setFilterByCountFromState] = useState<number | string>('')
  const [loaderFilter, setLoaderFilter] = useState<boolean>(false)
  const [selectedProjectsUUID, setSelectedProjectsUUID] = useState<string[]>([])
  const [isFilterApplayed, setIsFilterApplayes] = useState<boolean>(false)

  const {
    data, loading, error, refetch
  } = useQuery(CLIENTS, {
    variables: {
      sort: {
        field: 'lastName',
        direction: 'asc',
      },
      totalInvestSumFrom: '0',
      totalInvestSumTo: '1000000000',
      activeInvestSumFrom: null,
      activeInvestSumTo: null,
      totalProjectCountFrom: 0,
      totalProjectCountTo: 100,
      activeProjectCountFrom: null,
      activeProjectCountTo: null,
      expireOn: null,
      projectsUUID: null,
    },
  })

  const {
    data: projectsData,
    loading: loadingProjects,
    error: errorProjects,
  } = useQuery(ALL_PROJECTS)

  useEffect(() => {
    if (projectsData?.projects) {
      const tempArr = projectsData?.projects.map((elem: projectsArrItem) => ({
        ...elem,
        active: false,
      }))
      setActiveProjectItem([
        ...tempArr.filter((el: any) => el.projectStatusNew !== 'draft'),
      ])
    }
  }, [projectsData])

  const investorsFilteredByName = data?.clients?.filter(
    (elem: any) => elem?.firstName?.toLowerCase().includes(value.toLowerCase().trim())
      || elem?.middleName?.toLowerCase().includes(value.toLowerCase().trim())
      || elem?.lastName?.toLowerCase().includes(value.toLowerCase().trim())
  )

  // фильтр по месяцам до текущей даты
  const filter3m = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement

    if (target.id === '3m') {
      setIsButton3mActive(true)
      setIsButton2mActive(false)
      setIsButton1mActive(false)
    }

    if (target.id === '2m') {
      setIsButton3mActive(false)
      setIsButton2mActive(true)
      setIsButton1mActive(false)
    }

    if (target.id === '1m') {
      setIsButton3mActive(false)
      setIsButton2mActive(false)
      setIsButton1mActive(true)
    }

    if (
      (target.id === '3m' && isButton3mActive === true)
      || (target.id === '2m' && isButton2mActive === true)
      || (target.id === '1m' && isButton1mActive === true)
    ) {
      setIsButton3mActive(false)
      setIsButton2mActive(false)
      setIsButton1mActive(false)
    }
  }

  const filterByInvestmentFrom = (e: ChangeEvent<HTMLInputElement>) => {
    const sum1 = e.target.value
      .split('')
      .map(i => i.trim())
      .filter(i => i !== '')
      .join('')
    setFromSumValue(String(e.target.value))
    setFromSumValueNumber(sum1)
  }

  const filterByInvestmentTo = (e: ChangeEvent<HTMLInputElement>) => {
    const sum1 = e.target.value
      .split('')
      .map(i => i.trim())
      .filter(i => i !== '')
      .join('')
    setToSumValue(String(e.target.value))
    setToSumValueNumber(sum1)
  }

  // фильтр для 'от' для активных из выбранных по сумме инвестиций
  const filterByInvestmentFromActive = (e: ChangeEvent<HTMLInputElement>) => {
    // setFromSumValueActive(+e.target.value.trim() === 0 ? '' : +e.target.value.trim())
    const sum1 = e.target.value
      .split('')
      .map(i => i.trim())
      .filter(i => i !== '')
      .join('')
    setFromSumValueActive(String(e.target.value))
    setTotalInvestSumFromActiveNumber(sum1)
  }

  // фильтр для 'до' для активных из выбранных по сумме инвестиций
  const filterByInvestmentToActive = (e: ChangeEvent<HTMLInputElement>) => {
    // setToSumValueActive(+e.target.value.trim() === 0 ? '' : +e.target.value.trim())
    const sum1 = e.target.value
      .split('')
      .map(i => i.trim())
      .filter(i => i !== '')
      .join('')
    setToSumValueActive(String(e.target.value))
    setTotalInvestSumToActiveNumber(sum1)
  }

  // активные проекты в фильтре
  // const activeProj = useCallback(
  //   activeProjectItem
  //     ?.filter((proj: projectsArrItem) => proj.active === true)
  //     .map((item: projectsArrItem) => item.name),
  //   [activeProjectItem],
  // )

  const applyFilter = () => {
    const totalInvestSumFrom: any = +fromSumValueNumber
    const totalInvestSumTo: any = +toSumValueNumber === 0 ? 1_000_000_000_000 : +toSumValueNumber
    const totalInvestSumFromActive: any = totalInvestSumFromActiveNumber === ''
      || totalInvestSumFromActiveNumber === 0
      ? null
      : String(totalInvestSumFromActiveNumber)
    const totalInvestSumToActive: any = totalInvestSumToActiveNumber === '' || totalInvestSumToActiveNumber === 0
      ? null
      : String(totalInvestSumToActiveNumber)
    const toCount: any = filterByCountToState === '' || filterByCountToState === 0
      ? 1_000_000_000_000
      : filterByCountToState
    const fromCount: any = filterByCountFromState === '' || filterByCountFromState === 0
      ? 0
      : filterByCountFromState
    const toCountActive: any = filterByCountToStateActive === '' || filterByCountToStateActive === 0
      ? null
      : filterByCountToStateActive
    const fromCountActive: any = filterByCountFromStateActive === '' || filterByCountFromStateActive === 0
      ? null
      : filterByCountFromStateActive
    const expireOn: any = isButton3mActive
      ? -2
      : isButton2mActive
        ? -1
        : isButton1mActive
          ? 0
          : null
    const projectsUUID: any = selectedProjectsUUID
    refetch({
      totalInvestSumFrom: String(totalInvestSumFrom),
      totalInvestSumTo: String(totalInvestSumTo),
      activeInvestSumFrom: totalInvestSumFromActive,
      activeInvestSumTo: totalInvestSumToActive,
      totalProjectCountFrom: fromCount,
      totalProjectCountTo: toCount,
      activeProjectCountFrom: fromCountActive,
      activeProjectCountTo: toCountActive,
      expireOn,
      projectsUUID,
    })

    // сброс фильтров
    if (
      fromSumValue === ''
      && toSumValue === ''
      && fromSumValueActive === ''
      && toSumValueActive === ''
      && filterByCountToState === ''
      && filterByCountFromState === ''
      && filterByCountToStateActive === ''
      && filterByCountFromStateActive === ''
      && expireOn === null
      && projectsUUID.length === 0
    ) {
      setIsFilterApplayes(false)
    } else {
      setIsFilterApplayes(true)
    }
    // лоудер при нажатии
    setLoaderFilter(true)
    setTimeout(() => {
      setLoaderFilter(false)
    }, 1000)
  }

  return (
    <Container className={s.investros_list}>
      <div className={s.investor_list_header}>
        <Checkbox
          id="selectAll"
          name="selectAll"
          label="Выбрать всех"
          onChange={() => handleSelectAll(investorsFilteredByName)}
          isChecked={isCheckAll}
        />
        <div className={s.investor_list_inputs}>
          <Field
            className={clsx(s.investor_list_search)}
            icon
            placeholder="Поиск"
            placeholderColor="grey"
            view="search"
            type="text"
            value={value}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setValue(event.target.value)
              setIsCheckAll(false)
            }}
          />
          <SortingInput
            className={clsx(s.investor_list_sort)}
            src={filter}
            placeholder="Фильтр"
            arrow={arrow}
            onClick={() => setArrow(!arrow)}
          />
          {arrow && (
            <FilterClients
              className={s.investor_list_filter}
              filter3m={filter3m}
              isButton3mActive={isButton3mActive}
              isButton2mActive={isButton2mActive}
              isButton1mActive={isButton1mActive}
              applyFilter={applyFilter}
              activeProjectItem={activeProjectItem}
              setActiveProjectItem={setActiveProjectItem}
              fromSumValue={fromSumValue}
              toSumValue={toSumValue}
              fromSumValueActive={fromSumValueActive}
              toSumValueActive={toSumValueActive}
              filterByInvestmentFrom={filterByInvestmentFrom}
              filterByInvestmentTo={filterByInvestmentTo}
              filterByInvestmentFromActive={filterByInvestmentFromActive}
              filterByInvestmentToActive={filterByInvestmentToActive}
              filterByCountFromState={filterByCountFromState}
              filterByCountToState={filterByCountToState}
              setFilterByCountFromState={setFilterByCountFromState}
              setFilterByCountToState={setFilterByCountToState}
              filterByCountFromStateActive={filterByCountFromStateActive}
              filterByCountToStateActive={filterByCountToStateActive}
              setFilterByCountFromStateActive={setFilterByCountFromStateActive}
              setFilterByCountToStateActive={setFilterByCountToStateActive}
              setSelectedProjectsUUID={setSelectedProjectsUUID}
              loaderFilter={loaderFilter}
            />
          )}
        </div>
      </div>
      {error ? (
        <ErrorMessage>
          Ошибка:
          {' '}
          {error?.message}
        </ErrorMessage>
      ) : (
        <div className={s.investor_list_wrapper}>
          {investorsFilteredByName?.map((investor: any) => (
            <div className={s.investor_list_items} key={investor?.uuid}>
              <Checkbox
                id={investor?.profile?.uuid}
                name={investor?.profile?.uuid}
                src={investor?.photo}
                label={`${investor?.firstName} ${investor?.middleName} ${investor?.lastName}`}
                avatar
                firstName={investor?.firstName}
                lastName={investor?.lastName}
                fontSize="16px"
                isChecked={isCheck.includes(investor?.profile?.uuid)}
                onChange={handleClick}
              />
              <div>
                {investor?.contracts?.map((contract: any) => (
                  <div className={s.investor_list_project} key={contract?.uuid}>
                    <ProjectPanel>
                      {contract?.project?.name}
                      {' '}
                      {new Intl.DateTimeFormat('ru', {
                        day: '2-digit',
                        month: '2-digit',
                        year: '2-digit',
                      }).format(Date.parse(contract?.expireOn))}
                    </ProjectPanel>
                  </div>
                ))}
              </div>
              <div>
                <ProjectPanel className={s.investor_list_project}>
                  <span>
                    {investor?.contracts
                      ?.map((contract: number | any) => Number(contract?.investSum))
                      .reduce(
                        (acc: number, investSum: number) => acc + investSum,
                        0
                      )
                      .toLocaleString('ru')}
                    {' '}
                    ₽
                  </span>
                </ProjectPanel>
              </div>
            </div>
          ))}
        </div>
      )}
    </Container>
  )
}
