/* eslint-disable */

import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../redux/hook";
import { Container } from "../Container";
import s from "./nativeSelect.module.css";
// import { addSupportFields } from '../../redux/slices/projectSlice'
import {
  addSupportFields,
  changePledgeSelect,
  changeStatusSelect,
  changeCountriesSelect,
  addProjectFields,
  addInvestFormFields,
} from "../../redux/slices/projectSlice"; // добавил признак для дропдаун меню

import {
  changeFirstField,
  changeStatusSelectPromotionSlide,
} from "../../redux/slices/promotionSlice";
import {
  changeFirstFieldDynamic,
  changeStatusSelectDynamicPromotion,
} from "../../redux/slices/promotionDynamicSlice";
import {
  changeNav,
  changeIsCreateBroker,
} from "../../redux/slices/editSiteSlice";
import { openClient } from "../../redux/slices/pageSlice";

interface SelectType {
  className?: string;
  onClick?: any;
  onBlur?: () => void;
  show?: boolean;
  tags?: boolean;
  draft?: boolean;
  defaultTitle?: string;
  pledgeArr?: any;
  setPledgeArr?: any;
  status?: boolean;
  promotion?: string;
  promotionValue?: string;
  projectStatusNew?: string;
  brokerSelect?: boolean;
  setSelectedBroker?: any;
  setShowBrokerListSelect?: any;
  currency?: boolean;
  setShowCurrencySelect?: any;
  datePickerGrid?: boolean;
  dataPickerValue?: any;
  showDatePickerGrid?: boolean;
  setShowDatePickerGrid?: any;
  monthDay?: any;
  setDatePickerValue?: any;
  currencyDocHandler?: any;
  withdrawalDayHandler?: any;
  selectActProject?: boolean;
  setShowSelectAct?: any;
  dispatchActUUIDProject?: any;
  navigationSelect?: boolean;
  navigationArr?: any;
  changeSiteIcon?: string;
  src?: string;
  paymentPeriod?: boolean;
  setShowPaymentPeriod?: any;
  showPaymentPeriod?: boolean;
  setShowPaymentPeriodValue?: any;
  setAmount?: any;
  currencyTitle?: string;
  currencyFlag?: any;

  countryTitle?: string;
  countryFlag?: any;
  countries?: boolean;
  countryHandler?: any;
  selectedCountry?: any;
  countriesArr?: any;

  showPaymentPeriodValue?: number;
  dispatchwithdrawalCount?: (withdrawalCount: number) => void;
  projectTitleAct?: string;
  setProjectTitleAct?: ((arg: string) => void) | any;
  typeSelect?: boolean;
  uniqId?: any;
  selectTypeHandler?: any;
  showTypeHandlerClose?: any;
}

export const NativeSelect: React.FC<SelectType> = ({
  className,
  onClick,
  onBlur,
  show,
  tags = false,
  status,
  draft,
  defaultTitle,
  pledgeArr,
  setPledgeArr,
  promotion,
  promotionValue,
  projectStatusNew,
  brokerSelect,
  setSelectedBroker,
  setShowBrokerListSelect,
  currency,
  setShowCurrencySelect,
  datePickerGrid,
  dataPickerValue,
  showDatePickerGrid,
  setShowDatePickerGrid,
  monthDay,
  setDatePickerValue,
  currencyDocHandler,
  withdrawalDayHandler,
  selectActProject,
  setShowSelectAct,
  dispatchActUUIDProject,
  navigationSelect,
  navigationArr,
  changeSiteIcon,
  src,
  paymentPeriod,
  setShowPaymentPeriod,
  showPaymentPeriod,
  setShowPaymentPeriodValue,
  setAmount,
  currencyTitle,
  currencyFlag,

  //
  countryTitle,
  countryFlag,
  countryHandler,
  countries,
  selectedCountry,
  countriesArr,
  //
  showPaymentPeriodValue,
  dispatchwithdrawalCount,
  projectTitleAct = "",
  setProjectTitleAct,
  typeSelect,
  uniqId,
  selectTypeHandler,
  showTypeHandlerClose,
}) => {
  const dispatch = useAppDispatch();

  const [value, setValue] = useState<string | number | undefined>(
    defaultTitle || countryTitle || "Выберите из списка"
  );
  const [flagIcon, setFlagIcon] = useState(countryFlag || "");

  const statusArr = ["Активный", "Приостановленный", "Черновик", "Архив"];
  const statusArrDispatch = ["active", "suspended", "draft", "archive"];
  useEffect(() => {
    if (draft) {
      setValue("Выберите из списка");
    }
  }, [draft]);

  // для компонента editDoc
  useEffect(() => {
    if (currencyTitle) {
      setValue(currencyTitle);
      setFlagIcon(currencyFlag);
      setShowCurrencySelect(false);
    }
  }, [currencyTitle]);

  // для компонента editDoc
  useEffect(() => {
    if (currencyTitle) {
      setValue(currencyTitle);
    }
  }, [currencyTitle]);

  useEffect(() => {
    if (selectedCountry && selectedCountry.title) {
      setValue(selectedCountry.title);
      setFlagIcon(selectedCountry.flag);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (showPaymentPeriodValue) {
      setValue(showPaymentPeriodValue);
    }
  }, [showPaymentPeriodValue]);

  useEffect(() => {
    if (projectStatusNew) {
      const incomingStatus =
        statusArr[statusArrDispatch.findIndex((el) => el === projectStatusNew)];
      dispatch(
        addProjectFields({
          fieldName: "projectStatusNew",
          item: projectStatusNew,
        })
      );
      setValue(incomingStatus);
    }
  }, [projectStatusNew]);

  const useItemSelect = (id: string | number, title: string | number) => {
    setValue(title);
    setPledgeArr(
      pledgeArr.map((elem: any) => {
        if (elem.id === id) {
          elem.active = !elem.active;
          dispatch(
            addInvestFormFields(
              pledgeArr
                .map((e: any) => e.active && { name: e.title })
                .filter((e: any) => e)
            )
          );
        }
        return elem;
      })
    );
  };

  const handleSelectCountry = (title: string, img: string) => {
    setValue(title);
    setFlagIcon(img);

    if (countryHandler) {
      countryHandler(title, img);
    }

    dispatch(changeCountriesSelect()); // закрываем селект со странами
  };

  //  функция для статуса - устанавливаю значение и передаю в redux status
  const useItemSelectStatus = (title: string) => {
    setValue(title);
    const statudSend =
      statusArrDispatch[statusArr.findIndex((el) => el === title)];
    dispatch(
      dispatch(
        addProjectFields({ fieldName: "projectStatusNew", item: statudSend })
      )
    );
  };
  //  функция для выбора брокера
  const useItemSelectBrocker = (title: string) => {
    setValue(title);
    setSelectedBroker(title);
    setShowBrokerListSelect(false);
  };

  //  функция для обеспечения
  const useItemSelectPledge = (title: string) => {
    setValue(title);
    if (promotion === "dynamic") {
      dispatch(changeFirstFieldDynamic({ nameField: "status", event: title }));
      dispatch(changeStatusSelectDynamicPromotion());
    } else if (promotion === "stories") {
      dispatch(changeFirstField({ nameField: "status", event: title }));
      dispatch(changeStatusSelectPromotionSlide());
    }
  };

  // выбор валюты документа

  const useItemSelectCurrency = (title: string, img: string) => {
    setValue(title);
    setFlagIcon(img);
    setShowCurrencySelect(false);
    currencyDocHandler(title);
  };

  const useItemSelectActProject = (id: string, name: string) => {
    if (projectTitleAct) {
      setProjectTitleAct("");
    }
    setValue(name);
    setShowSelectAct(false);
    dispatchActUUIDProject(id);
  };
  // для выбора количества месяце
  const useItemSelectPaymentPeriod = (title: string) => {
    setValue(title);
    setShowPaymentPeriod(!showPaymentPeriod);
    setShowPaymentPeriodValue(+title);
    setAmount(title);
    if (dispatchwithdrawalCount !== undefined) {
      dispatchwithdrawalCount(+title);
    }
  };

  useEffect(() => {
    if (pledgeArr?.some((e: any) => e.active)) {
      setValue("");
    } else if (promotionValue) {
      setValue(promotionValue);
    } else {
      setValue("Выберите из списка");
    }
  }, [pledgeArr, promotionValue]);

  const handleDatePickerGrid = (e: any) => {
    setValue(e.target.innerText);
    setShowDatePickerGrid(false);
    setDatePickerValue(e.target.innerText);
    withdrawalDayHandler(e.target.innerText);
  };

  useEffect(() => {
    if (navigationSelect) {
      setValue("Изменить сайт");
    }
  }, [navigationSelect]);

  // навигация по изменению сайта
  const handleClickNav = (e: any) => {
    dispatch(changeNav(+e.target.id));
    dispatch(changeIsCreateBroker(false));
  };

  useEffect(() => {
    if (typeSelect) {
      if (defaultTitle) {
        setValue(defaultTitle !== "" ? defaultTitle : "Тип");
      } else {
        setValue("Тип");
      }
    }
  }, [typeSelect]);

  const useItemTypeSelect = (type: string, uniqId: string) => {
    setValue(type);
    selectTypeHandler(type, uniqId);
    showTypeHandlerClose(uniqId);
  };

  return (
    <div className={datePickerGrid ? s.wrapper : ""}>
      <button
        className={clsx(
          s.dropdownBtn,
          show && pledgeArr ? s.active : "",
          className,
          value == "Выберите из списка" ? s.placeholder_red : "",
          currency && value !== "Выберите из списка" ? s.title_currency : "",
          showDatePickerGrid ? s.active : "",
          selectActProject && show ? s.active : "",
          navigationSelect && s.title_navigationSelect,
          navigationSelect && show ? s.active : "",
          typeSelect && show ? s.active : "",
          countries && value !== "Выберите из списка" ? s.dropdownBtn_flex : ""
        )}
        id={uniqId}
        type="button"
        onClick={onClick}
        onBlur={onBlur}
      >
        {navigationSelect && (
          <img
            className={s.changeSiteIcon_img_value}
            src={src}
            alt="icon"
            width="22"
            height="22"
          />
        )}
        {currency && flagIcon !== "" && (
          <img
            className={s.currency_img_value}
            src={flagIcon}
            alt="flag"
            width="20"
            height="20"
          />
        )}

        {countries && flagIcon !== "" && (
          <img
            className={s.country_img_value}
            src={flagIcon}
            alt="flag"
            width="22"
            height="22"
          />
        )}

        {!selectActProject
          ? value ||
            pledgeArr?.map((e: any) => (e.active ? `${e.title}, ` : ""))
          : !projectTitleAct
          ? value
          : projectTitleAct}
      </button>

      {(pledgeArr || countriesArr?.length > 0) && !navigationSelect ? (
        <Container
          className={
            brokerSelect
              ? clsx(
                  s.container_broker,
                  className,
                  show ? s.visible_broker : ""
                )
              : clsx(s.container, className, show ? s.visible : "")
          }
        >
          {!datePickerGrid ? (
            <ul
              className={
                tags
                  ? clsx(s.list_container, tags ? s.tags_container : "")
                  : clsx(s.list_container, s.list_container_status)
              }
            >
              {pledgeArr &&
                pledgeArr?.map((item: any, index: number) => (
                  <li
                    className={clsx(
                      s.list,
                      tags ? s.tags : "",
                      tags ? item.active && s.activeItem : "",
                      currency ? s.list_currency : "",
                      countries ? s.list_country : ""
                    )}
                    onClick={() => {
                      if (tags) {
                        // для тегов
                        if (selectActProject)
                          useItemSelectActProject(item.uuid, item.name);
                        else {
                          useItemSelect(item.id, item.title);
                        }
                      } else if (status) {
                        // для статуса
                        useItemSelectStatus(item.title);
                        // закрытие drapdown
                        dispatch(changeStatusSelect());
                      } else if (brokerSelect) {
                        useItemSelectBrocker(item.title);
                      } else if (currency) {
                        useItemSelectCurrency(item.title, item.img);
                      } else if (paymentPeriod) {
                        useItemSelectPaymentPeriod(item.title);
                      } else if (typeSelect) {
                        useItemTypeSelect(item.title, uniqId);
                      } else {
                        useItemSelectPledge(item.title);
                        dispatch(changePledgeSelect());
                      }
                    }}
                    key={String(index).concat("pledgeArr")}
                  >
                    {currency && (
                      <img
                        className={s.currency_img}
                        src={item.img}
                        alt="flag"
                        width="20"
                        height="20"
                      />
                    )}
                    {!selectActProject ? item.title : item.name}
                  </li>
                ))}

              {countriesArr && // ДЛЯ МАССИВА СТРАН
                countriesArr?.map((country: any, index: number) => (
                  <li
                    className={clsx(
                      s.list,
                      tags ? s.tags : "",
                      tags ? country.active && s.activeItem : "",
                      currency ? s.list_currency : "",
                      countries ? s.list_country : ""
                    )}
                    key={String(index).concat("countriesArr")}
                  >
                    {countries && (
                      <label key={index} className={s.radioContainer}>
                        <input
                          type="radio"
                          name="country"
                          onChange={() => {
                            if (countries) {
                              handleSelectCountry(country.title, country.img);
                            }
                          }}
                          value={country.title}
                          checked={selectedCountry.title === country.title}
                          className={s.radioInput}
                        />
                        <img
                          className={s.country_img}
                          src={country.img}
                          alt="flag"
                          width="22"
                          height="22"
                        />
                      </label>
                    )}
                    {!selectActProject ? country.title : country.name}
                  </li>
                ))}
            </ul>
          ) : null}
        </Container>
      ) : (
        showDatePickerGrid && (
          <div className={s.datePickerGrid}>
            {monthDay.map((el: any, index: number) => (
              <div
                className={s.datePickerGrid_item}
                key={String(index).concat("monthDay")}
                onClick={(e: any) => handleDatePickerGrid(e)}
              >
                {el}
              </div>
            ))}
          </div>
        )
      )}
      {navigationSelect && (
        <Container
          className={clsx(s.container_navigationSelect, show ? s.visible : "")}
        >
          <ul className={clsx(s.list_container, s.list_container_status)}>
            {navigationArr?.map((item: any, index: number) => (
              <Link to={item.path} key={String(index).concat("navigationArr")}>
                <li
                  className={clsx(
                    s.list,
                    navigationSelect ? s.list_navigationSelect : ""
                  )}
                  key={String(item.id).concat("navigation")}
                  id={item.id}
                  onClick={(e: React.MouseEvent) => handleClickNav(e)}
                >
                  {item.title}
                </li>
              </Link>
            ))}
          </ul>
        </Container>
      )}
    </div>
  );
};
