import React, { FC } from 'react'
import clsx from 'clsx'
import s from './Section.module.css'

interface Section {
  children?: any
  className?: string
}

export const Section: FC<Section> = ({ children, className }) => <div className={clsx(s.section, className)}>{children}</div>
