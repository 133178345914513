/*eslint-disable*/
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { ChangeEvent, useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import { useQuery, useMutation } from "@apollo/client";
import { useAppDispatch } from "../../redux/hook";
import { useGetRole } from "../../hooks/useGetRole";
import { openClient } from "../../redux/slices/pageSlice";
import { CLIENTS } from "../../apollo/query/allClients";
import { ALL_PROJECTS } from "../../apollo/query/allProjects";
import { ALL_BROCKERS } from "../../apollo/query/allBrockers";
import { CONTRACT_UPDATE } from "../../apollo/mutation/contractUpdate";
import {
  Button,
  Container,
  Field,
  ProjectPanel,
  FilterClients,
  SortingInput,
  Edit,
  Checkbox,
  Panel,
  Title,
  Label,
  Loader,
} from "../../ui";
import { NativeSelect } from "../../ui/nativeSelect";
import { Portal } from "../../ui/Portal/Portal";
import { headerList } from "./data";
import { admin, lawyer, manager } from "../../references/data";
import client from "./img/icon-client.svg";
import filter from "./img/icon-filter.svg";
import s from "./ClientsList.module.css";

export type projectsArrItem = {
  id: number;
  name: string;
  uuid: string;
  __typename: string;
  active?: boolean;
};

export type Project = {
  name: string;
  uuid: string;
  __typename: string;
};

export type Contracts = {
  expireOn: string;
  investSum: any;
  uuid: string;
  __typename: string;
  project: Project;
};

export type DataClients = {
  firstName: string;
  lastName: string;
  middleName: string;
  photo: string;
  uuid: string;
  __typename: string;
  contracts: Contracts[];
  brokers: any;
};

export const ClientsList = () => {
  const dispatch = useAppDispatch();
  const { isRole, navigate } = useGetRole();

  const [arrow, setArrow] = useState(false);
  const [value, setValue] = useState("");
  const [fromSumValue, setFromSumValue] = useState<number | string>("");
  const [toSumValue, setToSumValue] = useState<number | string>("");
  const [activeProjectItem, setActiveProjectItem] = useState<
    projectsArrItem | any
  >([]);
  const [activeProjFilter, setActiveProjFilter] = useState<string[]>([]);
  const [activeSum, setActiveSum] = useState(false);
  const [activeSortBtn, setActiveSortBtn] = useState<number | null>(null);
  const [isButton3mActive, setIsButton3mActive] = useState<boolean>(false);
  const [isButton2mActive, setIsButton2mActive] = useState<boolean>(false);
  const [isButton1mActive, setIsButton1mActive] = useState<boolean>(false);
  const [fromSumValueActive, setFromSumValueActive] =
    useState<number | string>("");
  const [toSumValueActive, setToSumValueActive] = useState<number | string>("");
  const [filterByCountToState, setFilterByCountToState] =
    useState<number | string>("");
  const [filterByCountFromStateActive, setFilterByCountFromStateActive] =
    useState<number | string>("");
  const [filterByCountToStateActive, setFilterByCountToStateActive] =
    useState<number | string>("");
  const [filterByCountFromState, setFilterByCountFromState] =
    useState<number | string>("");
  const [selectedProjectsUUID, setSelectedProjectsUUID] = useState<any>([]);
  const [clientArr, setClientsArr] = useState<any>([]);
  const now: any = new Date();
  const today90: any = new Date();
  const today60: any = new Date();
  const today30: any = new Date();
  // день через 3 месяца
  const now90: any = today90.setMonth(today90.getMonth() + 3);
  // день через 2 месяца
  const now60: any = today60.setMonth(today60.getMonth() + 2);
  // день через 1 месяц
  const now30: any = today30.setMonth(today30.getMonth() + 1);
  const [arr3m, setArr3m]: any = useState([]);
  // выбранные проекты
  const [selectedProjects, setSelectedProjects] = useState<any>([]);
  const [filterByTerm, setFilerByTerm] = useState<any>([]);
  // действующие клиенты
  const [activeClients, setActiveClients] = useState<any>([]);
  const [editActive, setEditActive] = useState<number | null>(null);
  const [isCheck, setIsCheck] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const date30 = new Date(now30);
  const [isModal, setIsModal] = useState(false);
  const [clientToDelete, setClientToDelete] = useState("");
  const [isEditSelected, setIsEditSelected] = useState(false);
  const [brokerArr, setBrokerArr] = useState<any[]>([]);
  const [brokerList, setBrokerList] = useState<string[]>([]);
  const [showBrokerListSelect, setShowBrokerListSelect] =
    useState<boolean>(false);
  const [brokerSelect] = useState<boolean>(true);
  const [selectedBroker, setSelectedBroker] = useState<string>("");
  const [contractsToChangeBroker, setContractsToChangeBroker] = useState<any>(
    []
  );
  const [loaderFilter, setLoaderFilter] = useState<boolean>(false);
  const [isFilterApplayed, setIsFilterApplayes] = useState<boolean>(false);
  const [fromSumValueNumber, setFromSumValueNumber] = useState<any>("");
  const [toSumValueNumber, setToSumValueNumber] = useState<any>("");
  const [totalInvestSumFromActiveNumber, setTotalInvestSumFromActiveNumber] =
    useState<any>("");
  const [totalInvestSumToActiveNumber, setTotalInvestSumToActiveNumber] =
    useState<any>("");
  const [limit, setLimit] = useState(10);
  const [filteredClients, setFilteredClients] = useState<DataClients[] | []>(
    []
  );
  const [filterByFirstNameQuery, setFilterByFirstNameQuery] =
    useState<string | null>(null);
  const [filterByLastNameQuery, setFilterByLastNameQuery] =
    useState<string | null>(null);

  const [selectedBrockerFilter, setSelectedBrockerFilter] = useState<any>({
    uuid: null,
    name: "",
  });
  // console.log('selectedBrockerFilter', selectedBrockerFilter.uuid)

  const { data: allBrockers, error: errorBrocker } = useQuery(ALL_BROCKERS);
  // фильтр через сервер
  const { data, loading, refetch } = useQuery(CLIENTS, {
    variables: {
      sort: {
        field: "lastName",
        direction: "asc",
      },
      // slice: {
      //   offset: 0,
      //   limit,
      // },
      filter: {
        firstName: null,
        lastName: null,
        brokerUUID: null,
      },
      totalInvestSumFrom: "0",
      totalInvestSumTo: "1000000000",
      activeInvestSumFrom: null,
      activeInvestSumTo: null,
      totalProjectCountFrom: 0,
      totalProjectCountTo: 100,
      activeProjectCountFrom: null,
      activeProjectCountTo: null,
      expireOn: null,
      projectsUUID: null,
    },
  });

  const { data: projectsData } = useQuery(ALL_PROJECTS);

  // применить фильтр
  const applyFilter = () => {
    const totalInvestSumFrom: any = +fromSumValueNumber;
    const totalInvestSumTo: any =
      +toSumValueNumber === 0 ? 1000000000000 : +toSumValueNumber;
    const totalInvestSumFromActive: any =
      totalInvestSumFromActiveNumber === "" ||
      totalInvestSumFromActiveNumber === 0
        ? null
        : String(totalInvestSumFromActiveNumber);
    const totalInvestSumToActive: any =
      totalInvestSumToActiveNumber === "" || totalInvestSumToActiveNumber === 0
        ? null
        : String(totalInvestSumToActiveNumber);
    const toCount: any =
      filterByCountToState === "" || filterByCountToState === 0
        ? 1_000_000_000_000
        : filterByCountToState;
    const fromCount: any =
      filterByCountFromState === "" || filterByCountFromState === 0
        ? 0
        : filterByCountFromState;
    const toCountActive: any =
      filterByCountToStateActive === "" || filterByCountToStateActive === 0
        ? null
        : filterByCountToStateActive;
    const fromCountActive: any =
      filterByCountFromStateActive === "" || filterByCountFromStateActive === 0
        ? null
        : filterByCountFromStateActive;
    const expireOn: any = isButton3mActive
      ? -2
      : isButton2mActive
      ? -1
      : isButton1mActive
      ? 0
      : null;
    const projectsUUID: any = selectedProjectsUUID;
    refetch({
      totalInvestSumFrom: String(totalInvestSumFrom),
      totalInvestSumTo: String(totalInvestSumTo),
      activeInvestSumFrom: totalInvestSumFromActive,
      activeInvestSumTo: totalInvestSumToActive,
      totalProjectCountFrom: fromCount,
      totalProjectCountTo: toCount,
      activeProjectCountFrom: fromCountActive,
      activeProjectCountTo: toCountActive,
      expireOn,
      projectsUUID,
      filter: {
        brokerUUID: selectedBrockerFilter.uuid,
      },
      // brokerUUID: selectedBrockerFilter.uuid
    });
    // сброс фильтров
    if (
      fromSumValue === "" &&
      toSumValue === "" &&
      fromSumValueActive === "" &&
      toSumValueActive === "" &&
      filterByCountToState === "" &&
      filterByCountFromState === "" &&
      filterByCountToStateActive === "" &&
      filterByCountFromStateActive === "" &&
      expireOn === null &&
      projectsUUID.length === 0
    ) {
      setIsFilterApplayes(false);
    } else {
      setIsFilterApplayes(true);
    }
    // лоудер при нажатии
    setLoaderFilter(true);
    setTimeout(() => {
      setLoaderFilter(false);
    }, 1000);
    setArrow(false);
  };

  const showPage = () => dispatch(openClient());

  useEffect(() => {
    if (projectsData?.projects) {
      const tempArr = projectsData?.projects.map((elem: projectsArrItem) => ({
        ...elem,
        active: false,
      }));
      setActiveProjectItem([
        ...tempArr.filter((el: any) => el.projectStatusNew !== "draft"),
      ]);
    }
  }, [projectsData]);

  // активные проекты в фильтре
  const activeProj = useCallback(
    activeProjectItem
      ?.filter((proj: projectsArrItem) => proj.active === true)
      .map((item: projectsArrItem) => item.name),
    [activeProjectItem]
  );

  // фильтрация клиентов если есть активные проекты и суммы
  const saveFilter = (
    fromValue: number | string,
    toValue: number | string,
    indexSortBtn: number | null
  ) => {
    setActiveSum(false);
    setActiveProjFilter(activeProj);

    const activeSumFilter = data?.clients.filter(
      (elem: DataClients) =>
        (fromValue !== ""
          ? elem.contracts
              ?.map((e: Contracts) => +e.investSum)
              .reduce((acc: number, cur: number) => acc + cur) >= +fromValue
          : true) &&
        (toValue !== ""
          ? elem.contracts
              ?.map((e: Contracts) => +e.investSum)
              .reduce((acc: number, cur: number) => acc + cur) <= +toValue
          : true)
    );

    if (data) {
      if (fromValue || toValue) {
        setActiveSum(true);
        if (activeProj.length) {
          setFilteredClients(
            activeSumFilter
              .filter((elem: DataClients) =>
                elem.contracts?.some((item: Contracts) =>
                  activeProj.includes(item.project.name)
                )
              )
              .flat()
          );
        } else {
          setFilteredClients(activeSumFilter.flat());
        }
      } else if ((!fromValue || !toValue) && !!activeProj.length) {
        setActiveSum(true);
        setFilteredClients(
          data?.clients
            .filter((elem: DataClients) =>
              elem.contracts?.some((item: Contracts) =>
                activeProj.includes(item.project.name)
              )
            )
            .flat()
        );
      } else if (!fromValue && !toValue && !activeProj.length) {
        setActiveSum(false);
        setFilteredClients([]);
      }
    }
  };

  // выбираем действующие договоры
  useEffect(() => {
    if (!data) return;
    const tempArr2: any = data?.clients.map((el: any) =>
      el.contracts === null
        ? null
        : el.contracts.filter((el: any) => new Date(el.expireOn) > now)
    );
    const tempArr3: any = data?.clients
      .map((el: any, index: any) => {
        if (tempArr2[index] === null) {
          return null;
        }
        return { ...el, contracts: tempArr2[index] };
      })
      .filter((el: any) => el !== null && el.contracts.length > 0);
    setActiveClients([...tempArr3]);
  }, [data]);

  // фильтр по месяцам до текущей даты
  const filter3m = (e: any) => {
    if (e.target.id === "3m") {
      setIsButton3mActive(true);
      setIsButton2mActive(false);
      setIsButton1mActive(false);
    }
    if (e.target.id === "2m") {
      setIsButton3mActive(false);
      setIsButton2mActive(true);
      setIsButton1mActive(false);
    }
    if (e.target.id === "1m") {
      setIsButton3mActive(false);
      setIsButton2mActive(false);
      setIsButton1mActive(true);
    }
    if (
      (e.target.id === "3m" && isButton3mActive) ||
      (e.target.id === "2m" && isButton2mActive) ||
      (e.target.id === "1m" && isButton1mActive)
    ) {
      setIsButton3mActive(false);
      setIsButton2mActive(false);
      setIsButton1mActive(false);
    }
  };

  // принимаем данные и фильтруем котракты !== null
  useEffect(() => {
    if (!data) return;
    if (isFilterApplayed) {
      setClientsArr([
        ...data.clients.filter((el: any) => el.contracts !== null),
      ]);
    } else {
      setClientsArr([...data.clients]);
    }
  }, [data]);

  // устанавливаю имя
  const qeuryFilter = (value: string) => {
    setFilterByFirstNameQuery(value);
    setFilterByLastNameQuery(value);
  };
  // запрос с фильтрацией
  const { data: firstNameData, loading: firstNameLoading } = useQuery(CLIENTS, {
    variables: {
      sort: {
        field: "lastName",
        direction: "asc",
      },
      // slice: {
      //   offset: 0,
      //   limit: 10,
      // },
      filter: {
        firstName: filterByFirstNameQuery,
        lastName: null,
        brokerUUID: null,
      },
      totalInvestSumFrom: "0",
      totalInvestSumTo: "1000000000",
      activeInvestSumFrom: null,
      activeInvestSumTo: null,
      totalProjectCountFrom: 0,
      totalProjectCountTo: 100,
      activeProjectCountFrom: null,
      activeProjectCountTo: null,
      expireOn: null,
      projectsUUID: null,
    },
  });
  const { data: lastNameData, loading: lastNameLoading } = useQuery(CLIENTS, {
    variables: {
      sort: {
        field: "lastName",
        direction: "asc",
      },
      // slice: {
      //   offset: 0,
      //   limit: 10,
      // },
      filter: {
        firstName: null,
        lastName: filterByLastNameQuery,
        brokerUUID: null,
      },
      totalInvestSumFrom: "0",
      totalInvestSumTo: "1000000000",
      activeInvestSumFrom: null,
      activeInvestSumTo: null,
      totalProjectCountFrom: 0,
      totalProjectCountTo: 100,
      activeProjectCountFrom: null,
      activeProjectCountTo: null,
      expireOn: null,
      projectsUUID: null,
    },
  });
  const [tempArrFirstName, setTempArrFirstName] = useState<any>([]);
  const [tempArrLastName, setTempArrLastName] = useState<any>([]);
  const [tempArrLastNameAndFirstNameUniq, setTempArrLastNameAndFirstNameUniq] =
    useState<any>([]);
  useEffect(() => {
    let tempName: any = [];
    let lastName: any = [];
    if (
      firstNameData &&
      firstNameData !== undefined &&
      firstNameData?.clients !== null
    ) {
      setTempArrFirstName([...firstNameData?.clients]);
      tempName = [...firstNameData?.clients];
      if (firstNameData?.clients === null) {
        setTempArrFirstName([]);
        tempName = [];
      }
    }
    if (
      lastNameData &&
      lastNameData !== undefined &&
      lastNameData.clients !== null
    ) {
      setTempArrLastName([...lastNameData?.clients]);
      lastName = [...lastNameData?.clients];
    }
    const wholeArr = [...tempName, ...lastName];
    const uniqueObjects: any = [];
    wholeArr.forEach((el: any) => {
      if (!uniqueObjects.some((uObj: any) => uObj.uuid === el.uuid)) {
        uniqueObjects.push(el);
      }
    });
    if (data && value === "") {
      setClientsArr([...data?.clients]);
    } else {
      setClientsArr([...uniqueObjects]);
    }
  }, [firstNameData, lastNameData, value]);

  const filterByName = (value: string) => {
    qeuryFilter(value);
    setValue(value);
  };

  const filterByInvestmentFrom = (e: ChangeEvent<HTMLInputElement>) => {
    const sum1 = e.target.value
      .split("")
      .map((i) => i.trim())
      .filter((i) => i !== "")
      .join("");
    setFromSumValue(String(e.target.value));
    setFromSumValueNumber(sum1);
  };
  const filterByInvestmentTo = (e: ChangeEvent<HTMLInputElement>) => {
    const sum1 = e.target.value
      .split("")
      .map((i) => i.trim())
      .filter((i) => i !== "")
      .join("");
    setToSumValue(String(e.target.value));
    setToSumValueNumber(sum1);
  };
  // фильтр для 'от' для активных из выбранных по сумме инвестиций
  const filterByInvestmentFromActive = (e: ChangeEvent<HTMLInputElement>) => {
    const sum1 = e.target.value
      .split("")
      .map((i) => i.trim())
      .filter((i) => i !== "")
      .join("");
    setFromSumValueActive(String(e.target.value));
    setTotalInvestSumFromActiveNumber(sum1);
  };
  // фильтр для 'до' для активных из выбранных по сумме инвестиций
  const filterByInvestmentToActive = (e: ChangeEvent<HTMLInputElement>) => {
    const sum1 = e.target.value
      .split("")
      .map((i) => i.trim())
      .filter((i) => i !== "")
      .join("");
    setToSumValueActive(String(e.target.value));
    setTotalInvestSumToActiveNumber(sum1);
  };

  useEffect(() => {
    if (!allBrockers) return;
    setBrokerArr(allBrockers.brokers);
    setBrokerList(
      allBrockers.brokers.map((el: any) => ({
        ...el,
        active: false,
        title: el.name,
      }))
    );
  }, [allBrockers]);

  const handleSelectAll = (arr: any) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(arr?.map((elem: any) => elem.uuid));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // функция для выбора одного чекбокса инвестора для рассылки
  const handleClick = (e: any) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id));
    }
  };

  // мутация и рефетч данных
  const [
    updateContractsBrokers,
    {
      data: contractsBrokers,
      loading: contractsBrokersLoading,
      error: contractsBrokersError,
    },
  ] = useMutation(CONTRACT_UPDATE, {
    refetchQueries: [
      {
        query: CLIENTS,
        variables: {
          sort: {
            field: "lastName",
            direction: "asc",
          },
        },
      },
    ],
  });

  const removeHandler = () => {
    setIsModal(!isModal);
  };
  const editActiveHandle = (index: number) => {
    setClientToDelete(
      `${clientArr[index].lastName}  ${clientArr[index].firstName}`
    );
    if (index === editActive) {
      setEditActive(null);
    } else {
      setEditActive(index);
    }
  };

  const cancelSelectBroker = () => {
    setIsEditSelected(!isEditSelected);
    setSelectedBroker("");
  };

  // обработчие при нажатии на Редактировать выбранное
  const selectHandler = () => {
    if (isCheck.length === 0) return;
    setIsEditSelected(!isEditSelected);
    const tempArr = clientArr.filter((el: any) => isCheck.includes(el.uuid));
    const heplerArr = tempArr.filter((el: any) => el.contracts !== null);
    const helperArr2 = heplerArr.map((el: any) =>
      el.contracts.map((elem: any) => elem.uuid)
    );
    setContractsToChangeBroker([...helperArr2.flat()]);
  };

  // обработчик кнопки "Назначить"
  const changeBroker = () => {
    const brokerFilter: any = brokerList.filter(
      (el: any) => el.name === selectedBroker
    );
    const brokerUUID = brokerFilter[0].uuid;

    // через цикл
    contractsToChangeBroker.forEach((el: any) => {
      updateContractsBrokers({
        variables: {
          uuid: el,
          data: {
            brokerUUID,
          },
        },
      });
    });

    setTimeout(() => {
      setIsEditSelected(!isEditSelected);
      setSelectedBroker("");
    }, 2000);
  };

  const [maxAgregator, setMaxAgregator] = useState<any>([]);

  const handleScroll = (e: any) => {
    if (e.target.scrollTop !== 0) {
      if (
        e.target.scrollTop <
          e.target.scrollHeight - e.target.clientHeight + 10 &&
        e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight - 10
      ) {
        const maxTemp = e.target.scrollHeight - e.target.clientHeight;
        if (!maxAgregator.includes(maxTemp)) {
          setLimit(limit + 10);
        }
        setMaxAgregator([
          ...maxAgregator,
          e.target.scrollHeight - e.target.clientHeight,
        ]);
      }
    }
  };

  return (
    <Container className={s.container}>
      <div className={s.search__bar}>
        <Field
          icon
          placeholder="Поиск"
          placeholderColor="grey"
          view="search"
          type="text"
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            filterByName(event.target.value.trim())
          }
        />
        <div
          className={
            isRole === admin || isRole === lawyer || isRole === manager
              ? s.search__bar_inputs
              : s.search__bar_inputs_m
          }
        >
          <Button
            className={clsx(
              s.button__edit_select,
              isRole === admin || isRole === lawyer || isRole === manager
                ? ""
                : "hidden"
            )}
            theme="dark"
            onClick={selectHandler}
          >
            Редактировать выбранные
          </Button>
          <SortingInput
            className={clsx(
              s.search__bar_filter,
              isRole === admin || isRole === lawyer || isRole === manager
                ? ""
                : "hidden"
            )}
            src={filter}
            placeholder="Фильтр"
            arrow={arrow}
            onClick={() => setArrow(!arrow)}
            onBlur={() => setArrow(false)}
          />

          {arrow && (
            <FilterClients
              selectedBrockerFilter={selectedBrockerFilter}
              setSelectedBrockerFilter={setSelectedBrockerFilter}
              brokerList={brokerList}
              className={s.clients_filter}
              filter3m={filter3m}
              isButton3mActive={isButton3mActive}
              isButton2mActive={isButton2mActive}
              isButton1mActive={isButton1mActive}
              applyFilter={applyFilter}
              activeProjectItem={activeProjectItem}
              setActiveProjectItem={setActiveProjectItem}
              setSelectedProjects={setSelectedProjects}
              fromSumValue={fromSumValue}
              toSumValue={toSumValue}
              fromSumValueActive={fromSumValueActive}
              toSumValueActive={toSumValueActive}
              filterByInvestmentFrom={filterByInvestmentFrom}
              filterByInvestmentTo={filterByInvestmentTo}
              filterByInvestmentFromActive={filterByInvestmentFromActive}
              filterByInvestmentToActive={filterByInvestmentToActive}
              filterByCountFromState={filterByCountFromState}
              filterByCountToState={filterByCountToState}
              setFilterByCountFromState={setFilterByCountFromState}
              setFilterByCountToState={setFilterByCountToState}
              filterByCountFromStateActive={filterByCountFromStateActive}
              filterByCountToStateActive={filterByCountToStateActive}
              setFilterByCountFromStateActive={setFilterByCountFromStateActive}
              setFilterByCountToStateActive={setFilterByCountToStateActive}
              setSelectedProjectsUUID={setSelectedProjectsUUID}
              loaderFilter={loaderFilter}
            />
          )}
          <Button theme="icon" icon src={client} onClick={showPage}>
            Добавить клиента
          </Button>
        </div>
      </div>
      <div className={s.table}>
        {isRole === admin || isRole === lawyer || isRole === manager ? (
          <div className={clsx(s.tableItems, s.firstString)}>
            {headerList.map(({ title, icon }) =>
              title === "Фамилия" ? (
                <Checkbox
                  key={title}
                  id="selectAll"
                  name="selectAll"
                  label={title}
                  fontSize="16px"
                  onChange={() => handleSelectAll(clientArr)}
                  isChecked={isCheckAll}
                />
              ) : (
                <div key={title} className={s.tableTitle}>
                  <p>{title}</p>
                </div>
              )
            )}
          </div>
        ) : (
          <div className={clsx(s.tableItems_m, s.firstString)}>
            {headerList
              .filter(({ role }) => role === "marketer")
              .map(({ title, icon }) =>
                title === "Фамилия" ? (
                  <Checkbox
                    key={title}
                    id="selectAll"
                    name="selectAll"
                    label={title}
                    fontSize="16px"
                    onChange={() => handleSelectAll(clientArr)}
                    isChecked={isCheckAll}
                  />
                ) : (
                  <div key={title} className={s.tableTitle}>
                    <p>{title}</p>
                  </div>
                )
              )}
          </div>
        )}
        <div className={s.client_list_wrapper}>
          {clientArr.map((elem: any, index: number) => (
            <div
              className={
                isRole === admin || isRole === lawyer || isRole === manager
                  ? s.table__row
                  : s.table__row_m
              }
              key={elem.uuid}
            >
              <Checkbox
                key={elem?.uuid}
                id={elem?.uuid}
                name={elem?.uuid}
                label={`${elem.lastName} ${elem.firstName} ${elem.middleName} `}
                avatar
                src={elem?.photo}
                fontSize="16px"
                firstName={elem?.firstName}
                lastName={elem?.lastName}
                isChecked={isCheck.includes(elem?.uuid)}
                onChange={handleClick}
              />
              {(isRole === admin ||
                isRole === lawyer ||
                isRole === manager) && (
                <div>
                  {(!!filteredClients.length && !!activeProjFilter.length
                    ? elem.contracts
                        .filter((item: Contracts) =>
                          activeProjFilter?.includes(item.project.name)
                        )
                        .flat()
                    : elem?.contracts
                  )?.map((elem: Contracts) => (
                    <div className={s.table__items} key={elem.uuid}>
                      <ProjectPanel>{elem.project.name}</ProjectPanel>
                    </div>
                  ))}
                </div>
              )}
              {(isRole === admin ||
                isRole === lawyer ||
                isRole === manager) && (
                <div>
                  {(!!filteredClients.length && !!activeProjFilter.length
                    ? elem.contracts
                        .filter((item: Contracts) =>
                          activeProjFilter?.includes(item.project.name)
                        )
                        .flat()
                    : elem?.contracts
                  )?.map((elem: Contracts) =>
                    new Date(elem.expireOn) > date30 ||
                    new Date(elem.expireOn) < now ? (
                      <div className={s.table__items} key={elem.uuid}>
                        <ProjectPanel className={s.investsum}>
                          {new Intl.DateTimeFormat("ru", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "2-digit",
                          }).format(Date.parse(elem.expireOn))}
                        </ProjectPanel>
                      </div>
                    ) : (
                      <div className={s.table__items_yellow} key={elem.uuid}>
                        <div className={s.investsum}>
                          {new Intl.DateTimeFormat("ru", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "2-digit",
                          }).format(Date.parse(elem.expireOn))}
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
              {(isRole === admin ||
                isRole === lawyer ||
                isRole === manager) && (
                <div className={s.table__investsum}>
                  {(!!filteredClients.length && !!activeProjFilter.length
                    ? elem.contracts
                        .filter((item: Contracts) =>
                          activeProjFilter?.includes(item.project.name)
                        )
                        .flat()
                    : elem?.contracts
                  )?.map((elem: Contracts) => (
                    <div className={s.table__items} key={elem.uuid}>
                      {new Date(elem.expireOn) > now ? (
                        <ProjectPanel className={s.investsum}>
                          {new Intl.NumberFormat("ru-Ru").format(
                            elem.investSum
                          )}{" "}
                          ₽
                        </ProjectPanel>
                      ) : (
                        <ProjectPanel className={s.investsum_hide}>
                          {new Intl.NumberFormat("ru-Ru").format(0)} ₽
                        </ProjectPanel>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {(isRole === admin ||
                isRole === lawyer ||
                isRole === manager) && (
                <div className={s.table__investsum}>
                  {(!!filteredClients.length && !!activeProjFilter.length
                    ? elem.contracts
                        .filter((item: Contracts) =>
                          activeProjFilter?.includes(item.project.name)
                        )
                        .flat()
                    : elem?.contracts
                  )?.map((elem: Contracts) => (
                    <div
                      className={s.table__items}
                      key={elem.uuid.concat("total")}
                    >
                      <ProjectPanel className={s.investsum}>
                        {new Intl.NumberFormat("ru-Ru").format(elem.investSum)}{" "}
                        ₽
                      </ProjectPanel>
                    </div>
                  ))}
                </div>
              )}
              <div className={s.lastItem}>
                {elem.brokers === null ? (
                  <div className={s.lastItem_noBroker}>брокер не назначен</div>
                ) : (
                  <div>
                    <p className={s.table__client_broker}>
                      {`${elem?.brokers?.[0]?.name}`}
                    </p>
                  </div>
                )}
                <div className={s.lastItem_edit}>
                  <Edit
                    active={editActive === index}
                    onClick={() => editActiveHandle(index)}
                    onEditClick={() => navigate(`/clients/edit/${elem.uuid}`)}
                    removeDoc={removeHandler}
                    client
                  />
                </div>
              </div>
            </div>
          ))}
          {loading && (
            <div className={s.loader_wrapper_update}>
              <Loader />
            </div>
          )}
        </div>
      </div>
      {isEditSelected && (
        <Portal>
          <div className={s.editSelected_wrapper}>
            <Panel className={s.modal__panel} padding>
              <Title
                className={s.editSelected_wrapper_title}
                title="Назначить брокера"
              />
              <Label title="Брокер" className={s.editSelected_list_label}>
                <NativeSelect
                  className={s.editSelected_list}
                  pledgeArr={brokerList}
                  brokerSelect
                  setPledgeArr={setBrokerList}
                  show={showBrokerListSelect}
                  setSelectedBroker={setSelectedBroker}
                  setShowBrokerListSelect={setShowBrokerListSelect}
                  onClick={() => setShowBrokerListSelect(!showBrokerListSelect)}
                />
              </Label>
              {selectedBroker !== "" && (
                <p className={s.editSelected_appointment}>
                  Данный брокер будет назначен выбранным вами клиентам
                </p>
              )}
              <div className={s.modal__btns}>
                {contractsBrokersLoading ? (
                  <div className={s.editSelected_button_loader}>
                    <Loader />
                  </div>
                ) : (
                  <Button
                    className={s.editSelected_button}
                    theme={selectedBroker === "" ? "secondary" : "dark"}
                    disabled={selectedBroker === ""}
                    onClick={changeBroker}
                  >
                    Назначить
                  </Button>
                )}
                <Button
                  className={s.editSelected_button_cancel}
                  theme="outline"
                  onClick={cancelSelectBroker}
                >
                  Отмена
                </Button>
              </div>
            </Panel>
          </div>
        </Portal>
      )}
    </Container>
  );
};
