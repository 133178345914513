import React, { FC } from 'react'
import clsx from 'clsx'
import { Section } from '../Section/Section'
import { SectionTitle } from '../SectionTitle/SectionTitle'
import s from './AboutProject.module.css'

interface AboutProject {
  project?: any
}

export const AboutProject: FC <AboutProject> = ({ project }) => (
  <Section className={s.section__margin__bottom}>
    <SectionTitle text="О проекте" />
    <div className={s.about__wrapper}>
      <div className={s.about__text}>
        <p className={s.about__text_paragraph}>
          {project.fullDescription}
        </p>
      </div>
      <div className={s.about__income}>
        {project.minInterest && (
          <div className={s.about__income__numWrapper}>
            <h3 className={s.about__income_numbers}>
              {project.minInterest}
              % годовых
            </h3>
            <span className={s.about__income_numbersDescription}>
              доходность компании
            </span>
          </div>
        )}
        {project.income && (
          <div className={s.about__income__numWrapper}>
            <h3 className={s.about__income_numbers}>
              <div>
                <div>
                  {(project.income).toLocaleString('ru-RU').concat(' ₽')}
                </div>
              </div>
            </h3>
            <span className={s.about__income_numbersDescription}>
              уже заработали инвесторы
            </span>
          </div>
        )}

        <div
          className={clsx(
            s.about__income__numWrapper,
            s.about__income__numWrapper_invest,
          )}
        >
          {project.minInvestment && (
            <h3 className={s.about__income_numbers}>
              {(+project.minInvestment).toLocaleString('ru-RU').concat(' ₽')}
            </h3>
          )}
          <span className={s.about__income_numbersDescription}>
            Мин. сумма инвестиций
          </span>
        </div>
      </div>
    </div>
  </Section>
)
