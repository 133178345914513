import { gql } from '@apollo/client'

export const GET_ALL_STAGES = gql`
  query mediaStages {
    mediaStages {
      id
      uuid
      title
      description
      orderNum
    }
  }
`
export const GET_ALL_CONDITIONS = gql`
  query mediaStages {
    mediaConditions {
      id
      uuid
      title
      description
      orderNum
    }
  }
`

export const GET_MEDIAENTERPRNEUR = gql`
  query mediaEntrepreneur {
    mediaEntrepreneur {
      id
      uuid
      coverTitle
      projectsTitle
      formTitle
      formSubtitle
      buttonText
      buttonLink
    }
  }
`
