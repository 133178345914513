import React, { FC } from 'react'
import Form from '../form/Form'
import s from './LastScreen.module.css'

interface LastScreen {
  data?: any
}

export const LastScreen: FC<LastScreen> = ({ data }) => (
  <>
    <section className={s.wrapper}>
      <div className={s.lastscreen}>
        <div className={s.lastscreen__wrapper}>
          <div className={s.lastscreen__left_content}>
            <p className={s.lastscreen__title}>{data?.title}</p>
            <p className={s.lastscreen__decoration_marks}>“</p>
            <p className={s.lastscreen__description}>
              {data?.quote?.split(' ').slice(0, 8).join(' ')}
                &nbsp;
              <span className={s.lastscreen__yelow_line}>
                {data?.quote?.split(' ').slice(8, 10).join(' ')}
                  &nbsp;
              </span>
              {data?.quote?.split(' ').slice(10).join(' ')}
            </p>
          </div>
          <div className={s.lastscreen__right_content}>
            <div className={s.lastscreen__text_form}>
              <p>{data?.caption}</p>
            </div>
            <Form />
          </div>
        </div>
      </div>
    </section>
  </>
)
