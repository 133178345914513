/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable wrap-iife */
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../redux/hook'
import { useGetRole } from '../../hooks/useGetRole'
import { Layout } from '../../features/Layouts'
import { Title } from '../../ui'
import { EditDoc } from '../../components/EditDoc'
import { EditAct } from '../../components/EditAct'
import { EditReport } from '../../components/EditReport'
import {
  admin,
  lawyer,
  manager,
  marketer,
  technical1,
  technical2,
} from '../../references/data'
import s from './EditDocument.module.css'

export const EditDocument = () => {
  const params = useParams()
  const { isRole, location, navigate } = useGetRole()
  const { editDoc } = useAppSelector(state => state.tableSlice)

  useEffect(() => {
    ;(function redirect() {
      if (
        isRole === technical1 &&
        location.pathname === `/documents/edit/${params.id}`
      ) {
        navigate('/clients', { replace: true })
      } else if (
        isRole === marketer &&
        location.pathname === `/documents/edit/${params.id}`
      ) {
        navigate('/promotion', { replace: true })
      }
    })()
  }, [location, isRole])

  return (
    <Layout>
      {(isRole === admin ||
        isRole === technical2 ||
        isRole === lawyer ||
        isRole === manager) && (
        <>
          <Title className={s.docs__title} title="Документы" />
          {editDoc === 0 && <EditDoc params={params.id} />}
          {editDoc === 1 && <EditAct params={params.id} />}
          {editDoc === 2 && <EditReport params={params.id} />}
        </>
      )}
    </Layout>
  )
}
