import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { CHAT_BROKERS_LIST, CHAT_CLIENTS_LIST, CHAT_SUPERUSERS_LIST } from '../apollo/query/chats/chatsAllUsers'

export const useGetAllUsers = (chatArr: any[], profileUUID: any) => {
  const [allUsers, setAllUsers] = useState<any[]>([])

  const {
    data: brokersData,
    loading: brokersLoading,
    error: brokersError,
  } = useQuery(CHAT_BROKERS_LIST)

  const {
    data: clientsData,
    loading: clientsLoading,
    error: clientsError,
  } = useQuery(CHAT_CLIENTS_LIST)

  const {
    data: superusersData,
    loading: superusersLoading,
    error: superusersError,
  } = useQuery(CHAT_SUPERUSERS_LIST)

  useEffect(() => {
    if (
      brokersData?.brokers &&
      clientsData?.clients &&
      superusersData?.superusers &&
      (!!chatArr.length || chatArr)
    ) {
      const getUuidFromTitle = (title: any) => {
        if (title?.broker) return title.broker.profile?.uuid
        if (title?.investor) return title.investor.profile?.uuid
        if (title?.superuser?.profile?.uuid !== profileUUID) {
          return title?.superuser?.profile?.uuid
        }
        return null // или другое значение по умолчанию, если нужно
      }

      const uniqueUuids = [
        ...new Set(chatArr.map((e: any) => getUuidFromTitle(e.title))), // фильтрация null или других значений по умолчанию
      ]?.filter(uuid => Boolean(uuid))

      setAllUsers(
        [
          ...superusersData?.superusers,
          ...brokersData?.brokers,
          ...clientsData?.clients,
        ].filter(
          e =>
            !uniqueUuids.includes(e.profile.uuid) &&
            e.profile.uuid !== profileUUID
        )
      )
    }
  }, [brokersData, clientsData, superusersData, chatArr])

  return allUsers
}
