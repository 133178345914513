/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  CREATE_CLIENT,
  CREATE_PROFILE,
} from '../../apollo/mutation/createClient'
import { CLIENTS } from '../../apollo/query/allClients'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  addClientFields,
  resetClientFields,
} from '../../redux/slices/clientSlice'
import { closeClient } from '../../redux/slices/pageSlice'
import {
  Button,
  ClientInfo,
  ClientInfoOrg,
  CloseIcon,
  Container,
  Field,
  Label,
  Loader,
  NavProjects,
  Panel,
  Title,
} from '../../ui'
import iconSent from './icon-sent.svg'
import s from './CreateClient.module.css'

export const CreateClients = () => {
  const dispatch = useAppDispatch()
  const { clientInfo } = useAppSelector(state => state.clientSlice)
  const [createClient, { data, loading, error }] = useMutation(CREATE_CLIENT, {
    refetchQueries: [
      {
        query: CLIENTS,
        variables: {
          sort: {
            field: 'lastName',
            direction: 'asc',
          },
        },
      },
    ],
  })
  const [
    createProfile,
    { data: dataProfile, loading: loadingProfile, error: errorProfile },
  ] = useMutation(CREATE_PROFILE)

  const dataNavTitle = ['Физ. лицо', 'ИП']
  const [navValue, setNavValue] = useState(0)
  const [senting, setSenting] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [birthDay, setBirthDay] = useState<Date | null>(new Date())
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [iis, setIis] = useState('')
  const [inn, setInn] = useState('')

  const [name, setName] = useState('')
  const [innOrg, setInnOrg] = useState('')
  const [address, setAddress] = useState('')
  const [rs, setRs] = useState('')
  const [ks, setKs] = useState('')
  const [bank, setBank] = useState('')
  const [bik, setBik] = useState('')

  const closePage = () => {
    dispatch(closeClient())
    dispatch(resetClientFields())
  }

  useEffect(() => {
    if (dataProfile) {
      createClient({
        variables: {
          profileUUID: dataProfile.admProfileCreate.uuid,
          data: {
            ...clientInfo,
          },
        },
      })
    }
  }, [dataProfile])

  useEffect(() => {
    if (data) {
      setSenting(true)
      setTimeout(() => {
        closePage()
        setSenting(false)
      }, 3000)
    }
  }, [data])

  const handleSaveBtn = () => {
    createProfile({
      variables: {
        data: {
          login: clientInfo.phone,
          password: '',
          role: 'investor',
          status: 'wait',
        },
      },
    })
  }

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: '7px' }}>Сохранено</span>
    </>
  )

  return (
    <Container className={s.container}>
      <CloseIcon onClick={closePage} />
      <Title title="Добавить клиента" />
      <div className={s.wrapper}>
        <NavProjects
          className={s.nav}
          setNavValue={setNavValue}
          navValue={navValue}
          dataNavTitle={dataNavTitle}
        />
        {navValue === 0 ? (
          <ClientInfo
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            middleName={middleName}
            setMiddleName={setMiddleName}
            birthDay={birthDay}
            setBirthDay={setBirthDay}
            phone={phone}
            setPhone={setPhone}
            email={email}
            setEmail={setEmail}
            iis={iis}
            setIis={setIis}
            inn={inn}
            setInn={setInn}
          />
        ) : (
          <ClientInfoOrg
            name={name}
            setName={setName}
            innOrg={innOrg}
            setInnOrg={setInnOrg}
            address={address}
            setAddress={setAddress}
            rs={rs}
            setRs={setRs}
            ks={ks}
            setKs={setKs}
            bank={bank}
            setBank={setBank}
            bik={bik}
            setBik={setBik}
          />
        )}
      </div>
      <Button
        onClick={handleSaveBtn}
        className={s.btn}
        theme={
          !(firstName && lastName && middleName && phone) ? 'secondary' : 'dark'
        }
        disabled={!(firstName && lastName && middleName && phone)}
      >
        {loading && loadingProfile ? (
          <Loader />
        ) : error && errorProfile ? (
          'Ошибка'
        ) : senting ? (
          sentSaved
        ) : (
          'Внести в базу данных'
        )}
      </Button>
    </Container>
  )
}
