/* eslint-disable wrap-iife */
/* eslint-disable @typescript-eslint/no-extra-semi */
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../redux/hook'
import { useGetRole } from '../../hooks/useGetRole'
import { resetPromotionObj } from '../../redux/slices/promotionSlice'
import { resetPromotionDynamicObj } from '../../redux/slices/promotionDynamicSlice'
import { EditDynamicPromotion, EditStoriesPromotion } from '../../components'
import { Layout } from '../../features/Layouts'
import { CloseIcon, Container, Title } from '../../ui'
import {
  admin,
  lawyer,
  manager,
  marketer,
  technical1,
  technical2,
} from '../../references/data'
import s from './EditPromotion.module.css'

export const EditPromotion = () => {
  const params = useParams()
  const dispatch = useAppDispatch()
  const { isRole, location, navigate } = useGetRole()

  useEffect(() => {
    ;(function redirect() {
      if (
        isRole === technical1 &&
        (location.pathname === `/promotion/edit/stories/${params?.id}` ||
          location.pathname === `/promotion/edit/dynamic/${params?.id}`)
      ) {
        navigate('/clients', { replace: true })
      } else if (
        isRole === technical2 &&
        (location.pathname === `/promotion/edit/stories/${params?.id}` ||
          location.pathname === `/promotion/edit/dynamic/${params?.id}`)
      ) {
        navigate('/documents', { replace: true })
      } else if (
        isRole === lawyer &&
        (location.pathname === `/promotion/edit/stories/${params?.id}` ||
          location.pathname === `/promotion/edit/dynamic/${params?.id}`)
      ) {
        navigate('/home', { replace: true })
      } else if (
        isRole === manager &&
        (location.pathname === `/promotion/edit/stories/${params?.id}` ||
          location.pathname === `/promotion/edit/dynamic/${params?.id}`)
      ) {
        navigate('/home', { replace: true })
      }
    })()
  }, [location, isRole])

  return (
    <Layout>
      {(isRole === admin || isRole === marketer) && (
        <>
          <div className={s.title}>
            <Title
              className={s.clients__title}
              title={
                params?.slug === 'stories'
                  ? 'Редактирование истории'
                  : 'Редактирование динамической акции'
              }
            />
          </div>
          <Container className={s.container}>
            <CloseIcon
              onClick={() => {
                navigate('/promotion', { replace: true })
                dispatch(resetPromotionObj())
                dispatch(resetPromotionDynamicObj())
              }}
            />
            {params?.slug === 'stories' ? (
              <EditStoriesPromotion uuid={params?.id} />
            ) : (
              <EditDynamicPromotion uuid={params?.id} />
            )}
          </Container>
        </>
      )}
    </Layout>
  )
}
