import { gql } from '@apollo/client'

export const CHAT_BROKERS_LIST = gql`
  query ($filter: BrokerFilter) {
    brokers(filter: $filter) {
      uuid
      name
      photo
      profile {
        uuid
        role
        login
      }
    }
  }
`

export const CHAT_CLIENTS_LIST = gql`
  query ($filter: ClientFilter) {
    clients(filter: $filter) {
      id
      uuid
      lastName
      firstName
      middleName
      photo
      profile {
        role
        uuid
        login
      }
    }
  }
`

export const CHAT_SUPERUSERS_LIST = gql`
  query {
    superusers {
      uuid
      name
      photo
      profile {
        uuid
        role
        login
      }
    }
  }
`

export const CHAT_LAWYER_LIST = gql`
  query {
    lawyer {
      uuid
      name
      photo
      profile {
        uuid
        role
        login
      }
    }
  }
`
export const CHAT_MANAGER_LIST = gql`
  query {
    manager {
      uuid
      name
      photo
      profile {
        uuid
        role
        login
      }
    }
  }
`
