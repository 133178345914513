import React, { FC } from 'react'
import { Section } from '../../Section/Section'
import { MediaSectionTitle } from '../../MediaSectionTItle/MediaSectionTitle'
import s from './FirstStepSection.module.css'

interface FirstStepSection {
  mediaEntrepreneur?: any
}

export const FirstStepSection: FC<FirstStepSection> = ({ mediaEntrepreneur }) => (
  <>
    <Section className={s.wrapper}>
      <div className={s.section_wrapper}>
        <div className={s.content_wrapper}>
          <MediaSectionTitle
            className={s.sectionTitle}
            text1={mediaEntrepreneur?.formTitle?.split(' ')[0]}
            text2={mediaEntrepreneur?.formTitle
              ?.split(' ')
              .slice(1)
              .join(' ')}
          />
          <p className={s.content_text}>{mediaEntrepreneur.formSubtitle}</p>
          <a
            className={s.button_form}
            href={mediaEntrepreneur?.buttonLink}
            target="_blank"
            rel="noreferrer"
            // rel="noopener"
          >
            {mediaEntrepreneur?.buttonText}
            <div className={s.button_form_arrow}>&nbsp;</div>
          </a>
        </div>
      </div>
    </Section>
  </>
)
