import React, { useState, useEffect, FC } from 'react'
import { useQuery } from '@apollo/client'
import clsx from 'clsx'
import { useAppSelector } from '../../../../redux/hook'
// import { TeamPopup } from './teamPopup/TeamPopup'
import { SliderTeam } from './sliderTeam/SliderTeam'
import { GET_TEAM } from '../../../../apollo/query/editSite/teamPage'
import { GET_MAINPAGE } from '../../../../apollo/query/editSite/mainPage'
import s from './TeamSection.module.css'

interface TeamSection {
  width: number
  dataPage?: any
}

export const TeamSection:FC<TeamSection> = ({ width, dataPage }) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [dataTeam, setDataTeam]: any = useState([])
  const [popUpTeam, setPopUpTeam]: any = useState([])
  const { data } = useQuery(GET_TEAM)
  const { data: dataMainPage } = useQuery(GET_MAINPAGE)
  const person = useState({})
  const { leftText } = useAppSelector(state => state.editSiteSlice)

  useEffect(() => {
    if (!data) return
    setDataTeam([...data?.personnel])
    setPopUpTeam([...data?.personnel])
  }, [data])

  // Блок скрола.
  // useEffect(() => {
  //   isOpenPopup && (document.body.style.overflow = "hidden")
  // }, [isOpenPopup])

  const clickHandler = (e: any) => {
    const helpElement = dataTeam.filter((el: any) => el.uuid === e.target.id)
    const firstElement = helpElement[0]
    const restTeam = dataTeam.filter((el: any) => el.uuid !== e.target.id)
    setPopUpTeam([firstElement, ...restTeam])
    setIsOpenPopup(true)
  }
  return (
    <div className={s.team__block}>
      <p className={s.team__block_title}>команда Frontiers</p>
      <div className={s.team__block_main}>
        <div className={s.team__block_description}>
          <p className={s.team__block_description_text}>
            {dataMainPage?.page?.leftText}
          </p>
          <div className={s.team__yellow_line}>
            &nbsp;
          </div>
        </div>
        {/* {isOpenPopup && (
          <TeamPopup
            useData={dataTeam}
            width={width}
            setIsOpenPopup={setIsOpenPopup}
            popUpTeam={popUpTeam}
          />
        )} */}
        <SliderTeam clickHandler={clickHandler} width={width} />
        {/* {width > 1024 ? (
          <SliderTeam clickHandler={clickHandler} />
        ) : (
          <div>
            {popUpTeam?.length > 0 && (
              <TeamPopup
                useData={dataTeam}
                width={width}
                setIsOpenPopup={setIsOpenPopup}
                popUpTeam={popUpTeam}
              />
            )}
          </div>
        )} */}
      </div>
    </div>
  )
}
