/* eslint-disable */

import React, { ChangeEvent, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import {
  Title,
  Container,
  Panel,
  Label,
  Field,
  Textarea,
  Button,
  Loader,
  AddButton,
  CloseIcon,
  DataPicker,
  ProgressBar,
} from "../../ui";
import { UploadEditPages } from "../UploadEditPages";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
  changeIsCreateBroker,
  changeNav,
} from "../../redux/slices/editSiteSlice";
import { UPDATE_OUTLET } from "../../apollo/mutation/editSite/mediaPageUpdate";
import {
  GET_ALL_OUTLETS,
  GET_OUTLET,
} from "../../apollo/query/editSite/mediaPage";
import iconSent from "./img/icon-sent.svg";
import fullFill from "./img/icon-fillfull.svg";
import empty from "./img/icon-empty.svg";
import s from "./EditOutlet.module.css";

export const EditOutlet = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [sumField, setSumField] = useState(0);
  const [senting, setSenting] = useState(false);
  const [obj, setObj] = useState<any>({
    picture: "",
    title: "",
    titleEn: "",
    decription: "",
    descriptionEn: "",
    sourse: "",
    sourceEn: "",
    datemarker: "",
    link: "",
  });

  // расчет прогрессбара
  useEffect(() => {
    const tempObj = {
      picture: obj.picture,
      title: obj.title.trim() && obj.titleEn.trim(),
      decription: obj.decription.trim() && obj.descriptionEn.trim(),
      sourse: obj.sourse.trim(),
      datemarker: obj.datemarker,
      link: obj.link.trim(),
    };
    const values = Object.values(tempObj).map((elem) =>
      elem !== "" ? 16.66 : 0
    );
    setSumField(values.reduce((a: any, b: any) => a + b));
    // setSumField(100)
  }, [obj]); // сложение значений объекта

  const { data: outletData, loading: outletLoading } = useQuery(GET_OUTLET, {
    variables: {
      uuid: id,
    },
  });

  const [
    updateOutlet,
    { data: updateOutletData, loading: loadingOutlet, error: errorOutlet },
  ] = useMutation(UPDATE_OUTLET, {
    refetchQueries: [
      {
        query: GET_ALL_OUTLETS,
        variables: {
          filter: {},
        },
      },
    ],
  });

  useEffect(() => {
    if (!outletData) return;
    setObj({
      picture: outletData?.mediaOutlet?.picture,
      title: outletData?.mediaOutlet?.title,
      titleEn: outletData?.mediaOutlet?.titleEn,
      decription: outletData?.mediaOutlet?.decription,
      descriptionEn: outletData?.mediaOutlet?.descriptionEn,
      sourse: outletData?.mediaOutlet?.sourse,
      sourceEn: outletData?.mediaOutlet?.sourceEn,
      datemarker: new Date(Date.parse(outletData?.mediaOutlet?.datemarker)),
      link: outletData?.mediaOutlet?.link,
    });
  }, [outletData]);

  const changeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setObj({ ...obj, title: event.target.value });
  };

  const changeTitleEn = (event: ChangeEvent<HTMLInputElement>) => {
    setObj({ ...obj, titleEn: event.target.value });
  };
  const changeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setObj({ ...obj, decription: event.target.value });
  };

  const changeDescriptionEn = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setObj({ ...obj, descriptionEn: event.target.value });
  };
  const changeDate = (date: any) => {
    setObj({ ...obj, datemarker: date });
  };
  const changeSource = (event: ChangeEvent<HTMLInputElement>) => {
    setObj({ ...obj, sourse: event.target.value });
  };

  const changeSourceEn = (event: ChangeEvent<HTMLInputElement>) => {
    setObj({ ...obj, sourceEn: event.target.value });
  };
  const changeLink = (event: ChangeEvent<HTMLInputElement>) => {
    setObj({ ...obj, link: event.target.value });
  };
  const changeOutletPicture = (url: string) => {
    setObj({ ...obj, picture: url });
  };

  const closePage = () => {
    dispatch(changeIsCreateBroker(false));
    dispatch(changeNav(4));
    setObj({
      avatar: "",
      firstname: "",
      lastname: "",
      sourse: "",
      sourceEn: "",
      link: "",
      comment: "",
      datemarker: "",
      review: "",
    });
  };

  const handleSaveSent = () => {
    setSenting(true);
    updateOutlet({
      variables: {
        uuid: id,
        data: {
          ...obj,
          datemarker: obj.datemarker.toISOString(),
        },
      },
    });
    if (!loadingOutlet || errorOutlet === undefined) {
      setTimeout(() => {
        closePage();
      }, 2000);
    } else {
      setTimeout(() => {
        closePage();
      });
    }
  };

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: "7px" }}>Сохранено</span>
    </>
  );
  return (
    <Container className={s.container}>
      <CloseIcon onClick={closePage} />
      {/* <CloseIcon onClick={() => console.log()} /> */}
      <Title title="Редактировать статью" />
      <div className={s.wrapper}>
        <div className={s.first_block}>
          <Panel className={s.info__panel} padding>
            <Label title="Заголовок">
              <div className={s.input__wrapper}>
                <Field
                  className={s.broker_name}
                  value={obj.title}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeTitle?.(event)
                  }
                  rows={5}
                  maxLength={100}
                />
                <Field
                  className={s.broker_name}
                  value={obj.titleEn}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeTitleEn?.(event)
                  }
                  rows={5}
                  maxLength={100}
                  eng
                />
              </div>
            </Label>
            <Label title="Описание">
              <div className={s.input__wrapper}>
                <Textarea
                  className={s.description}
                  value={obj.decription}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    changeDescription?.(event)
                  }
                  rows={5}
                  // maxLength={350}
                />
                <Textarea
                  className={s.description}
                  value={obj.descriptionEn}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    changeDescriptionEn?.(event)
                  }
                  rows={5}
                  // maxLength={350}
                  eng
                />
              </div>
            </Label>
            <div className={s.line}>
              <Label
                title="Дата публикации"
                // onClick={() => dispatch(closeOnlySelect())}
              >
                <DataPicker
                  className={s.data__picker}
                  startDate={obj.datemarker}
                  onChange={(date) => changeDate?.(date)}
                />
              </Label>
            </div>

            <Label title="Источник">
              <div className={s.input__wrapper}>
                {" "}
                <Field
                  className={s.youTube_link}
                  // placeholder="59 симв. макс. включая пробелы"
                  value={obj.sourse}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeSource?.(event)
                  }
                  rows={5}
                  maxLength={100}
                />
                <Field
                  className={s.youTube_link}
                  // placeholder="59 симв. макс. включая пробелы"
                  value={obj.sourceEn}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeSourceEn?.(event)
                  }
                  rows={5}
                  maxLength={100}
                  eng
                />
              </div>
            </Label>
            <Label title="Ссылка">
              <Field
                className={s.link}
                // placeholder="59 симв. макс. включая пробелы"
                value={obj.link}
                view="default"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  changeLink?.(event)
                }
                rows={5}
                maxLength={100}
              />
            </Label>
            <div className={s.uploads}>
              <UploadEditPages
                title="Загрузить фото"
                titleStatus="Статья"
                text=""
                text2="Максимальный размер фото 8 Мб. Форматы: jpeg, jpg, png. Оптимальный размер: 296 х 220 px"
                // application=".webp"
                subText1="Загрузить с компьютера"
                subText2="или перетащите сюда"
                uploadId="Outlet"
                changeOutletPicture={changeOutletPicture}
                outletPicture={obj.picture}
              />
            </div>
          </Panel>
        </div>
        <Panel className={s.btns__panel}>
          <Button
            onClick={handleSaveSent}
            className={
              Math.ceil(sumField) === 100
                ? s.btns__panel_save
                : s.btns__panel_save_disabled
            }
            // className={broker?.firstname ? s.btns__panel_save : s.btns__panel_save_disabled}
            theme="dark"
            disabled={Math.ceil(sumField) < 100}
            // disabled={!broker?.firstname}
          >
            {!senting ? (
              "Сохранить и опубликовать"
            ) : loadingOutlet ? (
              <Loader />
            ) : !loadingOutlet && errorOutlet ? (
              "Ошибка"
            ) : (
              sentSaved
            )}
          </Button>
        </Panel>
      </div>
      <Panel className={s.panel__progress} padding>
        <ProgressBar
          className={s.project_create}
          value={Math.ceil(sumField)}
          // value={100}
        />
        <div className={s.project_indicator}>
          <img
            src={obj.title.trim() && obj.titleEn.trim() ? fullFill : empty}
            alt="empty"
          />
          <p>Заголовок</p>
        </div>
        <div className={s.project_indicator}>
          <img
            src={
              obj.decription.trim() && obj.descriptionEn.trim()
                ? fullFill
                : empty
            }
            alt="empty"
          />
          <p>Описание</p>
        </div>
        <div className={s.project_indicator}>
          <img src={obj.datemarker ? fullFill : empty} alt="empty" />
          <p>Дата публикации</p>
        </div>
        <div className={s.project_indicator}>
          <img
            src={obj.sourse.trim() && obj.sourceEn.trim() ? fullFill : empty}
            alt="empty"
          />
          <p>Источник</p>
        </div>
        <div className={s.project_indicator}>
          <img src={obj.link.trim() ? fullFill : empty} alt="empty" />
          <p>Ссылка</p>
        </div>
        <div className={s.project_indicator}>
          <img src={obj.picture ? fullFill : empty} alt="empty" />
          <p>Фото</p>
        </div>
      </Panel>
    </Container>
  );
};
