import { ChangeEvent, FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { useQuery, useMutation } from '@apollo/client'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { openPromotion } from '../../redux/slices/pageSlice'
import { resetPromotionObj } from '../../redux/slices/promotionSlice'
import {
  addStoryName,
  showDeletePromotionStoryModal,
  hideDeletePromotionStoryModal,
} from '../../redux/slices/tableSlice'
import { STORIES } from '../../apollo/query/promotion/stories'
import {
  PROMOTION_STORY_CHANGE_STATUS,
  PROMOTION_STORY_DELETE,
} from '../../apollo/mutation/promotion/promotionUpdate'
import {
  Button,
  Container,
  DropdownButton,
  ErrorMessage,
  Field,
  Modal,
  NavProjects,
  Panel,
  PromotionsDropdown,
  PromotionsEdit,
  Title,
  ToggleButton,
} from '../../ui'
import addPromotion from './img/icon-addPromotion.svg'
import s from './PromotionStories.module.css'

interface PromotionStoriesType {
  title: string
  dataNavTitle: string[]
  setNavValuePromotions: (num: number) => void
}

const settings = ['Редактировать', 'В черновики', 'В архив', 'Удалить']

export const PromotionStories: FC<PromotionStoriesType> = ({
  title,
  dataNavTitle,
  setNavValuePromotions,
}) => {
  const [value, setValue] = useState<any>('')
  const [navValue, setNavValue] = useState<number>(0)
  const [shortStories, setShortStories] = useState<boolean>(true)
  const [editActive, setEditActive] = useState<number | null>(null)
  const [storyUUID, setStoryUUID] = useState<string>('')

  const dispatch = useAppDispatch()
  const { storyName, deletePromotionStoryModal } = useAppSelector(
    state => state.tableSlice
  )
  const createPromotionStories = () => {
    dispatch(resetPromotionObj())
    dispatch(openPromotion())
    setNavValuePromotions(0)
  }
  const navigate = useNavigate()

  const {
    data: stories,
    loading: loadingStories,
    error: errorStories,
  } = useQuery(
    STORIES,
    navValue === 0
      ? {}
      : {
        variables: {
          filter: {
            status:
                navValue === 1
                  ? 'active'
                  : navValue === 2
                    ? 'draft'
                    : 'archive',
          },
        },
      }
  )

  const storiesFiltered = stories?.promotionStories === null
    ? []
    : stories?.promotionStories?.filter((item: any) => item?.header?.toLowerCase().includes(value.toLowerCase().trim()))

  function editActiveHandle(header: string, index: number, uuid: string) {
    dispatch(addStoryName(header))
    setStoryUUID(uuid)
    if (index === editActive) {
      setEditActive(null)
    } else {
      setEditActive(index)
    }
  }

  const [
    changePromotionStoryStatus,
    {
      data: changeStatus,
      loading: loadingChangeStatus,
      error: errorChangeStatus,
    },
  ] = useMutation(PROMOTION_STORY_CHANGE_STATUS, {
    refetchQueries: [
      {
        query: STORIES,
      },
      {
        query: STORIES,
        variables: {
          filter: {
            status: 'active',
          },
        },
      },
      {
        query: STORIES,
        variables: {
          filter: {
            status: 'draft',
          },
        },
      },
      {
        query: STORIES,
        variables: {
          filter: {
            status: 'archive',
          },
        },
      },
    ],
  })

  const [
    deletePromotionStory,
    {
      data: deletePromotion,
      loading: loadingdeletePromotion,
      error: errordeletePromotion,
    },
  ] = useMutation(PROMOTION_STORY_DELETE, {
    refetchQueries: [
      {
        query: STORIES,
      },
      {
        query: STORIES,
        variables: {
          filter: {
            status: 'active',
          },
        },
      },
      {
        query: STORIES,
        variables: {
          filter: {
            status: 'draft',
          },
        },
      },
      {
        query: STORIES,
        variables: {
          filter: {
            status: 'archive',
          },
        },
      },
    ],
  })

  const changeStoryStatus = (value: string, uuid: string) => {
    switch (value) {
    case 'В черновики':
      changePromotionStoryStatus({
        variables: {
          uuid,
          data: {
            status: 'draft',
          },
        },
      })
      setStoryUUID('')
      break
    case 'В архив':
      changePromotionStoryStatus({
        variables: {
          uuid,
          data: {
            status: 'archive',
          },
        },
      })
      setStoryUUID('')
      break
    case 'Удалить':
      dispatch(showDeletePromotionStoryModal())
      break
    default:
      dispatch(resetPromotionObj())
      navigate(`/promotion/edit/stories/${uuid}`)
    }
  }

  return (
    <Container>
      <div className={s.promotion_panel}>
        <Title className={s.title} title={title} />
        <div className={s.promotion_search}>
          <Field
            icon
            placeholder="Поиск"
            placeholderColor="grey"
            view="search"
            type="text"
            value={value}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
          />
          <Button
            className={s.promotion_btn}
            src={addPromotion}
            theme="icon"
            icon
            onClick={createPromotionStories}
          >
            Добавить акцию
          </Button>
        </div>
      </div>
      <NavProjects
        className={s.promotion_type}
        setNavValue={setNavValue}
        navValue={navValue}
        dataNavTitle={dataNavTitle}
      />
      {errorStories ? (
        <ErrorMessage>
          Ошибка:
          {' '}
          {errorStories?.message}
        </ErrorMessage>
      ) : (
        <div className={s.promotion_wrapper}>
          <>
            {(shortStories
              ? storiesFiltered?.slice(0, 18)
              : storiesFiltered
            )?.map((story: any, index: number) => (
              <Panel
                className={s.promotion_item}
                key={story.uuid}
                style={
                  story.image
                    ? {
                      background: `url(${story.image}) center/cover no-repeat`,
                    }
                    : { background: `${story.color}` }
                }
              >
                {story.status === 'active' ? null : (
                  <div className={s.dropFilter} />
                )}
                <p
                  className={clsx(
                    story.status === 'active'
                      ? s.promotion_header
                      : s.promotion_header_black
                  )}
                >
                  {story.header}
                </p>
                <PromotionsEdit
                  className={s.stories_edit}
                  onClick={() => editActiveHandle(story?.header, index, story?.uuid)}
                  active={editActive === index}
                >
                  <PromotionsDropdown className={s.promotion_edit_stories}>
                    {settings.map((value: string, index: number) => (
                      <DropdownButton
                        key={index}
                        onClick={() => changeStoryStatus(value, storyUUID)}
                      >
                        {value}
                      </DropdownButton>
                    ))}
                  </PromotionsDropdown>
                </PromotionsEdit>
              </Panel>
            ))}
          </>
        </div>
      )}
      <ToggleButton
        className={s.promotion_stories_btn}
        rotate={shortStories}
        onClick={() => {
          setShortStories(!shortStories)
        }}
      />
      {deletePromotionStoryModal && (
        <Modal
          storyName={storyName}
          background="rgba(250 250 250 / 0.8)"
          question="Вы действительно хотите удалить сторис"
        >
          <>
            <Button
              className={s.promotion_stories_remove}
              theme="dark"
              onClick={() => {
                deletePromotionStory({
                  variables: {
                    uuid: `${storyUUID}`,
                  },
                })
                dispatch(hideDeletePromotionStoryModal())
                setStoryUUID('')
              }}
            >
              Удалить
            </Button>
            <Button
              className={s.promotion_stories_remove}
              theme="outline"
              onClick={() => {
                dispatch(hideDeletePromotionStoryModal())
                setStoryUUID('')
              }}
            >
              Отмена
            </Button>
          </>
        </Modal>
      )}
    </Container>
  )
}
