import { gql } from '@apollo/client'

export const AUTH = gql`
  query Auth($login: String!, $password: String!) {
    authorize(login: $login, password: $password) {
      profile {
        id
        uuid
        login
        status
        role
      }
      accessToken
    }
  }
`
