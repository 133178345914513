/* eslint-disable function-paren-newline */
/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import React, { FC } from 'react'
import { useQuery } from '@apollo/client'
import { ALL_PROJECTS_TO_CARD } from '../../../../../apollo/query/allProjects'
import s from './ProjectCards.module.css'

interface ProjectCards {
  setClickOnCard?: (arg: any) => void
  setSelectedProjectCard: (arg: any) => void
  handleValue: any
}

export const ProjectCards: FC<ProjectCards> = ({
  setClickOnCard,
  handleValue,
  setSelectedProjectCard,
}) => {
  const clickOnProjectCard = (uuid: string) => {
    setSelectedProjectCard(uuid)
    setClickOnCard?.(true)
  }

  const { data, loading, error } = useQuery(ALL_PROJECTS_TO_CARD)

  return (
    <div>
      <div className={s.projects_filter__cadrs_block}>
        {data?.projects?.map((elem: any) =>
          elem?.projectStatusNew !== 'archive' &&
          elem?.projectStatusNew !== 'draft' ? (
            +handleValue < +elem?.minInvestment ||
            elem?.projectStatusNew === 'suspended' ? (
              <div
                className={s.project_filter__card}
                key={elem?.uuid.concat('project_filter__card')}
                onClick={() => clickOnProjectCard(elem?.uuid)}
              >
                <div className={s.project_filter_card__grey}>
                  <div
                    className={s.project_filter_card__grey_logo}
                    style={{
                      backgroundImage: `url('${elem?.projectImages?.logo}')`,
                    }}
                  >
                    &nbsp;
                  </div>
                  {elem?.projectStatusNew === 'active' ? (
                    <div className={s.project_filter_card__min_invest}>
                      <p className={s.project_filter_card_min_invest__numbers}>
                        {Number(elem?.minInvestment)
                          .toLocaleString('ru-RU')
                          .concat(' ₽')}
                      </p>
                      <p
                        className={
                          s.project_filter_card_min_invest__description
                        }
                      >
                        Мин. размер инвестиций
                      </p>
                    </div>
                  ) : elem?.projectStatusNew === 'suspended' ? (
                    <p className={s.projects_filter__cadrs_block_stop}>
                      Привлечение средств
                      <br />
                      временно приостановлено
                    </p>
                  ) : (
                    <div className={s.projects_filter__cadrs_block_archive}>
                      &nbsp;
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                className={s.project_filter__card}
                key={elem?.uuid.concat('project_filter__card_color')}
                onClick={() => clickOnProjectCard(elem?.uuid)}
              >
                <div
                  className={s.project_filter_card__color}
                  style={{
                    backgroundImage: `url('${elem?.projectImages?.background}')`,
                  }}
                >
                  <div
                    className={s.project_filter_card__logo}
                    style={{
                      backgroundImage: `url('${elem?.projectImages?.logo}')`,
                    }}
                  >
                    &nbsp;
                  </div>
                  <div className={s.project_filter_card__income}>
                    <p className={s.project_filter_card_income_percent_numbers}>
                      {elem?.investThresholds[4]
                        ? handleValue >= elem?.investThresholds[4].sum
                          ? elem?.investThresholds[4]?.percent.concat(
                              '% годовых'
                            )
                          : handleValue >= elem?.investThresholds[3].sum &&
                            handleValue < elem?.investThresholds[4].sum
                          ? elem?.investThresholds[3]?.percent.concat(
                              '% годовых'
                            )
                          : handleValue >= elem?.investThresholds[2].sum &&
                            handleValue < elem?.investThresholds[3].sum
                          ? elem?.investThresholds[2]?.percent.concat(
                              '% годовых'
                            )
                          : handleValue >= elem?.investThresholds[1].sum &&
                            handleValue < elem?.investThresholds[2].sum
                          ? elem?.investThresholds[1]?.percent.concat(
                              '% годовых'
                            )
                          : handleValue >= elem?.investThresholds[0].sum &&
                            handleValue < elem?.investThresholds[1].sum
                          ? elem?.investThresholds[0]?.percent.concat(
                              '% годовых'
                            )
                          : elem?.investThresholds[0]?.percent.concat(
                              '% годовых'
                            )
                        : elem?.investThresholds[3]
                        ? handleValue >= elem?.investThresholds[3].sum
                          ? elem?.investThresholds[3]?.percent.concat(
                              '% годовых'
                            )
                          : handleValue >= elem?.investThresholds[2].sum &&
                            handleValue < elem?.investThresholds[3].sum
                          ? elem?.investThresholds[2]?.percent.concat(
                              '% годовых'
                            )
                          : handleValue >= elem?.investThresholds[1].sum &&
                            handleValue < elem?.investThresholds[2].sum
                          ? elem?.investThresholds[1]?.percent.concat(
                              '% годовых'
                            )
                          : handleValue >= elem?.investThresholds[0].sum &&
                            handleValue < elem?.investThresholds[1].sum
                          ? elem?.investThresholds[0]?.percent.concat(
                              '% годовых'
                            )
                          : elem?.investThresholds[0]?.percent.concat(
                              '% годовых'
                            )
                        : elem?.investThresholds[2]
                        ? handleValue >= elem?.investThresholds[2].sum
                          ? elem?.investThresholds[2]?.percent.concat(
                              '% годовых'
                            )
                          : handleValue >= elem?.investThresholds[1].sum &&
                            handleValue < elem?.investThresholds[2].sum
                          ? elem?.investThresholds[1]?.percent.concat(
                              '% годовых'
                            )
                          : handleValue >= elem?.investThresholds[0].sum &&
                            handleValue < elem?.investThresholds[1].sum
                          ? elem?.investThresholds[0]?.percent.concat(
                              '% годовых'
                            )
                          : elem?.investThresholds[0]?.percent.concat(
                              '% годовых'
                            )
                        : elem?.investThresholds[1]
                        ? handleValue >= elem?.investThresholds[1].sum
                          ? elem?.investThresholds[1]?.percent.concat(
                              '% годовых'
                            )
                          : handleValue >= elem?.investThresholds[0].sum &&
                            handleValue < elem?.investThresholds[1].sum
                          ? elem?.investThresholds[0]?.percent.concat(
                              '% годовых'
                            )
                          : elem?.investThresholds[0]?.percent.concat(
                              '% годовых'
                            )
                        : elem?.investThresholds[0]?.percent.concat(
                            '% годовых'
                          )}
                    </p>
                    <div className={s.project_filter_card__income_money}>
                      <p className={s.project_filter_card_income_money_numbers}>
                        {elem?.investThresholds[4]
                          ? handleValue >= elem?.investThresholds[4].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[4]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                            : handleValue >= elem?.investThresholds[3].sum &&
                              handleValue < elem?.investThresholds[4].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[3]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                            : handleValue >= elem?.investThresholds[2].sum &&
                              handleValue < elem?.investThresholds[3].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[2]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                            : handleValue >= elem?.investThresholds[1].sum &&
                              handleValue < elem?.investThresholds[2].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[1]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                            : handleValue >= elem?.investThresholds[0].sum &&
                              handleValue < elem?.investThresholds[1].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                            : Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                          : elem?.investThresholds[3]
                          ? handleValue >= elem?.investThresholds[3].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[3]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                            : handleValue >= elem?.investThresholds[2].sum &&
                              handleValue < elem?.investThresholds[3].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[2]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                            : handleValue >= elem?.investThresholds[1].sum &&
                              handleValue < elem?.investThresholds[2].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[1]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                            : handleValue >= elem?.investThresholds[0].sum &&
                              handleValue < elem?.investThresholds[1].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                            : Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                          : elem?.investThresholds[2]
                          ? handleValue >= elem?.investThresholds[2].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[2]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                            : handleValue >= elem?.investThresholds[1].sum &&
                              handleValue < elem?.investThresholds[2].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[1]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                            : handleValue >= elem?.investThresholds[0].sum &&
                              handleValue < elem?.investThresholds[1].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                            : Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                          : elem?.investThresholds[1]
                          ? handleValue >= elem?.investThresholds[1].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[1]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                            : handleValue >= elem?.investThresholds[0].sum &&
                              handleValue < elem?.investThresholds[1].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                            : Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              )
                                .toLocaleString('ru-RU')
                                .concat(' ₽ в год')
                          : Number(
                              (handleValue *
                                elem?.investThresholds[0]?.percent) /
                                100
                            )
                              .toLocaleString('ru-RU')
                              .concat(' ₽ в год')}
                      </p>
                    </div>
                    <div className={s.project_filter_card__arrow_to_down}>
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div
              className={s.projects_filter__cadrs_block_archive}
              key={elem?.uuid.concat('project_filter__card_archive')}
            >
              &nbsp;
            </div>
          )
        )}
      </div>
    </div>
  )
}
