import React, { FC } from 'react'
import { Section } from '../Section/Section'
import { SectionTitle } from '../SectionTitle/SectionTitle'
import { SliderProjects } from '../SliderProjects/SliderProjects'
import s from './OtherProjects.module.css'

interface OtherProjects {
  width?: number
  isTurnDevice?: boolean
  clientWidth?: number
}

export const OtherProjects: FC <OtherProjects> = ({ width, isTurnDevice, clientWidth }) => (
  <Section className={s.section__margin}>
    <SectionTitle text="Другие проeкты" />
    {/* <Slider projectSlider={true} photoSlider={false} width={width} /> */}
    <SliderProjects
      width={width}
      isTurnDevice={isTurnDevice}
      clientWidth={1025}
    />
  </Section>
)
