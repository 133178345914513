import { FC } from 'react'
import {
  DataPicker, Field, Label, Panel, Title,
} from '../../ui'
import { promotionState } from '../../redux/slices/promotionSlice'
import { NativeSelect } from '../nativeSelect'
import { statusArr } from './data'
import s from './StoriesInfo.module.css'

interface StoriesInfoType
	extends Omit<
		promotionState,
		'slide' | 'title' | 'colorMiniature' | 'imageMiniature'
	> {
	changeField: (e: any, str: string) => void
	statusSelect: boolean
	promotion?: string
}

export const StoriesInfo: FC<StoriesInfoType> = ({
  name,
  status,
  startDate,
  endDate,
  changeField,
  statusSelect,
  changeStatus,
  promotion,
}) => (
  <Panel padding>
    <Title title="Информация" />
    <div className={s.blockName}>
      <Label>
        Название
        <Field
          view="default"
          value={name}
          onChange={e => changeField(e, 'name')}
          maxLength={50}
        />
      </Label>
      <Label>
        Статус
        <NativeSelect
          promotionValue={status}
          promotion={promotion}
          pledgeArr={statusArr}
          // status
          show={statusSelect}
          // draft={draft}
          onClick={changeStatus}
        />
      </Label>
    </div>
    <div style={{ marginBottom: '10px' }} className={s.blockName}>
      <Label>
        Начало
        <DataPicker
          className={s.dataPicker}
          startDate={startDate}
          onChange={date => changeField(date, 'startedAt')}
        />
      </Label>
      <Label>
        Конец акции
        <DataPicker
          className={s.dataPicker}
          startDate={endDate}
          onChange={date => changeField(date, 'endedAt')}
        />
      </Label>
    </div>
    <p className={s.info}>
      Акция появится в списке с момента ее публикации. По истечению срока акция
      будет перемещена в архив.
    </p>
  </Panel>
)
