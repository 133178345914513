/*eslint-disable*/
import React, { FC, TextareaHTMLAttributes } from "react";
import clsx from "clsx";
import s from "./Textarea.module.css";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  name?: string | undefined;
  cols?: number | undefined;
  rows?: number | undefined;
  maxLength?: number | undefined;
  minLength?: number | undefined;
  value?: string | ReadonlyArray<string> | number | undefined;
  placeholder?: string | undefined;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  eng?: boolean;
}

export const Textarea: FC<TextAreaProps> = props => {
  const {
    className,
    name,
    cols,
    rows,
    maxLength,
    minLength,
    value,
    placeholder,
    onChange,
    eng,
    ...rest
  } = props;
  return (
    <>
      <textarea
        className={clsx(s.description, className, eng && s.descriptionEng)}
        name={name}
        cols={cols}
        rows={rows}
        maxLength={maxLength}
        minLength={minLength}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        {...rest}
      />
      {eng && <span className={s.engIcon} />}
    </>
  );
};
