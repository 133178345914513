import React, { FC } from 'react'
import s from './SocialLinks.module.css'
// import { DataContacts } from "../contacts/DataContacts"

interface Footer {
  info?: any
}

export const SocialLinks: FC<Footer> = ({ info }) => (
  <div className={s.social_links}>
    <a className={s.youtube} href={info?.socnetYoutube}>
      {' '}
    </a>
    <a className={s.telegramm} href={info?.socnetTelegram}>
      {' '}
    </a>
    <a className={s.instagramm} href={info?.socnetInstagram}>
      {' '}
    </a>
    <a className={s.VK_icon} href={info?.socnetVK}>
      {' '}
    </a>
  </div>
)
