/* eslint-disable*/

import React, { ChangeEvent, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import {
  Title,
  Container,
  Panel,
  Label,
  Field,
  Textarea,
  Button,
  Loader,
  AddButton,
} from "../../ui";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
  GET_MAINPAGE,
  GET_NUMBERS,
} from "../../apollo/query/editSite/mainPage";
import {
  MAINPAGE_UPDATE,
  NUMBERS_CREATE,
  NUMBERS_DELETE,
  NUMBERS_UPDATE,
} from "../../apollo/mutation/editSite/mainPageUpdate";
import { EditMainPageSelect } from "../EditMainPageSelect/EditMainPageSelect";
import { UploadEditPages } from "../UploadEditPages";
import { Portal } from "../../ui/Portal/Portal";
import { HomePage } from "../preview/home/HomePage";
import preview from "./img/icon-eye.png";
import iconSent from "./img/icon-sent.svg";
import iconDraft from "./img/icon-draft.svg";
import s from "./EditMainPage.module.css";

export const EditMainPage = () => {
  const { mainPage } = useAppSelector((state) => state.editSiteSlice);
  const navigate = useNavigate();
  const [coverText, setCoverText] = useState("");
  const [coverTextEn, setCoverTextEn] = useState("");
  const [cover, setCover] = useState<string | null>("");
  const [photoAbout, setPhotoAbout] = useState("");
  const [photoText, setPhotoText] = useState("");
  const [photoTextEn, setPhotoTextEn] = useState("");
  const [formTitleText, setFormTitleText] = useState("");
  const [formTitleTextEn, setFormTitleTextEn] = useState("");
  const [qouteText, setQouteText] = useState("");
  const [qouteTextEn, setQouteTextEn] = useState("");
  const [textAboveForm, setTextAboveForm] = useState("");
  const [textAboveFormEn, setTextAboveFormEn] = useState("");
  const [senting, setSenting] = useState(false);
  const [uniqArr, setUniqArr] = useState<any>([]);
  const [deletedArrays, setDeletedArrays]: any = useState([]);
  const [deletedArrayUUID, setDeletedArrayUUID] = useState<string[]>([""]);
  const [arrayYears, setArrayYears] = useState<any>([]);
  const [investorProfit, setInvestorProfit] = useState("");
  const [returnOnInvestment, setReturnOnInvestment] = useState("");
  const [reinvest, setReinvest] = useState("");
  const [investorInvested, setInvestorInvested] = useState("");
  const [transactionsCompleted, setTransactionsCompleted] = useState("");
  const [averageInvestorCheck, setAverageInvestorCheck] = useState("");
  const [arrayYearsToUpdate, setArrayYearsToUpdate] = useState([]);
  const arr = [
    { id: 1, value: "млн ₽", valueEn: "mn ₽" },
    { id: 2, value: "млрд ₽", valueEn: "bn ₽" },
    { id: 3, value: "%", valueEn: "%" },
    { id: 4, value: " ₽", valueEn: "₽" },
    { id: 0, value: "Ед. изм.", valueEn: "Ед. изм." },
  ];
  const [dataArray, setDataArray]: any = useState([]);
  // объект с данными для мутации без цифр
  const obj = {
    button: "save",
    heading: coverText,
    headingEn: coverTextEn,
    photo: cover,
    about: photoText,
    aboutEn: photoTextEn,
    photoAbout,
    title: formTitleText,
    titleEn: formTitleTextEn,
    quote: qouteText,
    quoteEn: qouteTextEn,
    caption: textAboveForm,
    captionEn: textAboveFormEn,
  };
  const { data, loading, error } = useQuery(GET_MAINPAGE);
  const [
    updateMainPage,
    {
      data: updateMainPageData,
      loading: loadingUpdateMainPage,
      error: errorUpdateMainPage,
    },
  ] = useMutation(MAINPAGE_UPDATE);
  const {
    data: numbersData,
    loading: numbersLoading,
    error: numbersError,
  } = useQuery(GET_NUMBERS, {
    variables: {
      filter: {},
    },
  });

  const [
    createYear,
    {
      data: createYearData,
      loading: loadingCreateYear,
      error: errorCreateYear,
    },
  ] = useMutation(NUMBERS_CREATE, {
    refetchQueries: [
      {
        query: GET_NUMBERS,
        variables: {
          filter: {},
        },
      },
    ],
  });
  const [
    deleteYearMutation,
    { loading: loadingDeleteYear, error: errorDeleteYear },
  ] = useMutation(NUMBERS_DELETE, {
    refetchQueries: [
      {
        query: GET_NUMBERS,
        variables: {
          filter: {},
        },
      },
    ],
  });

  const [
    updateNumbers,
    { loading: loadingUpdateNumbers, error: errorNumbers },
  ] = useMutation(NUMBERS_UPDATE, {
    refetchQueries: [
      {
        query: GET_NUMBERS,
        variables: {
          filter: {},
        },
      },
    ],
  });

  // удаление кавычек
  const removeQuotesFromString = (value: any) => {
    if (typeof value === "string") {
      const stringWithoutQuotes = value.replace(/"/g, "");
      return stringWithoutQuotes;
    }
    return value;
  };
  // запрет печатать числа
  const typeNoNumbers = (text: string) => text.replace(/\d/g, "");
  // запрет печатать буквы и точку
  const typeNoLеtters = (text: string) => text.replace(/[^0-9,]/g, "");

  useEffect(() => {
    if (data) {
      setCoverText(data?.page.heading);
      setCoverTextEn(data?.page.headingEn);
      setCover(data?.page.photo);
      setPhotoText(data?.page.about);
      setPhotoTextEn(data?.page.aboutEn);
      setPhotoAbout(data?.page.photoAbout);

      setFormTitleText(data?.page.title);
      setFormTitleTextEn(data?.page.titleEn);
      setQouteText(data?.page.quote);
      setQouteTextEn(data?.page.quoteEn);
      setTextAboveForm(data?.page.caption);
      setTextAboveFormEn(data?.page.captionEn);
    }
  }, [data]);
  // добавляю конкат т.к все созадются одновременно
  useEffect(() => {
    if (numbersData) {
      if (numbersData.mediaNumbers === null) {
        // console.log('numbersData.mediaNumbers', numbersData?.mediaNumbers, typeof numbersData.mediaNumbers[9].number)
      } else {
        let helpArr = [...numbersData?.mediaNumbers];

        console.log("helpArr", helpArr);
        helpArr = helpArr?.map((el: any, index: number) => ({
          ...el,
          idIndex: index,
          returnOnInvestment: {
            uuid: el.returnOnInvestment.uuid,
            number:
              el.returnOnInvestment.number === undefined ||
              el.returnOnInvestment.number === "null"
                ? ""
                : removeQuotesFromString(el.returnOnInvestment.number),
            type:
              el.returnOnInvestment.type === undefined
                ? ""
                : el.returnOnInvestment.type,
          },
          returnOnInvestmentType:
            el.returnOnInvestment.type === undefined
              ? ""
              : el.returnOnInvestment.type,
          reinvest: {
            uuid: el.reinvest.uuid,
            number:
              el.reinvest.number === undefined || el.reinvest.number === "null"
                ? ""
                : removeQuotesFromString(el.reinvest.number),
            type: el.reinvest.type === undefined ? "" : el.reinvest.type,
          },
          reinvestType: el.reinvest.type === undefined ? "" : el.reinvest.type,
          investorProfit: {
            uuid: el.investorProfit.uuid,
            // number: el.investorProfit.number === undefined || el.investorProfit.number === 'null' ? '' : removeQuotesFromString(el.investorProfit.number),
            number:
              el.investorProfit.number === "null"
                ? ""
                : removeQuotesFromString(el.investorProfit.number),
            type:
              el.investorProfit.type === undefined
                ? ""
                : el.investorProfit.type,
          },
          investorProfitType: el.investorProfit.type,
          transactionsCompleted: {
            uuid: el.transactionsCompleted.uuid,
            number:
              el.transactionsCompleted.number === undefined ||
              el.transactionsCompleted.number === "null"
                ? ""
                : removeQuotesFromString(el.transactionsCompleted.number),
            type:
              el.transactionsCompleted.type === undefined
                ? ""
                : el.transactionsCompleted.type,
          },
          transactionsCompletedType: el.transactionsCompleted.type,
          averageInvestorCheck: {
            uuid: el.averageInvestorCheck.uuid,
            // number: el.averageInvestorCheck.number === undefined || el.transactionsCompleted.number === 'null' ? '' : removeQuotesFromString(el.averageInvestorCheck.number),
            number:
              el.transactionsCompleted.number === "null"
                ? ""
                : removeQuotesFromString(el.averageInvestorCheck.number),
            type:
              el.averageInvestorCheck.type === undefined
                ? ""
                : el.averageInvestorCheck.type,
          },
          averageInvestorCheckType: el.averageInvestorCheck.type,
          investorInvested: {
            uuid: el.investorInvested.uuid,
            number:
              el.investorInvested.number === undefined ||
              el.investorInvested.number === "null"
                ? ""
                : removeQuotesFromString(el.investorInvested.number),
            type:
              el.investorInvested.type === undefined
                ? ""
                : el.investorInvested.type,
          },
          investorInvestedType:
            el.investorInvested.type === undefined
              ? ""
              : el.investorInvested.type,
          uniqId: new Date().toISOString().concat(String(index)),
          showType: false,
          showTypeReturnOnInvestment: false,
          showTypeReinvest: false,
          showTypeInvestorInvested: false,
          showTypeTransactionsCompleted: false,
          showTypeAverageInvestorCheck: false,
        }));
        setArrayYears([...helpArr]);
      }
    }
  }, [numbersData]);

  const changeCover = (url: string) => setCover(url);
  const changePhotoAbout = (url: string) => setPhotoAbout(url);
  const coverTextHandler = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setCoverText(event.target.value);
  const coverTextHandlerEn = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setCoverTextEn(event.target.value);
  const photoTextHandler = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setPhotoText(event.target.value);
  const photoTextHandlerEn = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setPhotoTextEn(event.target.value);
  const formTitleTextHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setFormTitleText(event.target.value);
  const formTitleTextHandlerEn = (event: ChangeEvent<HTMLInputElement>) =>
    setFormTitleTextEn(event.target.value);
  const qouteTextHandler = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setQouteText(event.target.value);
  const qouteTextHandlerEn = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setQouteTextEn(event.target.value);
  const textAboveFormHandler = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setTextAboveForm(event.target.value);
  const textAboveFormHandlerEn = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setTextAboveFormEn(event.target.value);

  // добавление года
  const addArrayYear = () => {
    setArrayYears([
      ...arrayYears,
      {
        year: "",
        returnOnInvestment: "",
        returnOnInvestmentType: "",
        reinvest: "",
        reinvestType: "",
        investorProfit: "",
        investorProfitType: "",
        transactionsCompleted: "",
        transactionsCompletedType: "",
        averageInvestorCheck: "",
        averageInvestorCheckType: "",
        investorInvested: "",
        investorInvestedType: "",
        idIndex: arrayYears.length,
        uniqId: new Date().toISOString(),
      },
    ]);
  };

  // массив для создания новых годов
  useEffect(() => {
    setUniqArr(
      arrayYears
        .filter((el: any) => el.uuid === undefined)
        .map((el: any) => ({
          year: el.year,
          returnOnInvestment: el.returnOnInvestment,
          returnOnInvestmentType: el.returnOnInvestmentType,
          reinvest: el.reinvest,
          reinvestType: el.reinvestType,
          investorProfit: el.investorProfit,
          investorProfitType: el.investorProfitType,
          transactionsCompleted: el.transactionsCompleted,
          transactionsCompletedType: el.transactionsCompletedType,
          averageInvestorCheck: el.averageInvestorCheck,
          averageInvestorCheckType: el.averageInvestorCheckType,
          investorInvested: el.investorInvested,
          investorInvestedType: el.investorInvestedType,
        }))
    );
    setDeletedArrayUUID([
      ...deletedArrays
        .filter((el: any) => el.uuid !== undefined)
        .map((el: any) => el.uuid),
    ]);
    setArrayYearsToUpdate(
      arrayYears
        .filter((el: any) => el.uuid !== undefined)
        .map((el: any) => ({
          uuid: el.uuid,
          year: el.year,
          returnOnInvestment: {
            uuid: el.returnOnInvestment.uuid,
            number: el.returnOnInvestment.number,
            type: el.returnOnInvestmentType,
          },
          reinvest: {
            uuid: el.reinvest.uuid,
            number: el.reinvest.number,
            type: el.reinvestType,
          },
          investorProfit: {
            uuid: el.investorProfit.uuid,
            number: el.investorProfit.number,
            type: el.investorProfitType,
          },
          transactionsCompleted: {
            uuid: el.transactionsCompleted.uuid,
            number: el.transactionsCompleted.number,
            type: el.transactionsCompletedType,
          },
          averageInvestorCheck: {
            uuid: el.averageInvestorCheck.uuid,
            number: el.averageInvestorCheck.number,
            type: el.averageInvestorCheckType,
          },
          investorInvested: {
            uuid: el.investorInvested.uuid,
            number: el.investorInvested.number,
            type: el.investorInvestedType,
          },
        }))
    );
  }, [arrayYears, deletedArrays]);

  useEffect(() => {
    setDataArray(
      arrayYears.map((el: any) => ({
        uuid: el.uuid,
        year: el.year,
        returnOnInvestment: {
          uuid: el.returnOnInvestment.uuid,
          number: el.returnOnInvestment.number,
          type: el.returnOnInvestmentType,
        },
        reinvest: {
          uuid: el.reinvest.uuid,
          number: el.reinvest.number,
          type: el.reinvestType,
        },
        investorProfit: {
          uuid: el.investorProfit.uuid,
          number: el.investorProfit.number,
          type: el.investorProfitType,
        },
        transactionsCompleted: {
          uuid: el.transactionsCompleted.uuid,
          number: el.transactionsCompleted.number,
          type: el.transactionsCompletedType,
        },
        averageInvestorCheck: {
          uuid: el.averageInvestorCheck.uuid,
          number: el.averageInvestorCheck.number,
          type: el.averageInvestorCheckType,
        },
        investorInvested: {
          uuid: el.investorInvested.uuid,
          number: el.investorInvested.number,
          type: el.investorInvestedType,
        },
      }))
    );
  }, [arrayYears]);

  const hidePage = () => {
    navigate("/home", { replace: true });
  };

  const changeYear = (e: any) => {
    const temp = typeNoLеtters(e.target.value);
    arrayYears[e.target.id].year = temp;
    setArrayYears([...arrayYears]);
  };

  const changeInvestorProfit = (e: ChangeEvent<HTMLInputElement>) => {
    // нахожу какое поле изменильось.меняю значения ключа investorProfit, переразворачиваю объект, чтобы не терять  uuid, меняю значение на e.target.value
    arrayYears.find((el: any) => el.uniqId === e.target.id).investorProfit = {
      ...arrayYears.find((el: any) => el.uniqId === e.target.id).investorProfit,
      number: typeNoLеtters(e.target.value),
    };
    setInvestorProfit(typeNoLеtters(e.target.value));
    setArrayYears([...arrayYears]);
  };
  const changeReturnOnInvestment = (e: ChangeEvent<HTMLInputElement>) => {
    const temp = typeNoLеtters(e.target.value);
    arrayYears.find((el: any) => el.uniqId === e.target.id).returnOnInvestment =
      {
        ...arrayYears.find((el: any) => el.uniqId === e.target.id)
          .returnOnInvestment,
        number: temp,
      };
    setReturnOnInvestment(temp);
    setArrayYears([...arrayYears]);
  };
  const changeReinvest = (e: ChangeEvent<HTMLInputElement>) => {
    const temp = typeNoLеtters(e.target.value);
    // arrayYears.find((el: any) => el.uniqId === e.target.id).reinvest = { ...arrayYears.find((el: any) => el.uniqId === e.target.id).reinvest, number: removePercentExceptLast(temp.concat('%')) }
    arrayYears.find((el: any) => el.uniqId === e.target.id).reinvest = {
      ...arrayYears.find((el: any) => el.uniqId === e.target.id).reinvest,
      number: temp,
    };
    setReinvest(e.target.value);
    setArrayYears([...arrayYears]);
  };
  const changeInvestorInvested = (e: ChangeEvent<HTMLInputElement>) => {
    const temp = typeNoLеtters(e.target.value);
    arrayYears.find((el: any) => el.uniqId === e.target.id).investorInvested = {
      ...arrayYears.find((el: any) => el.uniqId === e.target.id)
        .investorInvested,
      number: temp,
    };
    setInvestorInvested(temp);
    setArrayYears([...arrayYears]);
  };
  const changeTransactionsCompleted = (e: ChangeEvent<HTMLInputElement>) => {
    const temp = typeNoLеtters(e.target.value);
    arrayYears.find(
      (el: any) => el.uniqId === e.target.id
    ).transactionsCompleted = {
      ...arrayYears.find((el: any) => el.uniqId === e.target.id)
        .transactionsCompleted,
      number: temp,
    };
    setTransactionsCompleted(temp);
    setArrayYears([...arrayYears]);
  };
  const changeAverageInvestorCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const temp = typeNoLеtters(e.target.value);
    arrayYears.find(
      (el: any) => el.uniqId === e.target.id
    ).averageInvestorCheck = {
      ...arrayYears.find((el: any) => el.uniqId === e.target.id)
        .averageInvestorCheck,
      number: temp,
    };
    setAverageInvestorCheck(temp);
    setArrayYears([...arrayYears]);
  };

  const deleteYear = (e: any) => {
    setArrayYears([
      ...arrayYears.filter((el: any) => el.uniqId !== e.target.id),
    ]);
    setDeletedArrays([
      ...deletedArrays,
      ...arrayYears.filter((el: any) => el.uniqId === e.target.id),
    ]);
  };

  const clickHandler = (uniqId: string, fieldName: string, num: number) => {
    if (fieldName === "returnOnInvestment")
      arrayYears.find(
        (el: any) => el.uniqId === uniqId
      ).returnOnInvestmentType = arr[num].value;
    if (fieldName === "investorProfit")
      arrayYears.find((el: any) => el.uniqId === uniqId).investorProfitType =
        arr[num].value;
    if (fieldName === "reinvest")
      arrayYears.find((el: any) => el.uniqId === uniqId).reinvestType =
        arr[num].value;
    if (fieldName === "investorInvested")
      arrayYears.find((el: any) => el.uniqId === uniqId).investorInvestedType =
        arr[num].value;
    if (fieldName === "transactionsCompleted")
      arrayYears.find(
        (el: any) => el.uniqId === uniqId
      ).transactionsCompletedType = arr[num].value;
    if (fieldName === "averageInvestorCheck")
      arrayYears.find(
        (el: any) => el.uniqId === uniqId
      ).averageInvestorCheckType = arr[num].value;
    setArrayYears([...arrayYears]);
  };
  // сохранение и публикация договора
  const handleSaveSent = () => {
    setSenting(true);
    arrayYearsToUpdate.forEach((element: any) => {
      updateNumbers({
        variables: {
          uuid: element.uuid,
          data: {
            year: element.year,
            investorProfit:
              element.investorProfit === ""
                ? ""
                : JSON.stringify(element.investorProfit),
            returnOnInvestment:
              element.returnOnInvestment === ""
                ? ""
                : JSON.stringify(element.returnOnInvestment),
            reinvest:
              element.reinvest === "" ? "" : JSON.stringify(element.reinvest),
            transactionsCompleted:
              element.transactionsCompleted === ""
                ? ""
                : JSON.stringify(element.transactionsCompleted),
            averageInvestorCheck:
              element.averageInvestorCheck === ""
                ? ""
                : JSON.stringify(element.averageInvestorCheck),
            investorInvested:
              element.investorInvested === ""
                ? ""
                : JSON.stringify(element.investorInvested),
          },
        },
      });
    });
    // удаление годов
    deletedArrayUUID.forEach((element: any) => {
      deleteYearMutation({
        variables: {
          uuid: element,
        },
      });
    });
    // создание годов
    uniqArr.forEach((element: any) => {
      createYear({
        variables: {
          data: {
            year: element.year,
            investorProfit:
              element.investorProfit.number === undefined
                ? ""
                : JSON.stringify(element.investorProfit.number),
            investorProfitType: element.investorProfitType,
            returnOnInvestment:
              element.returnOnInvestment.number === undefined
                ? ""
                : JSON.stringify(element.returnOnInvestment.number),
            returnOnInvestmentType: element.returnOnInvestmentType,
            reinvest:
              element.reinvest.number === undefined
                ? ""
                : JSON.stringify(element.reinvest.number),
            reinvestType: element.reinvestType,
            investorInvested:
              element.investorInvested.number === undefined
                ? ""
                : JSON.stringify(element.investorInvested.number),
            investorInvestedType: element.investorInvestedType,
            transactionsCompleted:
              element.transactionsCompleted.number === undefined
                ? ""
                : JSON.stringify(element.transactionsCompleted.number),
            transactionsCompletedType: element.transactionsCompletedType,
            averageInvestorCheck:
              element.averageInvestorCheck.number === undefined
                ? ""
                : JSON.stringify(element.averageInvestorCheck.number),
            averageInvestorCheckType: element.averageInvestorCheckType,
          },
        },
      });
    });
    // обновление страницы кромецифр
    updateMainPage({
      variables: {
        data: {
          ...obj,
        },
      },
    });

    if (!loadingUpdateMainPage || errorUpdateMainPage === undefined) {
      setTimeout(() => {
        hidePage();
      }, 2000);
    } else {
      setTimeout(() => {
        setSenting(true);
      });
    }
  };

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: "7px" }}>Сохранено</span>
    </>
  );

  const [previewToggler, setPreviewToggler] = useState(false);
  const closeHandler = (e: any) => {
    e.stopPropagation();
    setPreviewToggler(false);
  };

  return (
    <Container className={s.container}>
      {/* <CloseIcon onClick={closePage} /> */}
      <Title title="Главная страница" />
      <div className={s.wrapper}>
        <div className={s.first_block}>
          <Panel className={s.info__panel} padding>
            <Title className={s.title__panel} title="Обложка" />

            <Label title="Текст">
              <div className={s.input__wrapper}>
                <Textarea
                  className={s.description}
                  value={coverText}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    coverTextHandler(event)
                  }
                  rows={5}
                  maxLength={350}
                />

                <Textarea
                  className={s.description}
                  value={coverTextEn}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    coverTextHandlerEn(event)
                  }
                  rows={5}
                  maxLength={350}
                  eng
                />
              </div>
            </Label>

            <div className={s.uploads}>
              <UploadEditPages
                title="Загрузить фото"
                titleStatus="Обложка"
                text=""
                text2="Максимальный размер фото 50 Мб. Форматы: jpg, png, webp. Оптимальный размер: 1352 х 366 px"
                // application=".webp"
                subText1="Загрузить с компьютера"
                subText2="или перетащите сюда"
                changeCover={changeCover}
                cover={cover}
                uploadId="Cover"
              />
            </div>
          </Panel>
        </div>
        <div className={s.first_block}>
          <Panel className={s.info__panel} padding>
            <Title className={s.title__panel} title="О нас" />
            <Label title="Текст под фото">
              <div className={s.input__wrapper}>
                <Textarea
                  className={s.description}
                  value={photoText}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    photoTextHandler(event)
                  }
                  rows={5}
                  maxLength={350}
                />
                <Textarea
                  className={s.description}
                  value={photoTextEn}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    photoTextHandlerEn(event)
                  }
                  rows={5}
                  maxLength={350}
                  eng
                />
              </div>
            </Label>
            <div className={s.uploads}>
              <UploadEditPages
                title="Загрузить фото"
                titleStatus="O нас"
                text=""
                text2="Максимальный размер фото 50 Мб. Форматы: jpg, png, webp. Оптимальный размер: 400 х 400 px"
                // application=".webp"
                subText1="Загрузить с компьютера"
                subText2="или перетащите сюда"
                uploadId="AboutUs"
                photoAbout={photoAbout}
                changePhotoAbout={changePhotoAbout}
              />
            </div>
          </Panel>
        </div>
        <div className={s.third_block}>
          <Panel className={s.info__panel} padding>
            <Title className={s.title__panel} title="Цифры" />
            {arrayYears?.map((el: any, index: number) => (
              <EditMainPageSelect
                className={s.selectYear}
                remove
                clickHandler={clickHandler}
                key={index}
                data={arrayYears}
                index={index}
                changeYear={changeYear}
                changeInvestorProfit={changeInvestorProfit}
                investorProfit={el.investorProfit.number}
                investorProfitType={el.investorProfitType}
                changeReturnOnInvestment={changeReturnOnInvestment}
                returnOnInvestment={el.returnOnInvestment.number}
                changeReinvest={changeReinvest}
                reinvest={el.reinvest.number}
                changeInvestorInvested={changeInvestorInvested}
                investorInvested={el.investorInvested.number}
                changeTransactionsCompleted={changeTransactionsCompleted}
                transactionsCompleted={el.transactionsCompleted.number}
                changeAverageInvestorCheck={changeAverageInvestorCheck}
                averageInvestorCheck={el.averageInvestorCheck.number}
                idIndex={el.idIndex}
                deleteYear={deleteYear}
                uniqId={el.uniqId}
                returnOnInvestmentType={el.returnOnInvestmentType}
                reinvestType={el.reinvestType}
                investorInvestedType={el.investorInvestedType}
                transactionsCompletedType={el.transactionsCompletedType}
                averageInvestorCheckType={el.averageInvestorCheckType}
              />
            ))}
            <AddButton className={s.addButton} onClick={addArrayYear}>
              Добавить год
            </AddButton>
          </Panel>
        </div>
        <div className={s.fourth_block}>
          <Panel className={s.info__panel} padding>
            <Title className={s.title__panel} title="Форма связи" />
            <Label title="Заголовок ">
              <div className={s.input__wrapper}>
                <Field
                  className={s.description}
                  view="default"
                  value={formTitleText}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    formTitleTextHandler(event)
                  }
                  maxLength={100}
                />
                <Field
                  className={s.description}
                  view="default"
                  value={formTitleTextEn}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    formTitleTextHandlerEn(event)
                  }
                  maxLength={100}
                  eng
                />
              </div>
            </Label>
            <Label title="Цитата">
              <div className={s.input__wrapper}>
                <Textarea
                  className={s.description}
                  value={qouteText}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    qouteTextHandler(event)
                  }
                  rows={5}
                  maxLength={350}
                />
                <Textarea
                  className={s.description}
                  value={qouteTextEn}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    qouteTextHandlerEn(event)
                  }
                  rows={5}
                  maxLength={350}
                  eng
                />
              </div>
            </Label>
            <Label title="Текст над формой">
              <div className={s.input__wrapper}>
                <Textarea
                  className={s.description}
                  value={textAboveForm}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    textAboveFormHandler(event)
                  }
                  rows={5}
                  maxLength={350}
                />
                <Textarea
                  className={s.description}
                  value={textAboveFormEn}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    textAboveFormHandlerEn(event)
                  }
                  rows={5}
                  maxLength={350}
                  eng
                />
              </div>
            </Label>
          </Panel>
        </div>
        <Panel className={s.btns__panel}>
          <Button
            // onClick={() => console.log('click')}
            // disabled
            onClick={() => setPreviewToggler(true)}
            className={s.btns__panel_preview}
            icon
            src={preview}
          >
            Предпросмотр
          </Button>

          <Button
            onClick={handleSaveSent}
            // disabled={Math.ceil(sumFieldReport) !== 100}
            className={s.btns__panel_save}
            theme="dark"
          >
            {!senting ? (
              "Сохранить и опубликовать"
            ) : loadingUpdateMainPage ? (
              <Loader />
            ) : !loadingUpdateMainPage && errorUpdateMainPage ? (
              "Ошибка"
            ) : (
              sentSaved
            )}
          </Button>
        </Panel>
      </div>
      {previewToggler && (
        <Portal>
          <div className={s.preview} onClick={closeHandler}>
            <HomePage
              width={1025}
              setPreviewToggler={setPreviewToggler}
              data={obj}
              dataArray={dataArray}
            />
          </div>
        </Portal>
      )}
    </Container>
  );
};
