import React, { useEffect, useState, FC } from 'react'
import { Section } from '../../Section/Section'
import { MediaSectionTitle } from '../../MediaSectionTItle/MediaSectionTitle'
import { ListItem } from './listItem/ListItem'
import s from './StartListSection.module.css'

interface StartListSection {
  startList?: any
}

export const StartListSection: FC<StartListSection> = ({ startList }) => {
  const [demandList, setDemandList]: any = useState([])

  return (
    <>
      <Section className={s.wrapper}>
        <MediaSectionTitle
          className={s.mediaSectionTitle}
          text1="Как начать"
          text2="работать с нами"
        />
        <div className={s.block__listItems} id="startList">
          <div className={s.main__listItems_flex}>
            <ul className={s.main__listItems_container}>
              {startList?.sort((a: any, b: any) => a.order - b.order)?.map((item: any, index: number) => (
                <ListItem item={item} key={String(index).concat('startList')} />
              ))}
            </ul>
          </div>
        </div>
      </Section>
    </>
  )
}
