import React, { useRef, useState } from 'react'
import './PaymentTable.css'
import s from './PaymentTable.module.css'
import { Title } from '../../ui'
import copyIcon from './copy-icon.svg'

interface PaymentTable {
  arrayTable: any
  showTable: boolean
  radioIncomeTax: boolean
}

export const PaymentTable: React.FC<PaymentTable> = ({
  arrayTable,
  showTable,
  radioIncomeTax,
}) => {
  const tableRef = useRef<HTMLTableElement>(null)
  const [tooltip, setShowToolTip] = useState(false)
  // проверка что договоры заведены со всеми полями для расчета таблицы
  // console.log('arrayTable', arrayTable[0]?.returnLoan === '-')
  const handleButtonClick = () => {
    const table: any = tableRef.current
    const range = document.createRange()
    range.selectNode(table)
    window.getSelection()?.removeAllRanges()
    window.getSelection()?.addRange(range)
    document.execCommand('copy')
    window.getSelection()?.removeAllRanges()
    setShowToolTip(true)
    setTimeout(() => {
      setShowToolTip(false)
    }, 3000)
  }

  return (
    <>
      {showTable && (arrayTable[0]?.returnLoan === '-') && (
        <div>
          <div className={s.line}>
            <Title className={s.title} title="График выплат" />
            <button className={s.copy} type="button" onClick={handleButtonClick}>
              <img className={s.copyIcon} src={copyIcon} alt="copy" width="20" height="20" />
              Скопировать график
            </button>
            {tooltip && (
              <div className={s.tooltip}>
                <span className={s.tooltip_text}>График скопирован</span>
              </div>
            )}
          </div>
          <table ref={tableRef} id="data-table" className={s.table}>
            <thead>
              <tr className={s.headLine}>
                <th><p className={s.headLine_text}>№ п/п</p></th>
                <th>Дата платежа (до)</th>
                <th>Процент от суммы займа к выплате</th>
                <th>Платеж по процентам за период, руб.</th>
                {radioIncomeTax ? <th>Расчет НДФЛ, руб.</th> : null}
                {radioIncomeTax ? <th>К выплате после вычета НДФЛ, руб.</th> : null}
                <th>Возврат Суммы займа, руб.</th>
              </tr>
            </thead>
            <tbody>
              {arrayTable?.map((el:any, i:number) => (
                <tr key={i}>
                  <td>{el.number}</td>
                  <td>{el.paymentDate}</td>
                  {/* <td>{(+(el?.percentPetMonth?.toFixed(4))).toLocaleString('ru-RU').concat('%')}</td> */}
                  <td>{el?.percentPetMonth?.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).concat('%')}</td>
                  {/* <td>{(+el?.monthlyPayment?.toFixed(2)).toLocaleString('ru-RU')}</td> */}
                  <td>{el?.monthlyPayment?.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                  {/* {radioIncomeTax ? <td>{(+el?.ndfl?.toFixed(2)).toLocaleString('ru-RU')}</td> : null} */}
                  {radioIncomeTax ? <td>{el?.ndfl?.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td> : null}
                  {/* {radioIncomeTax ? <td>{(+el?.monthlyPaymentToClient?.toFixed(2)).toLocaleString('ru-RU')}</td> : null} */}
                  {radioIncomeTax ? <td>{el?.monthlyPaymentToClient?.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</td> : null}
                  <td>{el?.returnLoan === '-' ? '-' : (+el?.returnLoan).toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className={s.totalRow}>
                <td className={s.totalRow_td} colSpan={2}>ИТОГО</td>
                {/* <td className={s.totalRow_td}>{arrayTable[0]?.percentPerMonthByReduce?.toFixed(2).concat('%')}</td> */}
                <td className={s.totalRow_td}>{arrayTable[0]?.percentPerMonthByReduce?.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).concat('%')}</td>
                {/* <td className={s.totalRow_td}>{(+arrayTable[0]?.totalPercentPayment.toFixed(2)).toLocaleString('ru-RU')}</td> */}
                <td className={s.totalRow_td}>{arrayTable[0]?.totalPercentPayment?.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                {/* {radioIncomeTax ? <td className={s.totalRow_td}>{(+arrayTable[0]?.totalNdfl.toFixed(2)).toLocaleString('ru-RU')}</td> : null} */}
                {radioIncomeTax ? <td className={s.totalRow_td}>{arrayTable[0]?.totalNdfl?.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td> : null}
                {/* {radioIncomeTax ? <td className={s.totalRow_td}>{(+arrayTable[0]?.totalPaymentToClient.toFixed(2)).toLocaleString('ru-RU')}</td> : null} */}
                {radioIncomeTax ? <td className={s.totalRow_td}>{arrayTable[0]?.totalPaymentToClient?.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td> : null}
                {/* <td className={s.totalRow_td}>{(+arrayTable[0]?.sumTable.toFixed(0)).toLocaleString('ru-RU')}</td> */}
                <td className={s.totalRow_td}>{(+arrayTable[0]?.sumTable)?.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </>
  )
}
