import React, { FC } from 'react'
import s from './OpeningText.module.css'

interface OpeningTextProps {
	elem?: any
}

export const OpeningText: FC<OpeningTextProps> = ({ elem }) => (
  <>
    <details className={s.container}>
      <summary className={s.title}>{elem.title}</summary>
      <p className={s.description}>{elem.description}</p>
    </details>
  </>
)
