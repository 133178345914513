/* eslint-disable */
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  concat,
  split,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { setContext } from '@apollo/client/link/context'

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL_CLIENT,
  credentials: 'same-origin',
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${process.env.REACT_APP_API_URL_WS}`,
  })
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `bearer ${token}` : '',
    },
  }
})

const client = new ApolloClient({
  link: concat(authLink, splitLink),
  cache: new InMemoryCache(),
})

export default client
