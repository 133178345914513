/* eslint-disable*/

import React, { ChangeEvent, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { GET_TEAM } from "../../apollo/query/editSite/teamPage";
import {
  GET_MAINPAGE,
  GET_NUMBERS,
} from "../../apollo/query/editSite/mainPage";
import { MAINPAGE_UPDATE } from "../../apollo/mutation/editSite/mainPageUpdate";
import {
  DELETE_BROKER,
  UPDATE_BROKER,
} from "../../apollo/mutation/editSite/teamPageUpdate";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
  Title,
  Container,
  Panel,
  Label,
  Field,
  Textarea,
  Button,
  Loader,
  AddButton,
  Edit,
  Modal,
} from "../../ui";
import { Portal } from "../../ui/Portal/Portal";
import { TeamPopup } from "../preview/home/teamSection/teamPopup/TeamPopup";
import preview from "./img/icon-eye.png";
import iconSent from "./img/icon-sent.svg";
import iconGallery from "./img/icon-gallery.svg";
import s from "./EditTeamPage.module.css";
import {
  changeIsCreateBroker,
  changeNav,
  changeLeftText,
} from "../../redux/slices/editSiteSlice";

export const EditTeamPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [description, setDescription] = useState("");
  const [senting, setSenting] = useState(false);
  const [active, setActive] = useState(false);
  const [editActive, setEditActive] = useState<number | null>(null);
  const [isModal, setIsModal] = useState(false);
  const [personalToDelete, setPersonalToDelete] = useState("");
  const [personalToDeleteUUID, setPersonalToDeleteUUID] = useState("");
  const [leftText, setLeftText] = useState("");
  const [leftTextEn, setLeftTextEn] = useState("");
  const [obj, setObj] = useState<any>({});
  const { data, loading, error } = useQuery(GET_TEAM);
  const { data: dataMainPage } = useQuery(GET_MAINPAGE);
  const [
    updateMainPage,
    {
      data: updateMainPageData,
      loading: loadingUpdateMainPage,
      error: errorUpdateMainPage,
    },
  ] = useMutation(MAINPAGE_UPDATE);
  const [previewToggler, setPreviewToggler] = useState(false);

  const {
    data: numbersData,
    loading: numbersLoading,
    error: numbersError,
  } = useQuery(GET_NUMBERS, {
    variables: {
      filter: {},
    },
  });

  const [deleteBroker, { data: deleteBrokerData }] = useMutation(
    DELETE_BROKER,
    {
      refetchQueries: [
        {
          query: GET_TEAM,
        },
      ],
    }
  );

  const [updateBroker, { data: updateBrokerData }] = useMutation(
    UPDATE_BROKER,
    {
      refetchQueries: [
        {
          query: GET_TEAM,
        },
      ],
    }
  );

  useEffect(() => {
    if (!dataMainPage) return;
    setObj({ ...dataMainPage.page });
    setLeftText(dataMainPage.page.leftText);
  }, [dataMainPage]);
  // const leftTextHandler = (event: ChangeEvent<HTMLTextAreaElement>) => setLeftText(event.target.value)
  const leftTextHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setObj({ ...obj, leftText: event.target.value });
    dispatch(changeLeftText(event.target.value));
  };

  const leftTextHandlerEn = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setObj({ ...obj, leftTextEn: event.target.value });
    dispatch(changeLeftText(event.target.value));
  };

  const editActiveHandle = (index: number) => {
    setPersonalToDelete(`${data?.personnel?.[index].firstname}`);
    setPersonalToDeleteUUID(data?.personnel?.[index].uuid);
    if (index === editActive) {
      setEditActive(null);
    } else {
      setEditActive(index);
    }
  };

  const teamHandler = (event: any) => {
    const uuid =
      event.target.parentElement.parentElement.parentElement.parentElement.id;
    const selectStatus =
      event.target.innerText === "Опубликовать" ? "published" : "draft";
    updateBroker({
      variables: {
        uuid,
        data: {
          status: selectStatus,
        },
      },
    });
  };

  const removeHandler = () => {
    setIsModal(!isModal);
  };

  const handleSaveSent = () => {
    setSenting(true);
    updateMainPage({
      variables: {
        data: {
          about: obj.about,
          button: obj.button,
          caption: obj.caption,
          heading: obj.heading,
          leftText: obj.leftText,
          leftTextEn: obj.leftTextEn,
          photo: obj.photo,
          photoAbout: obj.photoAbout,
          quote: obj.quote,
          title: obj.title,
        },
      },
    });
    if (!loadingUpdateMainPage || errorUpdateMainPage === undefined) {
      setTimeout(() => {
        // hidePage()
      }, 2000);
    } else {
      setTimeout(() => {
        setSenting(true);
      });
    }
  };

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: "7px" }}>Сохранено</span>
    </>
  );

  return (
    <Container className={s.container}>
      {/* <CloseIcon onClick={closePage} /> */}
      <Title title="Команда" />
      <div className={s.wrapper}>
        <div className={s.first_block}>
          <Panel className={s.info__panel} padding>
            <Label title="Текст слева">
              <div className={s.input__wrapper}>
                <Textarea
                  className={s.description}
                  value={obj.leftText}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    leftTextHandler(event)
                  }
                  rows={5}
                  maxLength={350}
                />
                <Textarea
                  className={s.description}
                  value={obj.leftTextEn}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    leftTextHandlerEn(event)
                  }
                  rows={5}
                  maxLength={350}
                  eng
                />
              </div>
            </Label>
          </Panel>
        </div>
        <div>
          <Panel className={s.info__panel_team} padding>
            <div className={s.title__panel_wrapper}>
              <Title className={s.title__panel} title="Сотрудники" />
              <AddButton
                className={s.addButton}
                onClick={() => dispatch(changeIsCreateBroker(true))}
              >
                Добавить сотрудника
              </AddButton>
            </div>
            <div className={s.grid_container}>
              {data?.personnel.map((item: any, index: number) => (
                <div
                  className={s.grid_item_wrapper}
                  key={item.uuid.concat(index)}
                >
                  {/* <div className={item.status === 'draft' ? s.grid_item : s.grid_item_draft} key={item.uuid}> */}
                  <div className={s.grid_item} key={item.uuid} id={item.uuid}>
                    <Edit
                      className={s.grid_item_edit}
                      active={editActive === index}
                      onClick={() => editActiveHandle(index)}
                      onEditClick={() => {
                        dispatch(changeNav(3));
                        dispatch(changeIsCreateBroker(false));
                        navigate(`/editsite/edit/${item.uuid}`);
                      }}
                      removeDoc={removeHandler}
                      teamHandler={teamHandler}
                      team
                      teamPublished={item.status === "published"}
                    />
                    {!item.photo && (
                      <div className={s.empty_rectangle}>epmty</div>
                    )}
                    <div
                      className={
                        item.status === "draft"
                          ? s.grid_item_draft
                          : s.grid_item_published
                      }
                    >
                      <img
                        className={item.photo ? s.img : s.img_empty}
                        src={item.photo ? item.photo : iconGallery}
                        alt="broker"
                      />
                    </div>
                    {/* <span className={s.grid_item_caption}>{item.firstname}</span> */}
                  </div>
                  <span className={s.grid_item_caption}>{item.firstname}</span>
                  {isModal && (
                    <Modal
                      contractNumber={personalToDelete}
                      background="rgb(250 250 250 / 0.2)"
                      question="Вы действительно хотите удалить сотрудника"
                    >
                      <Button
                        className={s.table__btn}
                        theme="dark"
                        onClick={() => {
                          deleteBroker({
                            variables: {
                              // uuid: `${contractId}`,
                              uuid: personalToDeleteUUID,
                            },
                          });
                          setIsModal(false);
                        }}
                      >
                        Удалить
                      </Button>
                      <Button
                        className={s.table__btn}
                        theme="outline"
                        onClick={() => {
                          // dispatch(hideModal())
                          // setContractId('')
                          setIsModal(false);
                        }}
                      >
                        Отмена
                      </Button>
                    </Modal>
                  )}
                </div>
              ))}
            </div>
          </Panel>
        </div>
        <Panel className={s.btns__panel}>
          {/* <Button
            onClick={() => setPreviewToggler(true)}
            className={s.btns__panel_preview}
            icon
            src={preview}
          >
            Предпросмотр
          </Button> */}

          <Button
            onClick={handleSaveSent}
            // disabled={Math.ceil(sumFieldReport) !== 100}
            // className={s.btns__panel_save}
            className={s.btns__panel_save_wide}
            theme="dark"
          >
            {!senting ? (
              "Сохранить и опубликовать"
            ) : loadingUpdateMainPage ? (
              <Loader />
            ) : !loadingUpdateMainPage && errorUpdateMainPage ? (
              "Ошибка"
            ) : (
              sentSaved
            )}
          </Button>
        </Panel>
      </div>
    </Container>
  );
};
