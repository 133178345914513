/*eslint-disable*/

import { gql } from "@apollo/client";

export const ALL_PROJECTS = gql`
  query Projects($filter: ProjectFilter) {
    projects(filter: $filter) {
      id
      uuid
      name
      projectStatusNew
    }
  }
`;
// Запрос для рендера страницы Проекты
export const PROJECTS = gql`
  query allProjects($filter: ProjectFilter) {
    projects(filter: $filter) {
      uuid
      name
      sendStatus
      status
      projectStatusNew
      projectImages {
        logo
      }
      contracts {
        investSum
      }
    }
  }
`;

// Запрпос данных проекта по uuid для рендера на странице редактирвания проекта
export const GET_DATA_PROJECT = gql`
  query getDataProject($filter: ProjectFilter) {
    projects(filter: $filter) {
      uuid
      name
      nameEn
      description
      descriptionEn
      country
      minInvestment
      minInterest
      requisites
      support
      images
      sendStatus
      status
      fullDescription
      fullDescriptionEn
      url
      socialNetworks
      projectStatusNew
      target
      targetEn
      investForms
      income
      investThresholds
      projectImages {
        id
        uuid
        bannerSite
        bannerApp
        background
        logo
        gallery
      }
    }
  }
`;

export const ALL_PROJECTS_TO_CARD = gql`
  query ALL_PROJECTS_TO_CARD {
    projects {
      uuid
      projectStatusNew
      name
      minInvestment
      minInterest
      investThresholds
      projectImages {
        logo
        background
      }
      support
      description
      url
      socialNetworks
    }
  }
`;
export const GET_ALL_INVESTFORMS = gql`
  query InvestForms {
    investForms {
      name
      id
      uuid
      name
      description
    }
  }
`;
export const ALL_PROVIDE = gql`
  query ALL_SUPPORT {
    projectProcurings {
      uuid
      name
      description
    }
  }
`;
export const ALL_PROJECTS_GALLERY = gql`
  query Projects {
    projects {
      id
      uuid
      name
      images
      requisites
      support
      url
      projectStatusNew
      status
      projectImages {
        id
        uuid
        logo
        bannerApp
        bannerSite
        background
        gallery
      }
    }
  }
`;
