import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type AuthState = { token: string | null }

const initialState: AuthState = {
  token: localStorage.token ?? null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logIn(
      state: { token: string | null },
      action: PayloadAction<string | null>,
    ): void {
      state.token = action.payload
    },
    logOut(state) {
      state.token = null
    },
  },
})

export const { logIn, logOut } = authSlice.actions
export default authSlice.reducer
