import { gql } from '@apollo/client'

export const UPDATE_CLIENT = gql`
  mutation ClientUpdate($uuid: String!, $data: ClientData!) {
    clientUpdate(uuid: $uuid, data: $data) {
      uuid
      photo
      firstName
      middleName
      lastName
      phone
      birthDay
      info {
        email
        iis
        inn
      }
      infoOrg {
        name
        inn
        address
        rs
        ks
        bank
        bik
      }
      contracts {
        uuid
        expireOn
        investSum
        project {
          uuid
          name
        }
      }
    }
  }
`
