import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client/react'
import { Provider } from 'react-redux'
import { UseAppContext } from './features/Context/useAppContext'
import client from './apollo/client'
import store from './redux/store'
import App from './app'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <UseAppContext>
            <App />
          </UseAppContext>
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
)
