/*eslint-disable*/

import React, { ChangeEvent, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import {
  Title,
  Container,
  Panel,
  Label,
  Field,
  Textarea,
  Button,
  Loader,
  AddButton,
} from "../../ui";
import { StageItem } from "../StageItem/StageItem";
import { UploadEditPages } from "../UploadEditPages";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
  changeIsCreateBroker,
  changeNav,
} from "../../redux/slices/editSiteSlice";
import { GET_ALL_FAQ } from "../../apollo/query/editSite/faqPage";
import {
  CREATE_FAQ,
  DELETE_FAQ,
  UPDATE_FAQ,
} from "../../apollo/mutation/editSite/faqPage";
import preview from "./img/icon-eye.png";
import iconSent from "./img/icon-sent.svg";
import fullFill from "./img/icon-fillfull.svg";
import empty from "./img/icon-empty.svg";
import s from "./EditFaq.module.css";
import { Faq } from "../preview/faq/Faq";
import { Portal } from "../../ui/Portal/Portal";

export const EditFaq = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [sumField, setSumField] = useState(0);
  const [senting, setSenting] = useState(false);
  const [cover, setCover] = useState("");
  const [form, setForm] = useState<any>({
    coverTitle: "",
    projectsTitle: "",
    formTitle: "",
    formSubtitle: "",
    buttonText: "",
    buttonLink: "",
  });
  const [tempArr, setTempArr]: any[] = useState([]);
  const [deletedFaq, setDeletedFaq]: any = useState([]);
  const [createdFaq, setCreatedFaq]: any = useState([]);
  const [updatedFaq, setUpdatedFaq]: any = useState([]);
  const {
    data: faqData,
    loading: faqLoading,
    error: faqError,
  } = useQuery(GET_ALL_FAQ);
  const [previewToggler, setPreviewToggler] = useState(false);

  useEffect(() => {
    if (!faqData) return;
    const temp = faqData?.allDataFaq?.map((el: any, index: number) => ({
      ...el,
      description: el.decription,
      order: index + 1,
      uniqId: new Date().toISOString().concat(String(index)),
    }));
    if (temp?.length > 0) {
      setTempArr([...temp]);
    }
    // setTempArr([...temp])
  }, [faqData]);

  const [
    createFaq,
    { data: createFaqData, loading: loadingCreateFaq, error: errorCreateFaq },
  ] = useMutation(CREATE_FAQ, {
    refetchQueries: [
      {
        query: GET_ALL_FAQ,
        variables: {
          filter: {},
        },
      },
    ],
  });

  const [
    deleteFaq,
    { data: deleteFaqData, loading: loadintDeleteFaq, error: errorDeleteFaq },
  ] = useMutation(DELETE_FAQ, {
    refetchQueries: [
      {
        query: GET_ALL_FAQ,
        variables: {
          filter: {},
        },
      },
    ],
  });

  const [
    updateFaq,
    { data: updateFaqData, loading: loadingUpdateFaq, error: errorUpdateFaq },
  ] = useMutation(UPDATE_FAQ, {
    refetchQueries: [
      {
        query: GET_ALL_FAQ,
        variables: {
          filter: {},
        },
      },
    ],
  });

  useEffect(() => {
    setCreatedFaq(
      tempArr
        .filter((el: any) => el.uuid === undefined)
        .map((el: any) => ({
          title: el.title,
          titleEn: el.titleEn,
          description: el.description,
          descriptionEn: el.descriptionEn,
          orderNum: el.order,
        }))
    );
    setUpdatedFaq(
      tempArr
        .filter((el: any) => el.uuid !== undefined)
        .map((el: any) => ({
          uuid: el.uuid,
          title: el.title,
          titleEn: el.titleEn,
          description: el.description,
          descriptionEn: el.descriptionEn,
          orderNum: el.order,
        }))
    );
  }, [tempArr]);

  const changeCoverText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setForm({ ...form, coverTitle: event.target.value });
  };
  const changeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, title: event.target.value });
  };
  const changesubtitle = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setForm({ ...form, subtitle: event.target.value });
  };
  const changeButtonText = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, sourse: event.target.value });
  };
  const changeButtonLink = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, link: event.target.value });
  };
  const changeProjectTitle = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setForm({ ...form, projectsTitle: event.target.value });
  };

  const changeStageTitle = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTempArr(
      tempArr.map((el: any) => {
        if (el.uniqId === e.target.id) {
          return { ...el, title: e.target.value };
        }
        return el;
      })
    );
  };

  const changeStageTitleEn = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTempArr(
      tempArr.map((el: any) => {
        if (el.uniqId === e.target.id) {
          return { ...el, titleEn: e.target.value };
        }
        return el;
      })
    );
  };

  const changeStageDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTempArr(
      tempArr.map((el: any) => {
        if (el.uniqId === e.target.id) {
          return { ...el, description: e.target.value };
        }
        return el;
      })
    );
  };

  const changeStageDescriptionEn = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTempArr(
      tempArr.map((el: any) => {
        if (el.uniqId === e.target.id) {
          return { ...el, descriptionEn: e.target.value };
        }
        return el;
      })
    );
  };

  // добавление этапа
  const addTempArr = () => {
    setTempArr([
      ...tempArr,
      {
        id: new Date().toISOString(),
        description: "",
        descriptionEn: "",
        title: "",
        titleEn: "",
        order: tempArr.length + 1,
        uniqId: new Date().toISOString(),
      },
    ]);
  };

  const deleteTempArr = (e: any) => {
    setTempArr([
      ...tempArr
        .filter((el: any) => el.uniqId !== e.target.id)
        .map((el: any, index: number) => ({ ...el, order: index + 1 })),
    ]);
    // оставляю только тех у кого есть uuid, для мутации удаления
    setDeletedFaq([
      ...deletedFaq,
      ...tempArr
        .filter((el: any) => el.uniqId === e.target.id)
        .filter((el: any) => el.uuid !== undefined),
    ]);
  };
  const closePage = () => {
    dispatch(changeIsCreateBroker(false));
    dispatch(changeNav(1));
    setForm({
      avatar: "",
      firstname: "",
      lastname: "",
      sourse: "",
      link: "",
      comment: "",
      datemarker: "",
      review: "",
    });
  };

  const [currentItem, setCurrentItem]: any = useState(null);
  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>, item: any) => {
    setCurrentItem(item);
  };
  const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    // console.log('e', e)
  };
  const dragEndHandler = (e: React.DragEvent<HTMLDivElement>) => {
    // console.log('e', e)
    // console.log('target', e.target.parentElement)
    // const parentElement = e.currentTarget
    // const childElement = parentElement.querySelector('#child-element')
    // console.log('childElement', childElement)
    // e.target.style.background = 'white'
    // childElement.style.background = 'white'
  };
  const dragOverHandler = (e: any) => {
    e.preventDefault();
    // e.target.style.background = 'teal'
    // console.log('e', e)
  };
  const dropHandler = (e: React.DragEvent<HTMLDivElement>, item: any) => {
    e.preventDefault();
    setTempArr(
      tempArr.map((el: any) => {
        if (el.uniqId === currentItem.uniqId) {
          return { ...el, order: item.order };
        }
        if (el.uniqId === item.uniqId) {
          return { ...el, order: currentItem.order };
        }
        return el;
      })
    );
  };

  const sortItems = (a: any, b: any) => {
    if (a.order > b.order) {
      return 1;
    }
    return -1;
  };
  const handleSaveSent = () => {
    setSenting(true);
    createdFaq.forEach((element: any) => {
      createFaq({
        variables: {
          data: {
            title: element.title,
            titleEn: element.titleEn,
            decription: element.description,
            descriptionEn: element.descriptionEn,
            orderNum: element.orderNum,
          },
        },
      });
    });
    deletedFaq.forEach((element: any) => {
      deleteFaq({
        variables: {
          uuid: element.uuid,
        },
      });
    });
    updatedFaq.forEach((element: any) => {
      updateFaq({
        variables: {
          uuid: element.uuid,
          data: {
            title: element.title,
            titleEn: element.titleEn,
            decription: element.description,
            descriptionEn: element.descriptionEn,
            orderNum: element.orderNum,
          },
        },
      });
    });
    // updateOutlet({
    //   variables: {
    //     uuid: id,
    //     data: {
    //       ...obj,
    //       datemarker: obj.datemarker.toISOString(),
    //     },
    //   },
    // })
    if (!createFaq || errorCreateFaq === undefined) {
      setTimeout(() => {
        closePage();
      }, 2000);
    } else {
      setTimeout(() => {
        closePage();
      });
    }
  };

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: "7px" }}>Сохранено</span>
    </>
  );

  const closeHandlerPreview = (e: any) => {
    setPreviewToggler(false);
  };

  return (
    <Container className={s.container}>
      <Title title="Страница FAQ" />
      <div className={s.wrapper}>
        <div className={s.first_block}>
          <Panel className={s.info__panel} padding>
            <Title className={s.panel__title} title="Вопросы" />
            {tempArr.sort(sortItems).map((item: any) => (
              <StageItem
                key={item.id}
                id={item.uniqId}
                item={item}
                dragStartHandler={dragStartHandler}
                dragLeaveHandler={dragLeaveHandler}
                dragEndHandler={dragEndHandler}
                dragOverHandler={dragOverHandler}
                dropHandler={dropHandler}
                deleteItem={deleteTempArr}
                changeStageTitle={changeStageTitle}
                changeStageTitleEn={changeStageTitleEn}
                changeStageDescription={changeStageDescription}
                changeStageDescriptionEn={changeStageDescriptionEn}
                remove
              />
            ))}
            <AddButton className={s.addButton} onClick={() => addTempArr()}>
              Добавить вопрос
            </AddButton>
          </Panel>
        </div>
        <Panel className={s.btns__panel}>
          <Button
            onClick={() => setPreviewToggler(true)}
            // disabled
            className={s.btns__panel_preview}
            icon
            src={preview}
          >
            Предпросмотр
          </Button>
          <Button
            onClick={handleSaveSent}
            className={s.btns__panel_save}
            // className={Math.ceil(sumField) === 100 ? s.btns__panel_save : s.btns__panel_save_disabled}
            theme="dark"
            // disabled={Math.ceil(sumField) < 100}
            // disabled={!broker?.firstname}
          >
            {!senting ? (
              "Сохранить и опубликовать"
            ) : faqLoading ? (
              <Loader />
            ) : !faqLoading && faqError ? (
              "Ошибка"
            ) : (
              sentSaved
            )}
          </Button>
        </Panel>
      </div>
      {previewToggler && (
        <Portal>
          <div className={s.preview} onClick={closeHandlerPreview}>
            <Faq data={tempArr} setPreviewToggler={setPreviewToggler} />
          </div>
        </Portal>
      )}
    </Container>
  );
};
