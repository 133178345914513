import { gql } from '@apollo/client'

export const ALL_PLEDGE = gql`
  query {
    projectProcurings {
      id
      uuid
      name
      description
    }
  }
`
