import React, { FC } from 'react'
import * as tus from 'tus-js-client'
import { upload } from '@testing-library/user-event/dist/upload'
import { Panel, InputFile } from '../../ui'
import s from './UploadChat.module.css'

interface UploadChatProps {
	application?: string
	src?: string
	status?: string | null
	titleStatus?: string
	uploadId?: string
	fileUrl?: string
	text?: string
	handlerFile?: any
}

export const UploadChat: FC<UploadChatProps> = ({
  application,
  src,
  text,
  status = '',
  titleStatus = '',
  uploadId,
  fileUrl,
  handlerFile,
}) => {
  const upLoad = (e: any): void => {
    const file = e.target.files

    for (const item in file) {
      if (typeof file[item] === 'object') {
        const fileType = file[item].name.slice(-4)

        const upload = new tus.Upload(file[item], {
          endpoint: process.env.REACT_APP_API_URL,
          retryDelays: [100, 300, 500, 1000, 3000],
          metadata: {
            filename: file[item].name,
            filetype: file[item].type,
          },
          onProgress(bytesUploaded: number, bytesTotal: number) {
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
            console.log(bytesUploaded, bytesTotal, `${percentage}%`)
          },
          onSuccess() {
            if (uploadId === 'image') {
              handlerFile(uploadId, upload.url, 'Фотография')
            } else if (uploadId === 'doc') {
              handlerFile(uploadId, upload.url, file[item].name)
            }
            // console.log(
            //   'Download %s from %s',
            //   upload.url?.replace(`${process.env.REACT_APP_API_URL}`, ''),
            //   file[item].name.slice(-4)
            // )
          },
          onError(error: any) {
            console.log(`Failed because: ${error}`)
          },
        })
        upload.findPreviousUploads().then((previousUploads: string | any[]) => {
          // Found previous uploads so we select the first one.
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0])
          }
          // Start the upload
          upload.start()
        })
      }
    }
  }

  return (
    <Panel className={s.chat_upload_item}>
      <InputFile
        className={s.chat__input}
        uploadId={uploadId}
        src={src}
        text={text}
        onChange={e => upLoad(e)}
        application={application}
        label={false}
      />
    </Panel>
  )
}
