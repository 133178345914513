import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { Panel } from '../Panel'
import s from './ErrorMessage.module.css'

interface errorMessageProps {
    children: ReactNode
    className?: string
}

export const ErrorMessage = ({ className, children }: errorMessageProps) => (
  <Panel
    className={clsx(s.error_message, className)}
  >
    <p>{children}</p>
  </Panel>
)
