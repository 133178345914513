import React, { ButtonHTMLAttributes, FC } from 'react'
import clsx from 'clsx'
import { Counter } from '../Counter'
import s from './ChatSwitcher.module.css'

interface ChatSwitcherType {
  active: string
  archive: string
}

const themes: ChatSwitcherType = {
  active: s.active,
  archive: s.archive,
}

interface ChatSwitcherProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  theme?: keyof typeof themes
  isIndicators?: boolean
  unRead?: number | null
  unReadByLower?: number | null
  title: string
  isRole?: string | null
}

export const ChatSwitcher: FC<ChatSwitcherProps> = props => {
  const {
    className,
    theme,
    isIndicators,
    unRead,
    unReadByLower,
    title,
    isRole,
    ...rest
  } = props
  return (
    <button
      type="button"
      className={clsx(s.switcher, theme ? themes[theme] : null, className)}
      {...rest}
    >
      <span className={s.title}>{title}</span>
      {isIndicators && (
        <>
          <Counter className={s.counter__unred} count={unRead} theme="warn" />
          {/* {isRole !== 'manager' && (
            <Counter count={unReadByLower} theme="primary" />
          )} */}
        </>
      )}
    </button>
  )
}
