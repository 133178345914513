import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useAppDispatch } from '../../redux/hook'
// добавил признак для дропдаун меню
import { addSupportFields } from '../../redux/slices/projectSlice'
import { Container } from '../Container'
import s from './nativeSelectPledge.module.css'

interface SelectType {
  className?: string;
  defaultTitle?: string;
  idChange?: string;
  name?: string;
  show?: boolean;
  tags?: boolean;
  draft?: boolean;
  pledgeArr?: any;
  pledgeArrForState?: any;
  item?: any;
  setPledgeArr?: (arg: any) => void;
  setPledgeArrFroState?: (arg: any) => void;
  onBlur?: () => void;
  clickHandler?: (e: any) => void;
  closeHandler?: (arg: string) => void;
}

export const NativeSelectPledge: React.FC<SelectType> = ({
  className,
  onBlur,
  tags = false,
  draft,
  defaultTitle,
  pledgeArr,
  setPledgeArr,
  pledgeArrForState,
  setPledgeArrFroState,
  idChange,
  clickHandler,
  item,
  closeHandler,
}) => {
  const dispatch = useAppDispatch()

  const [value, setValue] = useState<string | number>(
    defaultTitle || 'Выберите из списка',
  )
  const [pledgeValue, setPledgeValue] = useState<any>('Выберите из списка')

  useEffect(() => {
    if (draft) {
      setValue('Выберите из списка')
    }
  }, [draft])

  const useItemSelect = (
    id: string | number,
    title: string | number,
    idChange: any,
  ) => {
    // нельзя нажать повторно на выбранный варинат
    if (
      pledgeArr.findIndex((el: any) => el.active === true && el.id === id) > -1
    ) return
    // пересоздаем массив с обеспечением, присваимваем значения active, выбранным тегам, также создаем дополнительные признак idChange для связки с массивом созданиям линий.
    setPledgeArr?.(pledgeArr.map((elem: any) => elem))
    setPledgeValue(title)
    // находим индекс элемента по idChange, чтобы связать массив для добавления строк с выборкой тэгов
    const index = pledgeArrForState.findIndex((el: any) => el.id === idChange)
    // в массив для добавления строк добавляем из массива с обеспечением выбранные тэге, фильтруем по признаку актвыный и idChange массива с тэгами === idChange массива для добавления
    pledgeArrForState[index].name = title
    // добавление описания залога
    pledgeArrForState[index].provide_descriptoin = pledgeArr[
      pledgeArr.findIndex((el: any) => el.name === title)
    ].description
    // меняем сосотояние массива для добавления строк
    setPledgeArrFroState?.([...pledgeArrForState])
    // отправляем для формирования объекта с проектом
    dispatch(addSupportFields(pledgeArrForState))
    // закрываем выпадающий список
    closeHandler?.(idChange)
  }

  return (
    <div>
      <button
        // className={clsx(s.dropdownBtn, toggler && pledgeArr ? s.active : '', className)}
        className={clsx(s.dropdownBtn, item?.show ? s.active : '', className)}
        type="button"
        onBlur={onBlur}
        // если в массива для формирования объекта имя непустое, то ничего не делаем, если пустое, то пускаем в выпадающий список
        // onClick={(pledgeArrForState[index].name !== '') ? changeTooglerNull : changeToogler}
        onClick={clickHandler}
        id={idChange}
      >
        {
          pledgeArrForState[
            pledgeArrForState.findIndex((el: any) => el.id === idChange)
          ].name
        }
      </button>
      {pledgeArr ? (
        <Container
          className={clsx(s.container, item?.show ? s.visible : '', className)}
        >
          <ul className={clsx(s.list_container, s.tags_container)}>
            {pledgeArr?.map((item: any) => (
              <li
                className={clsx(
                  s.list,
                  tags ? s.tags : '',
                  tags ? item.active && s.activeItem : '',
                )}
                onClick={() => {
                  useItemSelect(item.id, item.title, idChange)
                }}
                key={item.id}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </Container>
      ) : null}
    </div>
  )
}
