/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable wrap-iife */
import { useState, useEffect } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { useGetRole } from '../../hooks/useGetRole'
import { logOut } from '../../redux/slices/authSlice'
import {
  openClient,
  openPage,
  openProject,
  openPromotion,
} from '../../redux/slices/pageSlice'
import { Layout } from '../../features/Layouts'
import { Chats } from '../Chats'
import {
  Title, Ring, Panel, ActionButton, Loader, Dropdown
} from '../../ui'
import { ALL_CLIENTS } from '../../apollo/query/allClients'
import { ALL_PROJECTS } from '../../apollo/query/allProjects'
import { ALL_CONTRACTS } from '../../apollo/query/allContracts'
import {
  admin,
  lawyer,
  manager,
  marketer,
  technical1,
  technical2,
} from '../../references/data'
import document from './icons/icon-document.svg'
import promotion from './icons/icon-promo.svg'
import project from './icons/icon-project.svg'
import clientIcon from './icons/icon-client.svg'
import logout from './icons/icon-logo-logout.svg'
import toggleArrow from './icons/icon-toggleArrow.svg'
import s from './Home.module.css'
import { UserProfile } from '../../components/UserProfile/UserProfile'

export const Home = () => {
  const [dropdown, setDropdown] = useState<boolean>(false)
  const { isChat } = useAppSelector(state => state.pageSlice)

  const dispatch = useAppDispatch()
  const client = useApolloClient()

  const { isRole, location, navigate } = useGetRole()

  useEffect(() => {
    ;(function redirect() {
      if (isRole === technical1 && location.pathname === '/home') {
        navigate('/clients', { replace: true })
      } else if (isRole === technical2 && location.pathname === '/home') {
        navigate('/documents', { replace: true })
      } else if (isRole === marketer && location.pathname === '/home') {
        navigate('/promotion', { replace: true })
      }
    })()
  }, [location, isRole])

  const exit = () => {
    dispatch(logOut(localStorage.clear()))
  }

  const {
    data: clients,
    loading: clientsLoading,
    error: clientsError,
  } = useQuery(ALL_CLIENTS)

  const {
    data: projects,
    loading: projectsLoading,
    error: projectsError,
  } = useQuery(ALL_PROJECTS)

  const {
    data: contracts,
    loading: contractsLoading,
    error: contractsError,
  } = useQuery(ALL_CONTRACTS)

  const infoPanel = [
    {
      title: 'Клиенты',
      number: clients?.clients?.length,
      loading: clientsLoading,
      error: clientsError,
    },
    {
      title: 'Проекты',
      number: projects?.projects?.length,
      loading: projectsLoading,
      error: projectsError,
    },
    {
      title: 'Документы',
      number: contracts?.contracts?.length,
      loading: contractsLoading,
      error: contractsError,
    },
  ]

  return (
    <Layout>
      {(isRole === admin || isRole === lawyer || isRole === manager) && (
        <>
          <div className={s.home__header}>
            <Title className={s.home__title} title="Главная" />
            {/*  */}

            <UserProfile isRole={isRole} navigate={navigate} />
            {/* <button
              className={s.home__btn}
              onClick={() => setDropdown(!dropdown)}
              type="button"
            >
              <div>
                {isRole === lawyer && (
                  <span className={s.home_isRole}>Правовой советник</span>
                )}
                {isRole === manager && (
                  <span className={s.home_isRole}>Менеджер</span>
                )}
                {isRole === admin && (
                  <span className={s.home_isRole}>Администратор</span>
                )}
                <img src={logout} alt="logout" />
                <img
                  src={toggleArrow}
                  alt="upDown"
                  width="11"
                  style={
                    dropdown
                      ? { transform: 'rotateX(0)' }
                      : { transform: 'rotateX(180deg)' }
                  }
                />
              </div>
            </button>
            {dropdown && (
              <Dropdown
                className={s.home__dropdown}
                content="Выйти"
                onClick={async () => {
                  exit()
                  await client.clearStore()
                  navigate('/home', { replace: true })
                }}
              />
              )} */}
          </div>

          {!isChat && (
            <>
              <Panel className={s.home__info}>
                {infoPanel.map((elem: any) => (
                  <Ring className={s.home__ring} key={elem.title}>
                    {elem.loading ? (
                      <Loader />
                    ) : (
                      <>
                        <p className={s.home__subtitle}>{elem.title}</p>
                        <p className={s.home__number}>{elem.number}</p>
                      </>
                    )}
                  </Ring>
                ))}
              </Panel>
              <div className={s.home__action}>
                <ActionButton
                  src={document}
                  onClick={() => {
                    navigate('/documents')
                    dispatch(openPage())
                  }}
                >
                  Загрузить документ
                </ActionButton>
                <ActionButton
                  src={promotion}
                  onClick={() => {
                    navigate('/promotion')
                    dispatch(openPromotion())
                  }}
                >
                  Добавить акцию
                </ActionButton>
                <ActionButton
                  src={project}
                  onClick={() => {
                    navigate('/projects')
                    dispatch(openProject())
                  }}
                >
                  Добавить проект
                </ActionButton>
                <ActionButton
                  src={clientIcon}
                  onClick={() => {
                    navigate('/clients')
                    dispatch(openClient())
                  }}
                >
                  Добавить клиента
                </ActionButton>
              </div>
            </>
          )}
          {(isRole === lawyer || isRole === manager) && isChat && <Chats />}
        </>
      )}
    </Layout>
  )
}
