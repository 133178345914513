import React from 'react'
import clsx from 'clsx'
import { Upload } from '../../components'
import {
  Field, Label, Panel, Title,
} from '../../ui'
import s from './StoriesMiniature.module.css'
import { UploadStories } from '../../components/UploadStories'

interface temporaryProps {
	colorsMiniature: any
	title: string
	changeField: any
	changeColorMiniature: any
}

export const StoriesMiniature: React.FC<temporaryProps> = ({
  colorsMiniature,
  title,
  // colorMiniature,
  // image,
  changeField,
  changeColorMiniature,
}) => (
  <Panel padding>
    <Title title="Миниатюра" />
    <div className={s.blockMiniature}>
      <Label>
        Заголовок
        <Field
          maxLength={35}
          placeholder="35 символов максимум включая пробелы"
          className={s.title}
          view="default"
          value={title}
          onChange={e => changeField(e, 'header')}
        />
      </Label>
      <h2 className={s.chooseTitle}>Загрузить изображение</h2>
      <div style={{ display: 'flex' }}>
        <div style={{ flexBasis: '392px', marginRight: '107px' }}>
          <UploadStories
            style={{ padding: '0px' }}
            // selectedFile={indexSlide === slide.id}
            // slideId={slide.id}
            titleStatus="Файл"
            text="Максимальный размер фото 200 Кб. Формат: png. Размер: 180 х 180 px"
            application=".png"
            subText1="Загрузить с компьютера"
            subText2="или перетащите сюда"
            // status={statusFilePDF}
            uploadId="miniature"
            classNameSubText={s.btnSubText}
          />
        </div>
        <div>
          <div style={{ marginBottom: '10px' }}>
            <p className={s.colorTitle}>Или задайте цвет фона</p>
            <p className={s.colorSubTitle}>
              Убедитесь, что текст хорошо читается
            </p>
          </div>
          <div>
            <div>
              {colorsMiniature?.map((elem: any, i: number) => (
                <div key={i} className={s.blockColors}>
                  {elem.defaultColors?.map((color: any) => (
                    <div
                      key={color.id}
                      className={
                        color.active ? s.colorItemActive : s.blockColorItem
                      }
                      onClick={() => changeColorMiniature(
                        color.id,
                        color.color,
                        'defaultColors',
                        i,
                      )}
                    >
                      <div
                        className={clsx(s.colorItem)}
                        style={{ backgroundColor: color.color }}
                      />
                    </div>
                  ))}
                  {elem.gradientColors?.map((color: any) => (
                    <div
                      key={color.id}
                      className={
                        color.active ? s.colorItemActive : s.blockColorItem
                      }
                      onClick={() => changeColorMiniature(
                        color.id,
                        color.color,
                        'gradientColors',
                        i,
                      )}
                    >
                      <div
                        className={clsx(s.colorItem)}
                        style={{ background: color.color }}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Panel>
)
