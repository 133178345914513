import React, { FC } from 'react'
import s from './ListItem.module.css'

interface ListItem {
  item?: any
}

export const ListItem: FC<ListItem> = ({ item }) => (
  <li className={s.flex__container}>
    <div className={s.list_number}>{'0'.concat(item.order)}</div>
    <div className={s.text__block}>
      <div className={s.top__part}>
        <h3 className={s.title}>{item.title}</h3>
        {item.order !== '7' ? (
          <p className={s.description}>{item.description}</p>
        ) : (
          <>
            <p className={s.description_list_title}>{item.description}</p>
            <ul className={s.description_list}>
              {item.list.map((elem: any) => (
                <li className={s.description_item} key={elem}>
                  {elem}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  </li>
)
