import { createSlice } from '@reduxjs/toolkit'

interface InfoOrg {
	name: string | null
	inn: string | null
	address: string | null
	rs: string | null
	ks: string | null
	bank: string | null
	bik: string | null
}

interface Info {
	email: string | null
	iis: string | null
	inn: string | null
}

interface ClientInfo {
	firstName: string | null
	lastName: string | null
	middleName: string | null
	photo: string | null
	birthDay: string | null
	phone: string | null
	info: Info
	infoOrg: InfoOrg
}

interface ClientSlice {
	clientInfo: ClientInfo
}

const initialState: ClientSlice = {
  clientInfo: {
    firstName: null,
    lastName: null,
    middleName: null,
    photo: null,
    birthDay: null,
    phone: null,
    info: {
      email: null,
      iis: null,
      inn: null,
    },
    infoOrg: {
      name: null,
      inn: null,
      address: null,
      rs: null,
      ks: null,
      bank: null,
      bik: null,
    },
  },
}

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    addClientFields(state, action) {
      state.clientInfo = {
        ...state.clientInfo,
        [action.payload.fieldName]: action.payload.item,
      }
    },
    addInfoField(state, action) {
      state.clientInfo.info = {
        ...state.clientInfo.info,
        [action.payload.fieldName]: action.payload.item,
      }
    },
    addInfoOrgField(state, action) {
      state.clientInfo.infoOrg = {
        ...state.clientInfo.infoOrg,
        [action.payload.fieldName]: action.payload.item,
      }
    },
    resetClientFields(state) {
      state.clientInfo = {
        firstName: null,
        lastName: null,
        middleName: null,
        photo: null,
        birthDay: null,
        phone: null,
        info: {
          email: null,
          iis: null,
          inn: null,
        },
        infoOrg: {
          name: null,
          inn: null,
          address: null,
          rs: null,
          ks: null,
          bank: null,
          bik: null,
        },
      }
    },
  },
})

export const {
  addClientFields,
  addInfoField,
  addInfoOrgField,
  resetClientFields,
} = clientSlice.actions

export default clientSlice.reducer
