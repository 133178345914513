import clsx from 'clsx'
import { ReactNode } from 'react'
import s from './Container.module.css'

type Props = {
  children: ReactNode
  className?: string
}

export const Container = ({ className, children }: Props) => <div className={clsx(s.container, className)}>{children}</div>
