import React, { use, useEffect, useState } from 'react'
import clsx from 'clsx'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { addAct, resetAct, statusPDFAct } from '../../redux/slices/actSlice'
import {
  changeSelect,
  addUsers,
  changeSingleSelect,
  resetObj,
  closeSelect,
  closeOnlySelect,
  statusPDF,
} from '../../redux/slices/selectSlice'
import { ALL_PROJECTS } from '../../apollo/query/allProjects'
import { ALL_BROCKERS } from '../../apollo/query/allBrockers'
import { ALL_CLIENTS } from '../../apollo/query/allClients'
import {
  GET_CONTRACT,
  GET_ACT,
  GET_REPORT,
} from '../../apollo/query/getContract'
import { ALL_CONTRACTS } from '../../apollo/query/allContracts'
import {
  CONTRACT_UPDATE,
  ACT_UPDATE,
  REPORT_UPDATE,
} from '../../apollo/mutation/contractUpdate'
import { NativeSelect } from '../../ui/nativeSelect'
import { Upload } from '../../components/Upload'
import {
  Container,
  Field,
  Label,
  Panel,
  Select,
  DataPicker,
  ProgressBar,
  Title,
  CloseIcon,
  Button,
  Loader,
  NavProjects,
} from '../../ui'
import { arr3, dateReplacements, periodArr } from './data'
import iconSent from './icon-sent.svg'
import iconDraft from './icon-draft.svg'
import s from './EditDoc.module.css'
import { PaymentTable } from '../PaymentTable/PaymentTable'
import flagRussia from '../CreateDoc/img/flag_russia.svg'
import flagUSA from '../CreateDoc/img/flag_usa.svg'
import flagEuro from '../CreateDoc/img/flag_euro.svg'
import flagJapan from '../CreateDoc/img/flag_japan.svg'
import flagChina from '../CreateDoc/img/flag_china.svg'
import iconUSDT from '../CreateDoc/img/icon_usdt.svg'
import {
  addReport,
  resetReport,
  statusPDFReport,
} from '../../redux/slices/reportSlice'

interface EditDocProps {
	params?: string
}

export const EditDoc: React.FC<EditDocProps> = ({ params }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    selNum, singleSelect, information, statusFileXML, statusFilePDF
  } =
		useAppSelector(state => state.selectSlice)
  const { act } = useAppSelector(state => state.actSlice)
  const { report } = useAppSelector(state => state.reportSlice)

  const [showInput, setShowInput] = useState(false)
  const [isContract, setIsContract] = useState('') // значение из поля "Номер договора"
  const [sum, setSum] = useState('') // значение из поля "Сумма вложения"
  const [amount, setAmount] = useState('') // значение из поля "Количество выплат"
  const [percent, setPercent] = useState('') // значение из поля "Процент годовых"
  const [senting, setSenting] = useState(false)
  const [draft, setDraft] = useState(false)
  const docArr = [1, 2, 3] // массив для отображения 3 кастомных select
  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [endDate, setEndDate] = useState<Date | null>(
    new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  ) // +1год
  const [sumField, setSumField] = useState(0) // сумма полей (у нас их 10)
  const [arrayTable, setArrayTable] = useState<any>([])
  const [showTable, setShowTable] = useState(false)
  const [showPaymentPeriod, setShowPaymentPeriod] = useState(false)
  const [showPaymentPeriodValue, setShowPaymentPeriodValue] = useState(0)
  const [monthly, setMonthly] = useState(true)
  const [currency] = useState([
    { img: flagRussia, title: 'Рубль' },
    { img: flagUSA, title: 'Доллар' },
    { img: flagEuro, title: 'Евро' },
    { img: flagJapan, title: 'Иена' },
    { img: flagChina, title: 'Юань' },
    { img: iconUSDT, title: 'USDT' },
  ])
  const currencyList: any = ['Рубль', 'Доллар', 'Евро', 'Иена', 'Юань', 'USDT']
  const currencyListForDispatch = [
    'ruble',
    'dollar',
    'euro',
    'yen',
    'yuan',
    'usdt',
  ]
  const [showCurrencySelect, setShowCurrencySelect] = useState(false)
  const [currencyTitle, setCurrencyTitle] = useState('')
  const [currencyFlag, setCurrencyFlag] = useState('')
  // ндфл есть - нет
  const [radioIncomeTax, setRadioIncomeTax] = useState(false)
  const [sumTable, setSumTable] = useState(0)
  const [paymentDay, setPaymentDay] = useState(0)
  // тип графика
  const [changeGraphic, setChangeGraphic] = useState(true)
  const [dataPickerValue, setDataPickerValue] = useState('')
  // состояние открыт / закрыт
  const [showDatePickerGrid, setShowDatePickerGrid] = useState(false)
  const dataNavTitle = ['Договор', 'Акт', 'Отчет']
  const [navValue, setNavValue] = useState(0)
  const [showToolTip, setShowToolTip] = useState(true)
  const tooltipText = 'название'
  // разворачиваем дополнительное поле active в проектах
  const [selectProject, setSelectedProjects] = useState([])
  const [searchValueClientActContracts, setSearchValueClientActContracts] =
		useState('')
  const [showInputActContracts, setShowInputActContracts] = useState(false)
  const [showSelectClientsActContracts, setShowSelectClientsActContracts] =
		useState(false)
  // тип графика
  const [searchValueClientAct, setSearchValueClientAct] = useState('')
  const [showInputAct, setShowInputAct] = useState(false)
  const [showSelectClientsAct, setShowSelectClientsAct] = useState(false)
  const [actName, setActName] = useState('')
  const [contractsArr, setContractsArr] = useState<any>([])
  const [showSelectAct, setShowSelectAct] = useState(false)
  const [projectTitleAct, setProjectTitleAct] = useState('')
  const [sumAct, setSumAct] = useState('')
  const [startDateAct, setStartDateAct] = useState<Date | null>(new Date())
  const [fileAct, setFileAct] = useState('')
  const [sumFieldAct, setSumFieldAct] = useState(0) // сумма полей (6)
  const [searchValueClient, setSearchValueClient] = useState('')
  // const valuesAct = Object.values(act).map(elem => (elem !== '' ? 20 : 0)) // проверка объекта на его значения
  const valuesAct = Object.values(act).map(elem => (elem !== '' ? 16.66 : 0)) // проверка объекта на его значения

  // день месяца ипортируется из data.ts
  const monthDay = arr3
  // const values = Object.values(information).map(elem => (elem !== '' ? 7.69 : 0)) // проверка объекта на его значения
  const values = Object.values(information).map(elem =>
    elem !== '' ? 7.14 : 0
  ) // проверка объекта на его значения

  const { data: contract, loading: loadingGetContract } = useQuery(
    GET_CONTRACT,
    {
      variables: {
        uuid: params,
      },
    }
  )

  // console.log('arrayTable', arrayTable)

  const {
    loading,
    data: allContracts,
    refetch: refetchAllContracts,
  } = useQuery(ALL_CONTRACTS, {
    // variables: {
    //   slice: {
    //     offset: 0,
    //     limit,
    //   },
    // },
  })

  const [
    isUpdateContract,
    {
      data: isUpdateContractData,
      loading: isLoadingUpdateContract,
      error: isErrorUpdateContract,
    },
  ] = useMutation(CONTRACT_UPDATE, {
    refetchQueries: [
      {
        query: ALL_CONTRACTS,
        variables: {
          filter: {},
        },
      },
      {
        query: GET_CONTRACT,
        variables: {
          uuid: params,
        },
      },
    ],
  })

  const { data: allProjects } = useQuery(ALL_PROJECTS, {
    variables: {
      filter: {
        // status: 'published',
      },
    },
  })
  const { data: allBrockers, error: errorBrocker } = useQuery(ALL_BROCKERS)
  const { data: allClients, refetch } = useQuery(ALL_CLIENTS)

  // добавление валюты
  const currencyDocHandler = (title: string) => {
    const currencyList: any = [
      'Рубль',
      'Доллар',
      'Евро',
      'Иена',
      'Юань',
      'USDT',
    ]
    const currencyListForDispatch = [
      'ruble',
      'dollar',
      'euro',
      'yen',
      'yuan',
      'usdt',
    ]
    dispatch(
      addUsers({
        project: 'currency',
        item: currencyListForDispatch[currencyList.indexOf(title)],
      })
    )
  }

  useEffect(() => {
    if (params) {
      const objGetContract: any = {
        number: contract?.contract.number,
        brokerUUID: contract?.contract.broker.uuid,
        clientUUID: contract?.contract.client.uuid,
        expireOn: contract?.contract.expireOn,
        investSum: contract?.contract.investSum,
        pcYearly: contract?.contract.pcYearly,
        projectUUID: contract?.contract.project.uuid,
        signedOn: contract?.contract.signedOn,
        withdrawalCount: contract?.contract.withdrawalCount,
        withdrawalDay: contract?.contract.withdrawalDay,
        currency: contract?.contract.currency,
        taxStatus: contract?.contract.tax_status,
        paymentSchedule: contract?.contract.paymentSchedule,
        // contractFileName: contract?.contract.contractFileUrl,
        contractFileName: contract?.contract.contractFileUrl.slice(
          contract?.contract.contractFileUrl.lastIndexOf('/') + 1
        ),
      }
      for (const key in objGetContract) {
        dispatch(addUsers({ project: key, item: objGetContract[key] }))
      }
    }
  }, [params, contract])
  // принимаем контракт
  useEffect(() => {
    if (contract?.contract) {
      setIsContract(contract?.contract.number)
      setSum(contract?.contract.investSum)
      setAmount(contract?.contract.withdrawalCount)
      setPercent(contract?.contract.pcYearly)
      setStartDate(new Date(Date.parse(contract?.contract.signedOn)))
      setEndDate(new Date(Date.parse(contract?.contract.expireOn)))
      setArrayTable(contract?.contract.paymentSchedule)
      // setCurrencyTitle(contract?.contract.currency)
      setCurrencyTitle(
        currencyList[
          currencyListForDispatch.indexOf(contract?.contract.currency)
        ]
      )
      setCurrencyFlag(
        currency
          .filter(
            (el: any) =>
              el.title ===
							currencyList[
							  currencyListForDispatch.indexOf(contract?.contract.currency)
							]
          )
          .map((el: any) => el.img)
          .join()
      )
      setRadioIncomeTax(contract?.contract.tax_status)
      setShowPaymentPeriodValue(contract?.contract.withdrawalCount)
      setRadioIncomeTax(contract?.contract.tax_status)
      setSumTable(contract?.contract.investSum)
    }
  }, [contract])

  // console.log('information', information)

  useEffect(() => {
    setSumField(values.reduce((a: any, b: any) => a + b))
  }, [information]) // сложение значений объекта

  useEffect(() => {
    setSumFieldAct(valuesAct.reduce((a: any, b: any) => a + b))
    // setSumField(100)
  }, [act]) // сложение значений объекта

  useEffect(() => {
    if (!allProjects) return
    setSelectedProjects(
      allProjects.projects.map((e: any) => ({ ...e, active: false }))
    )
  }, [allProjects])

  const showPaymentHandler = () => {
    setShowPaymentPeriod(!showPaymentPeriod)
    setAmount(String(showPaymentPeriodValue))
    // dispatch(addUsers({ project: 'withdrawalCount', item: showPaymentPeriodValue }))
  }

  const dispatchwithdrawalCount = (withdrawalCount: number) => {
    dispatch(addUsers({ project: 'withdrawalCount', item: withdrawalCount }))
  }

  const paymentPeriodHandler = () => {
    setMonthly(!monthly)
  }

  const currencyHandler = () => {
    setShowCurrencySelect(!showCurrencySelect)
  }

  const datePickerGridHandler = () => {
    setShowDatePickerGrid(!showDatePickerGrid)
  }

  // добавление дня выплат
  const withdrawalDayHandler = (day: number) => {
    dispatch(addUsers({ project: 'withdrawalDay', item: +day }))
    setPaymentDay(day)
  }

  // тип графика
  const graphicHandler = () => {
    setChangeGraphic(!changeGraphic)
  }

  // страница Акта
  const sumHandlerAct = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sum2 = e.target.value
      .split('')
      .map(i => i.trim())
      .filter(i => i !== '')
      .join('')
    setSumAct(e.target.value)
    dispatch(addAct({ project: 'sum', item: String(sum2) }))
  }

  const draftSaved = (
    <>
      <img src={iconDraft} alt="draft" width="17" height="17" />
      <span style={{ marginLeft: '7px' }}>Черновик сохранен</span>
    </>
  )

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: '7px' }}>Сохранено</span>
    </>
  )

  const handler = (i: number) => {
    dispatch(changeSelect(i))
    if (i === 2) {
      setShowInput(!showInput)
    }
  }

  const contractHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsContract(e.target.value)
    dispatch(addUsers({ project: 'number', item: e.target.value })) // debounce?
  }

  const sumHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sum1 = e.target.value
      .split('')
      .map(i => i.trim())
      .filter(i => i !== '')
      .join('')
    setSum(e.target.value)
    dispatch(addUsers({ project: 'investSum', item: String(sum1) }))
  }

  const taxHandler = () => {
    setRadioIncomeTax(!radioIncomeTax)
    if (radioIncomeTax) {
      dispatch(addUsers({ project: 'taxStatus', item: radioIncomeTax }))
    }
    dispatch(addUsers({ project: 'taxStatus', item: !radioIncomeTax }))
  }

  const amountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value)
    dispatch(addUsers({ project: 'withdrawalCount', item: +e.target.value }))
  }

  const percentHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPercent(e.target.value)
    dispatch(addUsers({ project: 'pcYearly', item: e.target.value }))
  }

  const hidePage = () => {
    navigate('/documents', { replace: true })
    dispatch(resetObj())
    dispatch(statusPDF(null))
    dispatch(statusPDFAct(null))
    dispatch(statusPDFReport(null))
  }

  const selectActHandler = () => {
    setShowSelectAct(!showSelectAct)
  }

  // console.log('arrayTable', arrayTable)

  // сохранение черновика договора
  const handleSaveDraft = () => {
    setDraft(true)
    // gql mutation
    isUpdateContract({
      variables: {
        uuid: params,
        data: {
          ...information,
          // status: 'sent',
          // передаю null т.к. если ничего не передавать будет ошибка
          calendarFileName: null,
          status: 'draft',
          paymentSchedule: JSON.stringify(arrayTable),
        },
      },
    })
    setIsContract('')
    setSum('')
    setAmount('')
    setPercent('')
    dispatch(resetObj())
    if (!isLoadingUpdateContract && isErrorUpdateContract === undefined) {
      setTimeout(() => {
        setDraft(false)
        navigate('/documents', { replace: true })
        // если не обновлять страницу данные не подгружаются
        // window.location.reload()
      }, 2000)
    } else {
      setTimeout(() => {
        setDraft(false)
      }, 2000)
    }
  }

  // сохранение и публикация договора
  const handleSaveSent = () => {
    setSenting(true)
    // gql mutation
    isUpdateContract({
      variables: {
        uuid: params,
        data: {
          ...information,
          status: 'sent',
          // передаю null т.к. если ничего не передавать будет ошибка
          calendarFileName: null,
          // status: 'draft',
          paymentSchedule: JSON.stringify(arrayTable),
        },
      },
    })
    if (!isLoadingUpdateContract || isErrorUpdateContract === undefined) {
      setTimeout(() => {
        navigate('/documents', { replace: true })
        // если не обновлять страницу данные не подгружаются
        // window.location.reload()
      }, 2000)
    } else {
      setTimeout(() => {
        setSenting(true)
      })
    }
  }

  // -----------------------------------------расчета графика выплат-------------------------------------------------
  // формировать график
  const handlerTable = () => {
    setShowTable(true)

    let result = new Array(+amount).fill({
      number: 1,
      percent: 0,
      paymentDate: startDate,
      percentPerYear: percent,
      percentPetMonth: 0,
      percentPerMonthByReduce: 0,
      monthlyPayment: 0,
      sumTable,
      totalPercentPayment: 0,
      ndfl: 0,
      totalNdfl: 0,
      monthlyPaymentToClient: 0,
      totalPaymentToClient: 0,
      returnLoan: 0,
    })
    // let result = contract?.contract.paymentSchedule

    const datesArray: any = []
    const currentDate: any = startDate

    // функция форматирования даты
    const formatDate = (date: any) => {
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0') // Месяцы начинаются с 0
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    }

    // платежи для 28 или 29 числа
    let datesArray29: string[] = []
    const startDateOfMonth: any = startDate?.getDate()
    if (startDateOfMonth === 28 || startDateOfMonth === 29) {
      // месяцы
      let j = 0
      while (j < +amount) {
        const newDate = new Date(currentDate)
        newDate.setMonth(newDate.getMonth() + j + 1)
        newDate.setDate(newDate.getDate() + 1)
        datesArray29.push(formatDate(newDate))
        j += 1
      }
      // заменяю 28, 29 число на случай високосного года.
      datesArray29 = datesArray29.map(
        (el: string) => dateReplacements[el] || el
      )
    }

    // платежи для 30 или 31 числа
    const datesArray30: string[] = []
    const tempArr = []
    const startDateOfMonth30: any = startDate?.getDate()
    if (startDateOfMonth30 === 30 || startDateOfMonth30 === 31) {
      // месяцы
      const firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      )
      let j = 0
      while (j < +amount) {
        const newDate = new Date(firstDayOfMonth)
        newDate.setMonth(newDate.getMonth() + j + 2)
        // newDate.setDate(newDate.getDate() + 1)
        // newDate.setDate(1)
        datesArray30.push(formatDate(newDate))
        j += 1
      }
    }

    // месяцы
    let i = 0
    while (i < +amount) {
      const newDate = new Date(currentDate)
      newDate.setMonth(newDate.getMonth() + i + 1)
      newDate.setDate(newDate.getDate() + 1)
      datesArray.push(formatDate(newDate))
      i += 1
    }

    // если первый платеж в конкретное число
    const datesArrayByHand: any = []
    let differenceInDaysForCalculation = 0
    // первый платежный период
    let firstPaymentPeriod = 0
    if (paymentDay !== 0) {
      const currentDate: any = startDate
      const newDate = new Date(currentDate)
      // прибавляем один месяц
      newDate.setMonth(newDate.getMonth() + 1)
      // устанавливаем день месяца соответсвующий выбранному (в нью дейт передается день месяца)
      const paymentDayNextMonth = newDate.setDate(paymentDay)
      // вспомогательное условие для 31 числа месяцев за которыми следуем более короткий месяц, из-за этого происходит неккоректный расчет количества месяцев
      if (
        (currentDate.getDate() === 31 && currentDate.getMonth() === 7) ||
				(currentDate.getDate() === 31 && currentDate.getMonth() === 9) ||
				(currentDate.getDate() === 31 && currentDate.getMonth() === 0) ||
				(currentDate.getDate() === 31 && currentDate.getMonth() === 2) ||
				(currentDate.getDate() === 31 && currentDate.getMonth() === 4)
      ) {
        newDate.setMonth(newDate.getMonth() - 1)
      }
      // считаю разницу дней между установленным днем и днем заключение контрака,
      // отнимаю -1 т.к. начисление начинается со следующего дня
      const differenceInDays =
				(newDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24) - 1
      differenceInDaysForCalculation = differenceInDays
      // если разница меньшк двацати дней то первая выплата еще через месяц
      if (differenceInDays < 20) {
        // хелпер для расчета певрого процентного периода
        const helperDate = newDate.setMonth(newDate.getMonth() + 1)
        firstPaymentPeriod =
					(helperDate - currentDate.getTime()) / (1000 * 60 * 60 * 24) - 1
      }
      // получаю первый день выплаты процентов
      const paymentDaySetByHand = formatDate(newDate)

      // состовляю платежные даты
      // добавляю первый элемент в массив
      datesArrayByHand.push(paymentDaySetByHand)

      // если меньше 20 дней, то платежных периодом меньше на 1, т.к. первый длиннее,
      if (differenceInDays < 20) {
        let i = 0
        // отнимаю от количества месяцев 1 как первый элемент массива уже есть.
        while (i < +amount - 2) {
          const nextDate = new Date(newDate)
          nextDate.setHours(0, 0, 0, 0)
          nextDate.setMonth(nextDate.getMonth() + i + 1) // nextDate.setDate(nextDate.getDate() + 1)
          datesArrayByHand.push(formatDate(nextDate))
          i += 1
        }
      } else {
        // если больше 20 дней, то платежных периодом равен количеству месяцев
        let i = 0
        // отнимаю от количества месяцев 1 как первый элемент массива уже есть.
        while (i < +amount - 1) {
          const nextDate = new Date(newDate)
          nextDate.setHours(0, 0, 0, 0)
          nextDate.setMonth(nextDate.getMonth() + i + 1) // nextDate.setDate(nextDate.getDate() + 1)
          datesArrayByHand.push(formatDate(nextDate))
          i += 1
        }
      }
    }
    const monthPercent = Math.floor((+percent / 12) * 100) / 100
    const monthlyPercent: number[] = []

    // проценты в месяц
    let j = 1
    while (j < +amount) {
      // если не последний месяц и не 12й
      if (j < +amount && j % 12 !== 0) {
        monthlyPercent.push(monthPercent)
      }
      // если 12й то находим недостачу по году и отправляем в 12 месяц
      if (j % 12 === 0) {
        monthlyPercent.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        )
        const temp =
					+percent * (j / 12) -
					monthlyPercent.reduce(
					  (accumulator, currentValue) => accumulator + currentValue,
					  0
					)
        monthlyPercent.push(temp)
      }
      // последний месяц
      if (j + 1 === +amount) {
        monthlyPercent.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        )
        const some =
					(+percent / 12) * +amount -
					monthlyPercent.reduce(
					  (accumulator, currentValue) => accumulator + currentValue,
					  0
					)
        monthlyPercent.push(some)
      }
      j += 1
    }

    // платежи по процентам
    let k = 0
    const monthlyPercentPayment: number[] = []
    const monthlyNdfl: number[] = []
    // проценты за все время
    while (k < +amount) {
      monthlyPercentPayment.push(sumTable * (monthlyPercent[k] / 100))
      k += 1
    }

    // преобразуем формат в дату
    function createDateFromString(dateString: any) {
      const [day, month, year] = dateString.split('.').map(Number)
      // Месяцы в JavaScript начинаются с 0, поэтому вычитаем 1
      return new Date(year, month - 1, day)
    }
    // входит ли в промежуток
    function isDateInRange(startDate: Date, endDate: Date | any) {
      const leapYear = 2024
      const leapYearStartDate = new Date(leapYear, 1, 29) // 29 февраля 2024 года
      return startDate <= leapYearStartDate && leapYearStartDate <= endDate
    }
    // количество дней в году
    let numberOfDaysInYear = 0
    // расчет процентов если установлена дата

    // определение количества дней в году
    // стандартный расчет
    if (
      changeGraphic &&
			startDateOfMonth !== 28 &&
			startDateOfMonth !== 29 &&
			startDateOfMonth !== 30 &&
			startDateOfMonth !== 31
    ) {
      const lastDate =
				datesArray.length < 12
				  ? datesArray.at(-1)
				  : createDateFromString(datesArray[11])
      numberOfDaysInYear = isDateInRange(currentDate, lastDate) ? 366 : 365
    }
    // 28 и 29
    if (
      changeGraphic &&
			(startDateOfMonth === 28 || startDateOfMonth === 29) &&
			startDateOfMonth !== 30 &&
			startDateOfMonth !== 31
    ) {
      const lastDate =
				datesArray29.length < 12
				  ? datesArray29.at(-1)
				  : createDateFromString(datesArray29[11])
      numberOfDaysInYear = isDateInRange(currentDate, lastDate) ? 366 : 365
    }
    // 30 и 31
    if (
      changeGraphic &&
			startDateOfMonth !== 28 &&
			startDateOfMonth !== 29 &&
			(startDateOfMonth === 30 || startDateOfMonth === 31)
    ) {
      const lastDate =
				datesArray30.length < 12
				  ? datesArray30.at(-1)
				  : createDateFromString(datesArray30[11])
      numberOfDaysInYear = isDateInRange(currentDate, lastDate) ? 366 : 365
    }
    // платеж в определенную дату
    if (!changeGraphic) {
      const lastDate =
				datesArrayByHand.length < 12
				  ? datesArrayByHand.at(-1)
				  : createDateFromString(datesArrayByHand[11])
      numberOfDaysInYear = isDateInRange(currentDate, lastDate) ? 366 : 365
    }
    const percentPerDay = +(+percent / numberOfDaysInYear)

    // массив ежемесячных выплат рассчитанный из количества дней в платежном периоде
    let percentPerDayPayment: any = []
    //
    let totalNdflPerDay: any = []
    // находим первый эл массива разницы в днях, если меньше 20 дней, то берем длинный период, но периодов меньше, если больше, то количество дней
    let differenceInDays =
			differenceInDaysForCalculation < 20
			  ? [firstPaymentPeriod]
			  : [differenceInDaysForCalculation]
    let percentPerMonthByDays: any = []
    let totalPercentForWholePeriod = 0
    let percentPerMonthArray: number[] = []
    if (paymentDay !== 0) {
      const calculateDateDifferences = (dateStrings: string[]) => {
        const dateObjects = dateStrings.map(dateString => {
          const [day, month, year] = dateString.split('.').map(Number)
          return new Date(year, month - 1, day)
        })

        const differences = []
        for (let i = 1; i < dateObjects.length; i += 1) {
          const diffInMilliseconds =
						dateObjects[i].getTime() - dateObjects[i - 1].getTime()
          const diffInDays = Math.round(
            diffInMilliseconds / (1000 * 60 * 60 * 24)
          )
          differences.push(diffInDays)
        }
        return differences
      }
      const differences: number[] = calculateDateDifferences(datesArrayByHand)
      // получаем массив для расчета ставки по месяцам
      differenceInDays = [...differenceInDays, ...differences]
      // const startDateObj = new Date(startDate);
      const endDateObj = new Date(datesArrayByHand[datesArrayByHand.length - 1])
      const roundPercentPerDay = Math.round(percentPerDay * 1000) / 1000
      let percentPerMonthWitoutRoundHelper = differenceInDays.map(
        (el: number) => +(el * percentPerDay)
      )
      percentPerMonthWitoutRoundHelper = percentPerMonthWitoutRoundHelper.slice(
        0,
        12
      )
      const pecentWholePeriodHelper = percentPerMonthWitoutRoundHelper.reduce(
        (accumulator: any, currentValue: any) => accumulator + currentValue,
        0
      )
      percentPerMonthByDays = differenceInDays.map(
        (el: number) => +(el * roundPercentPerDay).toFixed(4)
      )
      totalPercentForWholePeriod =
				Math.round(
				  percentPerMonthByDays.reduce(
				    (accumulator: any, currentValue: any) => accumulator + currentValue,
				    0
				  ) * 100
				) / 100
      const percentForOtherPeriod =
				Math.floor(
				  ((totalPercentForWholePeriod - percentPerMonthByDays[0]) /
						(percentPerMonthByDays.length - 1)) *
						100
				) / 100
      const helperArr = []
      helperArr.push(percentPerMonthByDays[0])
      for (let i = 1; i < differenceInDays.length - 1; i += 1) {
        helperArr.push(percentForOtherPeriod)
      }
      const percentLastPeriod = +(
        pecentWholePeriodHelper -
				helperArr.reduce(
				  (accumulator: any, currentValue: any) => accumulator + currentValue,
				  0
				)
      ).toFixed(2)
      helperArr.push(percentLastPeriod)
      if (helperArr.length <= 12) {
        percentPerMonthArray = [...helperArr]
      } else if (helperArr.length > 12) {
        const helperArr2 = [...helperArr.slice(0, 11)]
        const percentLastPeriod2 =
					pecentWholePeriodHelper -
					helperArr2
					  .reduce(
					    (accumulator: any, currentValue: any) =>
					      accumulator + currentValue,
					    0
					  )
					  .toFixed(2)
        percentPerMonthArray = [...helperArr2]
        percentPerMonthArray.push(percentLastPeriod2)
        const leftPeriodPercent = []
        for (let i = 13; i < differenceInDays.length; i += 1) {
          if (i % 12 !== 0) {
            leftPeriodPercent.push(Math.floor((+percent / 12) * 100) / 100)
          }
          // если 12й то находим недостачу по году и отправляем в 12 месяц
          if (i % 12 === 0) {
            leftPeriodPercent.reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            )
            const temp: any =
							+percent -
							leftPeriodPercent.reduce(
							  (accumulator, currentValue) => accumulator + currentValue,
							  0
							)
            leftPeriodPercent.push(temp)
          }
          // последний месяц
          if (i + 1 === +differenceInDays.length) {
            leftPeriodPercent.reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            )
            const some: any =
							(+percent / 12) * (differenceInDays.length - 12) -
							leftPeriodPercent.reduce(
							  (accumulator, currentValue) => accumulator + currentValue,
							  0
							)
            leftPeriodPercent.push(some)
          }
        }
        percentPerMonthArray = [...percentPerMonthArray, ...leftPeriodPercent]
      }
      // расчет массива ежемесячных выплат береме проценты в процентном периоде и умножаем на сумму займа
      // для плавающих процентов от количества дней
      // percentPerDayPayment = percentPerMonthByDays.map((el:number, index: number) => +(sumTable * (el / 100)).toFixed(2))
      // для равных процентов, кроме первого месяца
      percentPerDayPayment = percentPerMonthArray.map(
        (el: number, index: number) => +(sumTable * (el / 100)).toFixed(2)
      )
      totalNdflPerDay = percentPerMonthByDays.map(
        (el: number) => +(+(sumTable * (el / 100)).toFixed(2) * 0.13).toFixed(0)
      )
    }

    // функция преобразования даты
    function formatDateToISOString(dateString: any, time = '00:00:00') {
      if (dateString === undefined) return undefined
      const [day, month, year] = dateString.split('.')
      const formattedDate = new Date(`${year}-${month}-${day}T${time}Z`)
      return formattedDate.toISOString()
    }

    // функция квартального расчета
    const quarterCalculation = (result: any) => {
      const temp = []
      let helpNumber = 0
      for (let i = 0; i < result.length; i += 3) {
        helpNumber += 1
        const chunk = result.slice(i, i + 3)
        const percent = chunk.reduce(
          (acc: any, currentValue: any) => acc + currentValue.percent,
          0
        )
        const percentPetMonth = chunk.reduce(
          (acc: any, currentValue: any) => acc + currentValue.percentPetMonth,
          0
        )
        const monthlyPayment = chunk.reduce(
          (acc: any, currentValue: any) => acc + currentValue.monthlyPayment,
          0
        )
        const monthlyPaymentToClient = chunk.reduce(
          (acc: any, currentValue: any) =>
            acc + currentValue.monthlyPaymentToClient,
          0
        )
        const ndfl = chunk.reduce(
          (acc: any, currentValue: any) => acc + currentValue.ndfl,
          0
        )
        const newObject = {
          paymentDate: chunk[2].paymentDate,
          percent,
          ndfl,
          monthlyPaymentToClient,
          monthlyPayment,
          number: helpNumber,
          percentPerMonthByReduce: chunk[2].percentPerMonthByReduce,
          percentPerYear: chunk[2].percentPerYear,
          returnLoan: chunk[2].returnLoan,
          sumTable: chunk[2].sumTable,
          totalNdfl: chunk[2].totalNdfl,
          totalPaymentToClient: chunk[2].totalPaymentToClient,
          totalPercentPayment: chunk[2].totalPercentPayment,
          percentPetMonth,
        }
        temp.push(newObject)
      }
      return temp
    }

    // итоговый объект если дата выпплаты не установлена и не 28 или 29 число
    // if (paymentDay === 0 && startDateOfMonth !== 28 && startDateOfMonth !== 29) {
    if (changeGraphic && startDateOfMonth !== 28 && startDateOfMonth !== 29) {
      const totalPercentPayment = +monthlyPercent
        .map((el: any) => Math.round(sumTable * (el / 100) * 100) / 100)
        .reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue,
          0
        )
        .toFixed(2)
      const totalNdfl = monthlyPercent
        .map((el: any) => Math.round(sumTable * (el / 100) * 0.13))
        .reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue,
          0
        )

      result = result.map((el: any, index: number) => ({
        ...el,
        number: index + 1,
        monthPercent,
        percent: monthPercent.toLocaleString('ru-RU').concat('%'),
        paymentDate: datesArray[index],
        percentPetMonth: Math.round(monthlyPercent[index] * 100) / 100,
        monthlyPayment:
					Math.round(sumTable * (monthlyPercent[index] / 100) * 100) / 100,
        sumTable,
        percentPerMonthByReduce: monthlyPercent.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        ),
        totalPercentPayment,
        ndfl: radioIncomeTax
          ? Math.round(sumTable * (monthlyPercent[index] / 100) * 0.13)
          : 0,
        totalNdfl,
        monthlyPaymentToClient: radioIncomeTax
          ? Math.round(
            (sumTable * (monthlyPercent[index] / 100) -
								Math.round(sumTable * (monthlyPercent[index] / 100) * 0.13)) *
								100
					  ) / 100
          : Math.round(sumTable * (monthlyPercent[index] / 100) * 100) / 100,
        totalPaymentToClient: radioIncomeTax
          ? totalPercentPayment - totalNdfl
          : totalPercentPayment,
        returnLoan: index + 1 === result.length ? sumTable : '-',
        return_sum: index + 1 === result.length ? String(sumTable) : '-',
        date: formatDateToISOString(datesArray[index]),
        after_ndfl: radioIncomeTax
          ? Math.round(
            (sumTable * (monthlyPercent[index] / 100) -
								Math.round(sumTable * (monthlyPercent[index] / 100) * 0.13)) *
								100
					  ) / 100
          : Math.round(sumTable * (monthlyPercent[index] / 100) * 100) / 100,
      }))
      // квартальный расчет
      setArrayTable(monthly ? [...result] : [...quarterCalculation(result)])
    }

    // если установлена конкретная дата платежа
    // if (paymentDay !== 0) {
    if (!changeGraphic) {
      const totalPercentPayment = percentPerMonthArray
        .map((el: any) => Math.round(sumTable * (el / 100) * 100) / 100)
        .reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue,
          0
        )
      const totalNdfl = percentPerMonthArray
        .map((el: any) => Math.round(sumTable * (el / 100) * 0.13))
        .reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue,
          0
        )

      result = result.map((el: any, index: number) => ({
        ...el,
        number: index + 1,
        monthPercent,
        percent: (Math.round(percentPerMonthArray[index] * 100) / 100)
          .toLocaleString('ru-RU')
          .concat('%'),
        paymentDate: datesArrayByHand[index],
        percentPetMonth: Math.round(percentPerMonthArray[index] * 100) / 100,
        monthlyPayment:
					Math.round(sumTable * (percentPerMonthArray[index] / 100) * 100) /
					100,
        sumTable,
        percentPerMonthByReduce:
					Math.round(
					  percentPerMonthArray.reduce(
					    (accumulator: any, currentValue: any) =>
					      accumulator + currentValue,
					    0
					  ) * 100
					) / 100,
        totalPercentPayment,
        ndfl: radioIncomeTax
          ? Math.round(percentPerDayPayment[index] * 0.13)
          : 0,
        totalNdfl: radioIncomeTax ? totalNdfl : 0,
        monthlyPaymentToClient: radioIncomeTax
          ? Math.round(sumTable * (percentPerMonthArray[index] / 100) * 100) /
							100 -
					  Math.round(percentPerDayPayment[index] * 0.13)
          : Math.round(sumTable * (percentPerMonthArray[index] / 100) * 100) /
					  100,
        totalPaymentToClient: radioIncomeTax
          ? totalPercentPayment - totalNdfl
          : percentPerDayPayment.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + currentValue
					  ),
        returnLoan: index + 1 === result.length ? sumTable : '-',
        return_sum: index + 1 === result.length ? String(sumTable) : '-',
        date: formatDateToISOString(datesArrayByHand[index]),
        after_ndfl: radioIncomeTax
          ? Math.round(
            (sumTable * (monthlyPercent[index] / 100) -
								Math.round(sumTable * (monthlyPercent[index] / 100) * 0.13)) *
								100
					  ) / 100
          : Math.round(sumTable * (monthlyPercent[index] / 100) * 100) / 100,
      }))
      const helper = [...result]
      result = result.filter((el: any) => el.paymentDate !== undefined)
      // проверка на подставления суммы возврата в послднюю дату платежа
      if (helper.length > result.length) {
        result[result.length - 1].returnLoan = sumTable
      }
      setArrayTable([...result])
      // квартальный расчет
      const temp = []
      let helperArr: any = [...result]
      // находим массив с элементами который делится на 3
      if (result.length % 3 !== 0) {
        helperArr = helperArr.slice(0, result.length - 2)
      }
      let helpNumber = 0
      for (let i = 0; i < helperArr.length; i += 3) {
        helpNumber += 1
        const chunk = helperArr.slice(i, i + 3)
        const percent = chunk.reduce(
          (acc: any, currentValue: any) => acc + currentValue.percent,
          0
        )
        const percentPetMonth = chunk.reduce(
          (acc: any, currentValue: any) => acc + currentValue.percentPetMonth,
          0
        )
        const monthlyPayment = chunk.reduce(
          (acc: any, currentValue: any) => acc + currentValue.monthlyPayment,
          0
        )
        const monthlyPaymentToClient = chunk.reduce(
          (acc: any, currentValue: any) =>
            acc + currentValue.monthlyPaymentToClient,
          0
        )
        const ndfl = chunk.reduce(
          (acc: any, currentValue: any) => acc + currentValue.ndfl,
          0
        )
        const newObject = {
          paymentDate: chunk[2].paymentDate,
          percent,
          ndfl,
          monthlyPaymentToClient,
          monthlyPayment,
          number: helpNumber,
          percentPerMonthByReduce: chunk[2].percentPerMonthByReduce,
          percentPerYear: chunk[2].percentPerYear,
          returnLoan: chunk[2].returnLoan,
          sumTable: chunk[2].sumTable,
          totalNdfl: chunk[2].totalNdfl,
          totalPaymentToClient: chunk[2].totalPaymentToClient,
          totalPercentPayment: chunk[2].totalPercentPayment,
          percentPetMonth,
        }
        temp.push(newObject)
      }
      setArrayTable([...temp])
      // находим остаток от массива в котором меньше 3х элементов
      let lastChunk = []
      if (result.length % 3 !== 0) {
        lastChunk = result.slice(-2)
        const percent = lastChunk.reduce(
          (acc: any, currentValue: any) => acc + currentValue.percent,
          0
        )
        const percentPetMonth = lastChunk.reduce(
          (acc: any, currentValue: any) => acc + currentValue.percentPetMonth,
          0
        )
        const monthlyPayment = lastChunk.reduce(
          (acc: any, currentValue: any) => acc + currentValue.monthlyPayment,
          0
        )
        const monthlyPaymentToClient = lastChunk.reduce(
          (acc: any, currentValue: any) =>
            acc + currentValue.monthlyPaymentToClient,
          0
        )
        const ndfl = lastChunk.reduce(
          (acc: any, currentValue: any) => acc + currentValue.ndfl,
          0
        )
        const newObject = {
          paymentDate: lastChunk[1].paymentDate,
          percent,
          ndfl,
          monthlyPaymentToClient,
          monthlyPayment,
          number: helpNumber + 1,
          percentPerMonthByReduce: lastChunk[1].percentPerMonthByReduce,
          percentPerYear: lastChunk[1].percentPerYear,
          returnLoan: lastChunk[1].returnLoan,
          sumTable: lastChunk[1].sumTable,
          totalNdfl: lastChunk[1].totalNdfl,
          totalPaymentToClient: lastChunk[1].totalPaymentToClient,
          totalPercentPayment: lastChunk[1].totalPercentPayment,
          percentPetMonth,
        }
        temp.push(newObject)
      }
      setArrayTable(monthly ? [...result] : [...temp])
    }

    // если 29 или 28 число
    // if (paymentDay === 0 && (startDateOfMonth === 28 || startDateOfMonth === 29)) {
    if (changeGraphic && (startDateOfMonth === 28 || startDateOfMonth === 29)) {
      const totalPercentPayment = +monthlyPercent
        .map((el: any) => Math.round(sumTable * (el / 100) * 100) / 100)
        .reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue,
          0
        )
        .toFixed(2)
      const totalNdfl = monthlyPercent
        .map((el: any) => Math.round(sumTable * (el / 100) * 0.13))
        .reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue,
          0
        )
      result = result.map((el: any, index: number) => ({
        ...el,
        number: index + 1,
        monthPercent,
        percent: monthPercent.toLocaleString('ru-RU').concat('%'),
        paymentDate: datesArray29[index],
        percentPetMonth: Math.round(monthlyPercent[index] * 100) / 100,
        monthlyPayment:
					Math.round(sumTable * (monthlyPercent[index] / 100) * 100) / 100,
        sumTable,
        percentPerMonthByReduce: monthlyPercent.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        ),
        // totalPercentPayment: monthlyPercentPayment.reduce((accumulator, currentValue) => accumulator + currentValue, 0),
        totalPercentPayment,
        ndfl: radioIncomeTax
          ? Math.round(sumTable * (monthlyPercent[index] / 100) * 0.13)
          : 0,
        // totalNdfl: radioIncomeTax ? monthlyPercentPayment.reduce((accumulator, currentValue) => accumulator + currentValue, 0) * 0.13 : 0,
        totalNdfl,
        monthlyPaymentToClient: radioIncomeTax
          ? Math.round(
            (sumTable * (monthlyPercent[index] / 100) -
								Math.round(sumTable * (monthlyPercent[index] / 100) * 0.13)) *
								100
					  ) / 100
          : Math.round(sumTable * (monthlyPercent[index] / 100) * 100) / 100,
        totalPaymentToClient: radioIncomeTax
          ? totalPercentPayment - totalNdfl
          : totalPercentPayment,
        returnLoan: index + 1 === result.length ? sumTable : '-',
        return_sum: index + 1 === result.length ? String(sumTable) : '-',
        date: formatDateToISOString(datesArray[index]),
        after_ndfl: radioIncomeTax
          ? Math.round(
            (sumTable * (monthlyPercent[index] / 100) -
								Math.round(sumTable * (monthlyPercent[index] / 100) * 0.13)) *
								100
					  ) / 100
          : Math.round(sumTable * (monthlyPercent[index] / 100) * 100) / 100,
      }))
      setArrayTable(monthly ? [...result] : [...quarterCalculation(result)])
    } // если 30 или 31 число
    // if (paymentDay === 0 && (startDateOfMonth === 30 || startDateOfMonth === 31)) {
    if (changeGraphic && (startDateOfMonth === 30 || startDateOfMonth === 31)) {
      const totalPercentPayment = monthlyPercent
        .map((el: any) => Math.round(sumTable * (el / 100) * 100) / 100)
        .reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue,
          0
        )
      const totalNdfl = monthlyPercent
        .map((el: any) => Math.round(sumTable * (el / 100) * 0.13))
        .reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue,
          0
        )

      result = result.map((el: any, index: number) => ({
        ...el,
        number: index + 1,
        monthPercent,
        percent: monthPercent.toLocaleString('ru-RU').concat('%'),
        paymentDate: datesArray30[index],
        percentPetMonth: Math.round(monthlyPercent[index] * 100) / 100,
        monthlyPayment:
					Math.round(sumTable * (monthlyPercent[index] / 100) * 100) / 100,
        sumTable,
        percentPerMonthByReduce: monthlyPercent.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        ),
        // totalPercentPayment: monthlyPercentPayment.reduce((accumulator, currentValue) => accumulator + currentValue, 0),
        totalPercentPayment,
        ndfl: radioIncomeTax
          ? Math.round(sumTable * (monthlyPercent[index] / 100) * 0.13)
          : 0,
        // totalNdfl: radioIncomeTax ? monthlyPercentPayment.reduce((accumulator, currentValue) => accumulator + currentValue, 0) * 0.13 : 0,
        totalNdfl,
        monthlyPaymentToClient: radioIncomeTax
          ? Math.round(
            (sumTable * (monthlyPercent[index] / 100) -
								Math.round(sumTable * (monthlyPercent[index] / 100) * 0.13)) *
								100
					  ) / 100
          : Math.round(sumTable * (monthlyPercent[index] / 100) * 100) / 100,
        totalPaymentToClient: radioIncomeTax
          ? totalPercentPayment - totalNdfl
          : totalPercentPayment,
        returnLoan: index + 1 === result.length ? sumTable : '-',
        return_sum: index + 1 === result.length ? String(sumTable) : '-',
        after_ndfl: radioIncomeTax
          ? Math.round(
            (sumTable * (monthlyPercent[index] / 100) -
								Math.round(sumTable * (monthlyPercent[index] / 100) * 0.13)) *
								100
					  ) / 100
          : Math.round(sumTable * (monthlyPercent[index] / 100) * 100) / 100,
      }))
      setArrayTable(monthly ? [...result] : [...quarterCalculation(result)])
    }

    // последний день платежа
    setEndDate(createDateFromString(result[result.length - 1].paymentDate))
    const endDate = createDateFromString(result[result.length - 1].paymentDate)
    dispatch(addUsers({ project: 'expireOn', item: endDate.toISOString() }))
    // объект с графиком
    dispatch(addUsers({ project: 'paymentSchedule', item: result }))
    // день платежа, если стандратный расчет
    if (changeGraphic) {
      dispatch(addUsers({ project: 'withdrawalDay', item: 0 }))
    }
  }

  // -----------------------------------------конец расчета графика выплат-------------------------------------------------
  const [sumFieldReport, setSumFieldReport] = useState(0) // сумма полей (6)
  const [projectTitleReport, setProjectTitleReport] = useState('')
  const valuesReport = Object.values(report).map(elem => (elem !== '' ? 50 : 0)) // проверка объекта на его значения
  useEffect(() => {
    setSumFieldReport(valuesReport.reduce((a: any, b: any) => a + b))
    // setSumField(100)
  }, [act]) // сложение значений объекта

  // запрос на репорт
  const { data: reportData, loading: loadingGetReport } = useQuery(GET_REPORT, {
    variables: {
      // uuid: params,
      uuid: 'ca0fa57d-e2b8-4ac3-ab54-dbea3c65f2b1',
    },
  })

  const [
    updateReport,
    {
      data: updateReportData,
      loading: loadingUpdateReport,
      error: errorUpdateReport,
    },
  ] = useMutation(REPORT_UPDATE)

  useEffect(() => {
    if (reportData) {
      const objGetReport: any = {
        projectUUID: reportData.report.project.uuid,
        clientUUID: reportData.report.client.uuid,
        file: reportData.report.file,
      }
      for (const key in objGetReport) {
        dispatch(addAct({ project: key, item: objGetReport[key] }))
      }
    }
  }, [reportData])

  // принимаем акт
  useEffect(() => {
    if (reportData?.report) {
      setProjectTitleReport(reportData?.report.project.name)
    }
  }, [reportData])

  const [showSelectReport, setShowSelectReport] = useState(false)
  const selectReportHandler = () => {
    setShowSelectReport(!showSelectReport)
  }
  // передаю номер uuid Проекта для формирования объекта
  const dispatchReportUUIDProject = (UUIDProject: string) => {
    dispatch(addReport({ project: 'projectUUID', item: UUIDProject }))
  }

  // сохранение черновика договора
  const handleSaveDraftReport = () => {
    setDraft(true)
    // gql mutation
    updateReport({
      variables: {
        uuid: 'd6f937e3-c65e-4c30-9d10-ae8edfe7be4f',
        data: {
          ...report,
          status: 'draft',
          file: 'null',
        },
      },
    })
    // setIsContract('')
    // setSum('')
    // setAmount('')
    // setPercent('')
    // dispatch(resetObj())
    dispatch(resetReport())
    if (!loadingUpdateReport && errorUpdateReport === undefined) {
      setTimeout(() => {
        setDraft(false)
        hidePage()
      }, 2000)
    } else {
      setTimeout(() => {
        setDraft(false)
      }, 2000)
    }
  }

  // сохранение и публикация договора
  const handleSaveSentReport = () => {
    setSenting(true)
    // gql mutation
    updateReport({
      variables: {
        uuid: 'd6f937e3-c65e-4c30-9d10-ae8edfe7be4f',
        data: {
          ...report,
          file: 'null',
          status: 'sent',
        },
      },
    })
    if (!loadingUpdateReport || errorUpdateReport === undefined) {
      setTimeout(() => {
        hidePage()
      }, 2000)
    } else {
      setTimeout(() => {
        setSenting(true)
      })
    }
  }

  if (loadingGetContract) {
    // return <h2>Loading...</h2>
    return <Loader className={s.loader} />
  }

  return (
    <Container className={s.container}>
      <CloseIcon onClick={hidePage} />
      <Title className={s.title} title="Редактировать документ" />
      {/* <NavProjects
        className={s.promotion_type}
        setNavValue={setNavValue}
        navValue={navValue}
        dataNavTitle={dataNavTitle}
      /> */}
      {navValue === 0 && (
        <div className={s.wrapper}>
          <div className={s.first__block}>
            <Panel padding className={s.panel}>
              <Label title="Номер договора">
                <Field
                  view="default"
                  placeholder="Номер договора"
                  value={isContract}
                  onChange={e => contractHandler(e)}
                />
              </Label>
              {docArr.map(e => (
                <Label
                  key={e}
                  title={e === 1 ? 'Проект' : e === 2 ? 'Клиент' : 'Брокер'}
                >
                  {contract?.contract && (
                    <Select
                      refetch={e === 2 ? refetch : null}
                      defaultTitle={
                        e === 1
                          ? `${contract?.contract.project.name}`
                          : e === 2
                            ? `${`${contract?.contract.client.lastName} ${contract?.contract.client.firstName} ${contract?.contract.client.middleName}`}`
                            : `${contract?.contract.broker.name}`
                      }
                      setSearchValueClient={
                        e === 2 ? setSearchValueClient : null
                      }
                      searchValueClient={e === 2 ? searchValueClient : null}
                      setShowInput={e === 2 ? setShowInput : null}
                      showInput={e === 2 ? showInput : null}
                      tags={e === 1}
                      user={
                        e === 1
                          ? allProjects?.projects
                          : e === 2
                            ? allClients?.clients
                            : allBrockers?.brokers
                      }
                      show={selNum === e}
                      onClick={() => handler(e)}
                      draft={draft}
                    />
                  )}
                </Label>
              ))}
              <Label title="Валюта">
                <NativeSelect
                  pledgeArr={currency}
                  currency
                  show={showCurrencySelect}
                  draft={draft}
                  onClick={currencyHandler}
                  setShowCurrencySelect={setShowCurrencySelect}
                  currencyDocHandler={currencyDocHandler}
                  currencyTitle={currencyTitle}
                  currencyFlag={currencyFlag}
                />
              </Label>
              <div className={s.inputRadioBlock}>
                <p className={s.inputRadioTitle}>Налоговый статус:</p>
                <div className={s.radio_btn_wrapper}>
                  <Label className={s.radioBtnBlock}>
                    <input
                      className={s.radioItem}
                      id="incomeTax"
                      type="radio"
                      value="C НДФЛ"
                      // checked={slide.positionTitle === 'сверху'}
                      checked={radioIncomeTax}
                      // onChange={e => changeField(e, slide.id, 'positionTitle')}
                      onChange={taxHandler}
                    />
                    <label htmlFor="incomeTax">C НДФЛ</label>
                  </Label>
                  <Label className={s.radioBtnBlock}>
                    <input
                      className={s.radioItem}
                      id="no-incomeTax"
                      type="radio"
                      value="Без НДФЛ"
                      // checked={slide.positionTitle === 'снизу'}
                      checked={!radioIncomeTax}
                      onChange={taxHandler}
                    />
                    <label htmlFor="no-incomeTax">Без НДФЛ</label>
                  </Label>
                </div>
              </div>
            </Panel>
            <Panel padding className={s.second__panel}>
              <Label
                title="Дата подписания"
                onClick={() => dispatch(closeOnlySelect())}
              >
                <DataPicker
                  className={s.data__picker}
                  startDate={startDate}
                  onChange={date => {
                    setStartDate(date)
                    if (date) {
                      dispatch(
                        addUsers({
                          project: 'signedOn',
                          item: date.toISOString(),
                        })
                      )
                    }
                  }}
                />
              </Label>
              {/* <Label
              title="Дата окончания"
              onClick={() => dispatch(closeOnlySelect())}
            >
              <DataPicker
                className={s.data__picker}
                startDate={endDate}
                onChange={date => {
                  setEndDate(date)
                  if (date) {
                    dispatch(
                      addUsers({
                        project: 'expireOn',
                        item: date.toISOString(),
                      }),
                    )
                  }
                }}
              />
            </Label> */}
              <Label title="Сумма вложения">
                <Field
                  view="default"
                  placeholder="Сумма вложения"
                  value={
                    !sum
                      ? sum
                      : `${Number(sum.replace(/\D/g, '')).toLocaleString('ru')}`
                  }
                  currency={!!sum}
                  caretPos={390 - sum.length * 8.8}
                  onChange={e => sumHandler(e)}
                />
              </Label>
              {/* <Label title="Количество выплат">
              <Field
                type="number"
                view="default"
                placeholder="Количество выплат"
                value={amount}
                onChange={e => amountHandler(e)}
              />
            </Label> */}
              <Label title="Срок займа:">
                <NativeSelect
                  pledgeArr={periodArr}
                  paymentPeriod
                  show={showPaymentPeriod}
                  setShowPaymentPeriod={setShowPaymentPeriod}
                  showPaymentPeriod={showPaymentPeriod}
                  setShowPaymentPeriodValue={setShowPaymentPeriodValue}
                  showPaymentPeriodValue={showPaymentPeriodValue}
                  setAmount={setAmount}
                  dispatchwithdrawalCount={dispatchwithdrawalCount}
                  onClick={() => showPaymentHandler()}
                />
              </Label>
              <div className={s.inputRadioBlock}>
                <p className={s.inputRadioTitle}>Выплаты:</p>
                <div className={s.radio_btn_wrapper}>
                  <Label className={s.radioBtnBlock}>
                    <input
                      className={s.radioItem}
                      id="monthly"
                      type="radio"
                      value="monthly"
                      // checked={slide.positionTitle === 'сверху'}
                      checked={monthly}
                      // onChange={e => changeField(e, slide.id, 'positionTitle')}
                      onChange={paymentPeriodHandler}
                    />
                    <label htmlFor="monthly">Ежемесячно</label>
                  </Label>
                  <Label className={s.radioBtnBlock}>
                    <input
                      className={s.radioItem}
                      id="quarterly"
                      type="radio"
                      value="quarterly"
                      // checked={slide.positionTitle === 'снизу'}
                      checked={!monthly}
                      onChange={paymentPeriodHandler}
                    />
                    <label htmlFor="quarterly">Ежеквартально</label>
                  </Label>
                </div>
              </div>
              <Label title="Процент годовых">
                <Field
                  type="number"
                  view="default"
                  placeholder="Процент годовых"
                  // value={String((+percent).toFixed(2))}
                  value={+percent}
                  onChange={e => percentHandler(e)}
                />
              </Label>
              <div className={s.inputRadioBlock}>
                <p className={s.inputRadioTitle_graphic}>Даты:</p>
                <div className={s.radio_btn_wrapper}>
                  <Label className={s.radioBtnBlock}>
                    <input
                      className={s.radioItem}
                      id="standart"
                      type="radio"
                      value="авто"
                      // checked={slide.positionTitle === 'сверху'}
                      checked={changeGraphic}
                      // onChange={e => changeField(e, slide.id, 'positionTitle')}
                      onChange={graphicHandler}
                    />
                    <label htmlFor="standart">Авто</label>
                  </Label>
                  <Label className={s.radioBtnBlock}>
                    <input
                      className={s.radioItem}
                      id="nonstandart"
                      type="radio"
                      value="выбрать"
                      // checked={slide.positionTitle === 'снизу'}
                      checked={!changeGraphic}
                      onChange={graphicHandler}
                    />
                    <label htmlFor="nonstandart">Выбрать</label>
                  </Label>
                </div>
              </div>
              {!changeGraphic && (
                <Label title="Дата выплат">
                  <NativeSelect
                    // pledgeArr={[]}
                    datePickerGrid
                    dataPickerValue={dataPickerValue}
                    setDatePickerValue={setDataPickerValue}
                    monthDay={monthDay}
                    showDatePickerGrid={showDatePickerGrid}
                    show={showDatePickerGrid}
                    setShowDatePickerGrid={setShowDatePickerGrid}
                    onClick={() => datePickerGridHandler()}
                    withdrawalDayHandler={withdrawalDayHandler}
                  />
                </Label>
              )}
              {/*  поменять на datapicker */}
              {/* {contract?.contract && (
              <Label title="Дата выплат">
                <Select
                  defaultTitle={contract?.contract.withdrawalDay}
                  className={s.payment__day}
                  user={arr3}
                  show={singleSelect}
                  onClick={() => dispatch(changeSingleSelect())}
                  draft={draft}
                />
              </Label>
            )} */}
              <Button
                className={s.btns__make_schedual}
                theme={Math.ceil(sumField) < 72 ? 'secondary' : 'dark'}
                disabled={Math.ceil(sumField) < 72}
                onClick={handlerTable}
              >
                Сформировать график выплат
              </Button>
              {/* <PaymentTable arrayTable={arrayTable} showTable={showTable} radioIncomeTax={radioIncomeTax} /> */}
              {/* <PaymentTable arrayTable={arrayTable} showTable radioIncomeTax={radioIncomeTax} /> */}
              {arrayTable[0]?.sum === '' ? null : (
                <PaymentTable
                  arrayTable={arrayTable}
                  showTable
                  radioIncomeTax={radioIncomeTax}
                />
              )}
            </Panel>
            {/* <Upload
            title="Загрузить график"
            titleStatus="График к"
            text="Формат файла: .csv"
            application=".csv"
            subText1="Загрузить с компьютера"
            subText2="или перетащите сюда"
            status={statusFileXML}
            uploadId="csv"
          /> */}
            <Upload
              title="Загрузить файл"
              titleStatus="Файл"
              text="Формат файла: .pdf"
              application="application/pdf"
              subText1="Загрузить с компьютера"
              subText2="или перетащите сюда"
              status={statusFilePDF}
              uploadId="pdf"
              fileUrl={contract?.contract.contractFileUrl}
            />
            <Panel className={s.btns__panel}>
              <Button
                onClick={handleSaveDraft}
                // disabled={Math.ceil(sumField) !== 100}
                disabled={Math.ceil(sumField) < 90}
                className={clsx(s.btns__panel_draft)}
                theme="outline"
              >
                {!draft ? (
                  'Сохранить как черновик'
                ) : isLoadingUpdateContract ? (
                  <Loader />
                ) : !isLoadingUpdateContract && isErrorUpdateContract ? (
                  'Ошибка'
                ) : (
                  draftSaved
                )}
              </Button>
              <Button
                onClick={handleSaveSent}
                disabled={Math.ceil(sumField) !== 100}
                className={s.btns__panel_save}
                theme="dark"
              >
                {!senting ? (
                  'Сохранить и опубликовать'
                ) : isLoadingUpdateContract ? (
                  <Loader />
                ) : !isLoadingUpdateContract && isErrorUpdateContract ? (
                  'Ошибка'
                ) : (
                  sentSaved
                )}
              </Button>
            </Panel>
          </div>
          <Panel className={s.panel__progress} padding>
            <ProgressBar value={Math.ceil(sumField)} />
          </Panel>
        </div>
      )}
    </Container>
  )
}
