import { useEffect, useState, FC } from 'react'
import { useQuery } from '@apollo/client'
import { GET_MAINPAGE } from '../../../apollo/query/editSite/mainPage'
import { Container } from './Container/Container'
import { Banner } from './Banner/Banner'
import { Purpose } from './Purpose/Purpose'
import { AboutProject } from './AboutProject/AboutProject'
import { InfoList } from './InfoList/InfoList'
import { Gallery } from './Gallery/Gallery'
import { OtherProjects } from './OtherProjects/OtherProjects'
import { LastScreen } from '../lastScreen/LastScreen'
import close from '../assets/close_icon.svg'
import s from './ProjectPage.module.css'
import { Section } from './Section/Section'

interface ProjectPage {
  width: number
  isTurnDevice?: boolean
  clientWidth?: number
  setPreviewToggler: (arg: any) => void
  projectInfo?: any
  logo?: string
  bannerApp?: string
  bannerSite?: string
  background?: string
  pledgeArrForState?: any
  arrUpload?: any
}

export const ProjectPage: FC <ProjectPage> = ({
  width,
  isTurnDevice,
  clientWidth,
  setPreviewToggler,
  projectInfo,
  logo,
  bannerApp,
  bannerSite,
  background,
  pledgeArrForState,
  arrUpload,
}) => {
  const [isGallery, setIsGallery] = useState(false)
  const [arrGallery, setArrGallery] = useState([])
  const [data, setData] = useState({})
  const { loading, error, data: dataMainPage } = useQuery(GET_MAINPAGE)

  useEffect(() => {
    // если данные не пришли
    if (!dataMainPage) return
    setData({
      quote: dataMainPage?.page?.quote,
      title: dataMainPage?.page?.title,
      caption: dataMainPage?.page?.caption,
    })
  }, [dataMainPage])

  // useEffect(() => {
  //   // Applying on mount
  //   document.body.style.overflow = "scroll"
  //   // Applying on unmount
  //   return () => {
  //     document.body.style.overflow = "hidden"
  //   }
  // }, [])

  return (
    <div className={s.wrapper} onClick={e => e.stopPropagation()}>
      <button className="close" type="button" onClick={() => setPreviewToggler?.(false)}>
        <img src={close} alt="close" />
      </button>
      <div
        className={s.page__wrapper}
      >
        <Container className={s.project__wrapper} id="returnToTop">
          <Banner project={projectInfo} logo={logo} bannerApp={bannerApp} bannerSite={bannerSite} background={background} />
          <InfoList project={projectInfo} pledgeArrForState={pledgeArrForState} />
          <Purpose project={projectInfo} />
          <AboutProject project={projectInfo} />
          {arrUpload.length > 0 && (
            <Gallery
              width={width}
              project={projectInfo}
              // isGallery={isGallery}
              arrGallery={arrUpload}
              // // isTurnDevice={isTurnDevice}
              // clientWidth={clientWidth}
            />
          )}
          <Section className={s.section__margin}>
            <LastScreen data={data} />
          </Section>
          <OtherProjects
            width={width}
            isTurnDevice={isTurnDevice}
            clientWidth={clientWidth}
          />
        </Container>
      </div>
    </div>
  )
}
