/* eslint-disable */

import React, { useState, useEffect, ChangeEvent } from 'react'
import clsx from 'clsx'
import { Panel, Field, Label, TypeToggler } from '../../ui'
import s from './EditMainPageSelect.module.css'

interface SelectType {
  className?: string
  onClick?: () => void
  // onClick?: (e:any) => any
  onBlur?: () => void
  changeYear?: (arg: ChangeEvent<HTMLInputElement>) => void
  changeInvestorProfit?: (arg: ChangeEvent<HTMLInputElement>) => void
  changeReturnOnInvestment?: (arg: ChangeEvent<HTMLInputElement>) => void
  changeReinvest?: (arg: ChangeEvent<HTMLInputElement>) => void
  changeInvestorInvested?: (arg: ChangeEvent<HTMLInputElement>) => void
  changeTransactionsCompleted?: (arg: ChangeEvent<HTMLInputElement>) => void
  changeAverageInvestorCheck?: (arg: ChangeEvent<HTMLInputElement>) => void
  remove?: boolean
  data?: any
  index?: any
  idIndex?: any
  deleteYear?: any
  uniqId?: string
  investorProfit?: string
  returnOnInvestment?: any
  reinvest?: string
  investorInvested: string
  transactionsCompleted: string
  averageInvestorCheck: string
  investorProfitType: string
  returnOnInvestmentType?: string
  reinvestType?: string
  investorInvestedType?: string
  transactionsCompletedType?: string
  averageInvestorCheckType?: string
  clickHandler?: (arg1: string, arg2: string, arg3: number) => void
}
export const EditMainPageSelect: React.FC<SelectType> = ({
  className,
  onClick,
  onBlur,
  remove,
  data,
  index,
  changeYear,
  idIndex,
  deleteYear,
  uniqId,
  changeInvestorProfit,
  investorProfit,
  changeReturnOnInvestment,
  returnOnInvestment,
  changeReinvest,
  reinvest,
  changeInvestorInvested,
  investorInvested,
  changeTransactionsCompleted,
  transactionsCompleted,
  changeAverageInvestorCheck,
  averageInvestorCheck,
  investorProfitType,
  returnOnInvestmentType,
  reinvestType,
  investorInvestedType,
  transactionsCompletedType,
  averageInvestorCheckType,
  clickHandler,
}) => {
  const [year, setYear] = useState('')
  const [value, setValue] = useState<string | number>('')
  const arr = [
    { id: 1, value: 'млн ₽' },
    { id: 2, value: 'млд ₽' },
    { id: 3, value: '%' },
    { id: 4, value: ' ₽' },
    { id: 4, value: 'без значения' },
  ]
  const [num, setNum] = useState(0)
  const [numReturnOnInvestment, setNumReturnOnInvestmentreturnOnInvestment] =
    useState(0)
  const [numReinvest, setNumReinvest] = useState(0)
  const [numInvestorInvested, setNumInvestorInvested] = useState(0)
  const [numTransactionsCompleted, setNumTransactionsCompleted] = useState(0)
  const [numAverageInvestorCheck, setNumAverageInvestorCheck] = useState(0)

  useEffect(() => {
    if (data) {
      setValue(data[index].year)
      setYear(data[index].year)
    }
  }, [data])

  const [toggler, setToggler] = useState(false)
  const [show, setShow] = useState('')
  // const index = pledgeArrForState?.findIndex((el: any) => el.id === idChange)

  const changeToogler = () => setToggler(!toggler)

  const clickPlusHandler = (e: any) => {
    const uniqId = e.target.parentElement.id
    const fieldName = e.target.id
    if (fieldName === 'investorProfit') {
      if (num === 4) {
        setNum(0)
      } else {
        setNum(num + 1)
      }
      clickHandler?.(uniqId, fieldName, num)
    }
    if (fieldName === 'returnOnInvestment') {
      if (numReturnOnInvestment === 4) {
        setNumReturnOnInvestmentreturnOnInvestment(0)
      } else {
        setNumReturnOnInvestmentreturnOnInvestment(numReturnOnInvestment + 1)
      }
      clickHandler?.(uniqId, fieldName, numReturnOnInvestment)
    }
    if (fieldName === 'reinvest') {
      if (numReinvest === 4) {
        setNumReinvest(0)
      } else {
        setNumReinvest(numReinvest + 1)
      }
      clickHandler?.(uniqId, fieldName, numReinvest)
    }
    if (fieldName === 'investorInvested') {
      if (numInvestorInvested === 4) {
        setNumInvestorInvested(0)
      } else {
        setNumInvestorInvested(numInvestorInvested + 1)
      }
      clickHandler?.(uniqId, fieldName, numInvestorInvested)
    }
    if (fieldName === 'transactionsCompleted') {
      if (numTransactionsCompleted === 4) {
        setNumTransactionsCompleted(0)
      } else {
        setNumTransactionsCompleted(numTransactionsCompleted + 1)
      }
      clickHandler?.(uniqId, fieldName, numTransactionsCompleted)
    }
    if (fieldName === 'averageInvestorCheck') {
      if (numAverageInvestorCheck === 4) {
        setNumAverageInvestorCheck(0)
      } else {
        setNumAverageInvestorCheck(numAverageInvestorCheck + 1)
      }
      clickHandler?.(uniqId, fieldName, numAverageInvestorCheck)
    }
  }

  const clickMinusHandler = (e: any) => {
    const uniqId = e.target.parentElement.id
    const fieldName = e.target.id
    if (fieldName === 'investorProfit') {
      if (num === 0) {
        setNum(4)
      } else {
        setNum(num - 1)
      }
      clickHandler?.(uniqId, fieldName, num)
    }
    if (fieldName === 'returnOnInvestment') {
      if (numReturnOnInvestment === 0) {
        setNumReturnOnInvestmentreturnOnInvestment(4)
      } else {
        setNumReturnOnInvestmentreturnOnInvestment(numReturnOnInvestment - 1)
      }
      clickHandler?.(uniqId, fieldName, numReturnOnInvestment)
    }
    if (fieldName === 'reinvest') {
      if (numReinvest === 0) {
        setNumReinvest(4)
      } else {
        setNumReinvest(numReinvest - 1)
      }
      clickHandler?.(uniqId, fieldName, numReinvest)
    }
    if (fieldName === 'investorInvested') {
      if (numInvestorInvested === 0) {
        setNumInvestorInvested(4)
      } else {
        setNumInvestorInvested(numInvestorInvested - 1)
      }
      clickHandler?.(uniqId, fieldName, numInvestorInvested)
    }
    if (fieldName === 'transactionsCompleted') {
      if (numTransactionsCompleted === 0) {
        setNumTransactionsCompleted(4)
      } else {
        setNumTransactionsCompleted(numTransactionsCompleted - 1)
      }
      clickHandler?.(uniqId, fieldName, numTransactionsCompleted)
    }
    if (fieldName === 'averageInvestorCheck') {
      if (numAverageInvestorCheck === 0) {
        setNumAverageInvestorCheck(4)
      } else {
        setNumAverageInvestorCheck(numAverageInvestorCheck - 1)
      }
      clickHandler?.(uniqId, fieldName, numAverageInvestorCheck)
    }
  }

  return (
    <div className={s.wrapper}>
      <button
        className={clsx(s.dropdownBtn, toggler ? s.active : '', className)}
        type="button"
        onBlur={onBlur}
        onClick={changeToogler}
      >
        {value}
      </button>
      {remove && (
        <button
          className={s.trash}
          id={uniqId}
          onClick={(e) => deleteYear(e)}
          type="button"
        >
          {}
        </button>
      )}
      {toggler && (
        <Panel className={s.form} padding>
          <Label title="Год">
            <Field
              className={s.form_field}
              placeholder="Год"
              value={year}
              idindex={idIndex}
              id={idIndex}
              onChange={(e: ChangeEvent<HTMLInputElement>) => changeYear?.(e)}
              maxLength={4}
            />
          </Label>
          <div className={s.form_field_section}>
            <div className={s.field_wrapper}>
              <Label title="Прибыль инвесторов" className={s.form_field_item}>
                <Field
                  className={s.form_field_value}
                  placeholder="млн ₽"
                  id={uniqId}
                  value={investorProfit}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    changeInvestorProfit?.(e)
                  }
                  maxLength={5}
                />
              </Label>
              <TypeToggler
                uniqId={uniqId}
                fieldName="investorProfit"
                value={investorProfitType}
                clickPlusHandler={clickPlusHandler}
                clickMinusHandler={clickMinusHandler}
              />
            </div>
            <div className={s.field_wrapper}>
              <Label
                title="Прибыльность инвестиций"
                className={s.form_field_item}
              >
                <Field
                  className={s.form_field_value}
                  placeholder="%"
                  id={uniqId}
                  value={returnOnInvestment}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    changeReturnOnInvestment?.(e)
                  }
                  maxLength={4}
                />
              </Label>
              <TypeToggler
                uniqId={uniqId}
                fieldName="returnOnInvestment"
                value={returnOnInvestmentType}
                clickPlusHandler={clickPlusHandler}
                clickMinusHandler={clickMinusHandler}
              />
            </div>
          </div>
          <div className={s.form_field_section}>
            <div className={s.field_wrapper}>
              <Label title="Инвестируют повторно" className={s.form_field_item}>
                <Field
                  className={s.form_field_value}
                  placeholder="%"
                  id={uniqId}
                  value={reinvest}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    changeReinvest?.(e)
                  }
                  maxLength={4}
                />
              </Label>
              <TypeToggler
                uniqId={uniqId}
                fieldName="reinvest"
                value={reinvestType}
                clickPlusHandler={clickPlusHandler}
                clickMinusHandler={clickMinusHandler}
              />
            </div>
            <div className={s.field_wrapper}>
              <Label title="Вложили инвесторы" className={s.form_field_item}>
                <Field
                  className={s.form_field_value}
                  placeholder="млд ₽"
                  id={uniqId}
                  value={investorInvested}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    changeInvestorInvested?.(e)
                  }
                  maxLength={4}
                />
              </Label>
              <TypeToggler
                uniqId={uniqId}
                fieldName="investorInvested"
                value={investorInvestedType}
                clickPlusHandler={clickPlusHandler}
                clickMinusHandler={clickMinusHandler}
              />
            </div>
          </div>
          <div className={s.form_field_section}>
            <div className={s.field_wrapper}>
              <Label title="Сделок завершено" className={s.form_field_item}>
                <Field
                  className={s.form_field_value}
                  // className={s.form_field_value_small}
                  placeholder="количество"
                  id={uniqId}
                  value={
                    transactionsCompleted
                      ? Number(
                          String(transactionsCompleted).replace(/\D/g, '')
                        ).toLocaleString()
                      : transactionsCompleted
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    changeTransactionsCompleted?.(e)
                  }
                  maxLength={8}
                />
              </Label>
              <TypeToggler
                uniqId={uniqId}
                fieldName="transactionsCompleted"
                value={transactionsCompletedType}
                clickPlusHandler={clickPlusHandler}
                clickMinusHandler={clickMinusHandler}
              />
            </div>
            <div className={s.field_wrapper}>
              <Label
                title="Средний чек инвестора "
                className={s.form_field_item}
              >
                <Field
                  className={s.form_field_value}
                  placeholder="млн ₽"
                  id={uniqId}
                  value={averageInvestorCheck}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    changeAverageInvestorCheck?.(e)
                  }
                  maxLength={4}
                />
              </Label>
              <TypeToggler
                uniqId={uniqId}
                fieldName="averageInvestorCheck"
                value={averageInvestorCheckType}
                clickPlusHandler={clickPlusHandler}
                clickMinusHandler={clickMinusHandler}
              />
            </div>
          </div>
        </Panel>
      )}
    </div>
  )
}
