import React, { useState, FC } from 'react'
import { useQuery } from '@apollo/client'
import { ALL_PLEDGE } from '../../../../../apollo/query/allPledge'
import s from './Provide.module.css'

interface Provide {
  errorHandleRange: any
}

export const Provide: FC <Provide> = ({ errorHandleRange }) => {
  const [showDescription, setShowDescription] = useState(false)
  const [useKey, setUseKey] = useState('')

  const findElementOnTap = (event: any, key: any) => {
    setUseKey(event.target.parentElement.parentElement.className)
    if (event.target.parentElement.parentElement.className === key) {
      setShowDescription(!showDescription)
    } else {
      setShowDescription(false)
    }
  }

  const { data, loading, error } = useQuery(ALL_PLEDGE)

  return (
    <div className={s.instuction_and_provide__container}>
      <div className={s.instuction}>
        <p className={s.instruction__title}>Калькулятор доходности</p>
        <div className={s.instruction__text}>
          <p>
            Двигайте ползунок или напишите сумму — калькулятор покажет, сколько
            можно заработать на разных проектах.
          </p>
          <p>
            Нажимайте на карточки с логотипами, чтобы увидеть подробности о
            каждом из проектов.
          </p>
          <p>
            Листайте страницы с проектами — стрелки находятся под карточками с
            логотипами.
          </p>
          <p
            className={
              errorHandleRange ? s.instuction_with_handle_range_error : ''
            }
          >
            Калькулятор считает доходность инвестиций до 10 млн ₽. Хотите узнать
            доходность большей суммы — оставьте заявку.
          </p>
        </div>
      </div>

      <div className={s.poride__block}>
        <p className={s.provide__title}>виды обеспечения</p>
        {data?.projectProcurings?.map((elem: any) => (
          <div
            key={elem?.uuid.concat('provide')}
            className={elem?.uuid}
            onClick={event => findElementOnTap(event, elem?.uuid)}
          >
            <div className={s.provide__line_text}>
              <p className={s.provide__text}>{elem?.name}</p>
              <div className={s.provide__quastion}>
                &nbsp;
              </div>
            </div>

            <p
              className={s.provide__description}
              style={{
                display:
                  useKey === elem?.uuid && showDescription ? 'block' : 'none',
              }}
            >
              {elem?.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}
