/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable wrap-iife */
import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useAppSelector } from '../../redux/hook'
import { useGetRole } from '../../hooks/useGetRole'
import { ClientsList, CreateClients } from '../../components'
import { Layout } from '../../features/Layouts'
import { Loader, Title } from '../../ui'
import { CLIENTS } from '../../apollo/query/allClients'
import {
  admin,
  lawyer,
  manager,
  marketer,
  technical1,
  technical2,
} from '../../references/data'
import s from './Customers.module.css'
import { UserProfile } from '../../components/UserProfile/UserProfile'

export const Customers = () => {
  const { isCreateClient } = useAppSelector(state => state.pageSlice)
  const { isRole, location, navigate } = useGetRole()
  const { data, loading, error } = useQuery(CLIENTS)

  useEffect(() => {
    ;(function redirect() {
      if (isRole === technical2 && location.pathname === '/clients') {
        navigate('/documents', { replace: true })
      } else if (isRole === marketer && location.pathname === '/clients') {
        navigate('/promotion', { replace: true })
      }
    })()
  }, [location, isRole])

  return (
    <Layout>
      {(isRole === admin ||
        isRole === technical1 ||
        isRole === lawyer ||
        isRole === manager) && (
        <>
          <div className={s.header}>
            <div className={s.title}>
              <Title className={s.clients__title} title="Клиенты" />
              {loading ? (
                <div>
                  (
                  <Loader className={s.clients_loader} />)
                </div>
              ) : error ? (
                ''
              ) : (
                <p>({data?.clients?.length})</p>
              )}
            </div>
            <UserProfile isRole={isRole} navigate={navigate} />
          </div>
          {(!isCreateClient && <ClientsList />) || <CreateClients />}
        </>
      )}
    </Layout>
  )
}
