import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import clsx from 'clsx'
import {
  Button,
  Container,
  Edit,
  Loader,
  Modal,
  NavProjects,
  Panel,
} from '../../ui'
import { useAppSelector, useAppDispatch } from '../../redux/hook'
import { openProject } from '../../redux/slices/pageSlice'
import {
  addProjectName,
  hideRemoveProjectModal,
  showRemoveProjectModal,
  showArchiveProjectModal,
  hideArchiveProjectModal,
  showResumeProjectModal,
  hideResumeProjectModal,
  showSuspendProjectModal,
  hideSuspendProjectModal,
  showActiveProjectModal,
  hideActiveProjectModal,
} from '../../redux/slices/tableSlice'
import { PROJECTS } from '../../apollo/query/allProjects'
import { REMOVE_PROJECT } from '../../apollo/mutation/removeProject'
import { PROJECT_UPDATE } from '../../apollo/mutation/projectUpdate'
import {
  active, draft, suspended, archive
} from './data'
import projects from './img/icon-addProjects.svg'
import s from './ProjectsList.module.css'

export const ProjectsList = () => {
  const [navValue, setNavValue] = useState(0)
  const [editActive, setEditActive] = useState<number | null>(null)
  const [projectUUID, setProjectUUID] = useState<string>('')

  const dispatch = useAppDispatch()
  const {
    removeProjectModal,
    archiveProjectModal,
    resumeProjectModal,
    suspendProjectModal,
    activeProjectModal,
    projectName,
  } = useAppSelector(state => state.tableSlice)

  const navigate = useNavigate()

  // Запрос проектов
  const { data, loading, error } = useQuery(
    PROJECTS,
    navValue === 0
      ? {}
      : navValue === 1
        ? {
          variables: {
            filter: {
              projectStatusNew: 'active',
            },
          },
        }
        : navValue === 2
          ? {
            variables: {
              filter: {
                projectStatusNew: 'draft',
              },
            },
          }
          : {
            variables: {
              filter: {
                projectStatusNew: navValue === 3 ? 'suspended' : 'archive',
              },
            },
          }
  )

  // Мутация на удаление проекта

  const [removeProject, { data: removed }] = useMutation(REMOVE_PROJECT, {
    refetchQueries: [
      {
        query: PROJECTS,
        variables:
          navValue === 0
            ? {}
            : navValue === 1
              ? {
                filter: {
                  projectStatusNew: 'active',
                },
              }
              : navValue === 2
                ? {
                  filter: {
                    projectStatusNew: 'draft',
                  },
                }
                : {
                  filter: {
                    projectStatusNew: navValue === 3 ? 'suspended' : 'archive',
                  },
                },
      },
    ],
    // update(cache) {
    //   const { projects }: any = cache.readQuery({
    //     query: PROJECTS,
    //     variables: {
    //       filter: {
    //         status:
    //             navValue === 1
    //               ? 'published'
    //               : navValue === 3
    //                 ? 'suspended'
    //                 : navValue === 4
    //                   ? 'hidden'
    //                   : '',
    //         sendStatus: navValue === 2 ? 'draft' : 'sent',
    //       },
    //     },
    //   })
    //   cache.writeQuery({
    //     query: PROJECTS,
    //     variables: {
    //       filter: {
    //         status:
    //             navValue === 1
    //               ? 'published'
    //               : navValue === 3
    //                 ? 'suspended'
    //                 : navValue === 4
    //                   ? 'hidden'
    //                   : '',
    //         sendStatus: navValue === 2 ? 'draft' : 'sent',
    //       },
    //     },
    //     data: {
    //       projects: projects.filter(
    //         (projects: any) => projects.uuid !== projectUUID,
    //       ),
    //     },
    //   })
    // },
  })

  // Мутация на изменение проекта

  const [
    switchProjectStatus,
    { data: switchData, loading: switchLoading, error: switchError },
  ] = useMutation(PROJECT_UPDATE, {
    refetchQueries: [
      {
        query: PROJECTS,
      },
      {
        query: PROJECTS,
        variables: {
          filter: {
            projectStatusNew: 'active',
          },
        },
      },
      {
        query: PROJECTS,
        variables: {
          filter: {
            projectStatusNew: 'draft',
          },
        },
      },
      {
        query: PROJECTS,
        variables: {
          filter: {
            projectStatusNew: 'suspended',
          },
        },
      },
      {
        query: PROJECTS,
        variables: {
          filter: {
            projectStatusNew: 'archive',
          },
        },
      },
    ],
  })

  function editActiveHandle(project: string, index: number, uuid: string) {
    dispatch(addProjectName(project))
    setProjectUUID(uuid)
    if (index === editActive) {
      setEditActive(null)
    } else {
      setEditActive(index)
    }
  }

  const showPage = () => dispatch(openProject())

  return (
    <Container className={s.container}>
      <div className={s.projects__panel}>
        <NavProjects setNavValue={setNavValue} navValue={navValue} />
        <div className={s.projects__add}>
          <Button
            className={s.project_add_btn}
            src={projects}
            theme="icon"
            icon
            onClick={showPage}
          >
            Добавить проект
          </Button>
        </div>
      </div>
      {loading ? (
        <div style={{ margin: '0 auto', width: '35px' }}>
          <Loader />
        </div>
      ) : (
        <div className={s.projects__items}>
          {data?.projects?.map((project: any, index: number) => (
            <Panel
              className={clsx(
                ['draft', 'suspended', 'archive'].includes(
                  project?.projectStatusNew
                )
                  ? s.projects__item_disable
                  : s.projects__item_enable
              )}
              key={project?.uuid}
            >
              {loading ? (
                <Loader className={s.loader} theme="light" />
              ) : (
                <>
                  <div className={s.projects__logo}>
                    <div
                      className={s.logo_wrapper}
                      style={
                        ['draft', 'suspended', 'archive'].includes(
                          project?.projectStatusNew
                        )
                          ? { opacity: 0.2 }
                          : { opacity: 1 }
                      }
                    >
                      <img
                        src={project?.projectImages?.logo}
                        alt={project?.name}
                      />
                    </div>
                    <div className={s.project_status_wrapper}>
                      <p className={s.project_status}>
                        {project?.projectStatusNew === 'draft'
                          ? 'Черновик'
                          : project?.projectStatusNew === 'archive'
                            ? 'Архив'
                            : project?.projectStatusNew === 'suspended'
                              ? 'Приостановлен'
                              : ''}
                      </p>
                      <Edit
                        classNametooltip={s.edit}
                        arrSettings={
                          project?.projectStatusNew === 'draft'
                            ? draft
                            : project?.projectStatusNew === 'suspended'
                              ? suspended
                              : project?.projectStatusNew === 'archive'
                                ? archive
                                : active
                        }
                        active={editActive === index}
                        onClick={() => editActiveHandle(project?.name, index, project?.uuid)}
                        onEditClick={() => navigate(`/projects/edit/${project?.uuid}`)}
                        resumeProject={() => dispatch(showResumeProjectModal())}
                        archiveProject={() => dispatch(showArchiveProjectModal())}
                        suspendProject={() => dispatch(showSuspendProjectModal())}
                        removeProject={() => {
                          dispatch(showRemoveProjectModal())
                        }}
                        activateProject={() => dispatch(showActiveProjectModal())}
                      />
                    </div>
                  </div>
                  <p
                    className={s.projects__title}
                    style={
                      ['draft', 'suspended', 'archive'].includes(
                        project?.projectStatusNew
                      )
                        ? { color: 'rgba(185, 185, 185, 1)' }
                        : { color: 'rgba(113, 113, 113, 1)' }
                    }
                  >
                    Клиентов
                    {' '}
                    <span
                      style={
                        ['draft', 'suspended', 'archive'].includes(
                          project?.projectStatusNew
                        )
                          ? { color: 'rgba(185, 185, 185, 1)' }
                          : { color: 'rgba(0, 0, 0, 1)' }
                      }
                    >
                      {project?.contracts?.length
                        ? project?.contracts?.length
                        : '0'}
                    </span>
                  </p>
                  <p
                    className={s.projects__investSum}
                    style={
                      ['draft', 'suspended', 'archive'].includes(
                        project?.projectStatusNew
                      )
                        ? { color: 'rgba(185, 185, 185, 1)' }
                        : { color: 'rgba(113, 113, 113, 1)' }
                    }
                  >
                    Всего вложено
                    <span
                      style={
                        ['draft', 'suspended', 'archive'].includes(
                          project?.projectStatusNew
                        )
                          ? { color: 'rgba(185, 185, 185, 1)' }
                          : { color: 'rgba(0, 0, 0, 1)' }
                      }
                    >
                      {new Intl.NumberFormat('ru-Ru').format(
                        project?.contracts?.length
                          ? project?.contracts
                            ?.map((elem: any) => +elem.investSum)
                            .reduce((acc: number, prev: number) => acc + prev)
                          : '0'
                      )}
                      {' '}
                      ₽
                    </span>
                  </p>
                </>
              )}
            </Panel>
          ))}
        </div>
      )}
      {(removeProjectModal
        || archiveProjectModal
        || resumeProjectModal
        || suspendProjectModal
        || activeProjectModal) && (
        <Modal
          projectName={projectName}
          background="rgba(250 250 250 / 0.8)"
          question={
            removeProjectModal
              ? 'Вы действительно хотите удалить проект'
              : archiveProjectModal
                ? 'Вы действительно хотите снять с публикации проект'
                : resumeProjectModal
                  ? 'Вы действительно хотите возобновить проект'
                  : suspendProjectModal
                    ? 'Вы действительно хотите приостановить проект'
                    : 'Вы действительно хотите опубликовать проект'
          }
        >
          {removeProjectModal ? (
            <>
              <Button
                className={clsx(s.projects__btn)}
                theme="dark"
                onClick={() => {
                  removeProject({
                    variables: {
                      uuid: `${projectUUID}`,
                    },
                  })
                  dispatch(hideRemoveProjectModal())
                  setProjectUUID('')
                }}
              >
                Удалить
              </Button>
              <Button
                className={s.projects__btn}
                theme="outline"
                onClick={() => {
                  dispatch(hideRemoveProjectModal())
                  setProjectUUID('')
                }}
              >
                Отмена
              </Button>
            </>
          ) : archiveProjectModal ? (
            <>
              <Button
                className={clsx(s.projects_archive_btn)}
                theme="dark"
                onClick={() => {
                  switchProjectStatus({
                    variables: {
                      uuid: `${projectUUID}`,
                      data: {
                        projectStatusNew: 'archive',
                      },
                    },
                  })
                  dispatch(hideArchiveProjectModal())
                  setProjectUUID('')
                }}
              >
                В архив
              </Button>
              <Button
                className={clsx(s.projects_archive_btn)}
                theme="outline"
                onClick={() => {
                  dispatch(hideArchiveProjectModal())
                  setProjectUUID('')
                }}
              >
                Отмена
              </Button>
            </>
          ) : resumeProjectModal ? (
            <>
              <Button
                className={clsx(s.projects_archive_btn)}
                theme="dark"
                onClick={() => {
                  switchProjectStatus({
                    variables: {
                      uuid: `${projectUUID}`,
                      data: {
                        projectStatusNew: 'active',
                      },
                    },
                  })
                  dispatch(hideResumeProjectModal())
                  setProjectUUID('')
                }}
              >
                Возобновить
              </Button>
              <Button
                className={clsx(s.projects_archive_btn)}
                theme="outline"
                onClick={() => {
                  dispatch(hideResumeProjectModal())
                  setProjectUUID('')
                }}
              >
                Отмена
              </Button>
            </>
          ) : suspendProjectModal ? (
            <>
              <Button
                className={clsx(s.projects_archive_btn)}
                theme="dark"
                onClick={() => {
                  switchProjectStatus({
                    variables: {
                      uuid: `${projectUUID}`,
                      data: {
                        projectStatusNew: 'suspended',
                      },
                    },
                  })
                  dispatch(hideSuspendProjectModal())
                  setProjectUUID('')
                }}
              >
                Приостановить
              </Button>
              <Button
                className={clsx(s.projects_archive_btn)}
                theme="outline"
                onClick={() => {
                  dispatch(hideSuspendProjectModal())
                  setProjectUUID('')
                }}
              >
                Отмена
              </Button>
            </>
          ) : (
            <>
              <Button
                className={clsx(s.projects_archive_btn)}
                theme="dark"
                onClick={() => {
                  switchProjectStatus({
                    variables: {
                      uuid: `${projectUUID}`,
                      data: {
                        projectStatusNew: 'active',
                      },
                    },
                  })
                  dispatch(hideActiveProjectModal())
                  setProjectUUID('')
                }}
              >
                Опубликовать
              </Button>
              <Button
                className={clsx(s.projects_archive_btn)}
                theme="outline"
                onClick={() => {
                  dispatch(hideActiveProjectModal())
                  setProjectUUID('')
                }}
              >
                Отмена
              </Button>
            </>
          )}
        </Modal>
      )}
    </Container>
  )
}
