import React, { FC } from 'react'
// import { useQuery } from "@apollo/client";
// import { GET_ALLFAQ } from "../../../apolo/query/query_faq";
// import { DataFaq } from "./../dataFaq";
import s from './Questions.module.css'
import { OpeningText } from '../openingText/OpeningText'
// import { OpeningText } from '../../../ux/openingText/OpeningText'

interface QuestionsProps {
	data?: any
}

export const Questions: FC<QuestionsProps> = ({ data }) => (
  <>
    <div className={s.questions__main}>
      <div className={s.questions__container}>
        {data?.map((elem: any, index: number) => (
          <OpeningText elem={elem} key={elem.uuid} />
        ))}
      </div>
    </div>
  </>
)
