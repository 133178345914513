import { gql } from '@apollo/client'

export const ALL_BROCKERS = gql`
  query allBrockers {
    brokers {
      id
      uuid
      name
    }
  }
`
