import home from './icons/icon-main.png'
import documents from './icons/icon-document.png'
import clients from './icons/icon-clients.png'
import percents from './icons/icon-percent.png'
import projects from './icons/icon-projects.png'
import {
  admin,
  lawyer,
  manager,
  marketer,
  technical1,
  technical2,
} from '../../references/data'

export const asideBar = [
  {
    src: home,
    title: 'Главная',
    path: '/home',
    role: { admin, lawyer, manager },
  },
  {
    src: clients,
    title: 'Клиенты',
    path: '/clients',
    role: {
      admin,
      lawyer,
      manager,
      technical1,
    },
  },
  {
    src: documents,
    title: 'Документы',
    path: '/documents',
    role: {
      admin,
      lawyer,
      manager,
      technical2,
    },
  },
  {
    src: percents,
    title: 'Акции',
    path: '/promotion',
    role: { admin, marketer },
  },
  {
    src: projects,
    title: 'Проекты',
    path: '/projects',
    role: { admin },
  },
]

export const editSiteArr = [
  {
    id: 1,
    active: false,
    title: 'Главная',
    path: '/editsite',
  },
  {
    id: 2,
    active: false,
    title: 'Команда',
    path: '/editsite',
  },
  {
    id: 4,
    active: false,
    title: 'Медиа',
    path: '/editsite',
  },
  {
    id: 13,
    active: false,
    title: 'Бизнесу',
    path: '/editsite',
  },
  {
    id: 14,
    active: false,
    title: 'FAQ',
    path: '/editsite',
  },
  {
    id: 15,
    active: false,
    title: 'Футер',
    path: '/editsite',
  },
]
