/* eslint-disable react/jsx-one-expression-per-line */
import { useQuery } from '@apollo/client'
import { useGetRole } from '../../hooks/useGetRole'
import { Layout } from '../../features/Layouts'
import { Loader, Title } from '../../ui'
import { EditProj } from '../../components'
import { PROJECTS } from '../../apollo/query/allProjects'
import { admin } from '../../references/data'
import s from './EditProject.module.css'

export const EditProject = () => {
  const { data, loading, error } = useQuery(PROJECTS)
  const { isRole } = useGetRole()

  return (
    <Layout>
      {isRole === admin && (
        <>
          <div className={s.title}>
            <Title className={s.projects__title} title="Проекты" />
            {loading ? <Loader /> : <p>({data?.projects?.length})</p>}
          </div>
          <EditProj />
        </>
      )}
    </Layout>
  )
}
