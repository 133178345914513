import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import { useApolloClient, useQuery, useSubscription } from '@apollo/client'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '../../redux/hook'
import {
  closeChat,
  closeChatListToWrite,
  closeChatMessageList,
  openActiveChat,
  openChat,
} from '../../redux/slices/pageSlice'
import { useGetRole } from '../../hooks/useGetRole'
import { logOut } from '../../redux/slices/authSlice'
import { resetObj } from '../../redux/slices/selectSlice'
import { Logo } from '../Logo'
import { Counter } from '../Counter'
import { NavButton } from '../NavButton'
import { asideBar, editSiteArr } from './asideBar'
import { NativeSelectNavigation } from '../nativeSelectNavigation'
import { lawyer, manager } from '../../references/data'
import chancgeSiteIcon from './icons/changeSiteIcon.svg'
import exitArrow from './icons/icon-exit-arrow.svg'
import chat from './icons/icon-chats2x.png'
import s from './Aside.module.css'
import { CHAT_CHANNEL } from '../../apollo/query/chats/chatChannels'
import { CHAT_JOIN } from '../../apollo/subscription/subscriptionChat'
import { AppContext } from '../../features/Context/useAppContext'

interface AsideType {
  className?: string
  marketer?: string
  admin?: string
}

export const Aside: React.FC<AsideType> = ({
  className,
  marketer = 'marketer',
  admin = 'admin',
}) => {
  const dispatch = useAppDispatch()
  const [showSelect, setShowSelect] = useState(false)
  const [chatArr, setChatArr] = useState<any[]>([])
  const { isCountUnread, setCountUnread } = useContext(AppContext)
  const {
    isRole, profileUUID, token, location, navigate
  } = useGetRole()
  const client = useApolloClient()

  const {
    data, loading, error, refetch
  } = useQuery(CHAT_CHANNEL, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    // pollInterval: 500,
  })

  const { data: chatData, error: subError } = useSubscription(CHAT_JOIN, {
    variables: {
      token,
    },
  })

  useEffect(() => {
    setCountUnread(
      chatArr
        ?.filter(e => e?.lastMessage?.profile?.uuid !== profileUUID)
        ?.map(e => e?.countUnread)
        ?.reduce((acc, e) => acc + e, 0)
    )
  }, [chatArr])

  useEffect(() => {
    if (data?.chatChannels) {
      setChatArr(data?.chatChannels)
    }
  }, [data])

  useEffect(() => {
    if (chatData) {
      setChatArr(prev => [...prev, chatData?.chatChannelJoined])
    }
  }, [chatData])

  const exit = () => {
    dispatch(logOut(localStorage.clear()))
    dispatch(closeChat())
    dispatch(closeChatListToWrite())
    dispatch(closeChatMessageList())
  }

  return (
    <nav className={clsx(s.nav, className)}>
      <Link to="/home">
        <Logo />
      </Link>

      <div className={s.items}>
        {asideBar?.map(
          elem =>
            isRole &&
            isRole in elem.role && (
              <Link
                onClick={() => {
                  if (elem.path !== '/documents') {
                    dispatch(resetObj())
                  }
                  if (elem.path !== '/home') {
                    dispatch(openActiveChat())
                    dispatch(closeChatMessageList())
                  }
                }}
                to={elem.path}
                key={elem.path}
              >
                <NavButton
                  className={s.btn}
                  active={location.pathname === elem.path}
                >
                  <div className={s.img}>
                    <img
                      className={
                        location.pathname === elem.path ? s.active : ''
                      }
                      src={elem.src}
                      alt={elem.path}
                      width="20"
                      height="20"
                    />
                  </div>
                  {elem.title}
                </NavButton>
              </Link>
            )
        )}
        {marketer === isRole || admin === isRole ? (
          <NativeSelectNavigation
            className={showSelect ? s.select_active : s.select}
            navigationArr={editSiteArr}
            navigationSelect
            show={showSelect}
            defaultTitle=""
            onClick={() => setShowSelect(!showSelect)}
            src={chancgeSiteIcon}
          />
        ) : null}
      </div>
      <div className={s.btns__wrapper}>
        <NavButton
          className={clsx(
            s.btn__wrapper_chats,
            isRole === lawyer || isRole === manager ? '' : 'hidden'
          )}
          onClick={() => {
            dispatch(openChat())
            if (location.pathname !== '/home') {
              navigate('/home', { replace: true })
            }
          }}
        >
          <div className={s.img}>
            <img src={chat} alt="chat_icon" width="40" height="40" />
          </div>
          <span>Мои чаты</span>
          <div>
            <Counter
              className={s.counter__unread}
              theme="warn"
              count={isCountUnread}
            />
            {/* {isRole !== manager && <Counter theme="primary" count={1} />} */}
          </div>
        </NavButton>
        <NavButton
          className={clsx(s.btn__wrapper_logout)}
          onClick={async () => {
            exit()
            navigate('/', { replace: true })
            await client.clearStore()
          }}
        >
          <div className={s.img}>
            <img src={exitArrow} alt="exit" width="25" height="25" />
          </div>
          Выход
        </NavButton>
      </div>
    </nav>
  )
}
