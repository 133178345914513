import React, { FC } from 'react'
import { Title } from './faqTitle/TitleFaq'
import { Questions } from './faqQuestions/Questions'
import computer from './computer_icon.svg'
import close from './close_icon.svg'
import { LastScreen } from '../lastScreen/LastScreen'
import s from './Faq.module.css'

interface FaqProps {
	data?: any
  setPreviewToggler?:(arg: boolean) => void
}

export const Faq: FC<FaqProps> = ({ data, setPreviewToggler }) => (
  <>
    <div className={s.wrapper} onClick={e => e.stopPropagation()}>
      <button className={s.close} type="button" onClick={() => setPreviewToggler?.(false)}>
        <img src={close} alt="" />
      </button>
      <main className={s.faq__main}>
        <Title />
        <Questions data={data} />
        {/* <LastScreen /> */}
      </main>
    </div>
  </>
)
