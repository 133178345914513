import React from 'react'
import clsx from 'clsx'
import { Avatar, Panel } from '../../ui'
import read from './img/icon-status-read.svg'
import unread from './img/icon-status-unread.svg'
import docIcon from './img/icon-doc.svg'
import test from './img/image-test.jpg'
import s from './ChatMessageUnit.module.css'

interface ChatMessageUnitType {
  broker: string
  investor: string
  superuser: string
  manager: string
}

const themes: ChatMessageUnitType = {
  broker: s.brocker,
  investor: s.investor,
  superuser: s.superuser,
  manager: s.manager,
}

interface ChatMessageUnitPerformance {
  indent: string | undefined
}

const indents: ChatMessageUnitPerformance = {
  indent: s.indent,
}

interface ChatMessageUnitProps {
  className?: string
  theme?: keyof typeof themes
  indent?: keyof typeof indents
  avatar?: string
  text?: string
  createdAt?: number | Date | undefined
  readStatus?: string
  name: string
  messageType?: string
  fileName?: string
}

export const ChatMessageUnit = ({
  className,
  theme,
  indent,
  avatar,
  text = 'Здравствуйте! Подскажите, чем я могу помочь?',
  createdAt,
  readStatus,
  name,
  messageType,
  fileName,
}: ChatMessageUnitProps) => {
  const [firstName = '', LastName = ''] = name?.split(' ') || []

  return (
    <div
      className={clsx(
        s.chat__message_unit,
        indent ? indents[indent] : null,
        className
      )}
    >
      <div className={s.chat_message_body}>
        {avatar ? (
          <div className={s.avatar}>
            <img
              className={s.img}
              src={avatar}
              alt="avatar"
              width="50"
              height="50"
            />
          </div>
        ) : (
          <Avatar
            className={s.chat__item_noavatar}
            firstName={firstName?.charAt(0)}
            lastName={LastName?.charAt(0)}
          />
        )}
        {(messageType === 'text' || messageType === 'doc') && (
          <Panel className={clsx(s.lower, theme ? themes[theme] : null)}>
            {messageType === 'text' && <p>{text}</p>}
            {messageType === 'doc' && (
              <div className={s.chat__document}>
                <img src={docIcon} alt="doc-icon" width="25" height="25" />
                <p className={s.chat__document_text}>
                  <a href={text} download>
                    {fileName || 'Документ'}
                  </a>
                </p>
              </div>
            )}
          </Panel>
        )}
        {messageType === 'image' && (
          <div className={s.chat__document_img}>
            <a href={text} download target="_blank" rel="noreferrer">
              <img src={text} alt="" width="350" height="196" />
            </a>
          </div>
        )}
      </div>
      <div className={s.chat_message_date}>
        <p>
          {new Intl.DateTimeFormat('ru-Ru', {
            hour: '2-digit',
            minute: '2-digit',
          }).format(createdAt)}
        </p>
        {readStatus && (
          <div className={s.chat__item_status}>
            <img
              src={readStatus === 'read' ? read : unread}
              alt="status"
              width="20"
              height="20"
            />
          </div>
        )}
      </div>
    </div>
  )
}
