/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable wrap-iife */
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useGetRole } from '../../hooks/useGetRole'
import { EditClient } from '../../components'
import { Layout } from '../../features/Layouts'
import { Title } from '../../ui'
import { CLIENTS } from '../../apollo/query/allClients'
import {
  admin,
  lawyer,
  manager,
  marketer,
  technical1,
  technical2,
} from '../../references/data'
import s from './EditClient.module.css'

export const EditClientPage = () => {
  const params = useParams()
  const { isRole, location, navigate } = useGetRole()

  const { data } = useQuery(CLIENTS)

  useEffect(() => {
    ;(function redirect() {
      if (
        isRole === technical2 &&
        location.pathname === `/clients/edit/${params.id}`
      ) {
        navigate('/documents', { replace: true })
      } else if (
        isRole === marketer &&
        location.pathname === `/clients/edit/${params.id}`
      ) {
        navigate('/promotion', { replace: true })
      }
    })()
  }, [location, isRole])

  return (
    <Layout>
      {(isRole === admin ||
        isRole === technical1 ||
        isRole === lawyer ||
        isRole === manager) && (
        <>
          <div className={s.title}>
            <Title className={s.clients__title} title="Клиенты" />
            <p>({data?.clients?.length})</p>
          </div>
          <EditClient params={params.id} />
        </>
      )}
    </Layout>
  )
}
