/* eslint-disable @typescript-eslint/no-extra-semi */
import { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { AppContext } from '../../features/Context/useAppContext'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { useGetRole } from '../../hooks/useGetRole'
import { useGetAllUsers } from '../../hooks/useGetAllUsers'
import { CHAT_CHANNEL } from '../../apollo/query/chats/chatChannels'
import { CHAT_JOIN } from '../../apollo/subscription/subscriptionChat'
import { CHAT_CHANNEL_CREATE } from '../../apollo/mutation/chats/chatChannelCreate'
import { CHAT_JOIN_TO_CHANNEL } from '../../apollo/mutation/chats/chatJoinToChannel'
import { setChatUserUUID } from '../../redux/slices/chatSlice'
import {
  closeChat,
  closeChatListToWrite,
  closeChatMessageList,
  openActiveChat,
} from '../../redux/slices/pageSlice'
import { ChatCreate, ChatMessagesList, ChatsPanel } from '../../components'
import {
  CloseIcon, Container, ProjectPanel, Title
} from '../../ui'
import s from './Chats.module.css'

export const Chats = () => {
  const [chatArr, setChatArr] = useState<any[]>([])

  const {
    isListToWrite,
    isChatMessageList,
    chatChannelId,
    titleChat,
    photoChatUser,
    countMembers,
    tempBrockerMembers,
  } = useAppSelector(state => state.pageSlice)

  const { userUUID } = useAppSelector(state => state.chatSlice)
  const dispatch = useAppDispatch()

  const { setCountUnread } = useContext(AppContext)

  const { profileUUID, token } = useGetRole()
  const allUsers = useGetAllUsers(chatArr, profileUUID)

  const {
    data, loading, error, refetch
  } = useQuery(CHAT_CHANNEL, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    // pollInterval: 500,
  })

  const { data: chatData, error: subError } = useSubscription(CHAT_JOIN, {
    variables: {
      token,
    },
  })

  const [
    chatCreate,
    {
      data: createChatData,
      loading: createChatDataLoading,
      error: createChatError,
    },
  ] = useMutation(CHAT_CHANNEL_CREATE)

  const [
    joinChat,
    { data: joinChatData, loading: joinChatLoading, error: joinChatError },
  ] = useMutation(CHAT_JOIN_TO_CHANNEL, {
    refetchQueries: [
      {
        query: CHAT_CHANNEL,
      },
    ],
  })

  useEffect(() => {
    if (data?.chatChannels) {
      setChatArr(data?.chatChannels)
    }
  }, [data])

  useEffect(() => {
    if (chatData) {
      setChatArr(prev => [...prev, chatData?.chatChannelJoined])
    }
  }, [chatData])

  useEffect(() => {
    setCountUnread(
      chatArr
        ?.filter(e => e?.lastMessage?.profile?.uuid !== profileUUID)
        ?.map(e => e?.countUnread)
        ?.reduce((acc, e) => acc + e, 0)
    )
  }, [chatArr])

  useEffect(() => {
    if (createChatData && userUUID) {
      ;(async () => {
        joinChat({
          variables: {
            chatChannelId: createChatData?.chatChannelCreate?.id,
            profileUUID,
          },
        })
        joinChat({
          variables: {
            chatChannelId: createChatData?.chatChannelCreate?.id,
            profileUUID: userUUID,
          },
        }).then(() => {
          dispatch(closeChatListToWrite())
        })
      })()
    }
  }, [createChatData])

  const createChatWithUser = async (uuid: any) => {
    dispatch(setChatUserUUID(uuid))
    chatCreate()
  }

  return (
    <Container className={s.container}>
      <Title className={s.title} title="Мои чаты" />
      <CloseIcon
        onClick={() => {
          dispatch(closeChat())
          dispatch(closeChatMessageList())
          dispatch(openActiveChat())
        }}
      />
      <div className={s.chats__wrapper}>
        <div className={s.chats__messages}>
          {(isChatMessageList && (
            <ChatMessagesList
              token={token}
              chatChannelId={chatChannelId}
              titleChat={titleChat}
              photoChatUser={photoChatUser}
              countMembers={countMembers}
              tempBrockerMembers={tempBrockerMembers}
            />
          )) || (
            <ProjectPanel className={s.chats__messages_info} theme="primary">
              <span>Выберите, кому хотели бы написать</span>
            </ProjectPanel>
          )}
        </div>
        <div className={s.chats__list}>
          {(isListToWrite && (
            <ChatCreate
              onChatCreate={uuid => createChatWithUser(uuid)}
              allUsers={allUsers}
            />
          )) || <ChatsPanel chatUsersJoind={chatArr} />}
        </div>
      </div>
    </Container>
  )
}
