import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useMutation } from '@apollo/client'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  changeColorDesign,
  changeFirstFieldDynamic,
  changeStatusSelectDynamicPromotion,
  resetPromotionDynamicObj,
} from '../../redux/slices/promotionDynamicSlice'
import { closePromotion } from '../../redux/slices/pageSlice'
import { resetPromotionObj } from '../../redux/slices/promotionSlice'
import {
  Button,
  Panel,
  ProgressBar,
  StoriesInfo,
  Title,
  ToggleButton,
} from '../../ui'
import { PromotionDynamicDesign } from '../../ui/PromotionDynamicDesign'
import { PROMOTION_DYNAMIC_CREATE } from '../../apollo/mutation/promotion/promotionCreate'
import { PROMOTION_DYNAMICS } from '../../apollo/query/promotion/dynamics'
import fullFill from './img/icon-fillfull.svg'
import empty from './img/icon-empty.svg'
import arrow from './img/icon-arrow-up.svg'
import s from './DynamicCreatePromotion.module.css'

export const DynamicCreatePromotion = () => {
  const [createDynamicPromotion, { data, loading, error }] = useMutation(
    PROMOTION_DYNAMIC_CREATE,
    {
      refetchQueries: [
        {
          query: PROMOTION_DYNAMICS,
        },
        {
          query: PROMOTION_DYNAMICS,
          variables: {
            filter: {
              status: 'active',
            },
          },
        },
        {
          query: PROMOTION_DYNAMICS,
          variables: {
            filter: {
              status: 'draft',
            },
          },
        },
        {
          query: PROMOTION_DYNAMICS,
          variables: {
            filter: {
              status: 'archive',
            },
          },
        },
      ],
    }
  )
  const { promotionDynamicObj, colorsDynamicMiniature, statusSelect } = useAppSelector(state => state.promotionDynamicSlice)
  const dispatch = useAppDispatch()

  const [sumField, setSumField] = useState(0)
  const {
    color,
    image,
    header,
    text,
    gradientColor,
    gradientDeg,
    gradientLocation,
    ...promDynamicObj
  } = promotionDynamicObj

  const arr = Object.values(promDynamicObj)

  useEffect(() => {
    setSumField(arr.map(e => (e ? 20 : 0)).reduce((a: any, b: any) => a + b))
  }, [promotionDynamicObj])

  const [collapse, setCollapse] = useState(false)

  return (
    <div>
      <div className={s.wrapper}>
        <StoriesInfo
          promotion="dynamic"
          statusSelect={statusSelect}
          name={promotionDynamicObj.name}
          status={promotionDynamicObj.status}
          startDate={new Date(Date.parse(promotionDynamicObj?.startedAt))}
          endDate={new Date(Date.parse(promotionDynamicObj?.endedAt))}
          changeField={(e: any, nameField: string) => dispatch(changeFirstFieldDynamic({ event: e, nameField }))}
          changeStatus={() => dispatch(changeStatusSelectDynamicPromotion())}
        />
        <PromotionDynamicDesign
          title={promotionDynamicObj.header}
          text={promotionDynamicObj.text}
          changeField={(e: any, nameField: string) => dispatch(changeFirstFieldDynamic({ event: e, nameField }))}
          colorsDesign={colorsDynamicMiniature}
          changeColorDesign={(
            colorId: number,
            color: string,
            nameGroup: string,
            index: number
          ) => dispatch(
            changeColorDesign({
              colorId,
              color,
              nameGroup,
              index,
            })
          )}
        />
        <Button
          disabled={
            Math.ceil(
              sumField + (image || color || header || text ? 20 : 0)
            ) !== 100
          }
          theme={
            Math.ceil(
              sumField + (image || color || header || text ? 20 : 0)
            ) === 100
              ? 'dark'
              : 'secondary'
          }
          onClick={() => {
            const { status, ...dynamicObj } = promotionDynamicObj
            createDynamicPromotion({
              variables: {
                data: {
                  ...dynamicObj,
                  status:
                    status === 'Активный'
                      ? 'active'
                      : status === 'Черновик'
                        ? 'draft'
                        : 'archive',
                },
              },
            })
            setTimeout(() => {
              dispatch(closePromotion())
              dispatch(resetPromotionObj())
              dispatch(resetPromotionDynamicObj())
            }, 1500)
          }}
        >
          Сохранить
        </Button>
      </div>
      <div className={s.panel__progress}>
        <Panel padding>
          <ProgressBar
            className={s.project_create}
            value={Math.ceil(
              sumField + (image || color || header || text ? 20 : 0)
            )}
          />
          <div className={s.project_indicator}>
            <img
              src={
                promotionDynamicObj.endedAt
                && promotionDynamicObj.startedAt
                && promotionDynamicObj.name
                && promotionDynamicObj.status
                  ? fullFill
                  : empty
              }
              alt="empty"
            />
            <p>Информация</p>
          </div>
          <div className={s.project_indicator}>
            <img
              src={header || text || color || image ? fullFill : empty}
              alt="empty"
            />
            <p>Дизайн</p>
          </div>
        </Panel>
        <Panel
          padding
          className={clsx(s.promotion_select, {
            [s.promotion_select_active]: collapse,
          })}
        >
          <div className={s.promotion_select_header}>
            <Title className={s.dynamic_title} title="Превью" />
            <ToggleButton
              className={s.promotion_select_btn}
              src={arrow}
              rotate={collapse}
              iconWidth="18px"
              iconHeight="18px"
              onClick={() => setCollapse(!collapse)}
            />
          </div>
          <Panel
            className={s.promotion_item_dynamic}
            style={{ background: `${color}` }}
            padding
          >
            <div
              className={s.dynamic_info}
              style={color ? { color: '#fff' } : { color: '#000' }}
            >
              <p className={s.dynamic_header}>{promotionDynamicObj.header}</p>
              <p className={s.dynamic_text}>{promotionDynamicObj.text}</p>
            </div>
            {image && (
              <div className={s.dynamic_logo}>
                <img
                  src={image}
                  alt={image ? 'logo' : ''}
                  width="80"
                  height="80"
                />
              </div>
            )}
          </Panel>
        </Panel>
      </div>
    </div>
  )
}
