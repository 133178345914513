import { gql } from '@apollo/client'

// мутация на создание stories
export const PROMOTION_STORY_CREATE = gql`
  mutation createStory($data: StoryData!) {
    admStoryCreate(data: $data) {
      uuid
      name
      status
      startedAt
      endedAt
      header
      image
      color
      slides
      gradientColor
      gradientDeg
      gradientLocation
    }
  }
`

// мутация на создание dynamic
export const PROMOTION_DYNAMIC_CREATE = gql`
  mutation createPromotionDynamic($data: PromotionDynamicData!) {
    admPromotionDynamicCreate(data: $data) {
      uuid
      name
      status
      startedAt
      endedAt
      header
      text
      image
      color
      gradientColor
      gradientDeg
      gradientLocation
    }
  }
`
