import React, { FC } from 'react'
import { Container, Title } from '../../ui'
import { PromotionInvestorsList } from '../PromotionInvestorsList'
import { PromotionStoriesSelect } from '../PromotionStoriesSelect'
import { PromotionDynamicSelect } from '../PromotionDynamicSelect'
import s from './PromotionNotifications.module.css'

interface PromotionNotificationsType {
  title: string
}

export const PromotionNotifications: FC<PromotionNotificationsType> = ({
  title,
}) => (
  <Container>
    <Title className={s.title} title={title} />
    <div className={s.sending_notifications}>
      <div className={s.sending_investors}>
        <PromotionInvestorsList />
      </div>
      <div className={s.sending_filters}>
        <PromotionStoriesSelect />
        <PromotionDynamicSelect />
      </div>
    </div>
  </Container>
)
