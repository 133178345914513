import { gql } from '@apollo/client'

export const CHAT_CHANNEL_CREATE = gql`
  mutation chatChannelCreate {
    chatChannelCreate {
      id
      title {
        investor {
          lastName
          firstName
          middleName
          photo
          profile {
            uuid
            login
            role
          }
        }
        broker {
          name
          photo
          profile {
            uuid
            login
            role
          }
        }
        superuser {
          name
          photo
          profile {
            uuid
            login
            role
          }
        }
      }
      createdAt
    }
  }
`
