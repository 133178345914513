/*eslint-disable*/

import { gql } from "@apollo/client";

export const CREATE_INVTERVIEW = gql`
  mutation createInterview($data: MediaInterviewsData!) {
    admMediaInterviewsCreate(data: $data) {
      id
      uuid
      video
      title
      titleEn
      decription
      descriptionEn
      datemarker
    }
  }
`;

export const CREATE_REVIEW = gql`
  mutation createReview($data: MediaReviewsTextData!) {
    admMediaReviewsTextCreate(data: $data) {
      id
      uuid
      choose
      avatar
      firstname
      firstnameEn
      lastname
      lastnameEn
      sourse
      sourceEn
      link
      comment
      commentEn
      datemarker
      review
    }
  }
`;
export const CREATE_OUTLET = gql`
  mutation createOutlet($data: MediaOutletsData!) {
    admMediaOutletsCreate(data: $data) {
      id
      uuid
      picture
      title
      titleEn
      decription
      descriptionEn
      sourse
      sourceEn
      datemarker
      link
    }
  }
`;

export const CREATE_MEDIAREVIEW = gql`
  mutation createMediaReport($data: MediaReviewsTextData!) {
    admMediaReviewsTextCreate(data: $data) {
      id
      uuid
      choose
      avatar
      datemarker
      firstname
      firstnameEn
      lastname
      lastnameEn
      sourse
      sourceEn
      link
      comment
      commentEn
      review
    }
  }
`;

export const DELETE_INVTERVIEW = gql`
  mutation deleteInterview($uuid: String!) {
    admMediaInterviewsDelete(uuid: $uuid)
  }
`;
export const UPDATE_INVTERVIEW = gql`
  mutation update_MediaInterview(
    $uuid: String!
    $data: MediaInterviewsUpdate!
  ) {
    admMediaInterviewsUpdate(uuid: $uuid, data: $data) {
      uuid
      video
      title
      titleEn
      decription
      descriptionEn
      datemarker
    }
  }
`;
export const CREATE_MEDIAREPORT = gql`
  mutation createMediaReport($data: MediaReportsData!) {
    admMediaReportsCreate(data: $data) {
      video
      title
      titleEn
      datemarker
    }
  }
`;

export const UPDATE_MEDIAREPORT = gql`
  mutation updateMediaReport($uuid: String!, $data: MediaReportsUpdate!) {
    admMediaReportsUpdate(uuid: $uuid, data: $data) {
      video
      title
      titleEn
      datemarker
    }
  }
`;
export const DELETE_MEDIAREPORT = gql`
  mutation admMediaReportsDelete($uuid: String!) {
    admMediaReportsDelete(uuid: $uuid)
  }
`;
export const UPDATE_OUTLET = gql`
  mutation admMediaOutletsUpdate($uuid: String!, $data: MediaOutletsUpdate!) {
    admMediaOutletsUpdate(uuid: $uuid, data: $data) {
      id
      uuid
      picture
      title
      titleEn
      decription
      descriptionEn
      sourse
      sourceEn
      datemarker
      link
    }
  }
`;

export const DELETE_OUTLET = gql`
  mutation admMediaOutletsDelete($uuid: String!) {
    admMediaOutletsDelete(uuid: $uuid)
  }
`;

export const DELETE_TEXTREVIEW = gql`
  mutation deleteTextReiview($uuid: String!) {
    admMediaReviewsTextDelete(uuid: $uuid)
  }
`;
export const UPDATE_TEXTREVIEW = gql`
  mutation updateReview($uuid: String!, $data: MediaReviewsTextUpdate!) {
    admMediaReviewsTextUpdate(uuid: $uuid, data: $data) {
      id
      uuid
      choose
      avatar
      firstname
      firstnameEn
      lastname
      lastnameEn
      sourse
      sourceEn
      link
      comment
      commentEn
      datemarker
      review
    }
  }
`;
