import React,
{
  useState,
  useRef,
  useEffect,
  FC,
} from 'react'
import { useQuery } from '@apollo/client'
import { GET_TEAM } from '../../../../../apollo/query/editSite/teamPage'
import Arrow from './icon_arrowRight.svg'
import s from './SliderTeam.module.css'

interface SliderTeam {
  width: number
  clickHandler: (e: any) => void
}

export const SliderTeam: FC <SliderTeam> = ({
  width,
  clickHandler,
}) => {
  // const projectSliderRef = useRef(null)
  const projectSliderRef = useRef(null)
  const sliderRef = projectSliderRef as React.RefObject<any>
  const [positionProject, setPositionProject] = useState(0)
  const [counterPageProject, setCounterPageProject] = useState(1)
  const [slideWidth] = useState(7.03)
  // const [slideWidth] = useState(30)
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const minSwipeDistance = 50
  const [dataSlider, setDataSlider]: any = useState([])
  // const [isOpenPopup, setIsOpenPopup] = useState(false)
  // const refreshData = null
  const [counter, setCounter] = useState(0)
  const [counterPerson, setCounterPerson] = useState(1)
  const [counterFirstPicture, setCounterFirstPicture] = useState(0)
  const { data: dataTeam, loading, error } = useQuery(GET_TEAM)

  useEffect(() => {
    if (!dataTeam) return
    const temp = [...dataTeam?.personnel]
    setDataSlider([...temp, ...temp])
  }, [dataTeam])

  // для сброса слайдера при повороте экрана
  useEffect(() => {
    setPositionProject(0)
    setCounterPageProject(1)
    // if (isTurnDevice) {
    //   projectSliderRef?.current?.childNodes?.forEach(
    //     (el) => (el.style = `transform: translateX(${0}px`),
    //   )
    // }
  }, [width])

  function changeFirstPicture() {
    setCounterFirstPicture(counterFirstPicture + 1)
    if (counterPerson > dataTeam?.personnel?.length) {
      setCounterPerson(counterPerson + 1 - dataSlider?.length)
    } else {
      setCounterPerson(counterPerson + 1)
    }
  }

  function changeFirstPictureReverse() {
    if (counterFirstPicture !== 0) setCounterFirstPicture(counterFirstPicture - 1)
  }

  const moveFirstToLast = () => {
    const tempElement = dataSlider[counter]
    setCounter(counter + 1)
    const newArray = [...dataSlider, tempElement]
    setDataSlider(newArray)
  }

  const nextProjectHandler = () => {
    const slideIncrement = 1
    const slideWidthValue = slideWidth
    moveFirstToLast()
    changeFirstPicture()

    const totalPages = Math.ceil(dataSlider.length / slideIncrement)
    const currentPosition = -(
      slideWidthValue * (dataSlider.length - slideIncrement)
    )

    if (positionProject === currentPosition) setCounterPageProject(totalPages)
    if (counterPageProject < totalPages) {
      setPositionProject((positionProject - slideIncrement * slideWidthValue))

      setCounterPageProject(counterPageProject + 1)
      const temp = positionProject - slideIncrement * slideWidthValue
      if (sliderRef.current && sliderRef.current.childNodes) {
        sliderRef.current.childNodes.forEach((el: any) => {
          el.style.transform = `translateX(${temp}vw)`
        })
      }
      // if (projectSliderRef.current !== null) {
      //   projectSliderRef.current.childNodes.forEach((el: any) => (el.style = `transform: translateX(${temp}${'vw'})`)) // eslint-disable-line
      // }
    }
    if (counterPageProject + 1 === totalPages) {
      setPositionProject((positionProject - (dataSlider.length - slideIncrement * counterPageProject) * slideWidthValue))
    }
    if (counterPageProject + 1 > totalPages) {
      setPositionProject((positionProject - slideIncrement * slideWidthValue))
      const temp = positionProject - slideIncrement * slideWidthValue
      // projectSliderRef?.current?.childNodes?.forEach((el: any) => (el.style = `transform: translateX(${temp}${translateUnit})`)) // eslint-disable-line
      if (sliderRef.current && sliderRef.current.childNodes) {
        sliderRef.current.childNodes.forEach((el: any) => {
          el.style.transform = `translateX(${temp}vw)`
        })
      }
    }
  }

  const clickToSlide = (e: any, index: number) => {
    setCounterPageProject(index + 1)
    setCounterFirstPicture(index)
    const counter = index - (counterPageProject - 1)
    setPositionProject((positionProject - counter * slideWidth))
    const tempPosition = positionProject - counter * slideWidth
    // projectSliderRef.current.childNodes.forEach((el: any) => el.style = `transform: translateX(${tempPosition}${'vw'}`) // eslint-disable-line
    if (sliderRef.current && sliderRef.current.childNodes) {
      sliderRef.current.childNodes.forEach((el: any) => {
        el.style.transform = `translateX(${tempPosition}vw)`
      })
    }
    const newArray = [...dataSlider, ...dataTeam?.personnel]
    setDataSlider(newArray)
  }

  const prevHandlerProject = () => {
    const slideIncrement = 1
    const slideWidthValue = slideWidth

    changeFirstPictureReverse()

    const totalPages = Math.ceil(dataSlider.length / slideIncrement)
    const currentPosition = 0

    if (positionProject === 0 || counterPageProject === 1) {
      return
    }
    if (counterPageProject === 1) setPositionProject(0)
    if (positionProject === currentPosition) {
      setCounterPageProject(totalPages)
      return
    }
    setCounterPageProject(counterPageProject - 1)
    if (counterPageProject - 1 === totalPages - 1) {
      setPositionProject((positionProject + (dataSlider.length % slideIncrement === 0
        ? slideIncrement
        : dataSlider.length % slideIncrement)
        * slideWidthValue))
    } else {
      setPositionProject(positionProject + slideIncrement * slideWidthValue)
      const temp = positionProject + slideIncrement * slideWidthValue
      // projectSliderRef.current.childNodes.forEach((el: any) => (el.style = `transform: translateX(${temp}${'vw'})`)) // eslint-disable-line
      if (sliderRef.current && sliderRef.current.childNodes) {
        sliderRef.current.childNodes.forEach((el: any) => {
          el.style.transform = `translateX(${temp}vw)`
        })
      }
    }
  }

  const onTouchStart = (e:any) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe || isRightSwipe) {
      if (isLeftSwipe) {
        nextProjectHandler()
      } else {
        prevHandlerProject()
      }
    }
  }

  if (loading) return <div>loading</div>
  if (error) return <div>error</div>

  return (
    <>
      <div
        className={s.projectSlider}
        ref={projectSliderRef}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {dataSlider.map((el: any, index: number) => (
          <div
            className={
              index !== counterFirstPicture ? s.projectSlider__link : null
            }
            key={'team0'.concat(String(index))}
            id={el.uuid}
            // onClick={index === counterFirstPicture ? openPopUp : null}
            onClick={
              index === counterFirstPicture
                ? (e: any) => clickHandler(e)
                : (e: any) => clickToSlide(e, index)
            }
          >
            <div
              className={
                index === counterFirstPicture
                  ? s.projectSlider__link__project__background_large
                  : s.projectSlider__link__project__background
              }
              style={{
                backgroundImage: `url('${el.photo}')`,
              }}
              id={el.uuid}
              key={'team1'.concat(String(index))}
            >
              {index === counterFirstPicture && (
                <div
                  className={s.persones_description}
                  id={el.uuid}
                  key={'team2'.concat(String(index))}
                >
                  <div className={s.persones__title_wrapper}>
                    <p
                      className={s.persones__title}
                      id={el.uuid}
                      key={'team3'.concat(String(index))}
                    >
                      {el.personRole}
                    </p>
                    <img
                      className={s.persones__arrow}
                      src={Arrow}
                      alt="arrow"
                    />
                  </div>
                  <hr
                    className={s.persones__hr}
                    id={el.uuid}
                    key={'team4'.concat(String(index))}
                  />
                  <p
                    className={s.persones__name}
                    id={el.uuid}
                    key={'team5'.concat(String(index))}
                  >
                    {el.firstname}
                  </p>
                  <p
                    className={s.persones__text}
                    id={el.uuid}
                    key={'team6'.concat(String(index))}
                  >
                    {el.slogan}
                  </p>
                  {/* <p
                    className={clsx(s.persones__read_more, s.persones__text)}
                    id={el.uuid}
                    key={"team7".concat(String(index))}
                  >
                    читать подробнее
                  </p> */}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className={s.navigation}>
        <div className={s.navigation_wrapper}>
          <div className={s.navigation_prevBtn} onClick={prevHandlerProject}>
            {counterPageProject > 1 && (
              <div className={s.navigation_prevBtn_image}>
                &nbsp;
              </div>
            )}
          </div>
          <div className={s.navigation_nextBtn} onClick={nextProjectHandler}>
            <div className={s.navigation_nextBtn_image}>
                &nbsp;
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
