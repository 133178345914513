import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UsersType {
  projectUUID: string
  clientUUID: string
  // contractUUID: string
  contractNumber: string
  number: string
  sum: string
  date: string
  file: string
  // actFileUrl: string
}

interface actSlice {
  act: UsersType
  statusFilePDFAct: string | null
}

const initialState: actSlice = {
  act: {
    number: '',
    projectUUID: '',
    contractNumber: '',
    clientUUID: '',
    sum: '',
    date: '',
    // contractUUID: '',
    file: '',
    // actFileUrl: '',
  },
  statusFilePDFAct: null,
}

export const actSlice = createSlice({
  name: 'select',
  initialState,
  reducers: {
    addAct(state, action) {
      state.act = {
        ...state.act,
        [action.payload.project]: action.payload.item,
      }
    },
    resetAct(state) {
      state.act = {
        number: '',
        projectUUID: '',
        contractNumber: '',
        clientUUID: '',
        sum: '',
        date: '',
        // contractUUID: '',
        file: '',
        // actFileUrl: '',
      }
      state.statusFilePDFAct = null
    },
    closeSelect(state) {
      state.statusFilePDFAct = null
    },
    statusPDFAct(state, action) {
      state.statusFilePDFAct = action.payload
    },
  },
})

export const {
  addAct, resetAct, closeSelect, statusPDFAct,
} = actSlice.actions

export default actSlice.reducer
