import React from 'react'
import clsx from 'clsx'
import { Panel } from '../Panel'
import logout from './img/icon-logout.svg'
import s from './Dropdown.module.css'

type dropDownProps = {
    className?: string
    content: string
    icon?: string
    onClick?: () => void
}

export const Dropdown = ({
  className, content, onClick, icon = logout,
}: dropDownProps) => (
  <div className={clsx(s.dropdown, className)}>
    <Panel className={s.dropdown_panel}>
      <button className={s.dropdrown_btn} type="button" onClick={onClick}>
        <img src={icon} alt="logout" width="15" />
        {content}
      </button>
    </Panel>
  </div>
)
