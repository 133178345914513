import React, { ReactNode, FC } from 'react'
import clsx from 'clsx'
import defaultEdit from './icons/icon-edit_doc.svg'
import activeEdit from './icons/icon-edit_doc_active.svg'
import s from './PromotionsEdit.module.css'

interface PromotionsEditType {
  className?: string
  active: boolean
  children?: ReactNode
  onClick?: () => void
}

export const PromotionsEdit: FC<PromotionsEditType> = ({
  className,
  active,
  children,
  onClick,
}) => (
  <div onClick={onClick} className={clsx(s.edit, className)}>
    {active ? (
      <>
        <img src={activeEdit} width="30px" height="30px" alt="defaultEdit" />
        {children}
      </>
    ) : (
      <img src={defaultEdit} width="30px" height="30px" alt="activeEdit" />
    )}
  </div>
)
