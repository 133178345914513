/* eslint-disable wrap-iife */
/* eslint-disable @typescript-eslint/no-extra-semi */
import { useEffect } from 'react'
import { useAppSelector } from '../../redux/hook'
import { useGetRole } from '../../hooks/useGetRole'
import { Layout } from '../../features/Layouts'
import { Title } from '../../ui'
import {
  Table, CreateDoc, CreateAct, CreateReport
} from '../../components'
import {
  admin,
  lawyer,
  manager,
  marketer,
  technical1,
  technical2,
} from '../../references/data'
import s from './Documents.module.css'
import { UserProfile } from '../../components/UserProfile/UserProfile'

export const Documents = () => {
  const { isShow, isShowAct, isShowReport } = useAppSelector(
    state => state.pageSlice
  )

  const { isRole, location, navigate } = useGetRole()

  useEffect(() => {
    ;(function redirect() {
      if (isRole === technical1 && location.pathname === '/documents') {
        navigate('/clients', { replace: true })
      } else if (isRole === marketer && location.pathname === '/documents') {
        navigate('/promotion', { replace: true })
      }
    })()
  }, [location, isRole])

  return (
    <Layout>
      {(isRole === admin ||
        isRole === technical2 ||
        isRole === lawyer ||
        isRole === manager) && (
        <>
          <div className={s.header}>
            <Title className={s.docs__title} title="Документы" />
            <UserProfile isRole={isRole} navigate={navigate} />
          </div>

          {(!isShow && !isShowAct && !isShowReport && <Table />) ||
            (isShow && <CreateDoc />) ||
            (isShowAct && <CreateAct />) ||
            (isShowReport && <CreateReport />)}
        </>
      )}
    </Layout>
  )
}
