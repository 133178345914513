/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

interface PageSlice {
	isShow: boolean
	isCreateProject: boolean
	isCreateClient: boolean
	isCreatePromotion: boolean
	isShowAct: boolean
	isShowReport: boolean
	isChat: boolean
	isChatActive: boolean
	isListToWrite: boolean
	isChatMessageList: boolean
	chatChannelId: number | null | undefined
	titleChat: string
	photoChatUser: string
	infoMembers: any
	countMembers: number | null
	tempBrockerMembers: any
}

const initialState: PageSlice = {
  isShow: false,
  isCreateProject: false,
  isCreateClient: false,
  isCreatePromotion: false,
  isShowAct: false,
  isShowReport: false,
  isChat: false,
  isChatActive: true,
  isListToWrite: false,
  isChatMessageList: false,
  chatChannelId: null,
  titleChat: '',
  photoChatUser: '',
  infoMembers: [],
  countMembers: null,
  tempBrockerMembers: {},
}

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    openPage(state) {
      state.isShow = true
    },
    closePage(state) {
      state.isShow = false
    },
    openProject(state) {
      state.isCreateProject = true
    },
    closeProject(state) {
      state.isCreateProject = false
    },
    openClient(state) {
      state.isCreateClient = true
    },
    closeClient(state) {
      state.isCreateClient = false
    },
    openPromotion(state) {
      state.isCreatePromotion = true
    },
    closePromotion(state) {
      state.isCreatePromotion = false
    },
    openPageAct(state) {
      state.isShowAct = true
    },
    closePageAct(state) {
      state.isShowAct = false
    },
    openPageReport(state) {
      state.isShowReport = true
    },
    closePageReport(state) {
      state.isShowReport = false
    },
    openChat(state) {
      state.isChat = true
    },
    closeChat(state) {
      state.isChat = false
    },
    openActiveChat(state) {
      state.isChatActive = true
    },
    closeActiveChat(state) {
      state.isChatActive = false
    },
    openChatListToWrite(state) {
      state.isListToWrite = true
    },
    closeChatListToWrite(state) {
      state.isListToWrite = false
    },
    openChatMessageList(state, action) {
      state.isChatMessageList = true
      state.chatChannelId = action.payload.id
      state.titleChat = action.payload.title
      state.photoChatUser = action.payload.photo
      state.countMembers = action.payload.members
      state.tempBrockerMembers = action.payload.brocker
    },
    openChatMessageListMembers(state, action) {
      state.isChatMessageList = true
      state.chatChannelId = action.payload.id
      state.titleChat = action.payload.title
      state.photoChatUser = action.payload.photo
    },
    closeChatMessageList(state) {
      state.isChatMessageList = false
      state.chatChannelId = null
      state.titleChat = ''
      state.photoChatUser = ''
      state.countMembers = null
      state.tempBrockerMembers = {}
    },
  },
})

export const {
  openPage,
  closePage,
  openProject,
  closeProject,
  openClient,
  closeClient,
  openPromotion,
  closePromotion,
  openPageAct,
  closePageAct,
  openPageReport,
  closePageReport,
  openChat,
  closeChat,
  openActiveChat,
  closeActiveChat,
  openChatListToWrite,
  closeChatListToWrite,
  openChatMessageList,
  closeChatMessageList,
} = pageSlice.actions

export default pageSlice.reducer
