import { gql } from '@apollo/client'

export const CREATE_PERSONNEL = gql`
  mutation create_Broker($data: PersonnelData!) {
    admPersonnelCreate(data: $data) {
      uuid
      status
      employee
      firstname
      lastname
      patronymic
      employee
      firstname
      lastname
      patronymic
      personRole
      phone
      otherPhone
      slogan
      photo
      numbers
      bio
    }
  }
`

export const DELETE_BROKER = gql`
  mutation delete_Broker($uuid: String!) {
    admPersonnelDelete(uuid: $uuid)
  }
`
export const UPDATE_BROKER = gql`
  mutation update_broker($uuid: String!, $data: PersonnelDataUpdate!) {
    admPersonnelUpdate(uuid: $uuid, data: $data) {
      uuid
      status
      employee
      firstname
      lastname
      patronymic
      personRole
      phone
      otherPhone
      slogan
      photo
      numbers
      bio
    }
  }
`
