import React, { CSSProperties } from 'react'
import clsx from 'clsx'
import { Avatar, Counter } from '../../ui'
import initial from './img/icon-initial.svg'
import read from './img/icon-status-read.svg'
import unread from './img/icon-status-unread.svg'
import s from './ChatMessageItemGroup.module.css'
import { compareDates } from '../../references/data'

interface ChatMessageItemGroupProps {
  className?: string
  src?: string
  srcInvestor?: string
  firstName?: string | undefined
  lastName?: string | undefined
  middleName?: string | undefined
  name?: string | undefined
  createdAt?: any
  statusIcon?: string
  countUnread?: number
  role?: string | undefined
  style?: CSSProperties | undefined
  onClick?: () => void
}

export const ChatMessageItemGroup = ({
  className,
  style,
  src = initial,
  srcInvestor = initial,
  firstName = '',
  lastName = '',
  middleName = '',
  name = '',
  createdAt = '',
  statusIcon,
  countUnread = 0,
  role,
  onClick,
}: ChatMessageItemGroupProps) => {
  const currentDate = Date.now()
  const msPerday = 24 * 3600 * 1000
  return (
    <div
      className={clsx(s.chat__item, className)}
      onClick={onClick}
      style={style}
    >
      <div className={s.chat__item_avatar}>
        {src ? (
          <div className={s.checkbox_avatar}>
            <img
              className={s.img}
              src={src}
              alt="avatar"
              width="50"
              height="50"
            />
          </div>
        ) : (
          <Avatar
            className={s.chat__item_noavatar}
            firstName={firstName[0]}
            lastName={lastName[0]}
          />
        )}
      </div>
      <div className={s.chat__item_info}>
        <div className={s.chat__item_title}>
          <p className={s.chat__item_name}>{name}</p>
          <div style={{ display: 'flex', columnGap: 5, alignItems: 'center' }}>
            <p className={s.chat__item_date}>
              {createdAt ? (
                compareDates(currentDate, createdAt) ? (
                  new Intl.DateTimeFormat('ru', {
                    hour: 'numeric',
                    minute: 'numeric',
                  }).format(Date.parse(createdAt))
                ) : currentDate - Date.parse(createdAt) < msPerday ? (
                  <>Вчера</>
                ) : (
                  new Intl.DateTimeFormat('ru', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  }).format(Date.parse(createdAt))
                )
              ) : null}
            </p>
            {statusIcon && (
              <div className={s.chat__item_status}>
                <img
                  src={statusIcon === 'read' ? read : unread}
                  alt="status"
                  width="20"
                  height="20"
                />
              </div>
            )}
          </div>
        </div>
        <div className={s.chat__item_message}>
          <div className={s.chat__item_investorTitle}>
            {srcInvestor ? (
              <div className={s.chat__avatar_investor}>
                <img
                  className={s.img}
                  src={srcInvestor}
                  alt="avatar"
                  width="40"
                  height="40"
                />
              </div>
            ) : (
              <Avatar
                className={s.chat__item_noavatar}
                firstName={firstName[0]}
                lastName={lastName[0]}
              />
            )}
            <p className={s.chat__item_investorName}>
              {`${firstName} ${middleName} ${lastName}`}
            </p>
          </div>
          {countUnread > 0 && role !== 'lawyer' ? (
            <Counter theme="warn" count={countUnread} />
          ) : null}
        </div>
        <p className={s.chat_help}>Нужна помощь</p>
      </div>
    </div>
  )
}
