import React from 'react'
import clsx from 'clsx'
import { ProjectPanel, Edit } from '../../ui'
import icon from './icons/icon-document.png'
import icon2x from './icons/icon-document2x.png'
import s from './User.module.css'

interface userItem {
	uuid: string
	client: { firstName: string; middleName: string; lastName: string }
	status: string
	number: string
	type?: string
	signedOn: string
	project: { name: string }
  broker: any
  date?: any
  name?: string
  contract?: any
}

interface UserType {
	user: userItem
	editActiveHandle: () => void
	active: boolean
	onEditClick: () => void
  removeDoc?: () => void
  act?: boolean
  // document?: boolean
  report?: boolean
}

export const User: React.FC<UserType> = ({
  user,
  editActiveHandle,
  active,
  onEditClick,
  removeDoc,
  act,
  report,
}) => (
  <div className={!report ? s.tableItems : s.tableItems_report} key={user.uuid}>
    <div className={clsx(s.tableItem)}>
      <img
        srcSet={`${icon} 1x, ${icon2x} 2x`}
        alt="icon"
        width="22px"
        height="22px"
      />
      <p className={clsx(s.table__p, user.status === 'sent' || user.status === 'publish' ? s.active : '')}>
        {/* Договор №
          {' '} */}
        {report ? user.name : user.number}
      </p>
    </div>
    {/* <div>{act ? 'Акт' : report ? 'Отчет' : 'Договор'}</div> */}
    {(!act && !report) && (
      <div>
        {new Intl.DateTimeFormat('ru', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
        }).format(Date.parse(user?.signedOn))}
      </div>
    )}
    {act && (
      <div>
        {new Intl.DateTimeFormat('ru', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
        }).format(Date.parse(user?.date))}
      </div>
    )}
    {/* {report && (<div>{null}</div>)} */}
    {!report ? (<div>{Object.values(user.client).splice(1, 3).join(' ')}</div>) : null}
    <ProjectPanel className={s.projectName}>{user.project.name}</ProjectPanel>
    {report && (<div>{null}</div>)}
    {report && (<div>{null}</div>)}
    <div className={s.lastItem}>
      <div>{user?.broker?.name || user?.contract?.broker?.name}</div>
      <Edit
        active={active}
        onClick={editActiveHandle}
        onEditClick={onEditClick}
        removeDoc={removeDoc}
      />
    </div>
  </div>
)
