import { useEffect } from 'react'
import clsx from 'clsx'
import { useAppSelector } from '../../redux/hook'
import { useGetRole } from '../../hooks/useGetRole'
import { Layout } from '../../features/Layouts'
import { Title } from '../../ui'
import {
  EditMainPage,
  EditTeamPage,
  CreateBroker,
  EditBroker,
  EdiMediaPage,
  CreateVideo,
  EditVideo,
  CreateVideoReport,
  EditVideoReport,
  CreateOutlet,
  CreateReview,
  EditReview,
  EditOutlet,
  EditBusinessPage,
  EditFaq,
  EditFooter,
} from '../../components'
import {
  admin,
  lawyer,
  manager,
  marketer,
  technical1,
  technical2,
} from '../../references/data'
import s from './EditSite.module.css'
import { UserProfile } from '../../components/UserProfile/UserProfile'

export const EditSite = () => {
  const { nav, isCreateBroker } = useAppSelector(state => state.editSiteSlice)
  const { isRole, location, navigate } = useGetRole()

  useEffect(() => {
    (function redirect() {
      if (isRole === technical1 && location.pathname === '/editsite') {
        navigate('/clients', { replace: true })
      } else if (isRole === technical2 && location.pathname === '/editsite') {
        navigate('/documents', { replace: true })
      } else if (isRole === lawyer && location.pathname === '/editsite') {
        navigate('/home', { replace: true })
      } else if (isRole === manager && location.pathname === '/editsite') {
        navigate('/home', { replace: true })
      }
    }())
  }, [location, isRole])

  return (
    <Layout>
      {isRole === admin || isRole === marketer ? (
        <>
          <div className={s.header}>
            <div className={s.title}>
              <Title
                className={clsx(s.promotion__title)}
                title="Изменить сайт"
              />
            </div>
            <UserProfile isRole={isRole} navigate={navigate} />
          </div>
          {nav === 1 && !isCreateBroker && <EditMainPage />}
          {nav === 2 && !isCreateBroker && <EditTeamPage />}
          {isCreateBroker && <CreateBroker />}
          {nav === 3 && !isCreateBroker && <EditBroker />}
          {nav === 4 && !isCreateBroker && <EdiMediaPage />}
          {nav === 5 && !isCreateBroker && <CreateVideo />}
          {nav === 6 && !isCreateBroker && <EditVideo />}
          {nav === 7 && !isCreateBroker && <CreateVideoReport />}
          {nav === 8 && !isCreateBroker && <EditVideoReport />}
          {nav === 9 && !isCreateBroker && <CreateOutlet />}
          {nav === 10 && !isCreateBroker && <CreateReview />}
          {nav === 11 && !isCreateBroker && <EditOutlet />}
          {nav === 12 && !isCreateBroker && <EditReview />}
          {nav === 13 && !isCreateBroker && <EditBusinessPage />}
          {nav === 14 && !isCreateBroker && <EditFaq />}
          {nav === 15 && !isCreateBroker && <EditFooter />}
        </>
      ) : null}
    </Layout>
  )
}
