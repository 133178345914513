import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useMutation, useQuery } from '@apollo/client'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  changeSelect,
  addUsers,
  changeSingleSelect,
  resetObj,
  closeOnlySelect,
  statusPDF,
} from '../../redux/slices/selectSlice'
import { closePageReport } from '../../redux/slices/pageSlice'
import { Upload } from '../Upload'
import {
  Container,
  Field,
  Label,
  Panel,
  Select,
  DataPicker,
  ProgressBar,
  Title,
  CloseIcon,
  Button,
  Loader,
  NavProjects,
} from '../../ui'
// import { arr3, dateReplacements, periodArr } from './data'
import { CREATE_DOC, CREATE_ACT, CREATE_REPORT } from '../../apollo/mutation/createDoc'
import { ALL_PROJECTS } from '../../apollo/query/allProjects'
import { ALL_BROCKERS } from '../../apollo/query/allBrockers'
import { ALL_CLIENTS, CLIENTS } from '../../apollo/query/allClients'
// import { ALL_CONTRACTS, ALL_ACTS, ALL_REPORTS } from '../../apollo/query/allContracts'
import { ALL_CONTRACTS, ALL_ACTS, ALL_REPORTS } from '../../apollo/query/allContracts'
import { NativeSelect } from '../../ui/nativeSelect'
import iconDraft from './icon-draft.svg'
import iconSent from './icon-sent.svg'
import s from './CreateReport.module.css'
import { addAct, resetAct, statusPDFAct } from '../../redux/slices/actSlice'
import { addReport, resetReport, statusPDFReport } from '../../redux/slices/reportSlice'
import { PaymentTable } from '../PaymentTable/PaymentTable'

export const CreateReport = () => {
  const dispatch = useAppDispatch()
  const { report, statusFilePDFReport } = useAppSelector(state => state.reportSlice)
  const { data: allClients, refetch } = useQuery(ALL_CLIENTS)
  const [searchValueClientReport, setSearchValueClientReport] = useState<string>('')
  const [showInputReport, setShowInputReport] = useState(false)
  const [showSelectClientReport, setShowSelectClientsReport] = useState(false)
  const [draft, setDraft] = useState(false)
  const [selectProject, setSelectedProjects] = useState([])
  const [showSelectReport, setShowSelectReport] = useState(false)
  const [senting, setSenting] = useState(false)
  const [sumFieldReport, setSumFieldReport] = useState(0)
  const [reportName, setReportName] = useState('')
  const valuesReport = Object.values(report).map(elem => (elem !== '' ? 33.33 : 0)) // проверка объекта отчет на его значения

  const [sendReport, { data: dataReport, loading: loadingReport, error: errorReport }] = useMutation(
    CREATE_REPORT,
    {
      refetchQueries: [
        {
          query: ALL_REPORTS,
          variables: {
            filter: {
              // поменять когда переделают на возможность передавать массив
              projectUUID: undefined,
            },
          },
        },
        {
          query: CLIENTS,
        },
      ],
    },
  )

  const {
    loading,
    data: allContracts,
    refetch: refetchAllContracts,
  } = useQuery(ALL_CONTRACTS, {
    // variables: {
    //   slice: {
    //     offset: 0,
    //     limit,
    //   },
    // },
  })

  const { data: allProjects } = useQuery(ALL_PROJECTS, {
    variables: {
      filter: {
        // status: 'published',
      },
    },
  })

  const hidePage = () => {
    dispatch(closePageReport())
    dispatch(resetReport())
    dispatch(statusPDF(null))
    dispatch(statusPDFAct(null))
    dispatch(statusPDFReport(null))
  }

  // передаю номер uuid Клиента для формирования объекта
  const dispatchReportUUIDClient = (UUIDClient: string) => {
    dispatch(addReport({ project: 'clientUUID', item: UUIDClient }))
  }

  const handlerReport = () => {
    setShowSelectClientsReport(!showSelectClientReport)
    setShowInputReport(!showInputReport)
  }

  const selectReportHandler = () => {
    setShowSelectReport(!showSelectReport)
  }

  // передаю номер uuid Проекта для формирования объекта
  const dispatchReportUUIDProject = (UUIDProject: string) => {
    dispatch(addReport({ project: 'projectUUID', item: UUIDProject }))
  }

  const actReportHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReportName(e.target.value)
    dispatch(addReport({ project: 'name', item: e.target.value })) // debounce?
  }

  // делаем через useEffect, а то будет передаваться пустой массив
  useEffect(() => {
    if (!allProjects) return
    setSelectedProjects(allProjects.projects.map((e:any) => ({ ...e, active: false })))
  }, [allProjects])

  useEffect(() => {
    setSumFieldReport(valuesReport.reduce((a: any, b: any) => a + b))
    // setSumField(100)
  }, [report]) // сложение значений объекта

  const handleSaveDraftReport = () => {
    setDraft(true)
    // gql mutation
    sendReport({
      variables: {
        data: {
          ...report,
          status: 'draft',
          // file: 'null',
        },
      },
    })
    // setIsContract('')
    // setSum('')
    // setAmount('')
    // setPercent('')
    // dispatch(resetObj())
    dispatch(resetReport())
    if (!loadingReport && errorReport === undefined) {
      setTimeout(() => {
        setDraft(false)
        // window.location.reload()
        hidePage()
      }, 2000)
    } else {
      setTimeout(() => {
        setDraft(false)
      }, 2000)
    }
  }

  // сохранение и публикация договора
  const handleSaveSentReport = () => {
    setSenting(true)
    // gql mutation
    sendReport({
      variables: {
        data: {
          ...report,
          // file: 'null',
          status: 'publish',
        },
      },
    })
    if (!loadingReport || errorReport === undefined) {
      // setTimeout(() => {
      //   hidePage()
      //   window.location.reload()
      // }, 2000)
    } else {
      setTimeout(() => {
        setSenting(true)
      })
    }
  }

  const draftSaved = (
    <>
      <img src={iconDraft} alt="draft" width="17" height="17" />
      <span style={{ marginLeft: '7px' }}>Черновик сохранен</span>
    </>
  )

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: '7px' }}>Сохранено</span>
    </>
  )

  return (
    <Container className={s.container}>
      <CloseIcon onClick={hidePage} />
      <Title className={s.title} title="Добавить документ" />
      <div className={s.wrapper}>
        <div className={s.first__block}>
          <Panel padding className={s.panel}>
            <Title className={s.report_title} title="Информация" />
            <div className={s.report_firstLine}>
              {/* <Label
                  title="Клиент"
                >
                  <Select
                    refetch={refetch}
                    setSearchValueClientReport={setSearchValueClientReport}
                    searchValueClientReport={searchValueClientReport}
                    setShowInputReport={setShowInputReport}
                    showInputReport={showInputReport}
                    dispatchReportUUIDClient={dispatchReportUUIDClient}
                    onClick={handlerReport}
                    setShowSelectClientsReport={setShowSelectClientsReport}
                    showSelectClientsReport={showSelectClientReport}
                    show={showSelectClientReport}
                    report
                    user={allClients?.clients}
                    draft={draft}
                  />
                </Label> */}
              <Label title="Номер отчета">
                <Field
                  view="default"
                  placeholder="Номер отчета"
                  value={reportName}
                  onChange={e => actReportHandler(e)}
                />
              </Label>
              <Label
                title="Проект"
              >
                <NativeSelect
                  tags
                  pledgeArr={selectProject}
                  selectActProject
                  draft={draft}
                  setShowSelectAct={setShowSelectReport}
                  show={showSelectReport}
                  onClick={() => selectReportHandler()}
                  dispatchActUUIDProject={dispatchReportUUIDProject}
                />
              </Label>
            </div>
          </Panel>
          <Upload
            title="Загрузить файл"
            titleStatus="Отчет"
            text="Формат файла: .pdf"
            application="application/pdf"
            subText1="Загрузить с компьютера"
            subText2="или перетащите сюда"
            status={statusFilePDFReport}
            uploadId="pdf"
          />
          <Panel className={s.btns__panel}>
            <Button
              onClick={handleSaveDraftReport}
              // disabled={Math.ceil(sumFieldReport) !== 100}
              disabled={Math.ceil(sumFieldReport) < 60}
              className={clsx(s.btns__panel_draft)}
              theme="outline"
            >
              {!draft ? (
                'Сохранить как черновик'
              ) : loadingReport ? (
                <Loader />
              ) : !loadingReport && errorReport ? (
                'Ошибка'
              ) : (
                draftSaved
              )}
            </Button>
            <Button
              onClick={handleSaveSentReport}
              disabled={Math.ceil(sumFieldReport) !== 100}
              className={s.btns__panel_save}
              theme="dark"
            >
              {!senting ? (
                'Сохранить и опубликовать'
              ) : loadingReport ? (
                <Loader />
              ) : !loadingReport && errorReport ? (
                'Ошибка'
              ) : (
                sentSaved
              )}
            </Button>
          </Panel>
        </div>
        <Panel className={s.panel__progress} padding>
          <ProgressBar value={Math.ceil(sumFieldReport)} />
        </Panel>
      </div>
    </Container>
  )
}
