/* eslint-disable @typescript-eslint/no-extra-semi */
import React, { useContext, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  Button, ChatSwitcher, Panel, Title
} from '../../ui'
import { ChatMessagesActive } from '../ChatMessagesActive'
import { ChatMessagesArchive } from '../ChatMessagesArchive'
import {
  closeActiveChat,
  openActiveChat,
  openChatListToWrite,
} from '../../redux/slices/pageSlice'
import createChat from './img/icon-create-chat.svg'
import s from './ChatsPanel.module.css'
import { AppContext } from '../../features/Context/useAppContext'
import { lawyer, manager } from '../../references/data'
import { useGetRole } from '../../hooks/useGetRole'

interface ChatsPanelProps {
  chatUsersJoind?: any[]
}

export const ChatsPanel = ({ chatUsersJoind }: ChatsPanelProps) => {
  const [active, setActive] = useState<boolean>(true)
  const [archive, setArchive] = useState<boolean>(false)
  const { isCountUnread } = useContext(AppContext)
  const { isRole } = useGetRole()

  const { isChatActive } = useAppSelector(state => state.pageSlice)

  const dispatch = useAppDispatch()

  return (
    <Panel className={s.chat__panel}>
      <div className={s.chat__header}>
        <Title className={s.chat__title} title="Список чатов" />
        <div className={s.chat__switchers}>
          <ChatSwitcher
            title="Все чаты"
            theme={active ? 'active' : 'archive'}
            unRead={isCountUnread}
            unReadByLower={1}
            isRole={isRole}
            isIndicators
            onClick={() => {
              setActive(true)
              setArchive(false)
              dispatch(openActiveChat())
            }}
          />
          {/* {isRole !== manager && isRole !== lawyer && (
            <ChatSwitcher
              title="Архив"
              theme={archive ? 'active' : 'archive'}
              onClick={() => {
                setActive(false)
                setArchive(true)
                dispatch(closeActiveChat())
              }}
            />
          )} */}
        </div>
      </div>
      <>
        {isChatActive ? (
          <ChatMessagesActive chatUsersJoind={chatUsersJoind} />
        ) : (
          <ChatMessagesArchive />
        )}
      </>
      {isChatActive && (
        <>
          <div className={s.chat__icon}>
            <img src={createChat} alt="create_chat" width="70" height="70" />
          </div>
          <Button
            className={s.chat__createChat}
            onClick={() => dispatch(openChatListToWrite())}
          />
        </>
      )}
    </Panel>
  )
}
