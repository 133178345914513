import React from 'react'
import clsx from 'clsx'
import s from './Counter.module.css'

interface counterTypes {
  warn: string
  primary: string
}

const themes: counterTypes = {
  warn: s.warn,
  primary: s.primary,
}

interface CounterProps {
  className?: string
  theme?: keyof typeof themes
  count?: number | null
}

export const Counter = ({ className, theme, count }: CounterProps) => (
  <div className={clsx(s.counter, theme ? themes[theme] : null, className)}>
    <span>{count}</span>
  </div>
)
