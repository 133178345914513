/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface TableSlice {
  isModal: boolean
  removeProjectModal: boolean
  archiveProjectModal: boolean
  resumeProjectModal: boolean
  suspendProjectModal: boolean
  activeProjectModal: boolean
  deletePromotionStoryModal: boolean
  deletePromotionDynamicModal: boolean
  contractNumber: string
  projectName: string
  storyName: string
  dynamicStoryName: string
  editDoc: number
}

const initialState: TableSlice = {
  isModal: false,
  removeProjectModal: false,
  archiveProjectModal: false,
  resumeProjectModal: false,
  suspendProjectModal: false,
  activeProjectModal: false,
  deletePromotionStoryModal: false,
  deletePromotionDynamicModal: false,
  contractNumber: '',
  projectName: '',
  dynamicStoryName: '',
  storyName: '',
  editDoc: 0,
}

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    showModal(state) {
      state.isModal = true
    },
    hideModal(state) {
      state.isModal = false
    },
    showRemoveProjectModal(state) {
      state.removeProjectModal = true
    },
    showArchiveProjectModal(state) {
      state.archiveProjectModal = true
    },
    showResumeProjectModal(state) {
      state.resumeProjectModal = true
    },
    showSuspendProjectModal(state) {
      state.suspendProjectModal = true
    },
    showActiveProjectModal(state) {
      state.activeProjectModal = true
    },
    showDeletePromotionStoryModal(state) {
      state.deletePromotionStoryModal = true
    },
    showDeleteDynamicStoryModal(state) {
      state.deletePromotionDynamicModal = true
    },
    hideRemoveProjectModal(state) {
      state.removeProjectModal = false
    },
    hideArchiveProjectModal(state) {
      state.archiveProjectModal = false
    },
    hideResumeProjectModal(state) {
      state.resumeProjectModal = false
    },
    hideSuspendProjectModal(state) {
      state.suspendProjectModal = false
    },
    hideActiveProjectModal(state) {
      state.activeProjectModal = false
    },
    hideDeletePromotionStoryModal(state) {
      state.deletePromotionStoryModal = false
    },
    hideDeleteDynamicStoryModal(state) {
      state.deletePromotionDynamicModal = false
    },
    changeName(state, action: PayloadAction<string>) {
      state.contractNumber = action.payload
    },
    addProjectName(state, action: PayloadAction<string>) {
      state.projectName = action.payload
    },
    addDynamicStoryName(state, action: PayloadAction<string>) {
      state.dynamicStoryName = action.payload
    },
    addStoryName(state, action: PayloadAction<string>) {
      state.storyName = action.payload
    },
    chooseEditDoc(state, action: PayloadAction<number>) {
      state.editDoc = action.payload
    },
  },
})

export const {
  showModal,
  hideModal,
  showRemoveProjectModal,
  hideRemoveProjectModal,
  showArchiveProjectModal,
  hideArchiveProjectModal,
  showResumeProjectModal,
  hideResumeProjectModal,
  showSuspendProjectModal,
  hideSuspendProjectModal,
  showActiveProjectModal,
  hideActiveProjectModal,
  showDeletePromotionStoryModal,
  hideDeletePromotionStoryModal,
  showDeleteDynamicStoryModal,
  hideDeleteDynamicStoryModal,
  changeName,
  addProjectName,
  addDynamicStoryName,
  addStoryName,
  chooseEditDoc,
} = tableSlice.actions

export default tableSlice.reducer
