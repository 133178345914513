import { gql } from '@apollo/client'

export const ALL_CONTRACTS = gql`
  query AllContracts(
    $slice: Slice
    $sort: ContractSort
    $filter: ContractsFilter
  ) {
    contracts(slice: $slice, sort: $sort, filter: $filter) {
      id
      uuid
      number
      signedOn
      status
      client {
        firstName
        middleName
        lastName
      }
      broker {
        name
      }
      project {
        uuid
        name
      }
      contractFileUrl
    }
  }
`
export const ALL_ACTS = gql`
  query Acts($sort: ActSort, $filter: ActsFilter) {
    acts(sort: $sort, filter: $filter) {
      id
      uuid
      number
      client {
        lastName
        firstName
        middleName
      }
      project {
        uuid
        name
      }
      sum
      date
      file
      status
      contract {
        broker {
          name
        }
      }
    }
  }
`
export const ALL_REPORTS = gql`
  query Report($filter: ReportsFilter) {
    reports(filter: $filter) {
      id
      uuid
      name
      project {
        uuid
        name
      }
      file
      status
    }
  }
`
