import React, { ChangeEvent, useContext, useState } from 'react'
import clsx from 'clsx'
import { useQuery, useMutation } from '@apollo/client'
import { AppContext } from '../../features/Context/useAppContext'
import { PROMOTION_DYNAMICS } from '../../apollo/query/promotion/dynamics'
import { ADM_DYNAMICS_PUSH } from '../../apollo/mutation/promotion/promotionPush'
import {
  Button, Checkbox, Field, Panel, Title, ToggleButton
} from '../../ui'
import arrow from './img/icon-arrow-up.svg'
import logo from './img/icon-logo-white.svg'
import s from '../PromotionStoriesSelect/PromotionStoriesSelect.module.css'

export const PromotionDynamicSelect = () => {
  const [value, setValue] = useState<any>('')
  const [collapse, setCollapse] = useState<boolean>(false)
  const {
    isCheck,
    setIsCheck,
    setIsCheckAll,
    isDynamic,
    setIsDynamic,
    isCheckDynamics,
    setIsCheckDynamics,
    handleSelectDynamics,
    handleSelectDynamic,
  } = useContext(AppContext)

  const {
    data: dynamics,
    loading: loadingStories,
    error: errorStories,
  } = useQuery(PROMOTION_DYNAMICS, {
    variables: {
      filter: {
        status: 'active',
      },
    },
  })

  const [sendDynamicsPush] = useMutation(ADM_DYNAMICS_PUSH)

  const sendDynamicPush = () => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth()
    const day = currentDate.getDate()
    const hour = currentDate.getHours()
    const minutes = currentDate.getMinutes()
    const seconds = currentDate.getSeconds()
    const ms = currentDate.getMilliseconds()

    sendDynamicsPush({
      variables: {
        profilesUUID: isCheck,
        promotionDynamicUUID: isDynamic,
        actualDate: new Date(
          Date.UTC(year, month, day, hour, minutes, seconds, ms)
        ).toISOString(),
      },
    })
    setIsCheck([])
    setIsDynamic([])
    setIsCheckAll(false)
    setIsCheckDynamics(false)
  }

  const dynamicsFilteredByName = dynamics?.promotionDynamics === null
    ? []
    : dynamics?.promotionDynamics?.filter((dynamic: any) => dynamic?.name?.toLowerCase().includes(value.toLowerCase().trim()))

  return (
    <Panel
      className={clsx(s.promotion_select, {
        [s.promotion_select_active]: collapse,
      })}
      padding
    >
      <div className={s.promotion_select_header}>
        <Title
          className={s.promotion_select_title}
          title="Динамические акции"
        />
        <ToggleButton
          className={s.promotion_select_btn}
          src={arrow}
          rotate={collapse}
          iconWidth="18px"
          iconHeight="18px"
          onClick={() => setCollapse(!collapse)}
        />
      </div>
      <div>
        <Field
          className={clsx(s.promotion_select_search)}
          icon
          placeholder="Поиск"
          placeholderColor="grey"
          view="search"
          type="text"
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value)
            setIsCheckDynamics(false)
          }}
        />
        <Checkbox
          id="stories"
          name="stories"
          label="Выбрать всех"
          fontSize="14px"
          color="#717171"
          onChange={() => handleSelectDynamics(dynamicsFilteredByName)}
          isChecked={isCheckDynamics}
        />
        <div className={s.promotion_select_wrapper}>
          {dynamicsFilteredByName?.map((dynamic: any) => (
            <Checkbox
              key={dynamic?.uuid}
              id={dynamic?.uuid}
              name={dynamic?.uuid}
              label={dynamic?.name}
              avatar
              src={dynamic?.image || logo}
              logoWidth="30px"
              logoHeight="30px"
              fontSize="14px"
              onChange={handleSelectDynamic}
              isChecked={isDynamic.includes(dynamic?.uuid)}
            />
          ))}
        </div>
        <Button
          className={clsx(s.promotion_select_send)}
          disabled={
            isCheck === undefined
            || isDynamic === undefined
            || isCheck.length === 0
            || isDynamic.length === 0
          }
          theme={
            isCheck === undefined
            || isDynamic === undefined
            || isCheck.length === 0
            || isDynamic.length === 0
              ? 'secondary'
              : 'dark'
          }
          onClick={sendDynamicPush}
        >
          Отправить
        </Button>
      </div>
    </Panel>
  )
}
