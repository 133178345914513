import { gql } from '@apollo/client'

// Мутация для обновления галерии при загрузке изображения
export const UPDATE_PROJECT_IMAGES = gql`
  mutation admProjectImageUpdate(
    $uuid: String!
    $data: ProjectImageDataUpdate!
  ) {
    admProjectImageUpdate(uuid: $uuid, data: $data) {
      id
      uuid
      logo
      bannerApp
      bannerSite
      background
      gallery
    }
  }
`
