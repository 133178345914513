import { createSlice } from '@reduxjs/toolkit'

interface ChatSlice {
  userUUID: string
}

const initialState: ChatSlice = {
  userUUID: '',
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatUserUUID(state, action) {
      state.userUUID = action.payload
    },
  },
})

export const { setChatUserUUID } = chatSlice.actions
export default chatSlice.reducer
