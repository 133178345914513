import { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { Avatar, Panel } from '../../ui'
import { ChatCreateText } from '../ChatCreateText'
import { ChatMessageUnit } from '../ChatMessageUnit'
import {
  GET_CHAT_MESSAGES,
  GET_CHAT_MESSAGES_LAWYER,
} from '../../apollo/query/chats/chatMessages'
import { CHAT_MESSAGES_CREATED } from '../../apollo/subscription/subscriptionChatMessageCreated'
import { useGetRole } from '../../hooks/useGetRole'
import { POST_CHAT_MESSAGES } from '../../apollo/mutation/chats/postChatMessages'
import { CHAT_CHANNEL } from '../../apollo/query/chats/chatChannels'
import { CHAT_MESSAGE_READ } from '../../apollo/mutation/chats/chatMessageRead'
import { CHAT_LAWYER_EXIT } from '../../apollo/mutation/chats/chatLawyerExit'
import s from './ChatMessagesList.module.css'

interface ChatMessagesListProps {
	chatChannelId: number | null | undefined
	photo?: string | null
	titleChat: string
	photoChatUser: string
	token: any
	countMembers?: number | null
	tempBrockerMembers?: any
}

export const ChatMessagesList = ({
  chatChannelId,
  titleChat,
  photoChatUser,
  token,
  countMembers,
  tempBrockerMembers,
}: ChatMessagesListProps) => {
  const [messageArr, setMessageArr] = useState<any>([])

  const { profileUUID, isRole } = useGetRole()

  const { client, refetch, data } = useQuery(CHAT_CHANNEL, {
    // pollInterval: 500,
  })

  const {
    data: chatMessages,
    loading: loadingChatMessages,
    error: errorChatMessages,
    refetch: refetchData,
  } = useQuery(GET_CHAT_MESSAGES, {
    variables: {
      chatChannelId,
    },
  })

  const {
    data: chatMessagesLawyer,
    loading: loadingChatMessagesLawyer,
    error: errorChatMessagesLawyer,
    refetch: refetchDataLawyer,
  } = useQuery(GET_CHAT_MESSAGES_LAWYER, {
    variables: {
      chatChannelId,
    },
  })

  const [
    postMessage,
    {
      data: postMessageDate,
      loading: postMessageLoading,
      error: postMessageError,
    },
  ] = useMutation(POST_CHAT_MESSAGES, {
    // refetchQueries: [
    // 	{
    // 		query: GET_CHAT_MESSAGES,
    // 		variables: {
    // 			chatChannelId,
    // 		},
    // 	},
    // 	{
    // 		query: GET_CHAT_MESSAGES_LAWYER,
    // 		variables: {
    // 			chatChannelId,
    // 		},
    // 	},
    // ],
  })

  const {
    data: messageCreate,
    loading: messageCreateLoading,
    error: messageCreatedError,
  } = useSubscription(CHAT_MESSAGES_CREATED, {
    variables: {
      chatChannelId,
      token,
    },
    // onData({ data }) {
    // 	console.log('data', data)
    // },

    // shouldResubscribe: true,
  })

  // console.log('messageCreate', messageCreate)
  // console.log('messageCreatedError', messageCreatedError)

  const [
    chatExit,
    { data: chatExitDate, loading: chatExitLoading, error: chatExitError },
  ] = useMutation(CHAT_LAWYER_EXIT, {
    refetchQueries: [
      {
        query: CHAT_CHANNEL,
      },
    ],
  })

  const [
    updateStatus,
    { data: dataRead, error: errorRead, client: clientReadMsg },
  ] = useMutation(CHAT_MESSAGE_READ, {
    refetchQueries: [
      {
        query: CHAT_CHANNEL,
      },
    ],
  })

  useEffect(() => {
    const func = async () => {
      await refetchData()
    }
    func()
  }, [chatChannelId])

  // Перемотка в самый низ в списке сообщений
  const divRefEndScroll = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (divRefEndScroll.current) {
      divRefEndScroll.current
        ?.scrollIntoView()
    }
  }
  scrollToBottom()
  //

  useEffect(() => {
    if (
      countMembers &&
			countMembers > 2 &&
			chatMessagesLawyer?.chatMessageALawyer
    ) {
      setMessageArr(
        chatMessagesLawyer?.chatMessageALawyer
          ? chatMessagesLawyer?.chatMessageALawyer
          : []
      )
      const messageArrReadStatus = chatMessagesLawyer?.chatMessageALawyer
        ?.filter(
          (e: any) => e?.status !== 'read' && e?.profile?.uuid !== profileUUID
        )
        ?.map((elem: any) => elem.id)
      if (messageArrReadStatus) {
        updateStatus({
          variables: {
            msgIds: messageArrReadStatus,
          },
        })
      }
    } else {
      setMessageArr(
        chatMessages?.chatMessages ? chatMessages?.chatMessages : []
      )
      const messageArrReadStatus = chatMessages?.chatMessages
        ?.filter(
          (e: any) => e?.status !== 'read' && e?.profile?.uuid !== profileUUID
        )
        ?.map((elem: any) => elem.id)
      if (messageArrReadStatus) {
        updateStatus({
          variables: {
            msgIds: messageArrReadStatus,
          },
        })
      }
    }
  }, [
    chatMessages?.chatMessages,
    chatChannelId,
    countMembers,
    chatMessagesLawyer?.chatMessages,
  ])

  useEffect(() => {
    if (data?.chatChannels) {
      const func = async () => {
        await refetchData()
      }
      func()
    }
  }, [data?.chatChannels])

  // console.log('data?.chatChannels', data?.chatChannels)

  useEffect(() => {
    if (messageCreate?.chatMessageCreated) {
      setMessageArr((prev: any) => [...prev, messageCreate?.chatMessageCreated])
      if (messageCreate?.chatMessageCreated?.profile.uuid !== profileUUID) {
        updateStatus({
          variables: {
            msgIds: [messageCreate?.chatMessageCreated.id],
          },
        })
      }
    }
  }, [messageCreate])

  const postMessageHandler = async (text: string) => {
    await postMessage({
      variables: {
        text,
        chatChannelId,
        messageType: 'text',
      },
    })
  }

  const chatLawerExit = async () => {
    await chatExit({
      variables: {
        profileUUID: process.env.REACT_APP_API_LAWYER,
        chatChannelId,
      },
    })
  }

  const [firstName = '', LastName = ''] = titleChat?.split(' ') || []

  const [firstNameBrocker = '', lastNameBrocker = ''] =
		tempBrockerMembers?.title?.split(' ') || []

  return (
    <Panel className={s.messages__list}>
      <div className={s.chat__header}>
        <div className={s.chat__item_wrapper}>
          <div className={s.chat__item_avatar}>
            {photoChatUser ? (
              <div className={s.checkbox_avatar}>
                <img
                  className={s.img}
                  src={photoChatUser}
                  alt="avatar"
                  width="60"
                  height="60"
                />
              </div>
            ) : (
              <Avatar
                className={s.chat__item_noavatar}
                firstName={firstName?.charAt(0)}
                lastName={LastName?.charAt(0)}
              />
            )}
          </div>
          <p>{titleChat}</p>
        </div>
        {countMembers && countMembers > 2 && (
          <div className={s.chat__item_wrapper}>
            <div className={s.chat__item_avatar}>
              {tempBrockerMembers?.photo ? (
                <div className={s.checkbox_avatar}>
                  <img
                    className={s.img}
                    src={tempBrockerMembers?.photo}
                    alt="avatar"
                    width="60"
                    height="60"
                  />
                </div>
              ) : (
                <Avatar
                  className={s.chat__item_noavatar}
                  firstName={firstNameBrocker?.charAt(0)}
                  lastName={lastNameBrocker?.charAt(0)}
                />
              )}
            </div>
            <p>{tempBrockerMembers?.title}</p>
          </div>
        )}
      </div>
      <div className={s.chat__messages_wrapper}>
        {messageArr?.map((e: any) => (
          <ChatMessageUnit
            avatar={
              e?.profile.role === 'investor'
                ? e?.profile?.client?.photo
                : e?.profile[e?.profile.role]?.photo
            }
            key={e.id}
            theme={e?.profile?.role}
            text={e?.text}
            name={
              e?.profile?.role === 'investor'
                ? `${e?.profile?.client.firstName} ${e?.profile?.client.lastName}`
                : e.profile[e.profile.role]?.name
            }
            indent={
              e?.profile?.role !== 'lawyer' &&
							e?.chatChannel?.members?.length >= 3
                ? 'indent'
                : undefined
            }
            createdAt={Date.parse(e?.createdAt)}
            readStatus={isRole === e?.profile?.role && e?.status}
            messageType={e?.messageType}
            fileName={e?.fileName}
          />
        ))}
        <div ref={divRefEndScroll} />
      </div>
      <ChatCreateText
        chatChannelId={chatChannelId}
        postMessage={(value: string) => postMessageHandler(value)}
        onChatExit={() => chatLawerExit()}
        isRole={isRole}
      />
    </Panel>
  )
}
