/* eslint no-use-before-define: "error" */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// type action = {
//   id: number
//   nameField: any
//   event: any
// }

interface colors1 extends Record<string, any> {
	defaultColors: { id: number; color: string; active: boolean }
	gradientColors: { id: number; color: string; active: boolean }
}

interface colors extends Record<string, any> {
	id: number
	color: string
	active: boolean
}

export interface promotionDynamicState extends Record<string, any> {
	name: string
	status: string
	startedAt: Date | null | any
	endedAt: Date | null | any
	text: string
	header: string
	color: string
	image: string
	gradientColor: string[] | []
	gradientDeg: number
	gradientLocation: number[] | []
}

interface promotionDynamicMainState {
	promotionDynamicObj: promotionDynamicState
	// colorsSlide: colors[]
	// colorsDynamicMiniature: colors[]
	colorsDynamicMiniature: any
	statusSelect: boolean
}

const colorsArr = [
  {
    defaultColors: [
      { id: 0, color: '#FF4D4D', active: false },
      { id: 1, color: '#47FFA7', active: false },
      { id: 2, color: '#FBFF38', active: false },
      { id: 3, color: '#5CFF33', active: false },
      { id: 4, color: '#36FFFF', active: false },
      { id: 5, color: '#497CFF', active: false },
      { id: 6, color: '#C751FF', active: false },
      { id: 7, color: '#FF39B0', active: false },
      { id: 8, color: '#2899A0', active: false },
      { id: 9, color: '#3D9528', active: false },
      { id: 10, color: '#D4FF28', active: false },
      { id: 11, color: '#F3A9FF', active: false },
    ],
  },
  {
    gradientColors: [
      {
        id: 0,
        color:
					'linear-gradient(46deg, #000 0%, rgba(0, 0, 0, 0.00) 100%), #FF4D4D',
        active: false,
        gradientColor: ['#FF4D4D', '#000', 'rgba(0, 0, 0, 0)'],
        gradientDeg: 46,
        gradientLocation: [1, 0, 1],
      },
      {
        id: 1,
        color:
					'linear-gradient(46deg, #36FFFF 0%, rgba(0, 0, 0, 0.00) 100%), #47FFA7',
        active: false,
        gradientColor: ['#47FFA7', '#36FFFF', 'rgba(0, 0, 0, 0)'],
        gradientDeg: 46,
        gradientLocation: [1, 0, 1],
      },
      {
        id: 2,
        color:
					'linear-gradient(46deg, #5CFF33 0%, rgba(0, 0, 0, 0.00) 100%), #FBFF38',
        active: false,
        gradientColor: ['#FBFF38', '#5CFF33', 'rgba(0, 0, 0, 0)'],
        gradientDeg: 46,
        gradientLocation: [1, 0, 1],
      },
      {
        id: 3,
        color:
					'linear-gradient(46deg, #000 0%, rgba(0, 0, 0, 0.00) 100%), #5CFF33',
        active: false,
        gradientColor: ['#5CFF33', '#000', 'rgba(0, 0, 0, 0)'],
        gradientDeg: 46,
        gradientLocation: [1, 0, 1],
      },
      {
        id: 4,
        color:
					'linear-gradient(46deg, #000 0%, rgba(0, 0, 0, 0.00) 100%), #36FFFF',
        active: false,
        gradientColor: ['#36FFFF', '#000', 'rgba(0, 0, 0, 0)'],
        gradientDeg: 46,
        gradientLocation: [1, 0, 1],
      },
      {
        id: 5,
        color:
					'linear-gradient(46deg, #000 0%, rgba(0, 0, 0, 0.00) 100%), #497CFF',
        active: false,
        gradientColor: ['#497CFF', '#000', 'rgba(0, 0, 0, 0)'],
        gradientDeg: 46,
        gradientLocation: [1, 0, 1],
      },
      {
        id: 6,
        color:
					'linear-gradient(46deg, #FF4C4C 0%, rgba(0, 0, 0, 0.00) 100%), #C751FF',
        active: false,
        gradientColor: ['#C751FF', '#FF4C4C', 'rgba(0, 0, 0, 0)'],
        gradientDeg: 46,
        gradientLocation: [1, 0, 1],
      },
      {
        id: 7,
        color:
					'linear-gradient(46deg, #FF9E45 0%, rgba(0, 0, 0, 0.00) 100%), #FF39B0',
        active: false,
        gradientColor: ['#FF39B0', '#FF9E45', 'rgba(0, 0, 0, 0)'],
        gradientDeg: 46,
        gradientLocation: [1, 0, 1],
      },
      {
        id: 8,
        color:
					'linear-gradient(46deg, #FBFF38 0%, rgba(0, 0, 0, 0.00) 100%), #2899A0',
        active: false,
        gradientColor: ['#2899A0', '#FBFF38', 'rgba(0, 0, 0, 0)'],
        gradientDeg: 46,
        gradientLocation: [1, 0, 1],
      },
      {
        id: 9,
        color:
					'linear-gradient(46deg, #5CFF33 0%, rgba(0, 0, 0, 0.00) 100%), #3D9528',
        active: false,
        gradientColor: ['#3D9528', '#5CFF33', 'rgba(0, 0, 0, 0)'],
        gradientDeg: 46,
        gradientLocation: [1, 0, 1],
      },
      {
        id: 10,
        color:
					'linear-gradient(46deg, #36FFFF 0%, rgba(0, 0, 0, 0.00) 100%), #D4FF28',
        active: false,
        gradientColor: ['#D4FF28', '#36FFFF', 'rgba(0, 0, 0, 0)'],
        gradientDeg: 46,
        gradientLocation: [1, 0, 1],
      },
      {
        id: 11,
        color:
					'linear-gradient(46deg, #497CFF 0%, rgba(0, 0, 0, 0.00) 100%), #F3A9FF',
        active: false,
        gradientColor: ['#F3A9FF', '#497CFF', 'rgba(0, 0, 0, 0)'],
        gradientDeg: 46,
        gradientLocation: [1, 0, 1],
      },
    ],
  },
]

const initialState: promotionDynamicMainState = {
  promotionDynamicObj: {
    name: '',
    status: '',
    startedAt: new Date(),
    endedAt: new Date(),
    header: '',
    text: '',
    color: '',
    image: '',
    gradientColor: [],
    gradientDeg: 0,
    gradientLocation: [],
  },
  colorsDynamicMiniature: colorsArr,
  statusSelect: false,
}

export const promotionDynamicSlice = createSlice({
  name: 'promotionDinamicSlice',
  initialState,
  reducers: {
    changeFirstFieldDynamic(state, action) {
      if (
        action.payload.nameField.includes('startedAt')
				|| action.payload.nameField.includes('endedAt')
      ) {
        state.promotionDynamicObj[action.payload.nameField] =					action.payload.event?.toISOString()
      } else if (typeof action.payload.event === 'string') {
        state.promotionDynamicObj[action.payload.nameField] =					action.payload.event
      } else {
        state.promotionDynamicObj[action.payload.nameField] =					action.payload.event.target.value
      }
    },
    editChangeFieldDynamic(state, action) {
      state.promotionDynamicObj[action.payload.nameField] =				action.payload.event === 'draft'
				  ? 'Черновик'
				  : action.payload.event === 'active'
				    ? 'Активный'
				    : action.payload.event === 'archive'
				      ? 'Архив'
				      : action.payload.event
    },
    changeColorDesign(state, action) {
      state.colorsDynamicMiniature[action.payload.index][
        action.payload.nameGroup
      ] = state.colorsDynamicMiniature[action.payload.index][
        action.payload.nameGroup
      ].map((color: colors) => {
        if (color.id === action.payload.colorId) {
          state.promotionDynamicObj.color = action.payload.color
          if (action.payload?.color?.includes('linear')) {
            state.promotionDynamicObj.gradientColor = color.gradientColor
            state.promotionDynamicObj.gradientDeg = color.gradientDeg
            state.promotionDynamicObj.gradientLocation = color.gradientLocation
          }
          color.active = !color.active
          if (!color.active) {
            state.promotionDynamicObj.color = ''
            if (action.payload?.color?.includes('linear')) {
              state.promotionDynamicObj.gradientColor = []
              state.promotionDynamicObj.gradientDeg = 0
              state.promotionDynamicObj.gradientLocation = []
            }
          }
        } else {
          color.active = false
          if (action.payload.nameGroup === 'defaultColors') {
            state.colorsDynamicMiniature[1].gradientColors?.map((e: any) => {
              if (e.active === true) e.active = false
              return e
            })
          } else if (action.payload.nameGroup === 'gradientColors') {
            state.colorsDynamicMiniature[0].defaultColors?.map((e: any) => {
              if (e.active === true) e.active = false
              return e
            })
          }
        }
        return color
      })
    },
    editChangeColorDesign(state, action) {
      state.colorsDynamicMiniature[action.payload.index][
        action.payload.nameGroup
      ] = state.colorsDynamicMiniature[action.payload.index][
        action.payload.nameGroup
      ].map((color: colors) => {
        if (color.color === action.payload.color) {
          color.active = true
        }
        return color
      })
    },
    changeStatusSelectDynamicPromotion(state) {
      state.statusSelect = !state.statusSelect
    },
    resetPromotionDynamicObj(state) {
      state.promotionDynamicObj = {
        name: '',
        status: '',
        startedAt: new Date(),
        endedAt: new Date(),
        header: '',
        text: '',
        color: '',
        image: '',
        gradientColor: [],
        gradientDeg: 0,
        gradientLocation: [],
      }
      state.colorsDynamicMiniature = colorsArr
      state.statusSelect = false
    },
    deleteDynamicImage(state) {
      state.promotionDynamicObj.image = ''
    },
  },
})

export const {
  changeFirstFieldDynamic,
  changeColorDesign,
  changeStatusSelectDynamicPromotion,
  editChangeFieldDynamic,
  editChangeColorDesign,
  resetPromotionDynamicObj,
  deleteDynamicImage,
} = promotionDynamicSlice.actions

export default promotionDynamicSlice.reducer
