import { ReactNode } from 'react'
import { Aside } from '../../ui/Aside'
import s from './Layout.module.css'

type Props = {
    children: ReactNode,
}

export const Layout = ({ children }: Props) => (
  <div className={s.layout}>
    <Aside className={s.navigation} />
    <div className={s.content}>
      {children}
    </div>
  </div>
)
