import React, { useState, FC } from 'react'
import { useForm } from 'react-hook-form'
import s from './RangeSlaider.module.css'

interface RangeSlider {
  width: number
  setHandleValue?: (arg: any) => void
  setErrorHandleRange?: (arg: any) => void
  handleValue?: any
  errorHandleRange?: any
}

export const RangeSlider: FC<RangeSlider> = ({
  width,
  setHandleValue,
  handleValue,
  setErrorHandleRange,
  errorHandleRange,
}) => {
  const [handleValueLabel, setHandleValueLabel] = useState('100 тыс. ₽')
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm()

  // function onSubmit(data) {
  //   setHandleValue(Number(data?.number))
  //   setHandleValueLabel(Number(data?.number).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB', notation: 'compact' }))
  // }

  // const handleChangeValue = (event) => {
  //   setHandleValue(event?.target?.value)
  //   setHandleValueLabel(Number(event?.target?.value).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB', notation: 'compact' }))
  // }

  return (
    <div className={s.range_slider__container}>
      <div className={s.range_slider__title_and_handle_input}>
        <h1 className={s.range_slider__title}>Куда инвестировать деньги</h1>
        {/* <form className="range_slider__form" onSubmit={handleSubmit(onSubmit)} onChange={handleSubmit(onSubmit)}> */}
        <form className={s.range_slider__form}>
          <input
            className={errorHandleRange ? s.range_slider__input_number__error : s.range_slider__input_number}
            type="number"
            // {...register('number', { required: true, max: 10000000, maxLength: 8 })}
            placeholder="Введите сумму инвестиций"
          />
          <input
            className={errorHandleRange ? s.range_slider__input_number__submit_disable : s.range_slider__input_number__submit_active}
            type="submit"
            value=""
          />
          {/* {errors.number && errors.number.type !== 'required' ? setErrorHandleRange(true) : setErrorHandleRange(false)} */}
        </form>
      </div>

      {/* <input className="range_slider" type="range" min="0" max="10000000" step="100000" value={handleValue} onChange={handleChangeValue} /> */}
      <input className={s.range_slider} type="range" min="0" max="10000000" step="100000" />
      {width > 768 ? (
        <div
          className={s.range_slider__label}
          style={{ left: `${handleValue < 400000 ? 0 : handleValue > 9600000 ? 93 : (handleValue * 100) / 10000000 - 4}%` }}
        >
          {handleValueLabel}
        </div>
      ) : width > 375 ? (
        <div
          className={s.range_slider__label}
          style={{ left: `${handleValue < 400000 ? 0 : handleValue > 9500000 ? 92 : (handleValue * 100) / 10000000 - 4}%` }}
        >
          {handleValueLabel}
        </div>
      ) : (
        <div
          className={s.range_slider__label}
          style={{ left: `${handleValue < 400000 ? 0 : handleValue > 8500000 ? 82 : (handleValue * 100) / 10000000 - 4}%` }}
        >
          {handleValueLabel}
        </div>
      )}
    </div>
  )
}
