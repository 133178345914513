import React from 'react'
import clsx from 'clsx'
import { UploadStories } from '../../components/UploadStories'
import {
  Field, Label, Panel, Textarea, Title,
} from '../../ui'
import s from './PromotionDynamicDesign.module.css'

interface temporaryProps {
	colorsDesign?: any
	title: string
	text: string
	changeField?: any
	changeColorDesign?: any
}

export const PromotionDynamicDesign: React.FC<temporaryProps> = ({
  colorsDesign,
  title,
  text,
  // colorMiniature,
  // image,
  changeField,
  changeColorDesign,
}) => (
  <>
    <Panel padding className={s.wrapper}>
      <Title title="Дизайн" />
      <Label>
        Заголовок
        <Field
          className={s.title}
          view="default"
          value={title}
          onChange={e => changeField(e, 'header')}
          maxLength={50}
        />
      </Label>
      <Label title="Полное описание">
        <Textarea
          className={s.description}
          value={text}
          onChange={e => changeField(e, 'text')}
          rows={5}
          maxLength={350}
        />
      </Label>
    </Panel>
    <div className={s.blockDesignColor}>
      <Panel padding style={{ flexBasis: '50%', height: '358px' }}>
        <div>
          <div style={{ marginBottom: '15px' }}>
            <p className={s.designColorTitle}>Задать цвет фона</p>
            <p className={s.designColorSubTitle}>
              Убедитесь, что текст хорошо читается
            </p>
          </div>
          <div>
            {colorsDesign?.map((elem: any, i: number) => (
              <div key={i} className={s.blockColors}>
                {elem.defaultColors?.map((color: any) => (
                  <div
                    key={color.id}
                    className={
                      color.active ? s.colorItemActive : s.blockColorItem
                    }
                    onClick={() => changeColorDesign(
                      color.id,
                      color.color,
                      'defaultColors',
                      i,
                    )}
                  >
                    <div
                      className={clsx(s.colorItem)}
                      style={{ backgroundColor: color.color }}
                    />
                  </div>
                ))}
                {elem.gradientColors?.map((color: any) => (
                  <div
                    key={color.id}
                    className={
                      color.active ? s.colorItemActive : s.blockColorItem
                    }
                    onClick={() => changeColorDesign(
                      color.id,
                      color.color,
                      'gradientColors',
                      i,
                    )}
                  >
                    <div
                      className={clsx(s.colorItem)}
                      style={{ background: color.color }}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Panel>
      <Panel style={{ flexBasis: '50%', height: '358px' }}>
        <UploadStories
          // selectedFile={indexSlide === slide.id}
          // slideId={slide.id}
          title="Загрузить файл"
          titleStatus="Файл"
          text="Максимальный размер фото 8 Мб. Формат: png. Размер: 160 х 160 px"
          application=".png"
          subText1="Загрузить с компьютера"
          subText2="или перетащите сюда"
          // status={statusFilePDF}
          uploadId="dynamicDesign"
          classNameSubText={s.btnSubText}
        />
      </Panel>
    </div>
  </>
)
