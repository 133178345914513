import React, { FC } from 'react'
import clsx from 'clsx'
import { Section } from '../Section/Section'
import { SectionTitle } from '../SectionTitle/SectionTitle'
import s from './Gallery.module.css'
import { SliderGallery } from '../SliderGallery/SliderGallery'

interface Gallery {
  width: number
  project?: any
  arrGallery?: any
}

export const Gallery: FC<Gallery> = ({
  width,
  project,
  // isGallery,
  arrGallery,
  // isTurnDevice,
  // clientWidth,
}) => (
  <Section className={s.section__margin__bottom}>
    <SectionTitle text="Галерея" />
    <div className={s.gallery__navigation}>
      <button
        className={clsx(
          s.gallery__navigation__button,
          s.gallery__navigation__button_active,
        )}
        type="button"
      >
        Фото
      </button>
      {/* <button className={s.gallery__navigation__button}>Видео</button> */}
    </div>
    <SliderGallery
      project={project}
      width={width}
      // isGallery={isGallery}
      arrGallery={arrGallery}
      // isTurnDevice={isTurnDevice}
      clientWidth={1025}
    />
  </Section>
)
