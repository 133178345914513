export const active = [
  'Редактировать',
  'Снять с публикации',
  'Приостановить',
  'Удалить',
]

export const draft = ['Редактировать', 'Удалить']

export const suspended = [
  'Редактировать',
  'Снять с публикации',
  'Возобновить',
  'Удалить',
]

export const archive = ['Опубликовать', 'Редактировать', 'Удалить']
