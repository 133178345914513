import { gql } from '@apollo/client'

export const GET_CONTRACT = gql`
  query getContract($uuid: String) {
    contract(uuid: $uuid) {
      contractFileUrl
      uuid
      number
      client {
        firstName
        lastName
        middleName
        uuid
      }
      project {
        name
        uuid
      }
      broker {
        name
        uuid
      }
      signedOn
      expireOn
      pcYearly
      withdrawalDay
      withdrawalCount
      investSum
      currency
      paymentSchedule
      tax_status
    }
  }
`

export const GET_ACT = gql`
  query get_Act($uuid: String!) {
    act(uuid: $uuid) {
      id
      uuid
      number
      contract {
        number
        broker {
          name
        }
      }
      client {
        uuid
        firstName
        middleName
        lastName
      }
      project {
        uuid
        description
        name
      }
      sum
      date
      file
      status
    }
  }
`
export const GET_REPORT = gql`
  query get_Report($uuid: String!) {
    report(uuid: $uuid) {
      id
      uuid
      name
      project {
        uuid
        description
        name
      }
      file
      status
    }
  }
`
