import React, { FC } from 'react'
import clsx from 'clsx'
import { Section } from '../Section/Section'
import s from './MediaSectionTitle.module.css'

interface MediaSectionTitle {
	text1?: string
  text2?: string
  text3?: string
  className?: string
}

export const MediaSectionTitle: FC<MediaSectionTitle> = ({
  text1, text2, text3, className,
}) => (
  <Section className={clsx(s.section_width, className)}>
    <div>
      <h2 className={s.firstLine}>{text1}</h2>
      <h2 className={s.secondLine}>{text2}</h2>
      <h2 className={s.thirdLine}>{text3}</h2>
    </div>
  </Section>
)
