/* eslint-disable */

import React, { useEffect, useState } from "react";
import * as tus from "tus-js-client";
import clsx from "clsx";
import { useMutation } from "@apollo/client";
import { UPDATE_PROJECT_IMAGES } from "../../apollo/mutation/updateProjectImages";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import projectSlice, {
  statusPng,
  statusWebp,
  statusFilePngBanner, // для третей картинки
  statusFileWebpBackground, // для 4ой картинки
  statusFileGallery,
} from "../../redux/slices/projectSlice";
import { Panel, InputFile, Loader } from "../../ui";
import iconSuccess from "./success.svg";
import s from "./UploadEditPages.module.css";
import { editMainPage } from "../../redux/slices/editSiteSlice";

interface UploadProps {
  application?: string;
  title: string;
  text: string;
  text2: string;
  subText1: string;
  subText2: string;
  titleStatus?: string;
  uploadId?: string;
  deletePhoto?: any;
  gallery?: boolean;
  arrUpload?: any;
  hadleDeleteImage?: any;
  cover?: any;
  handler?: any;
  changeCover?: any;
  changePhotoAbout?: (arg: any) => void;
  photoAbout?: string;
  changePhotoBroker?: (arg: any) => void;
  photoBroker?: string;
  changeOutletPicture?: (arg: any) => void;
  outletPicture?: string;
  changeAvatar?: (arg: any) => void;
  avatar?: string;
  // status?: boolean
  incomingStatus?: boolean;
}

export const UploadEditPages: React.FC<UploadProps> = ({
  application,
  title,
  text,
  text2,
  subText1,
  subText2,
  // status = '',
  titleStatus = "",
  uploadId,
  gallery,
  deletePhoto,
  arrUpload,
  hadleDeleteImage,
  cover,
  handler,
  changeCover,
  changePhotoAbout,
  photoAbout,
  changePhotoBroker,
  photoBroker,
  changeOutletPicture,
  outletPicture,
  changeAvatar,
  avatar,
  incomingStatus,
}) => {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(
    uploadId === "Cover" ||
      uploadId === "AboutUs" ||
      avatar ||
      outletPicture ||
      photoBroker ||
      incomingStatus
      ? "success"
      : ""
  );

  useEffect(() => {
    if (avatar || outletPicture || photoBroker) {
      setStatus("success");
    }
  }, [avatar, outletPicture, photoBroker]);

  const upLoad = (e: any, titleStatus: string): void => {
    const file = e.target.files[0];
    console.log("file", file);
    if (file.size <= 50000000) {
      if (typeof file === "object") {
        const fileType = file.name.slice(-4);
        console.log(fileType);
        const upload = new tus.Upload(file, {
          endpoint: process.env.REACT_APP_API_URL,
          retryDelays: [100, 300, 500, 1000, 3000],
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          onProgress(bytesUploaded: number, bytesTotal: number) {
            if (
              (fileType === ".png" ||
                fileType === ".jpg" ||
                fileType === "webp") &&
              titleStatus.includes("Обложка")
            ) {
              // dispatch(statusPng('pending'))
              setStatus("pending");
            } else if (
              (fileType === ".png" ||
                fileType === ".jpg" ||
                fileType === "webp") &&
              titleStatus.includes("O Нас")
            ) {
              // dispatch(statusFileWebpBackground('pending'))
              setStatus("pending");
            }
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            console.log(bytesUploaded, bytesTotal, `${percentage}%`);
          },
          onSuccess() {
            if (
              (fileType === ".png" ||
                fileType === ".jpg" ||
                fileType === "webp") &&
              titleStatus.includes("Обложка")
            ) {
              setStatus("success");
              changeCover(upload.url);
              dispatch(
                editMainPage({
                  project: "photo",
                  item: upload.url,
                })
              );
            } else if (
              (fileType === ".png" ||
                fileType === ".jpg" ||
                fileType === "webp") &&
              titleStatus.includes("O нас")
            ) {
              setStatus("success");
              changePhotoAbout?.(upload.url);
            } else if (
              (fileType === ".png" ||
                fileType === ".jpg" ||
                fileType === "webp") &&
              titleStatus.includes("Брокер")
            ) {
              setStatus("success");
              changePhotoBroker?.(upload.url);
            } else if (
              (fileType === ".png" ||
                fileType === ".jpg" ||
                fileType === "webp") &&
              titleStatus.includes("Статья")
            ) {
              setStatus("success");
              changeOutletPicture?.(upload.url);
            } else if (
              (fileType === ".png" ||
                fileType === ".jpg" ||
                fileType === "webp") &&
              titleStatus.includes("Отзыв")
            ) {
              setStatus("success");
              changeAvatar?.(upload.url);
            }
            console.log(
              "Download %s from %s",
              upload.url?.replace(`${process.env.REACT_APP_API_URL}`, ""),
              file.name.slice(-4)
            );
          },
          onError(error: any) {
            if (fileType === ".png") {
              dispatch(statusPng("error"));
            } else if (fileType === "webp") {
              dispatch(statusWebp("error"));
            }
            console.log(`Failed because: ${error}`);
          },
        });
        upload.findPreviousUploads().then((previousUploads: string | any[]) => {
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0]);
          }
          // Start the upload
          upload.start();
        });
      }
    } else {
      if (file.type === ".png") {
        dispatch(statusPng("errorSize"));
      } else if (file.type === "webp") {
        dispatch(statusWebp("errorSize"));
      }
      console.log("Failed");
    }
  };
  if (status === "success") {
    return (
      <Panel className={s.upload} padding>
        <p className={s.title}>{title}</p>
        <p className={s.description}>{text}</p>
        <p className={s.description}>{text2}</p>
        <Panel className={s.upload__file_success}>
          <div className={s.imageRow_wrapper}>
            {uploadId === "Cover" && cover !== "" && (
              <div className={s.image_wrapper}>
                <img src={cover} alt="cover" className={s.image} />
                {/* <div
                  className={s.image_delete}
                >
                  <div className={s.icon_delete} onClick={e => deleteLogo(e)} id="cover">{}</div>
                </div> */}
              </div>
            )}
            {uploadId === "AboutUs" && photoAbout !== "" && (
              <div className={s.image_wrapper}>
                <img src={photoAbout} alt="photoAbout" className={s.image} />
                {/* <div
                  className={s.image_delete}
                >
                  <div className={s.icon_delete} onClick={e => deleteLogo(e)} id="aboutUs">{}</div>
                </div> */}
              </div>
            )}
            {uploadId === "Broker" && photoBroker !== "" && (
              <div className={s.image_wrapper}>
                <img
                  src={photoBroker}
                  alt="photoBroker"
                  className={s.image_broker}
                />
                <div className={s.image_delete}>
                  <div
                    className={s.icon_delete}
                    onClick={(e) => deletePhoto(e)}
                  >
                    {}
                  </div>
                </div>
              </div>
            )}
            {uploadId === "Outlet" && outletPicture !== "" && (
              <div className={s.image_wrapper}>
                <img
                  src={outletPicture}
                  alt="outletPicture"
                  className={s.image_broker}
                />
                {/* <div
                  className={s.image_delete}
                >
                  <div className={s.icon_delete} onClick={e => deleteLogo(e)} id="aboutUs">{}</div>
                </div> */}
              </div>
            )}
            {uploadId === "Review" && avatar !== "" && (
              <div className={s.image_wrapper}>
                <img src={avatar} alt="avatar" className={s.image_broker} />
                {/* <div
                  className={s.image_delete}
                >
                  <div className={s.icon_delete} onClick={e => deleteLogo(e)} id="aboutUs">{}</div>
                </div> */}
              </div>
            )}
          </div>
          <div className={s.text_wrapper}>
            <InputFile
              className={s.wrapper__succes}
              classNameSpan={s.span__success}
              uploadId={uploadId}
              onChange={(e) => upLoad(e, titleStatus)}
              // application={application}
              subText1={gallery ? " Добавить фото" : "Загрузить другой файл"}
            />
          </div>
        </Panel>
      </Panel>
    );
  }

  if (status === "pending") {
    return (
      <Panel className={s.upload} padding>
        <Panel className={clsx(s.upload__file_success, s.upload__file_pending)}>
          <Loader />
        </Panel>
      </Panel>
    );
  }

  if (status === "error" || status === "errorSize") {
    return (
      <Panel className={s.upload} padding>
        <Panel className={clsx(s.upload__file_success, s.upload__file_error)}>
          <p>
            {status === "errorSize"
              ? "Ошибка, размер файла должен быть не больше 200 Кб"
              : "Ошибка попробуйте позже"}
          </p>
          <InputFile
            uploadId={uploadId}
            className={s.wrapper__succes}
            classNameSpan={s.span__success}
            onChange={(e) => upLoad(e, titleStatus)}
            application={application}
            subText1="Загрузить другое фото"
          />
        </Panel>
      </Panel>
    );
  }

  return (
    <Panel className={s.upload} padding>
      <p className={s.title}>{title}</p>
      <p className={s.description}>{text}</p>
      <p className={s.description}>{text2}</p>
      <Panel className={s.upload__file}>
        <InputFile
          uploadId={uploadId}
          onChange={(e) => upLoad(e, titleStatus)}
          subText1={subText1}
          subText2={subText2}
        />
      </Panel>
    </Panel>
  );
};
