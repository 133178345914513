/* eslint-disable */

import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { configureStore } from "@reduxjs/toolkit";
import { count } from "console";
import { useGetRole } from "../../hooks/useGetRole";
import {
  addProjectFields,
  addRequisitesFields,
  resetProjectFields,
  changePledgeSelect,
  changeStatusSelect,
  changeInvestFormSelect,
  changeCountriesSelect,
  closeCountriesSelect,
  addInvestTresholdsFields,
  addInvestFormFields,
  addSupportFields,
  addImagesFields,
  statusPng,
  statusWebp,
  statusFilePngBanner, // для третей картинки
  statusFileWebpBackground, // для 4ой картинки
  statusFileGallery,
  addProjectImagesFields,
  statusPngBannerEditProject,
  statusFileWebpBackgroundrEditProject,
  statusGalleryEditProject,
  addSrcGalleryArr,
} from "../../redux/slices/projectSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
  Container,
  CloseIcon,
  Title,
  Panel,
  Label,
  Field,
  Button,
  Loader,
  ProgressBar,
  Textarea,
  AddButton,
} from "../../ui";
import { UploadProject } from "../UploadProject";
import { NativeSelect } from "../../ui/nativeSelect";
import { NativeSelectPledge } from "../../ui/nativeSelectPledge";
import { GET_DATA_PROJECT, PROJECTS } from "../../apollo/query/allProjects";
import { ALL_PLEDGE } from "../../apollo/query/allPledge"; // для всех видов обеспечения
import { PROJECT_UPDATE } from "../../apollo/mutation/projectUpdate";
import { UPDATE_PROJECT_IMAGES } from "../../apollo/mutation/updateProjectImages";
import iconSent from "./img/icon-sent.svg";
import iconDraft from "./img/icon-draft.svg";
import preview from "./img/icon-eye.png";
import fullFill from "./img/icon-fillfull.svg";
import empty from "./img/icon-empty.svg";
import { status, investForm } from "../CreateProject/data";
import { countries } from "./data";
import {
  manager,
  marketer,
  technical1,
  technical2,
} from "../../references/data";
import s from "./EditProj.module.css";

export const EditProj = () => {
  const dispatch = useAppDispatch();
  const { isRole, location, navigate } = useGetRole();
  // статус проекта
  const [projectStatusNew, setProjectStatusNew] = useState("");
  // Название проекта
  const [projectName, setProjectName] = useState("");
  const [projectNameEn, setProjectNameEn] = useState("");
  // Описание проекта
  const [description, setDescription] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  // Мин. сумма инвестиций
  const [investments, setInvestments] = useState("");
  // Мин. доходность
  const [income, setIncome] = useState("");
  // Юр. лицо
  const [orgName, setOrgName] = useState("");
  const [orgNameEn, setOrgNameEn] = useState("");
  // ОГРН
  const [ogrn, setOgrn] = useState("");
  // ИНН
  const [inn, setInn] = useState("");
  // Адрес - legal_address field
  const [address, setAddress] = useState("");
  const [addressEn, setAddressEn] = useState("");
  // Место ведения деятельности - address field
  const [activity, setActivity] = useState("");
  const [activityEn, setActivityEn] = useState("");
  // Состояние конопки сохранения черновика проекта
  const [drafted, setDrafted] = useState(false);
  // Состояние конопки сохранения проекта
  const [sent, setSent] = useState(false);
  // array of provides
  // const [pledgeArr, setPledgeArr] = useState<any[]>(pledge)
  // стэйт для конопки сохранить как черновик
  const [draft, setDraft] = useState(false);
  // Получение uuid проекта из location
  const [projectUUID, setProjectUUID] = useState<string>();
  // сумма полей (у нас их 10)
  const [sumField, setSumField] = useState<number>(0);
  const [statusArr, setStatusArr] = useState(status); // выпадающий список со статусами

  const [countriesArr, setCountriesArr] = useState(countries);
  const [selectedCountry, setSelectedCountry] = useState({
    title: "",
    flag: "",
  }); // Текущая страна

  const [fullDescription, setFullDescription] = useState("");
  const [fullDescriptionEn, setFullDescriptionEn] = useState("");
  const [linkSite, setLinkSite] = useState("https://");
  const [linkTelegram, setLinkTelegram] = useState("https://t.me/");
  const [purposeDescription, setPurposeDescription] = useState("");
  const [purposeDescriptionEn, setPurposeDescriptionEn] = useState("");
  const [investFormArr, setInvestFormArr] = useState(investForm);
  const [pledgeArrForState, setPledgeArrForState] = useState<any>([
    {
      name: "",
      need_to_invest: "",
      id: new Date().toISOString(),
      key: new Date().toISOString(),
      provide_descriptoin: "",
      show: false,
    },
  ]);
  const [investLevels, setInvestLevels] = useState<any>([
    { sum: "", percent: "", id: new Date().toISOString() },
  ]);
  const [tempInvestLevels, setTempInvestLevels] = useState<any>([]);
  const [text, setText] = useState("Добавить еще один тип обеспечения");
  const [earnedAmount, setEarnedAmount] = useState("");
  const [arrUpload, setArrUpload]: any = useState([]);
  // const [pledgeArr, setPledgeArr] = useState<any[]>(pledge)
  // const [pledge, setPledge] = useState<any>([])
  const [showToolTip, setShowToolTip] = useState(false);
  const [tooltipText] = useState(
    "Заполните все поля или поменяйте статус на «Черновик»"
  );
  const [projectImagesUUID, setProjectImagesUUID] = useState("");
  const {
    projectInfo,
    statusFilePng,
    statusFileWebp,
    pledgeSelect,
    statusSelect,
    investFormSelect,
    countriesSelect,
    statusFilePngBanner,
    statusFileWebpBackground,
    statusFileGallery,
    galleryArr,
  } = useAppSelector((state) => state.projectSlice);
  const [logo, setLogo] = useState("");
  const [bannerApp, setBannerApp] = useState("");
  const [bannerSite, setBannerSite] = useState("");
  const [background, setBackground] = useState("");

  // получение uuid проекта из адреса локации по свойству pathname
  const uuid = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1,
    location.pathname.length
  );

  useEffect(() => {
    setProjectUUID(uuid);
  }, [location]);

  useEffect(() => {
    (function redirect() {
      if (
        isRole === technical1 &&
        location.pathname === `/projects/edit/${projectUUID}`
      ) {
        navigate("/clients", { replace: true });
      } else if (
        isRole === technical2 &&
        location.pathname === `/projects/edit/${projectUUID}`
      ) {
        navigate("/documents", { replace: true });
      } else if (
        isRole === marketer &&
        location.pathname === `/projects/edit/${projectUUID}`
      ) {
        navigate("/promotion", { replace: true });
      } else if (
        isRole === manager &&
        location.pathname === `/projects/edit/${projectUUID}`
      ) {
        navigate("/home", { replace: true });
      }
    })();
  }, [location, isRole]);

  const newArr = Object.values(projectInfo)
    .map((e: any) =>
      typeof e === "object" ? Object.values(e).map((e: any) => e) : e
    )
    .flat();

  useEffect(() => {
    const tempObj = {
      description: projectInfo.description && projectInfo.descriptionEn,
      fullDescription:
        projectInfo.fullDescription && projectInfo.fullDescriptionEn,
      country: projectInfo.country,
      income: projectInfo.income,
      investForms: projectInfo.investForms,
      investTresholds: projectInfo.investTresholds,
      minInterest: projectInfo.minInterest,
      minInvestment: projectInfo.minInvestment,
      name: projectInfo.name && projectInfo.nameEn,
      // projectImages: projectInfo.projectImages,
      background,
      bannerApp,
      bannerSite,
      logo,
      projectStatusNew: projectInfo.projectStatusNew,
      // requisites: projectInfo.requisites,
      inn: projectInfo.requisites.INN,
      OGRN: projectInfo.requisites.OGRN,
      address:
        projectInfo.requisites.address && projectInfo.requisites.addressEn,
      legal_address:
        projectInfo.requisites.legal_address &&
        projectInfo.requisites.legal_addressEn,
      legal_name: projectInfo.requisites.name && projectInfo.requisites.nameEn,
      socialNetworks: projectInfo.socialNetworks,
      support: projectInfo.support,
      target: projectInfo.target && projectInfo.targetEn,
      url: projectInfo.url,
      galleryArr: arrUpload.length,
    };
    setSumField(
      Object.values(tempObj)
        .map((e: any) => (e ? 4.166 : 0))
        .reduce((a: any, b: any) => a + b)
    );
  }, [projectInfo, logo, background, bannerApp, bannerSite, arrUpload]);

  const {
    data: project,
    loading: loadingProject,
    error: errorProject,
  } = useQuery(GET_DATA_PROJECT, {
    variables: {
      filter: {
        uuid,
      },
    },
  });

  const [
    projectUpdate,
    {
      data: updatedProject,
      loading: loadingUpdatedProject,
      error: errorUpdatedProject,
    },
  ] = useMutation(PROJECT_UPDATE, {
    refetchQueries: [
      {
        query: GET_DATA_PROJECT,
        variables: {
          filter: {
            uuid,
          },
        },
      },
      {
        query: PROJECTS,
      },
      {
        query: PROJECTS,
        variables: {
          filter: {
            sendStatus: "sent",
            status: "published",
          },
        },
      },
      {
        query: PROJECTS,
        variables: {
          filter: {
            sendStatus: "draft",
          },
        },
      },
      {
        query: PROJECTS,
        variables: {
          filter: {
            status: "suspended",
          },
        },
      },
      {
        query: PROJECTS,
        variables: {
          filter: {
            status: "hidden",
          },
        },
      },
    ],
  });

  useEffect(() => {
    if (!project) return;

    // вводим переменную для распарсивания JSON
    // const tempArr = (project?.projects[0]?.investForms.length === 0 ? [] : ([...JSON.parse(project?.projects[0]?.investForms)]))
    const tempArr =
      project?.projects[0]?.investForms.length === 0
        ? []
        : typeof project?.projects[0].investForms === "object"
        ? project?.projects[0].investForms
        : [...JSON.parse(project?.projects[0]?.investForms)];
    const tempPledgeArr =
      project?.projects[0]?.support.length === 0
        ? []
        : typeof project?.projects[0].support === "object"
        ? project?.projects[0].support
        : [...JSON.parse(project?.projects[0]?.support)];
    const tempInvestThresholds =
      project?.projects[0].investThresholds.length === 0
        ? []
        : typeof project?.projects[0].investThresholds === "object"
        ? project?.projects[0].investThresholds
        : JSON.parse(project?.projects[0].investThresholds);

    const tempGallery: any = Array.isArray(
      project.projects[0].projectImages.gallery
    )
      ? [...project.projects[0].projectImages.gallery]
      : [];
    dispatch(addSrcGalleryArr(tempGallery));

    setInvestFormArr(
      investFormArr.map((elem: any) => {
        tempArr.map((e: any) => {
          if (e.name.includes(elem.title)) {
            elem.active = true;
            dispatch(
              addInvestFormFields(
                investFormArr
                  .map((e: any) => e.active && { name: e.title })
                  .filter((e: any) => e)
              )
            );
          }
          return elem;
        });
        return elem;
      })
    );

    dispatch(addInvestTresholdsFields(tempInvestThresholds));
    dispatch(addSupportFields(tempPledgeArr));
  }, [project]);

  useEffect(() => {
    if (!project) return;
    // setPledgeArr(pledgeArr.map((elem: any) => {
    //   project?.projects[0].support.map((e: any) => {
    //     if (e.provide.includes(elem.title)) {
    //       elem.active = true
    //       dispatch(addSupportFields(pledgeArr.map((e: any) => e.active && { provide: e.title }).filter((e: any) => e)))
    //     }
    //     return elem
    //   })
    //   return elem
    // }))
    if (!project?.projects) {
      return;
    }

    setProjectStatusNew(project?.projects[0]?.projectStatusNew);
    setProjectName(project?.projects[0]?.name);
    setProjectNameEn(project?.projects[0]?.nameEn);
    setDescription(project?.projects[0]?.description);
    setDescriptionEn(project?.projects[0]?.descriptionEn);
    setInvestments(project?.projects[0]?.minInvestment);
    setIncome(project?.projects[0]?.minInterest);
    if (project?.projects[0]?.country) {
      const countryInfo = countriesArr.find(
        (c) => c.title === project.projects[0].country
      );
      if (countryInfo) {
        setSelectedCountry({ title: countryInfo.title, flag: countryInfo.img });
      }
    } // ______________________________________________________________________________________________________
    setOrgName(project?.projects[0]?.requisites?.name);
    setOrgNameEn(project?.projects[0]?.requisites?.nameEn);
    setOgrn(project?.projects[0]?.requisites?.OGRN);
    setInn(project?.projects[0]?.requisites?.INN);
    setAddress(project?.projects[0]?.requisites?.legal_address);
    setAddressEn(project?.projects[0]?.requisites?.legal_addressEn);
    setActivity(project?.projects[0]?.requisites?.address);
    setActivityEn(project?.projects[0]?.requisites?.addressEn);
    setPurposeDescription(project?.projects[0]?.target);
    setPurposeDescriptionEn(project?.projects[0]?.targetEn);
    setEarnedAmount(project?.projects[0].income);
    setLinkSite(project?.projects[0].url);
    setLinkTelegram(project?.projects[0].socialNetworks);
    setFullDescription(project?.projects[0].fullDescription);
    setFullDescriptionEn(project?.projects[0].fullDescriptionEn);
    setInvestLevels(
      project?.projects[0].investThresholds.length === 0
        ? [{ sum: "", percent: "", id: new Date().toISOString() }]
        : typeof project?.projects[0].investThresholds === "object"
        ? project?.projects[0].investThresholds
        : JSON.parse(project?.projects[0].investThresholds)
    );
    setPledgeArrForState(
      project?.projects[0].support.length === 0
        ? [
            {
              name: "",
              need_to_invest: "",
              id: new Date().toISOString(),
              key: new Date().toISOString(),
              provide_descriptoin: "",
              show: false,
            },
          ]
        : typeof project?.projects[0].support === "object"
        ? project?.projects[0].support
        : JSON.parse(project?.projects[0].support)
    );
    setProjectImagesUUID(project?.projects[0].projectImages.uuid);
    // входящая галерея
    setArrUpload(
      Array.isArray(project.projects[0].projectImages.gallery)
        ? [...project.projects[0].projectImages.gallery]
        : []
    );
  }, [project]);

  const {
    data: pledgeData,
    loading: pledgeLoading,
    error: pledgeError,
  } = useQuery(ALL_PLEDGE);
  const [pledge, setPledge] = useState<any>([]);

  // запись в стейт и добавление полей обеспечения для структуры nativeSelect
  useEffect(() => {
    if (!pledgeData) return;
    setPledge(
      pledgeData.projectProcurings.map((el: any) => ({
        ...el,
        active: false,
        title: el.name,
      }))
    );
  }, [pledgeData]);

  const changeLogo = (url: string) => setLogo(url);
  const changeBannerApp = (url: string) => setBannerApp(url);
  const changeBannerSite = (url: string) => setBannerSite(url);
  const changeBackground = (url: string) => setBackground(url);

  const deleteLogo = () => setLogo("");
  const deleteBannerApp = () => setBannerApp("");
  const deleteBannerSite = () => setBannerSite("");
  const deleteBackground = () => setBackground("");

  useEffect(() => {
    if (!projectUUID) {
      return;
    }
    dispatch(statusPng("success"));
    dispatch(statusWebp("success"));
    dispatch(statusPngBannerEditProject("success"));
    dispatch(statusFileWebpBackgroundrEditProject("success"));
    dispatch(statusGalleryEditProject("success"));
    // устанавливаем входящуюю картику
    dispatch(
      addProjectImagesFields({
        fieldName: "logo",
        item: project?.projects[0]?.projectImages.logo,
      })
    );
    setLogo(project?.projects[0]?.projectImages.logo);
    dispatch(
      addProjectImagesFields({
        fieldName: "background",
        item: project?.projects[0]?.projectImages.background,
      })
    );
    setBackground(project?.projects[0]?.projectImages.background);
    dispatch(
      addProjectImagesFields({
        fieldName: "bannerApp",
        item: project?.projects[0]?.projectImages.bannerApp,
      })
    );
    setBannerApp(project?.projects[0]?.projectImages.bannerApp);
    dispatch(
      addProjectImagesFields({
        fieldName: "bannerSite",
        item: project?.projects[0]?.projectImages.bannerSite,
      })
    );
    setBannerSite(project?.projects[0]?.projectImages.bannerSite);
    dispatch(
      addProjectImagesFields({
        fieldName: "uuid",
        item: project?.projects[0]?.projectImages.uuid,
      })
    );
    dispatch(
      addProjectImagesFields({
        fieldName: "id",
        item: project?.projects[0]?.projectImages.id,
      })
    );

    const addProjectInformation: any = {
      name: project?.projects[0]?.name,
      nameEn: project?.projects[0]?.nameEn,
      description: project?.projects[0]?.description,
      descriptionEn: project?.projects[0]?.descriptionEn,
      country: project?.projects[0]?.country,
      projectStatusNew: project?.projects[0]?.projectStatusNew,
      minInvestment: project?.projects[0]?.minInvestment,
      minInterest: project?.projects[0]?.minInterest,
      fullDescription: project?.projects[0]?.fullDescription,
      fullDescriptionEn: project?.projects[0]?.fullDescriptionEn,
      income: project?.projects[0]?.income,
      url: project?.projects[0]?.url,
      socialNetworks: project?.projects[0]?.socialNetworks,
      target: project?.projects[0]?.target,
      targetEn: project?.projects[0]?.targetEn,
    };

    for (const key in addProjectInformation) {
      dispatch(
        addProjectFields({ fieldName: key, item: addProjectInformation[key] })
      );
    }
    const addProjectRequisites: any = {
      name: project?.projects[0]?.requisites?.name,
      nameEn: project?.projects[0]?.requisites?.nameEn,
      OGRN: project?.projects[0]?.requisites?.OGRN,
      INN: project?.projects[0]?.requisites?.INN,
      legal_address: project?.projects[0]?.requisites?.legal_address,
      legal_addressEn: project?.projects[0]?.requisites?.legal_addressEn,
      address: project?.projects[0]?.requisites?.address,
      addressEn: project?.projects[0]?.requisites?.addressEn,
    };
    for (const key in addProjectRequisites) {
      dispatch(
        addRequisitesFields({ fieldName: key, item: addProjectRequisites[key] })
      );
    }
  }, [projectUUID, project]);

  const hidePage = () => {
    navigate("/projects", { replace: true });
    dispatch(resetProjectFields());
  };

  const projectNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
    dispatch(addProjectFields({ fieldName: "name", item: e.target.value }));
  };
  const projectNameHandlerEn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectNameEn(e.target.value);
    dispatch(addProjectFields({ fieldName: "nameEn", item: e.target.value }));
  };

  const descriptionHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
    dispatch(
      addProjectFields({ fieldName: "description", item: e.target.value })
    );
  };
  const descriptionHandlerEn = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescriptionEn(e.target.value);
    dispatch(
      addProjectFields({ fieldName: "descriptionEn", item: e.target.value })
    );
  };

  const fullDescriptionHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFullDescription(e.target.value);
    dispatch(
      addProjectFields({ fieldName: "fullDescription", item: e.target.value })
    );
  };

  const fullDescriptionHandlerEn = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFullDescriptionEn(e.target.value);
    dispatch(
      addProjectFields({ fieldName: "fullDescriptionEn", item: e.target.value })
    );
  };

  const handleCountrySelect = (title: string, img: string) => {
    setSelectedCountry({ title, flag: img });
    dispatch(addProjectFields({ fieldName: "country", item: title }));
  };

  const investmentsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const investments = e.target.value
      .split("")
      .map((i) => i.trim())
      .filter((i) => i !== "")
      .join("");
    setInvestments(e.target.value);
    dispatch(
      addProjectFields({
        fieldName: "minInvestment",
        item: String(investments),
      })
    );
  };

  const incomeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIncome(e.target.value);
    dispatch(
      addProjectFields({ fieldName: "minInterest", item: e.target.value })
    );
  };

  const orgInfoHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrgName(e.target.value);
    dispatch(addRequisitesFields({ fieldName: "name", item: e.target.value }));
  };
  const orgInfoHandlerEn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrgNameEn(e.target.value);
    dispatch(
      addRequisitesFields({ fieldName: "nameEn", item: e.target.value })
    );
  };

  const ogrnHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOgrn(e.target.value);
    dispatch(addRequisitesFields({ fieldName: "OGRN", item: e.target.value }));
  };

  const innHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInn(e.target.value);
    dispatch(addRequisitesFields({ fieldName: "INN", item: e.target.value }));
  };

  const addressHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
    dispatch(
      addRequisitesFields({ fieldName: "legal_address", item: e.target.value })
    );
  };

  const addressHandlerEn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddressEn(e.target.value);
    dispatch(
      addRequisitesFields({
        fieldName: "legal_addressEn",
        item: e.target.value,
      })
    );
  };

  const activityHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActivity(e.target.value);
    dispatch(
      addRequisitesFields({ fieldName: "address", item: e.target.value })
    );
  };

  const activityHandlerEn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActivityEn(e.target.value);
    dispatch(
      addRequisitesFields({ fieldName: "addressEn", item: e.target.value })
    );
  };

  const linkSiteHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkSite(e.target.value);
    dispatch(addProjectFields({ fieldName: "url", item: e.target.value }));
  };

  const linkTelegramHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkTelegram(e.target.value);
    dispatch(
      addProjectFields({ fieldName: "socialNetworks", item: e.target.value })
    );
  };

  const purposeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPurposeDescription(e.target.value);
    dispatch(addProjectFields({ fieldName: "target", item: e.target.value }));
  };

  const purposeHandlerEn = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPurposeDescriptionEn(e.target.value);
    dispatch(addProjectFields({ fieldName: "targetEn", item: e.target.value }));
  };

  const pledgeSumHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPledgeArrForState(
      pledgeArrForState.map((el: any, index: number) => {
        if (index === +e.target.id) {
          return {
            ...el,
            need_to_invest: e.target.value
              .split("")
              .map((i) => i.trim())
              .filter((i) => i !== "")
              .join(""),
          };
        }
        return el;
      })
    );
    dispatch(addSupportFields(pledgeArrForState));
  };

  // изменение обеспечения
  const clickHandler = (e: any) => {
    setPledgeArrForState(
      pledgeArrForState.map((el: any) => {
        if (el.id === e.target.id) {
          return { ...el, show: !el.show };
        }
        return el;
      })
    );
  };

  // закрытие обеспечения
  const closeHandler = (idChange: any) => {
    setPledgeArrForState(
      pledgeArrForState.map((el: any) => {
        if (el.id === idChange) {
          return { ...el, show: false };
        }
        return el;
      })
    );
  };
  // удаление строки из обеспечения
  const handleDeleteSelect = (id: any, name: any) => {
    // находим элемент из списка обеспечений по имени и меняем признак выбранного active на false
    // pledge[pledge.findIndex((el: any) => el.name === name)].active = false
    // setPledge([...pledge])
    const filteredPledge = [
      ...pledgeArrForState.filter((el: any) => el.id !== id),
    ];
    setPledgeArrForState([...filteredPledge]);
    dispatch(addSupportFields([...filteredPledge]));
  };
  // const addPledgeLine = () => {
  //   setPledgeArrForState([
  //     ...pledgeArrForState,
  //     {
  //       name: '',
  //       need_to_invest: '',
  //       id: new Date().toISOString(),
  //       key: new Date().toISOString(),
  //       provide_descriptoin: '',
  //     },
  //   ])
  // }

  const addPledgeLine = () => {
    setPledgeArrForState([
      ...pledgeArrForState,
      {
        name: "",
        need_to_invest: "",
        id: new Date().toISOString(),
        key: new Date().toISOString(),
        provide_descriptoin: "",
        show: false,
      },
    ]);
  };

  const addInvestLevelsLine = () => {
    if (investLevels.length >= 5) return;
    setInvestLevels([
      ...investLevels,
      { sum: "", percent: "", id: new Date().toISOString() },
    ]);
  };
  const levelsLineSumHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tempArr = [...investLevels];
    tempArr = tempArr.map((el: any) => {
      if (el.id === e.target.id) {
        return {
          ...el,
          sum: e.target.value
            .split("")
            .map((i) => i.trim())
            .filter((i) => i !== "")
            .join(""),
        };
      }
      return el;
    });
    setInvestLevels(
      investLevels.map((el: any) => {
        if (el.id === e.target.id) {
          return {
            ...el,
            sum: e.target.value
              .split("")
              .map((i) => i.trim())
              .filter((i) => i !== "")
              .join(""),
          };
        }
        return el;
      })
    );
    // dispatch(addInvestTresholdsFields(investLevels))
    dispatch(addInvestTresholdsFields(tempArr));
  };

  const levelsLineLevelHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tempArr = [...investLevels];
    tempArr = tempArr.map((el: any) => {
      if (el.id === e.target.id) {
        return { ...el, percent: +e.target.value };
      }
      return el;
    });
    // investLevels[e.target.id].percent = +e.target.value
    // setInvestLevels([...investLevels])
    setInvestLevels(
      investLevels.map((el: any) => {
        if (el.id === e.target.id) {
          return { ...el, percent: +e.target.value };
        }
        return el;
      })
    );
    // dispatch(addInvestTresholdsFields(investLevels))
    dispatch(addInvestTresholdsFields(tempArr));
  };
  const handleDelete = (id: any) => {
    const filteredLevels = [...investLevels.filter((el: any) => el.id !== id)];
    // setInvestLevels([...investLevels.filter((el:any) => el.id !== id)])
    setInvestLevels([...filteredLevels]);
    dispatch(addInvestTresholdsFields([...filteredLevels]));
  };
  const earnedAmountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEarnedAmount(e.target.value);
    dispatch(addProjectFields({ fieldName: "income", item: e.target.value }));
  };
  // состояние кнопки сохранения
  const [stateButton, setStateButton] = useState(false);

  useEffect(() => {
    if (
      projectInfo.projectStatusNew === "draft" ||
      projectInfo.projectStatusNew === "archive" ||
      (projectInfo.projectStatusNew === "suspended" && projectName)
    ) {
      setStateButton(true);
      return;
    }
    if (
      projectInfo.projectStatusNew === "active" &&
      projectName &&
      projectNameEn &&
      orgName &&
      orgNameEn &&
      description &&
      descriptionEn &&
      address &&
      addressEn &&
      activity &&
      activityEn &&
      // purposeDescription &&
      // purposeDescriptionEn &&
      ogrn &&
      inn &&
      fullDescription &&
      fullDescriptionEn &&
      investments &&
      income &&
      // earnedAmount &&
      pledgeArrForState.every(
        (item: any) => item.need_to_invest !== "" && item.name !== ""
      ) &&
      investLevels.every(
        (item: any) => item.percent !== "" && item.sum !== ""
      ) &&
      projectInfo.projectImages.logo &&
      projectInfo.projectImages.background &&
      projectInfo.projectImages.bannerApp &&
      projectInfo.projectImages.bannerSite
    ) {
      setStateButton(true);
      return;
    }
    setStateButton(false);
  }, [projectInfo]);

  const handlePlug = () => {
    console.log();
  };
  // обновление галереи
  const [
    updateProjectImages,
    {
      data: updateImagesData,
      loading: updateImagesLoading,
      error: updateImagesError,
    },
  ] = useMutation(UPDATE_PROJECT_IMAGES);

  const hadleDeleteImage = (e: any, i: any) => {
    // const tempArr = [...arrUpload.filter((el: any, index: any) => index !== i)]
    setArrUpload([...arrUpload.filter((el: any, index: any) => index !== i)]);
    // updateProjectImages({
    //   variables: {
    //     uuid: `${projectInfo.projectImages.uuid}`,
    //     data: {
    //       gallery: JSON.stringify(tempArr),
    //     },
    //   },
    // })
  };

  // функция удаления лого, бэкграунда и т.д
  const hadleDeleteImagesUpperSection = (e: any, i: any) => {
    if (e.target.id === "logo") {
      updateProjectImages({
        variables: {
          uuid: `${projectInfo.projectImages.uuid}`,
          data: {
            logo: "",
          },
        },
      });
    }
    if (e.target.id === "bannerApp") {
      updateProjectImages({
        variables: {
          uuid: `${projectInfo.projectImages.uuid}`,
          data: {
            bannerApp: "",
          },
        },
      });
    }
    if (e.target.id === "bannerSite") {
      updateProjectImages({
        variables: {
          uuid: `${projectInfo.projectImages.uuid}`,
          data: {
            bannerSite: "",
          },
        },
      });
    }
    if (e.target.id === "background") {
      updateProjectImages({
        variables: {
          uuid: `${projectInfo.projectImages.uuid}`,
          data: {
            background: "",
          },
        },
      });
    }
    dispatch(
      addProjectImagesFields({
        fieldName: e.target.id,
        item: "",
      })
    );
  };

  const draftSaved = (
    <>
      <img src={iconDraft} alt="draft" width="17" height="17" />
      <span style={{ marginLeft: "7px" }}>Черновик сохранен</span>
    </>
  );

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: "7px" }}>Сохранено</span>
    </>
  );

  // Сохранение действующего проекта
  const handleSaveSent = () => {
    // сохранение лого  только по нажатию кнопки
    dispatch(
      addProjectImagesFields({
        // для новой галереи
        fieldName: "logo",
        // item: upload.url,
        item: logo,
      })
    );

    updateProjectImages({
      variables: {
        uuid: `${projectInfo.projectImages.uuid}`,
        data: {
          logo,
        },
      },
    });

    // сохранение баннерАпп по кнопке
    dispatch(
      addProjectImagesFields({
        // для новой галереи
        fieldName: "bannerApp",
        item: bannerApp,
      })
    );

    updateProjectImages({
      variables: {
        uuid: `${projectInfo.projectImages.uuid}`,
        data: {
          bannerApp,
        },
      },
    });

    // сохранение баннерСайт по кнопке
    dispatch(
      addProjectImagesFields({
        fieldName: "bannerSite",
        item: bannerSite,
      })
    );

    updateProjectImages({
      variables: {
        uuid: `${projectInfo.projectImages.uuid}`,
        data: {
          bannerSite,
        },
      },
    });

    dispatch(
      addProjectImagesFields({
        fieldName: "background",
        item: background,
      })
    );

    updateProjectImages({
      variables: {
        uuid: `${projectInfo.projectImages.uuid}`,
        data: {
          background,
        },
      },
    });

    dispatch(
      addProjectImagesFields({
        fieldName: "gallery",
        item: JSON.stringify(arrUpload),
      })
    );
    dispatch(closeCountriesSelect());
    updateProjectImages({
      variables: {
        uuid: `${projectInfo.projectImages.uuid}`,
        data: {
          gallery: JSON.stringify(arrUpload),
        },
      },
    });

    setSent(true);
    // const { requisites, images, ...obj } = projectInfo

    const { requisites, support, investForms, investTresholds, ...obj } =
      projectInfo;

    projectUpdate({
      variables: {
        uuid,
        data: {
          name: projectInfo.name,
          nameEn: projectInfo.nameEn,
          description: projectInfo.description,
          descriptionEn: projectInfo.descriptionEn,
          country: projectInfo.country,
          minInvestment: projectInfo.minInvestment, // обязательное поле
          minInterest: projectInfo.minInterest, // обязательное поле
          // status: '',
          // images: '',
          requisites: JSON.stringify(requisites),
          // support: projectInfo.support,
          support:
            projectInfo.support === ""
              ? JSON.stringify(projectInfo.support)
              : projectInfo.support,
          // support: JSON.stringify(support),
          // sendStatus: '',
          url: projectInfo.url,
          fullDescription: projectInfo.fullDescription,
          fullDescriptionEn: projectInfo.fullDescriptionEn,
          target: projectInfo.target,
          targetEn: projectInfo.targetEn,
          // investForms: JSON.stringify(investForms),
          investForms:
            projectInfo.investForms === ""
              ? JSON.stringify(projectInfo.investForms)
              : projectInfo.investForms,
          // investThresholds: JSON.stringify(investTresholds),
          investThresholds:
            projectInfo.investTresholds === ""
              ? JSON.stringify(projectInfo.investTresholds)
              : projectInfo.investTresholds,
          // imageUUID: projectInfo.projectImages.uuid,
          projectStatusNew: projectInfo.projectStatusNew,
          income: projectInfo.income,
          socialNetworks: projectInfo.socialNetworks,
        },
      },
    });
    if (!loadingUpdatedProject || errorUpdatedProject === undefined) {
      setTimeout(() => {
        hidePage();
      }, 3000);
    } else {
      setTimeout(() => {
        setSent(true);
      });
    }

    // setSent(true)
    // const { requisites, images, ...obj } = projectInfo
    // projectUpdate({
    //   variables: {
    //     uuid,
    //     data: {
    //       ...obj,
    //       requisites: JSON.stringify(requisites),
    //       images: JSON.stringify(images),
    //       status: project?.projects[0].status,
    //       sendStatus: 'sent',
    //     },
    //   },
    // })
    // if (!loadingUpdatedProject || errorUpdatedProject === undefined) {
    //   setTimeout(() => {
    //     hidePage()
    //   }, 3000)
    // } else {
    //   setTimeout(() => {
    //     setSent(true)
    //   })
    // }
  };
  // Сохранение черновика проекта
  const handleSaveDraft = () => {
    setDrafted(true);
    const { requisites, images, ...obj } = projectInfo;
    projectUpdate({
      variables: {
        uuid,
        data: {
          ...obj,
          requisites: JSON.stringify(requisites),
          images: JSON.stringify(images),
          status: "published",
          sendStatus: "draft",
        },
      },
    });
    if (!loadingUpdatedProject || errorUpdatedProject === undefined) {
      setTimeout(() => {
        hidePage();
      }, 3000);
    } else {
      setTimeout(() => {
        setDrafted(true);
      });
    }
  };

  return (
    <Container className={s.container}>
      <CloseIcon onClick={hidePage} />
      <Title className={s.title} title="Добавить проект" />

      <div className={s.wrapper}>
        <div className={s.first_block}>
          <Panel className={s.info__panel} padding>
            <Title className={s.title__panel} title="Информация" />

            <Label title="Название">
              <div className={s.name_status_wrapper}>
                <Field
                  className={s.codes_numbers}
                  view="default"
                  value={projectName}
                  onChange={projectNameHandler}
                  maxLength={100}
                />

                <Field
                  className={s.codes_numbers}
                  view="default"
                  value={projectNameEn}
                  onChange={projectNameHandlerEn}
                  maxLength={100}
                  eng
                />
              </div>
            </Label>

            <div className={s.name_status_wrapper}>
              <Label title="Статус проекта">
                <NativeSelect
                  pledgeArr={statusArr}
                  status
                  setPledgeArr={setStatusArr}
                  show={statusSelect}
                  draft={draft}
                  projectStatusNew={projectStatusNew}
                  onClick={() => dispatch(changeStatusSelect())}
                />
              </Label>

              <Label title="Страна проекта">
                <NativeSelect
                  countriesArr={countriesArr} // массив со странами
                  countries // булевое значение
                  show={countriesSelect} // активный/неактивный селект
                  onClick={() => dispatch(changeCountriesSelect())} // изменение состояния по клику на селект
                  selectedCountry={selectedCountry}
                  countryHandler={handleCountrySelect} // функция изменения текущего значения
                />
              </Label>
            </div>

            <Label title="Юридическое название ">
              <div className={s.name_status_wrapper}>
                <Field
                  className={s.orgInfo}
                  view="default"
                  value={orgName}
                  onChange={orgInfoHandler}
                  maxLength={100}
                />
                <Field
                  className={s.orgInfo}
                  view="default"
                  value={orgNameEn}
                  onChange={orgInfoHandlerEn}
                  maxLength={100}
                  eng
                />
              </div>
            </Label>

            <div className={s.codes}>
              <Label title="ИНН">
                <Field
                  className={s.codes_numbers}
                  type="number"
                  view="default"
                  value={inn.length <= 12 ? inn : inn.slice(0, 12)}
                  onChange={innHandler}
                />
              </Label>
              <Label title="ОГРН">
                <Field
                  className={s.codes_numbers}
                  type="number"
                  view="default"
                  value={ogrn.length <= 13 ? ogrn : ogrn.slice(0, 13)}
                  onChange={ogrnHandler}
                />
              </Label>
            </div>

            <Label title="Адрес местонахождения">
              <div className={s.name_status_wrapper}>
                <Field
                  className={s.orgInfo}
                  view="default"
                  value={activity}
                  onChange={activityHandler}
                  maxLength={140}
                />

                <Field
                  className={s.orgInfo}
                  view="default"
                  value={activityEn}
                  onChange={activityHandlerEn}
                  maxLength={140}
                  eng
                />
              </div>
            </Label>

            <Label title="Юридический адрес">
              <div className={s.name_status_wrapper}>
                <Field
                  className={s.orgInfo}
                  view="default"
                  value={address}
                  onChange={addressHandler}
                  maxLength={100}
                />

                <Field
                  className={s.orgInfo}
                  view="default"
                  value={addressEn}
                  onChange={addressHandlerEn}
                  maxLength={100}
                  eng
                />
              </div>
            </Label>

            <Label title="Короткое описание">
              <div className={s.name_status_wrapper}>
                <Textarea
                  className={s.description}
                  value={description}
                  onChange={descriptionHandler}
                  rows={3}
                  maxLength={350}
                />
                <Textarea
                  className={s.description}
                  value={descriptionEn}
                  eng
                  onChange={descriptionHandlerEn}
                  rows={3}
                  maxLength={350}
                />
              </div>
            </Label>

            <Label title="Полное описание">
              <div className={s.name_status_wrapper}>
                <Textarea
                  className={s.description}
                  value={fullDescription}
                  onChange={fullDescriptionHandler}
                  rows={5}
                  maxLength={350}
                />

                <Textarea
                  className={s.description}
                  value={fullDescriptionEn}
                  onChange={fullDescriptionHandlerEn}
                  rows={5}
                  maxLength={350}
                  eng
                />
              </div>
            </Label>

            {/* </Panel> */}
            <Label title="Мин. сумма инвестиций">
              <Field
                className={s.minInvestments}
                view="default"
                placeholder="300 000 ₽"
                value={
                  investments
                    ? // eslint-disable-next-line operator-linebreak
                      // eslint-disable-next-line operator-linebreak
                      // ? `${Number(investments.replace(/\D/g, '')).toLocaleString(
                      //   'ru',
                      // )}`
                      `${Number(
                        String(investments).replace(/\D/g, "")
                      ).toLocaleString()}`
                    : investments
                }
                currency={!!investments}
                caretPos={840 - String(investments).length * 8}
                onChange={investmentsHandler}
                maxLength={16}
              />
            </Label>
            <Label title="Мин. доходность">
              <Field
                className={s.minIncome}
                view="default"
                placeholder="25%"
                // value={income}
                value={
                  income
                    ? `${Number(income.replace(/\D/g, "")).toLocaleString(
                        "ru"
                      )}`
                    : income
                }
                onChange={incomeHandler}
                maxLength={3}
              />
            </Label>
          </Panel>
          <Panel className={s.link__panel} padding>
            <Title className={s.title__panel} title="Ссылки" />
            <Label title="Сайт">
              <Field
                className={s.linkSite}
                view="default"
                value={linkSite}
                onChange={linkSiteHandler}
                maxLength={100}
              />
            </Label>
            <Label title="Соц. сети">
              <div className={s.telegram_wrapper}>
                <div className={s.icon_telegram}>{}</div>
                <Field
                  className={s.linkTelegram}
                  view="default"
                  value={linkTelegram}
                  onChange={linkTelegramHandler}
                  maxLength={100}
                  telegram
                />
              </div>
            </Label>
          </Panel>
          <Panel className={s.link__panel} padding>
            <Title className={s.title__panel} title="О Проекте" />
            <Label title="Цель проекта">
              <div className={s.name_status_wrapper}>
                <Textarea
                  className={s.description}
                  value={purposeDescription}
                  onChange={purposeHandler}
                  rows={5}
                  maxLength={350}
                />
                <Textarea
                  className={s.description}
                  value={purposeDescriptionEn}
                  onChange={purposeHandlerEn}
                  rows={5}
                  maxLength={350}
                  eng
                />
              </div>
            </Label>
            <Label title="Форма привлечения инвестиций">
              <NativeSelect
                className={s.pledge}
                tags
                pledgeArr={investFormArr}
                setPledgeArr={setInvestFormArr}
                show={investFormSelect}
                draft={draft}
                onClick={() => dispatch(changeInvestFormSelect())}
              />
            </Label>
          </Panel>

          <Panel className={s.pledge__panel} padding>
            <Title className={s.title__panel} title="Виды обеспечения" />
            {pledgeArrForState.map((item: any, index: any) => (
              <div className={s.pledge_wrapper} key={"pledge".concat(index)}>
                <Label title="Обеспечение">
                  {/* <NativeSelectPledge
                    className={s.pledge_dropdown}
                    tags
                    pledgeArr={pledge}
                    setPledgeArr={setPledge}
                    show={pledgeSelect}
                    draft={draft}
                    setPledgeArrFroState={setPledgeArrForState}
                    pledgeArrForState={pledgeArrForState}
                    name={pledgeArrForState[index].name}
                    key={item.id}
                    idChange={item.id}
                  /> */}
                  <NativeSelectPledge
                    className={s.pledge_dropdown}
                    tags
                    pledgeArr={pledge}
                    setPledgeArr={setPledge}
                    show={pledgeSelect}
                    draft={draft}
                    setPledgeArrFroState={setPledgeArrForState}
                    pledgeArrForState={pledgeArrForState}
                    closeHandler={closeHandler}
                    name={pledgeArrForState[index].name}
                    key={item.id}
                    idChange={item.id}
                    clickHandler={clickHandler}
                    item={item}
                  />
                </Label>
                <Label title="Необходимая сумма">
                  <Field
                    className={s.pledge_numbers}
                    placeholder="1 000 000 ₽"
                    view="default"
                    value={
                      item.need_to_invest
                        ? `${Number(
                            String(item.need_to_invest).replace(/\D/g, "")
                          ).toLocaleString()}`
                        : item.need_to_invest
                    }
                    onChange={pledgeSumHandler}
                    onClick={() => handleDeleteSelect(item.id, item.name)}
                    maxLength={12}
                    id={index}
                    remove
                    currency={!!item.need_to_invest}
                    caretPos={370 - String(item.need_to_invest).length * 8.5}
                    // caretPos={index === 0 ? 375 - (String(item.need_to_invest).length) * 8.1 : 375 - ((String(item.need_to_invest).length) - 1) * 8.1}
                  />
                </Label>
              </div>
            ))}
            <AddButton onClick={() => addPledgeLine()}>
              Добавить еще один тип обеспечения
            </AddButton>
          </Panel>

          <Panel className={s.investLevels__panel} padding>
            <Title className={s.title__panel} title="Инвестиционные пороги" />
            {investLevels.map((el: any, index: any) => (
              <div
                className={s.investLevels_wrapper}
                key={"thresholds".concat(index)}
              >
                <Label title="Сумма">
                  <Field
                    className={s.pledge_numbers}
                    placeholder="1 000 000 ₽"
                    view="default"
                    value={
                      el.sum
                        ? `${Number(
                            String(el.sum).replace(/\D/g, "")
                          ).toLocaleString()}`
                        : el.sum
                    }
                    onChange={levelsLineSumHandler}
                    maxLength={16}
                    id={el.id}
                    currency={!!el.sum}
                    caretPos={370 - String(el.sum).length * 8.5}
                  />
                </Label>
                <Label title="Процент">
                  <Field
                    className={s.pledge_numbers}
                    placeholder="25%"
                    view="default"
                    type="number"
                    value={el.percent}
                    onChange={levelsLineLevelHandler}
                    onClick={() => handleDelete(el.id)}
                    // id={index}
                    id={el.id}
                    remove
                    maxLength={3}
                  />
                </Label>
              </div>
            ))}
            <AddButton onClick={addInvestLevelsLine}>
              Добавить еще один инвестиционный порог
            </AddButton>
          </Panel>

          <Panel className={s.investLevels__panel} padding>
            <Title
              className={s.title__panel}
              title="Заработали инвесторы за все время"
            />
            <Label title="Сумма">
              <Field
                className={s.earnedAmount}
                view="default"
                placeholder="1 000 000 ₽"
                value={
                  earnedAmount
                    ? `${Number(earnedAmount.replace(/\D/g, "")).toLocaleString(
                        "ru"
                      )}`
                    : earnedAmount
                }
                currency={!!earnedAmount}
                caretPos={840 - earnedAmount.length * 7.1}
                onChange={earnedAmountHandler}
                maxLength={16}
              />
            </Label>
          </Panel>

          <div className={s.uploads}>
            <div className={s.icon_mobile}>{}</div>
            <div className={s.icon_desctope}>{}</div>
            <UploadProject
              title="Загрузить лого"
              titleStatus="Лого проекта"
              // text="Максимальный размер фото 200 Кб."
              text="Максимальный размер фото 50 Мб"
              text2="Формат:  png"
              application=".png"
              subText1="Загрузить с компьютера"
              subText2="или перетащите сюда"
              status={statusFilePng}
              uploadId="png"
              hadleDeleteImagesUpperSection={hadleDeleteImagesUpperSection}
              logo={logo}
              changeLogo={changeLogo}
              deleteLogo={deleteLogo}
            />
            <div className={s.icon_desctope_right}>{}</div>
            <UploadProject
              title="Загрузить заглавное фото"
              titleStatus="Заглавное фото проекта"
              // text="Максимальный размер фото 200 Кб"
              // text2="Формат: webp"
              text="Максимальный размер фото 50 Мб"
              text2="Форматы: jpg, png, webp"
              application=".webp"
              subText1="Загрузить с компьютера"
              subText2="или перетащите сюда"
              status={statusFileWebp}
              uploadId="webp"
              hadleDeleteImagesUpperSection={hadleDeleteImagesUpperSection}
              changeBannerApp={changeBannerApp}
              deleteBannerApp={deleteBannerApp}
              bannerApp={bannerApp}
            />
          </div>

          <div className={s.uploads}>
            <div className={s.icon_desctope}>{}</div>
            <UploadProject
              title="Загрузить баннер проекта"
              titleStatus="Загрузить баннер проекта"
              // text="Максимальный размер фото 200 Кб."
              // text2="Формат:  png"
              text="Максимальный размер фото 50 Мб"
              text2="Форматы: jpg, png, webp"
              application=".png"
              subText1="Загрузить с компьютера"
              subText2="или перетащите сюда"
              status={statusFilePngBanner}
              uploadId="pngBannerProject"
              hadleDeleteImagesUpperSection={hadleDeleteImagesUpperSection}
              bannerSite={bannerSite}
              changeBannerSite={changeBannerSite}
              deleteBannerSite={deleteBannerSite}
            />
            <div className={s.icon_desctope_right}>{}</div>
            <UploadProject
              title="Загрузить фон проекта"
              titleStatus="Загрузить фон проекта"
              // text="Максимальный размер фото 200 Кб"
              // text2="Формат: webp"
              text="Максимальный размер фото 50 Мб"
              text2="Форматы: jpg, png, webp"
              application=".webp"
              subText1="Загрузить с компьютера"
              subText2="или перетащите сюда"
              status={statusFileWebpBackground}
              uploadId="webpBackground"
              hadleDeleteImagesUpperSection={hadleDeleteImagesUpperSection}
              background={background}
              changeBackground={changeBackground}
              deleteBackground={deleteBackground}
            />
          </div>

          <div className={s.uploads}>
            <div className={s.icon_desctope_right}>{}</div>
            <UploadProject
              title="Загрузить фото в галерею"
              titleStatus="Загрузить фото в галерею"
              text=""
              // text2="Максимальный размер фото 200 кб. Форматы: jpeg, jpg, png, webp"
              text2="Максимальный размер фото 50 Мб. Форматы: jpg, png, webp"
              // application=".webp"
              subText1="Загрузить с компьютера"
              subText2="или перетащите сюда"
              status={statusFileGallery}
              uploadId="uploadGallery"
              arrUpload={arrUpload}
              setArrUpload={setArrUpload}
              hadleDeleteImage={hadleDeleteImage}
              gallery
            />
          </div>

          <Panel className={s.btns__panel}>
            {/* <Button
              onClick={() => console.log('click')}
              disabled
              className={s.btns__panel_preview}
              icon
              src={preview}
            >
              Предпросмотр
            </Button> */}

            <Button
              onClick={stateButton ? handleSaveSent : handlePlug}
              // disabled={Math.ceil(sumField) !== 100}
              className={
                stateButton
                  ? s.btns__panel_save_active
                  : s.btns__panel_save_disabled
              }
              theme="dark"
              onMouseEnter={
                stateButton
                  ? () => {
                      setShowToolTip(false);
                    }
                  : () => {
                      setShowToolTip(true);
                    }
              }
              onMouseLeave={
                stateButton
                  ? () => {
                      setShowToolTip(false);
                    }
                  : () => {
                      setShowToolTip(false);
                    }
              }
            >
              {!sent &&
              ["active", "suspended", "archive"].includes(
                projectInfo.projectStatusNew
              ) ? (
                "Сохранить и опубликовать"
              ) : !sent ? (
                "Сохранить"
              ) : loadingProject || loadingUpdatedProject ? (
                <Loader />
              ) : !loadingProject && !loadingUpdatedProject && errorProject ? (
                "Ошибка"
              ) : (
                sentSaved
              )}
            </Button>

            {showToolTip && (
              <div className={s.tooltip}>
                <span className={s.tooltip_text}>{tooltipText}</span>
              </div>
            )}
          </Panel>
        </div>
        <Panel className={s.panel__progress} padding>
          <ProgressBar
            className={s.project_create}
            value={Math.ceil(sumField)}
          />
          <div className={s.project_indicator}>
            <img
              src={
                projectName &&
                projectNameEn &&
                orgName &&
                orgNameEn &&
                description &&
                descriptionEn &&
                selectedCountry &&
                address &&
                addressEn &&
                activity &&
                activityEn &&
                ogrn &&
                inn &&
                fullDescription &&
                fullDescriptionEn &&
                investments &&
                income
                  ? fullFill
                  : empty
              }
              alt="empty"
            />
            <p>Информация</p>
          </div>
          <div className={s.project_indicator}>
            <img
              src={
                linkSite !== "https://" &&
                linkSite !== "" &&
                linkTelegram !== "https://t.me/" &&
                linkSite !== ""
                  ? fullFill
                  : empty
              }
              alt="empty"
            />
            <p>Ссылки</p>
          </div>
          <div className={s.project_indicator}>
            <img
              src={
                purposeDescription && projectInfo.investForms ? fullFill : empty
              }
              alt="empty"
            />
            <p>О проекте</p>
          </div>
          <div className={s.project_indicator}>
            <img src={projectInfo.support ? fullFill : empty} alt="empty" />
            <p>Виды обеспечения</p>
          </div>
          <div className={s.project_indicator}>
            <img
              src={projectInfo.investTresholds ? fullFill : empty}
              alt="empty"
            />
            <p>Инвестиционные пороги</p>
          </div>
          <div className={s.project_indicator}>
            <img src={earnedAmount ? fullFill : empty} alt="empty" />
            <p>Вложения</p>
          </div>

          <div className={s.project_indicator}>
            <img
              src={
                logo &&
                background &&
                bannerApp &&
                bannerSite &&
                arrUpload.length
                  ? fullFill
                  : empty
              }
              alt="empty"
            />
            <p>Фото</p>
          </div>
        </Panel>
      </div>
    </Container>
  );
};
