/* eslint-disable */

import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import s from "./DataPicker.module.css";
import "./DataPicker.css";

export type Data = Date | null;

type Props = {
  className?: string;
  startDate: Date | null;
  onChange: (arg0: Data) => void;
};

registerLocale("ru", ru);
setDefaultLocale("ru");

export const DataPicker = ({ className, startDate, onChange }: Props) => (
  <div className={clsx(s.picker, className)}>
    <DatePicker
      className={s.picker__input}
      selected={startDate}
      locale="ru"
      dateFormat="dd.MM.yy"
      popperPlacement="bottom"
      popperClassName={s.popper}
      onChange={onChange}
      customInput={
        <MaskedTextInput
          type="text"
          mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/]}
        />
      }
    />
  </div>
);
