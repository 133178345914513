import { gql } from '@apollo/client'

export const STORIES = gql`
  query Stories($filter: PromotionFilter) {
    promotionStories(filter: $filter) {
      uuid
      name
      status
      startedAt
      endedAt
      header
      image
      color
      slides
      gradientColor
  gradientDeg
  gradientLocation
    }
  }
`
