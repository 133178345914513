import { gql } from '@apollo/client'

export const GET_FOOTER = gql`
  query footer {
    footer {
      socnetYoutube
      socnetTelegram
      socnetInstagram
      socnetVK
      contactsAddress
      contactsAddressEn
      contactsMail
      contactsPhone
      textHeader
      textHeaderEn
      textText
      textTextEn
    }
  }
`
