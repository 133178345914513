/* eslint-disable */

import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { UPDATE_STORIES_PROMOTION } from "../../apollo/mutation/promotion/promotionUpdate";
import { STORIES } from "../../apollo/query/promotion/stories";
import {
  addSlide,
  changeColorSlide,
  changeFieldState,
  changeFirstField,
  deleteSlide,
  changeColorMiniature,
  changeStatusSelectPromotionSlide,
  editChangeField,
  editChangeColorMiniature,
  resetPromotionObj,
} from "../../redux/slices/promotionSlice";
import { resetPromotionDynamicObj } from "../../redux/slices/promotionDynamicSlice";
import {
  StoriesSlide,
  StoriesMiniature,
  StoriesInfo,
  Button,
  Loader,
  Panel,
  ProgressBar,
  Title,
  ToggleButton,
  MiniatureUI,
  SliderUI,
} from "../../ui";
import fullFill from "./img/icon-fillfull.svg";
import empty from "./img/icon-empty.svg";
import arrow from "./img/icon-arrow-up.svg";
import s from "./EditStoriesPromotion.module.css";

interface EditStoriesPromotionProps {
  uuid?: string;
}

export const EditStoriesPromotion: React.FC<EditStoriesPromotionProps> = ({
  uuid,
}) => {
  const navigate = useNavigate();

  const {
    data: dataStories,
    loading: loadingStories,
    error: errorStories,
  } = useQuery(STORIES, {
    variables: {
      filter: {
        uuid,
      },
    },
  });
  const [updateStories, { data, loading, error }] = useMutation(
    UPDATE_STORIES_PROMOTION,
    {
      refetchQueries: [
        {
          query: STORIES,
        },
        {
          query: STORIES,
          variables: {
            filter: {
              uuid,
            },
          },
        },
        {
          query: STORIES,
          variables: {
            filter: {
              status: "active",
            },
          },
        },
        {
          query: STORIES,
          variables: {
            filter: {
              status: "draft",
            },
          },
        },
        {
          query: STORIES,
          variables: {
            filter: {
              status: "archive",
            },
          },
        },
      ],
    }
  );
  const { promotionObj, colorsMiniature, statusSelect } = useAppSelector(
    (state) => state.promotionSlice
  );
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [sumField, setSumField] = useState(0);
  const {
    color,
    image,
    gradientColor,
    gradientDeg,
    gradientLocation,
    header,
    __typename,
    ...promDynamicObj
  } = promotionObj;
  const arr = Object.values(promDynamicObj);
  useEffect(() => {
    setSumField(
      arr.map((e) => (e ? 16.6 : 0)).reduce((a: any, b: any) => a + b)
    );
  }, [promotionObj]);

  const [collapse, setCollapse] = useState(false);
  const [collapseMiniature, setCollapseMiniature] = useState(false);
  useEffect(() => {
    if (dataStories?.promotionStories[0]) {
      for (const key in dataStories?.promotionStories[0]) {
        if (key !== "uuid") {
          if (key === "color") {
            dispatch(
              editChangeColorMiniature(
                dataStories?.promotionStories[0][key].includes("linear")
                  ? {
                      index: 1,
                      nameGroup: "gradientColors",
                      color: dataStories?.promotionStories[0][key],
                    }
                  : {
                      index: 0,
                      nameGroup: "defaultColors",
                      color: dataStories?.promotionStories[0][key],
                    }
              )
            );
          }
          dispatch(
            editChangeField({
              nameField: key,
              event: dataStories?.promotionStories[0][key],
            })
          );
        }
      }
      setIsLoading(false);
    }
  }, [dataStories]);

  const [btn, setBtn] = useState(1);

  const slideRef = useRef<any>(null);

  const next = () => {
    setBtn((prev) => (prev >= promotionObj?.slides?.length ? 1 : prev + 1));
    slideRef?.current?.slickNext();
  };
  const previous = () => {
    slideRef?.current?.slickPrev();
    setBtn((prev) => (prev <= 1 ? promotionObj?.slides?.length : prev - 1));
  };
  // console.log('isLoading', isLoading)
  // console.log('storiesData', data)
  // console.log('storiesError', error)
  // console.log('  promotionObj', promotionObj)

  if (isLoading) {
    return (
      <div className={s.loading}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={s.main_wrapper}>
      <div className={s.wrapper}>
        <StoriesInfo
          promotion="stories"
          statusSelect={statusSelect}
          name={promotionObj.name}
          status={promotionObj.status}
          startDate={new Date(Date.parse(promotionObj?.startedAt))}
          endDate={new Date(Date.parse(promotionObj?.endedAt))}
          changeField={(e: any, nameField: string) =>
            dispatch(changeFirstField({ event: e, nameField }))
          }
          changeStatus={() => dispatch(changeStatusSelectPromotionSlide())}
        />
        <StoriesMiniature
          colorsMiniature={colorsMiniature}
          title={promotionObj.header}
          // colorMiniature={promotionObj.colorMiniature}
          // image={promotionObj.imageMiniature}
          changeColorMiniature={(
            colorId: number,
            color: string,
            nameGroup: string,
            index: number
          ) =>
            dispatch(
              changeColorMiniature({
                colorId,
                color,
                nameGroup,
                index,
              })
            )
          }
          changeField={(e: any, nameField: string) =>
            dispatch(changeFirstField({ event: e, nameField }))
          }
        />
        {promotionObj.slides.map((elem, i) => (
          <StoriesSlide
            // colorsSlide={colorsSlide}
            key={i}
            indexSlide={i}
            slide={elem}
            addSlide={() => dispatch(addSlide())}
            changeField={(e: any, id: number, nameField: string) =>
              dispatch(
                changeFieldState({ event: e.target.value, id, nameField })
              )
            }
            deleteItemSlide={(id: number) => dispatch(deleteSlide(id))}
            changeColorSlide={(
              colorId: number,
              slideId: number,
              color: string,
              nameGroup: string,
              index: number
            ) =>
              dispatch(
                changeColorSlide({
                  colorId,
                  slideId,
                  color,
                  nameGroup,
                  index,
                })
              )
            }
          />
        ))}
        <Button
          disabled={
            Math.ceil(sumField + (image || color || header ? 16.6 : 0)) !== 100
          }
          theme={
            Math.ceil(sumField + (image || color || header ? 16.6 : 0)) !== 100
              ? "secondary"
              : "dark"
          }
          onClick={() => {
            const { status, slides, __typename, ...storiesObj } = promotionObj;
            updateStories({
              variables: {
                uuid,
                data: {
                  ...storiesObj,
                  status:
                    status === "Активный"
                      ? "active"
                      : status === "Черновик"
                      ? "draft"
                      : "archive",
                  slides: JSON.stringify(slides),
                },
              },
            });
            setTimeout(() => {
              navigate("/promotion", { replace: true });
              dispatch(resetPromotionObj());
              dispatch(resetPromotionDynamicObj());
            }, 1500);
          }}
        >
          Сохранить
        </Button>
      </div>
      <div className={s.panel__progress}>
        <Panel padding>
          <ProgressBar
            className={s.project_create}
            value={Math.ceil(sumField + (image || color || header ? 16.6 : 0))}
          />
          <div className={s.project_indicator}>
            <img
              src={
                promotionObj.endedAt &&
                promotionObj.startedAt &&
                promotionObj.name &&
                promotionObj.status
                  ? fullFill
                  : empty
              }
              alt="empty"
            />
            <p>Информация</p>
          </div>
          <div className={s.project_indicator}>
            <img
              src={promotionObj.header || color || image ? fullFill : empty}
              alt="empty"
            />
            <p>Превью</p>
          </div>
          <div className={s.project_indicator}>
            <img src={promotionObj.slides ? fullFill : empty} alt="empty" />
            <p>Контент</p>
          </div>
        </Panel>
        <Panel
          padding
          className={clsx(s.promotion_select, {
            [s.promotion_select_active]: collapseMiniature,
          })}
        >
          <div className={s.promotion_select_header}>
            <Title className={s.dynamic_title} title="Миниатюра" />
            <ToggleButton
              className={s.promotion_select_btn}
              src={arrow}
              rotate={collapseMiniature}
              iconWidth="18px"
              iconHeight="18px"
              onClick={() => setCollapseMiniature(!collapseMiniature)}
            />
          </div>
          <MiniatureUI
            image={image!}
            color={color!}
            header={promotionObj.header}
          />
        </Panel>
        <Panel
          padding
          className={clsx(s.promotion_select, {
            [s.promotion_select_active]: collapse,
          })}
        >
          <div className={s.promotion_select_header}>
            <Title className={s.dynamic_title} title="Слайд" />
            <ToggleButton
              className={s.promotion_select_btn}
              src={arrow}
              rotate={collapse}
              iconWidth="18px"
              iconHeight="18px"
              onClick={() => setCollapse(!collapse)}
            />
          </div>
          <SliderUI
            slides={promotionObj?.slides}
            slideRef={slideRef}
            next={next}
            previous={previous}
            btn={btn}
          />
        </Panel>
      </div>
    </div>
  );
};
