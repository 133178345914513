import { gql } from '@apollo/client'

export const UPDATE_FOOTER = gql`
  mutation admFooterUpdate($data: FooterDataUpdate!) {
    admFooterUpdate(data: $data) {
      socnetYoutube
      socnetTelegram
      socnetInstagram
      socnetVK
      contactsAddress
      contactsAddressEn
      contactsMail
      contactsPhone
      textHeader
      textHeaderEn
      textText
      textTextEn
    }
  }
`
