/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable wrap-iife */
import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useGetRole } from '../../hooks/useGetRole'
import { useAppSelector } from '../../redux/hook'
import { PROJECTS } from '../../apollo/query/allProjects'
import { Layout } from '../../features/Layouts'
import { Loader, Title } from '../../ui'
import { CreateProject, ProjectsList } from '../../components'
import {
  lawyer,
  manager,
  marketer,
  technical1,
  technical2,
} from '../../references/data'
import s from './Projects.module.css'
import { UserProfile } from '../../components/UserProfile/UserProfile'

export const Projects = () => {
  const { isCreateProject } = useAppSelector(state => state.pageSlice)
  const { data, loading, error } = useQuery(PROJECTS)
  const { isRole, location, navigate } = useGetRole()

  useEffect(() => {
    ;(function redirect() {
      if (isRole === technical1 && location.pathname === '/projects') {
        navigate('/clients', { replace: true })
      } else if (isRole === technical2 && location.pathname === '/projects') {
        navigate('/documents', { replace: true })
      } else if (isRole === marketer && location.pathname === '/projects') {
        navigate('/promotion', { replace: true })
      } else if (isRole === lawyer && location.pathname === '/projects') {
        navigate('/home', { replace: true })
      } else if (isRole === manager && location.pathname === '/projects') {
        navigate('/home', { replace: true })
      }
    })()
  }, [location, isRole])

  return (
    <Layout>
      {isRole === 'admin' && (
        <>
          <div className={s.header}>
            <div className={s.title}>
              <Title className={s.projects__title} title="Проекты" />
              {loading ? (
                <>
                  (
                  <Loader className={s.project_loader} />)
                </>
              ) : error ? (
                'Ошибка'
              ) : (
                <p>({data?.projects?.length})</p>
              )}
            </div>
            <UserProfile isRole={isRole} navigate={navigate} />
          </div>
          {(!isCreateProject && <ProjectsList />) || <CreateProject />}
        </>
      )}
    </Layout>
  )
}
