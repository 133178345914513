import { ButtonHTMLAttributes, FC } from 'react'
import clsx from 'clsx'
import pic from './img/icon-load_document.png'
import s from './Button.module.css'

interface buttonType {
  dark: string
  outline: string
  secondary: string
  icon: string
}

const themes: buttonType = {
  dark: s.dark,
  outline: s.outline,
  secondary: s.grey,
  icon: s.icon,
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  theme?: keyof typeof themes
  disabled?: boolean
  icon?: boolean
  src?: string
}

export const Button: FC<ButtonProps> = props => {
  const {
    className,
    children,
    src = pic,
    theme,
    disabled,
    icon,
    ...rest
  } = props

  return (
    <button
      type="button"
      className={clsx(s.btn, theme ? themes[theme] : null, className)}
      disabled={disabled}
      {...rest}
    >
      {icon && (
        <div className={s.btn__icon}>
          <img src={src} alt="icon" width="20" height="20" />
        </div>
      )}
      {children}
    </button>
  )
}
