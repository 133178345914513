import React from 'react'
import * as tus from 'tus-js-client'
import clsx from 'clsx'
import { useMutation } from '@apollo/client'
import { UPDATE_PROJECT_IMAGES } from '../../apollo/mutation/updateProjectImages'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import projectSlice, {
  statusPng,
  statusWebp,
  statusFilePngBanner, // для третей картинки
  statusFileWebpBackground, // для 4ой картинки
  statusFileGallery,
} from '../../redux/slices/projectSlice'
import { Panel, InputFile, Loader } from '../../ui'
import iconSuccess from './success.svg'
import s from './UploadProject.module.css'

interface UploadProps {
  application?: string
  title: string
  text: string
  text2: string
  subText1: string
  subText2: string
  status?: string | null
  titleStatus?: string
  uploadId?: string
  gallery?: boolean
  arrUpload?: any
  setArrUpload?: any
  hadleDeleteImage?: any
  hadleDeleteImagesUpperSection?:any
  logo?: string
  changeLogo?: any
  deleteLogo?: any
  changeBannerApp?:any
  deleteBannerApp?:any
  bannerApp?:string
  bannerSite?:string
  changeBannerSite?:any
  deleteBannerSite?:any
  background?:string,
  changeBackground?:any,
  deleteBackground?:any,
}

export const UploadProject: React.FC<UploadProps> = ({
  application,
  title,
  text,
  text2,
  subText1,
  subText2,
  status = '',
  titleStatus = '',
  uploadId,
  gallery,
  arrUpload,
  setArrUpload,
  hadleDeleteImage,
  hadleDeleteImagesUpperSection,
  logo,
  changeLogo,
  deleteLogo,
  changeBannerApp,
  deleteBannerApp,
  bannerApp,
  bannerSite,
  changeBannerSite,
  deleteBannerSite,
  background,
  changeBackground,
  deleteBackground,
}) => {
  const dispatch = useAppDispatch()

  const { projectInfo, galleryArr } = useAppSelector(
    state => state.projectSlice,
  )
  const [
    updateProjectImages,
    {
      data: updateImagesData,
      loading: updateImagesLoading,
      error: updateImagesError,
    },
  ] = useMutation(UPDATE_PROJECT_IMAGES)

  const upLoad = (e: any, titleStatus: string): void => {
    const file = e.target.files[0]
    console.log('file', file)
    // if (file.size <= 200000) {
    if (file.size <= 50000000) {
      if (typeof file === 'object') {
        // console.log('file', file)
        const fileType = file.name.slice(-4)
        console.log(fileType)
        const upload = new tus.Upload(file, {
          endpoint: process.env.REACT_APP_API_URL,
          retryDelays: [100, 300, 500, 1000, 3000],
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          onProgress(bytesUploaded: number, bytesTotal: number) {
            if ((fileType === '.png') && titleStatus.includes('Лого проекта')) {
              dispatch(statusPng('pending'))
            } else if (
              (fileType === '.png' || fileType === '.jpg' || fileType === 'webp') && titleStatus.includes('Заглавное фото проекта')
            ) {
              dispatch(statusWebp('pending'))
            } else if (
              (fileType === '.png' || fileType === '.jpg' || fileType === 'webp') && titleStatus.includes('Загрузить баннер проекта')
            ) {
              dispatch(statusFilePngBanner('pending'))
            } else if (
              (fileType === '.png' || fileType === '.jpg' || fileType === 'webp') && titleStatus.includes('Загрузить фон проекта')
            ) {
              dispatch(statusFileWebpBackground('pending'))
            }
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
            console.log(bytesUploaded, bytesTotal, `${percentage}%`)
          },
          onSuccess() {
            if (fileType === '.png' && titleStatus.includes('Лого проекта')) {
              changeLogo(upload.url)
              dispatch(statusPng('success'))
            } else if (
              (fileType === '.png' || fileType === '.jpg' || fileType === 'webp') && titleStatus.includes('Заглавное фото проекта')
            ) {
              changeBannerApp(upload.url)
              dispatch(statusWebp('success'))
            } else if (
              titleStatus.includes('Загрузить баннер проекта')
            ) {
              changeBannerSite(upload.url)
              dispatch(statusFilePngBanner('success'))
            } else if (
              (fileType === '.png' || fileType === '.jpg' || fileType === 'webp') && titleStatus.includes('Загрузить фон проекта')
            ) {
              changeBackground(upload.url)
              dispatch(statusFileWebpBackground('success'))
            } else if (
              (fileType === '.png' || fileType === '.jpg' || fileType === 'webp') && titleStatus.includes('Загрузить фото в галерею')
            ) {
              dispatch(statusFileGallery('success'))
              setArrUpload([...arrUpload, upload.url]) // добавляем url в массив который приходит из CreateProject
            }
            console.log(
              'Download %s from %s',
              upload.url?.replace(`${process.env.REACT_APP_API_URL}`, ''),
              file.name.slice(-4),
            )
          },
          onError(error: any) {
            if (fileType === '.png') {
              dispatch(statusPng('error'))
            } else if (fileType === 'webp') {
              dispatch(statusWebp('error'))
            }
            console.log(`Failed because: ${error}`)
          },
        })
        upload.findPreviousUploads().then((previousUploads: string | any[]) => {
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0])
          }
          // Start the upload
          upload.start()
        })
      }
    } else {
      if (file.type === '.png') {
        dispatch(statusPng('errorSize'))
      } else if (file.type === 'webp') {
        dispatch(statusWebp('errorSize'))
      }
      console.log('Failed')
    }
  }

  if (status === 'success') {
    return (
      <Panel className={s.upload} padding>
        <p className={s.title}>{title}</p>
        <p className={s.description}>{text}</p>
        <p className={s.description}>{text2}</p>
        <Panel className={s.upload__file_success}>
          <div className={s.imageRow_wrapper}>
            {uploadId === 'png' && logo !== '' && (
              <div
                className={s.image_wrapper}
              >
                <img
                  src={logo}
                  alt="logo"
                  className={s.image}
                />
                <div
                  className={s.image_delete}
                >
                  <div className={s.icon_delete} onClick={e => deleteLogo(e)} id="logo">{}</div>
                </div>
              </div>
            )}
            {uploadId === 'webp' && bannerApp !== '' && (
              <div
                className={s.image_wrapper}
              >
                <img
                // src={projectInfo.projectImages.bannerApp}
                  src={bannerApp}
                  alt="bannerApp"
                  className={s.image}
                />
                <div
                  className={s.image_delete}
                >
                  <div className={s.icon_delete} onClick={e => deleteBannerApp(e)} id="bannerApp">{}</div>
                </div>
              </div>
            )}
            {uploadId === 'pngBannerProject' && bannerSite !== '' && (
              <div
                className={s.image_wrapper}
              >
                <img
                  src={bannerSite}
                  alt="bannerSite"
                  className={s.image}
                />
                <div
                  className={s.image_delete}
                >
                  <div className={s.icon_delete} onClick={e => deleteBannerSite(e)} id="bannerSite">{}</div>
                </div>
              </div>
            )}
            {uploadId === 'webpBackground' && background !== '' && (
              <div
                className={s.image_wrapper}
              >
                <img
                  src={background}
                  alt="background"
                  className={s.image}
                />
                <div
                  className={s.image_delete}
                >
                  <div className={s.icon_delete} onClick={e => deleteBackground(e)} id="background">{}</div>
                </div>
              </div>
            )}
          </div>
          {uploadId === 'uploadGallery' && (
            <div className={s.imageRow_wrapper_wrap}>
              {arrUpload.map((el: any, i: any) => (
                <div
                  className={s.image_wrapper}
                  key={'galleryArr'.concat(i)}
                  id={'gallery'.concat(i)}
                >
                  <img
                    src={el}
                    alt="gallery"
                    className={s.image}
                    key={'galleryImg'.concat(i)}
                  />
                  <div
                    className={s.image_delete}
                    key={'galleryDiv'.concat(i)}
                    id={'gallery'.concat(i)}
                    onClick={e => hadleDeleteImage(e, i)}
                  >
                    <div className={s.icon_delete}>{}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className={s.text_wrapper}>
            <InputFile
              className={s.wrapper__succes}
              classNameSpan={s.span__success}
              uploadId={uploadId}
              onChange={e => upLoad(e, titleStatus)}
              // application={application}
              subText1={gallery ? ' Добавить фото' : 'Загрузить другой файл'}
            />
            {gallery && <div className={s.icon_plus}>{}</div>}
          </div>
        </Panel>
      </Panel>
    )
  }

  if (status === 'pending') {
    return (
      <Panel className={s.upload} padding>
        <Panel className={clsx(s.upload__file_success, s.upload__file_pending)}>
          <Loader />
        </Panel>
      </Panel>
    )
  }

  if (status === 'error' || status === 'errorSize') {
    return (
      <Panel className={s.upload} padding>
        <Panel className={clsx(s.upload__file_success, s.upload__file_error)}>
          <p>
            {status === 'errorSize'
              ? 'Ошибка, размер файла должен быть не больше 200 Кб'
              : 'Ошибка попробуйте позже'}
          </p>
          <InputFile
            uploadId={uploadId}
            className={s.wrapper__succes}
            classNameSpan={s.span__success}
            onChange={e => upLoad(e, titleStatus)}
            application={application}
            subText1="Загрузить другое фото"
          />
        </Panel>
      </Panel>
    )
  }

  return (
    <Panel className={s.upload} padding>
      <p className={s.title}>{title}</p>
      <p className={s.description}>{text}</p>
      <p className={s.description}>{text2}</p>
      <Panel className={s.upload__file}>
        <InputFile
          uploadId={uploadId}
          onChange={e => upLoad(e, titleStatus)}
          subText1={subText1}
          subText2={subText2}
        />
      </Panel>
    </Panel>
  )
}
