import clsx from 'clsx'
import { Label } from '../Label'
import s from './ProgressBar.module.css'

type progressProps = {
    className?: string
    value: number
}

export const ProgressBar = ({ className, value }: progressProps) => (
  <Label
    className={s.progress__label}
    title={`${value}% заполнено`}
  >
    <progress
      className={clsx(s.progress, className)}
      value={value}
      max="100"
    >
      {value}
    </progress>
  </Label>

)
