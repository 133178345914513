/* eslint-disable react/jsx-curly-newline */
/* eslint-disable newline-per-chained-call */
import { useState } from 'react'
import clsx from 'clsx'
import { useAppDispatch } from '../../redux/hook'
import { closeChatListToWrite } from '../../redux/slices/pageSlice'
import {
  Avatar, CloseIcon, Panel, Title
} from '../../ui'
import search from './img/icon-search.svg'
import s from './ChatCreate.module.css'

interface ChatCreateProps {
  allUsers?: any[]
  onChatCreate: (el: any) => void
}

export const ChatCreate = ({ onChatCreate, allUsers }: ChatCreateProps) => {
  const [value, setValue] = useState<any | undefined>('')

  const dispatch = useAppDispatch()

  const filteredAllUsersByLoginAndName = allUsers?.filter(
    (item: any) =>
      item?.name?.toLowerCase().includes(value.toLowerCase().trim()) ||
      item?.firstName?.toLowerCase().includes(value.toLowerCase().trim()) ||
      item?.lastName?.toLowerCase().includes(value.toLowerCase().trim()) ||
      item?.profile?.login?.toLowerCase().includes(value.toLowerCase().trim())
  )

  return (
    <Panel className={clsx(s.chat__add_panel)}>
      <div className={s.chat__add_header}>
        <Title className={s.chat__add_title} title="Кому написать" />
        <div className={s.chat__add_wrapper}>
          <input
            className={s.chat__add_search}
            type="text"
            onChange={e => {
              setValue(e.target.value)
            }}
            value={value}
            maxLength={50}
            placeholder="Поиск"
          />
          {!value && (
            <div className={s.chat__add_icon}>
              <img src={search} alt="search" width="15" height="15" />
            </div>
          )}
        </div>
        <CloseIcon
          type="button"
          onClick={() => dispatch(closeChatListToWrite())}
          width={30}
          height={30}
        />
      </div>
      <ul className={s.chat__add_itemsss}>
        {filteredAllUsersByLoginAndName?.map((elem: any, i: number) => (
          <div
            key={elem?.profile?.uuid}
            onClick={() => onChatCreate(elem?.profile?.uuid)}
          >
            <li className={clsx(s.chat__add_list)}>
              <div
                className={s.chat__add_item}
                style={{ backgroundColor: i % 2 === 0 ? '#f3f3f3' : '#fafafa' }}
              >
                {elem?.photo ? (
                  <img src={elem?.photo} alt="avatar" width="50" height="50" />
                ) : (
                  <Avatar
                    className={s.chat__avatar}
                    firstName={
                      elem?.name
                        ?.split(' ')
                        .splice(1, 1)
                        .reverse()
                        .join(' ')[0] ||
                      elem?.firstName
                        ?.split('')
                        .slice(0, 1)
                        .reverse()
                        .slice(0, 1)
                        .join('')[0]
                    }
                    lastName={
                      elem?.name
                        ?.split(' ')
                        .splice(0, 2)
                        .slice(0, 1)
                        .join(' ')[0] ||
                      elem?.lastName
                        ?.split(' ')
                        .slice(0, 2)
                        .reverse()
                        .slice(0, 1)
                        .join('')[0]
                    }
                  />
                )}
                <p>
                  {`${
                    elem?.firstName ||
                    elem?.name?.split(' ').splice(1, 1).reverse().join(' ')
                  } ${
                    elem?.lastName ||
                    elem?.name?.split(' ').splice(0, 2).slice(0, 1).join(' ')
                  }`}
                </p>
              </div>
            </li>
          </div>
        ))}
      </ul>
    </Panel>
  )
}
