import flagRus from './img/flag-rus.svg'
import flagUae from './img/flag-uae.svg'
import flagIndonesia from './img/flag-indonesia.svg'

export const pledge = [
  {
    id: 1,
    active: false,
    title: 'Поручительство основателя',
  },
  {
    id: 2,
    active: false,
    title: 'Поручительство головной компании',
  },
  {
    id: 3,
    active: false,
    title: 'Поручительство партнеров',
  },
  {
    id: 4,
    active: false,
    title: 'Залог оборудования',
  },
  {
    id: 5,
    active: false,
    title: 'Залог товара',
  },
  {
    id: 6,
    active: false,
    title: 'Залог недвижимости',
  },
  {
    id: 7,
    active: false,
    title: 'Банковские гарантии',
  },
  {
    id: 8,
    active: false,
    title: 'Неустойка',
  },
]

export const countries = [
  {
    title: 'Россия',
    img: flagRus,
  },
  {
    title: 'Индонезия',
    img: flagIndonesia,
  },
  {
    title: 'ОАЭ',
    img: flagUae,
  },
]
