import clsx from 'clsx'
import React, { ReactNode } from 'react'
import s from './ProjectPanel.module.css'

interface panelType {
  primary: string
  success: string
  warning: string
}

const themes: panelType = {
  primary: s.primary,
  success: s.success,
  warning: s.warning,
}

interface PanelProps {
  children: ReactNode
  className?: string
  theme?: keyof typeof themes
}

export const ProjectPanel: React.FC<PanelProps> = ({
  children,
  className,
  theme,
}) => (
  <div className={clsx(s.panel, theme ? themes[theme] : null, className)}>
    {children}
  </div>
)
