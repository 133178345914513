import React, { ChangeEvent, FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { openPromotion } from '../../redux/slices/pageSlice'
import {
  addDynamicStoryName,
  showDeleteDynamicStoryModal,
  hideDeleteDynamicStoryModal,
} from '../../redux/slices/tableSlice'
import { resetPromotionDynamicObj } from '../../redux/slices/promotionDynamicSlice'
import {
  Button,
  Container,
  DropdownButton,
  ErrorMessage,
  Field,
  Modal,
  NavProjects,
  Panel,
  PromotionsDropdown,
  PromotionsEdit,
  Title,
  ToggleButton,
} from '../../ui'
import { PROMOTION_DYNAMICS } from '../../apollo/query/promotion/dynamics'
import {
  PROMOTION_DYNAMIC_CHANGE_STATUS,
  PROMOTION_DYNAMIC_DELETE,
} from '../../apollo/mutation/promotion/promotionUpdate'
import addPromotion from './img/icon-addPromotion.svg'
import logo from './img/icon-logo-white.svg'
import s from '../PromotionStories/PromotionStories.module.css'
import sd from './PromotionDynamic.module.css'

interface PromotionDynamicType {
  title: string
  dataNavTitle: string[]
  setNavValuePromotions: (num: number) => void
}

const settings = ['Редактировать', 'В черновики', 'В архив', 'Удалить']

export const PromotionDynamic: FC<PromotionDynamicType> = ({
  title,
  dataNavTitle,
  setNavValuePromotions,
}) => {
  const [value, setValue] = useState<any>('')
  const [navValue, setNavValue] = useState<number>(0)
  const [shortStories, setShortStories] = useState<boolean>(true)
  const [editActive, setEditActive] = useState<number | null>(null)
  const [dynamicStoryUUID, setDynamicStoryUUID] = useState<string>('')

  const dispatch = useAppDispatch()
  const { dynamicStoryName, deletePromotionDynamicModal } = useAppSelector(
    state => state.tableSlice
  )
  const createPromotionStories = () => {
    dispatch(resetPromotionDynamicObj())
    dispatch(openPromotion())
    setNavValuePromotions(1)
  }
  const navigate = useNavigate()

  const {
    data: dynamic,
    loading: loadingDynamic,
    error: errorDynamic,
  } = useQuery(
    PROMOTION_DYNAMICS,
    navValue === 0
      ? {}
      : {
        variables: {
          filter: {
            status:
                navValue === 1
                  ? 'active'
                  : navValue === 2
                    ? 'draft'
                    : 'archive',
          },
        },
      }
  )

  const dynamicFiltered = dynamic?.promotionDynamics === null
    ? []
    : dynamic?.promotionDynamics?.filter(
      (item: any) => item?.header?.toLowerCase().includes(value.toLowerCase().trim())
            || item?.text?.toLowerCase().includes(value.toLowerCase().trim())
    )

  function editActiveHandle(header: string, index: number, uuid: string) {
    dispatch(addDynamicStoryName(header))
    setDynamicStoryUUID(uuid)
    if (index === editActive) {
      setEditActive(null)
    } else {
      setEditActive(index)
    }
  }

  const [
    changeDynamicStatus,
    {
      data: changeStatus,
      loading: loadingChangeStatus,
      error: errorChangeStatus,
    },
  ] = useMutation(PROMOTION_DYNAMIC_CHANGE_STATUS, {
    refetchQueries: [
      {
        query: PROMOTION_DYNAMICS,
      },
      {
        query: PROMOTION_DYNAMICS,
        variables: {
          filter: {
            status: 'active',
          },
        },
      },
      {
        query: PROMOTION_DYNAMICS,
        variables: {
          filter: {
            status: 'draft',
          },
        },
      },
      {
        query: PROMOTION_DYNAMICS,
        variables: {
          filter: {
            status: 'archive',
          },
        },
      },
    ],
  })

  const [
    deleteDynamic,
    {
      data: deletePromotionDynamic,
      loading: loadingPromotionDynamic,
      error: errorPromotionDynamic,
    },
  ] = useMutation(PROMOTION_DYNAMIC_DELETE, {
    refetchQueries: [
      {
        query: PROMOTION_DYNAMICS,
      },
      {
        query: PROMOTION_DYNAMICS,
        variables: {
          filter: {
            status: 'active',
          },
        },
      },
      {
        query: PROMOTION_DYNAMICS,
        variables: {
          filter: {
            status: 'draft',
          },
        },
      },
      {
        query: PROMOTION_DYNAMICS,
        variables: {
          filter: {
            status: 'archive',
          },
        },
      },
    ],
  })

  const changePromotionDynamicStatus = (value: string, uuid: string) => {
    switch (value) {
    case 'В черновики':
      changeDynamicStatus({
        variables: {
          uuid,
          data: {
            status: 'draft',
          },
        },
      })
      setDynamicStoryUUID('')
      break
    case 'В архив':
      changeDynamicStatus({
        variables: {
          uuid,
          data: {
            status: 'archive',
          },
        },
      })
      setDynamicStoryUUID('')
      break
    case 'Удалить':
      dispatch(showDeleteDynamicStoryModal())
      break
    default:
      dispatch(resetPromotionDynamicObj())
      navigate(`/promotion/edit/dynamic/${uuid}`)
    }
  }

  return (
    <Container>
      <div className={s.promotion_panel}>
        <Title className={s.title} title={title} />
        <div className={s.promotion_search}>
          <Field
            icon
            placeholder="Поиск"
            placeholderColor="grey"
            view="search"
            type="text"
            value={value}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
          />
          <Button
            className={s.promotion_btn}
            src={addPromotion}
            theme="icon"
            icon
            onClick={createPromotionStories}
          >
            Добавить акцию
          </Button>
        </div>
      </div>
      <NavProjects
        className={s.promotion_type}
        setNavValue={setNavValue}
        navValue={navValue}
        dataNavTitle={dataNavTitle}
      />
      {errorDynamic ? (
        <ErrorMessage>
          Ошибка:
          {' '}
          {errorDynamic?.message}
        </ErrorMessage>
      ) : (
        <div className={s.promotion_wrapper}>
          {(shortStories ? dynamicFiltered?.slice(0, 8) : dynamicFiltered)?.map(
            (dynamic: any, index: number) => (
              <Panel
                key={dynamic.uuid}
                className={s.promotion_item_dynamic}
                style={{ background: `${dynamic.color}` }}
              >
                <div
                  className={s.dynamic_info}
                  style={dynamic.color ? { color: '#fff' } : { color: '#000' }}
                >
                  <p className={s.dynamic_header}>{dynamic.header}</p>
                  <p className={s.dynamic_text}>{dynamic.text}</p>
                </div>
                <div className={s.dynamic_logo}>
                  <img
                    src={dynamic?.image || logo}
                    alt={dynamic?.image ? 'logo' : ''}
                    width="80"
                    height="80"
                  />
                </div>
                <PromotionsEdit
                  className={s.dynamic_edit}
                  onClick={() => editActiveHandle(dynamic?.header, index, dynamic?.uuid)}
                  active={editActive === index}
                >
                  <PromotionsDropdown className={sd.promotion_edit_dynamic}>
                    {settings.map((value: string, index: number) => (
                      <DropdownButton
                        key={index}
                        onClick={() => changePromotionDynamicStatus(value, dynamicStoryUUID)}
                      >
                        {value}
                      </DropdownButton>
                    ))}
                  </PromotionsDropdown>
                </PromotionsEdit>
              </Panel>
            )
          )}
        </div>
      )}

      <ToggleButton
        className={s.promotion_stories_btn}
        rotate={shortStories}
        onClick={() => setShortStories(!shortStories)}
      />
      {deletePromotionDynamicModal && (
        <Modal
          storyName={dynamicStoryName}
          background="rgba(250 250 250 / 0.8)"
          question="Вы действительно хотите удалить динамическую акцию"
        >
          <>
            <Button
              className={s.promotion_stories_remove}
              theme="dark"
              onClick={() => {
                deleteDynamic({
                  variables: {
                    uuid: `${dynamicStoryUUID}`,
                  },
                })
                dispatch(hideDeleteDynamicStoryModal())
                setDynamicStoryUUID('')
              }}
            >
              Удалить
            </Button>
            <Button
              className={s.promotion_stories_remove}
              theme="outline"
              onClick={() => {
                dispatch(hideDeleteDynamicStoryModal())
                setDynamicStoryUUID('')
              }}
            >
              Отмена
            </Button>
          </>
        </Modal>
      )}
    </Container>
  )
}
