import { ReactNode } from 'react'
import { Portal } from '../Portal/Portal'
import { Panel } from '../Panel'
import s from './Modal.module.css'

type Props = {
  children: ReactNode
  background?: string
  contractNumber?: string
  projectName?: string
  storyName?: string
  question?: string
}

export const Modal = ({
  children,
  background,
  contractNumber,
  projectName,
  storyName,
  question,
}: Props) => (
  <Portal>
    <div className={s.modal} style={{ background }}>
      <Panel className={s.modal__panel} padding>
        <p className={s.modal__question}>
          {question}
          &nbsp;
          <span>
            {contractNumber || projectName || storyName}
            ?
          </span>
        </p>
        <div className={s.modal__btns}>{children}</div>
      </Panel>
    </div>
  </Portal>
)
