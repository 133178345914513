import React from 'react'
import type { ReactNode } from 'react'
import clsx from 'clsx'
import s from './Label.module.css'

interface LabelType {
  className?: string
  children: ReactNode
  title?: string
  onClick?: () => void
}

export const Label: React.FC<LabelType> = ({
  children,
  title,
  className,
  onClick,
}) => (
  <div className={clsx(s.label, className)} onClick={onClick}>
    {title}
    {children}
  </div>
)
