import React, { ChangeEvent, useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Title,
  Container,
  Panel,
  Label,
  Field,
  Textarea,
  Button,
  Loader,
  AddButton,
} from '../../ui'
import { StageItem } from '../StageItem/StageItem'
import { UploadEditPages } from '../UploadEditPages'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { changeIsCreateBroker, changeNav } from '../../redux/slices/editSiteSlice'
import { GET_ALL_STAGES, GET_ALL_CONDITIONS, GET_MEDIAENTERPRNEUR } from '../../apollo/query/editSite/businessPage'
import {
  CREATE_STAGE,
  DELETE_STAGE,
  UPDATE_STAGE,
  CREATE_CONDITION,
  DELETE_CONDITION,
  UPDATE_CONDITION,
  UPDATE_MEDIAENTERPRNEUR,
} from '../../apollo/mutation/editSite/businessPage'
import preview from './img/icon-eye.png'
import iconSent from './img/icon-sent.svg'
import fullFill from './img/icon-fillfull.svg'
import empty from './img/icon-empty.svg'
import s from './EditBusinessPage.module.css'
import { Portal } from '../../ui/Portal/Portal'
import { BusinessmanPage } from '../preview/businessmanPage/BusinessmanPage'

export const EditBusinessPage = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const [senting, setSenting] = useState(false)
  const [form, setForm] = useState<any>({
    coverTitle: '',
    projectsTitle: '',
    formTitle: '',
    formSubtitle: '',
    buttonText: '',
    buttonLink: '',
  })
  const [tempArr, setTempArr]: any = useState([])
  const [deletedStages, setDeletedStages]: any = useState([])
  const [createdStages, setCreatedStages]: any = useState([])
  const [updatedStages, setUpdatedStages]: any = useState([])
  const [tempArrConditions, setTempArrConditions]: any = useState([])
  const [deletedConditions, setDeletedConditions]: any = useState([])
  const [createdCondititons, setCreatedConditions]: any = useState([])
  const [updatedCondititons, setUpdatedCondititons]: any = useState([])
  const [currentItem, setCurrentItem]:any = useState(null)
  const [currentItemCondition, setCurrentItemCondition]:any = useState(null)
  const { data: stageData, loading: stageLoading, error: stageError } = useQuery(GET_ALL_STAGES)
  const { data: conditionData, loading: conditionLoading, error: conditionError } = useQuery(GET_ALL_CONDITIONS)
  const { data: businessPageData, loading: businessPageLoading, error: businessPageError } = useQuery(GET_MEDIAENTERPRNEUR)
  const [
    createStage,
    {
      data: createStageData,
      loading: loadingCreateStage,
      error: errorCreateStage,
    },
  ] = useMutation(
    CREATE_STAGE,
    {
      refetchQueries: [
        {
          query: GET_ALL_STAGES,
          variables: {
            filter: {
            },
          },
        },
      ],
    },
  )
  const [
    deleteStage,
    {
      data: deleteStageData,
      loading: loadintDeleteStage,
      error: errorDeleteStage,
    },
  ] = useMutation(
    DELETE_STAGE,
    {
      refetchQueries: [
        {
          query: GET_ALL_STAGES,
          variables: {
            filter: {
            },
          },
        },
      ],
    },
  )
  const [
    updateStages,
    {
      data: updateStageData,
      loading: loadingUpdateStage,
      error: errorUpdateStage,
    },
  ] = useMutation(
    UPDATE_STAGE,
    {
      refetchQueries: [
        {
          query: GET_ALL_STAGES,
          variables: {
            filter: {
            },
          },
        },
      ],
    },
  )
  const [
    updateBusinessPage,
    {
      data: businessPageDataUpdate,
      loading: businessPageLoadingUpdate,
      error: businessPageErrorUpdate,
    },
  ] = useMutation(
    UPDATE_MEDIAENTERPRNEUR,
    {
      refetchQueries: [
        {
          query: GET_MEDIAENTERPRNEUR,
          variables: {
            filter: {
            },
          },
        },
      ],
    },
  )
  const [
    updateConditions,
    {
      data: updatedConditionsData,
      loading: loadingUpdateConditions,
      error: errorUpdateCondititons,
    },
  ] = useMutation(
    UPDATE_CONDITION,
    {
      refetchQueries: [
        {
          query: GET_ALL_CONDITIONS,
          variables: {
            filter: {
            },
          },
        },
      ],
    },
  )
  const [previewToggler, setPreviewToggler] = useState(false)

  useEffect(() => {
    if (!stageData) return
    const temp = stageData?.mediaStages.map((el: any, index: number) => ({ ...el, order: index + 1, uniqId: new Date().toISOString().concat(String(index)) }))
    setTempArr([...temp])
    if (temp?.length > 0) {
      setTempArr([...temp])
    }

    if (!conditionData) return
    const tempConditions = conditionData?.mediaConditions.map((el:any, index: number) => ({ ...el, order: index + 1, uniqId: new Date().toISOString().concat(String(index)) }))
    setTempArrConditions([...tempConditions])
    if (tempConditions?.length > 0) {
      setTempArrConditions([...tempConditions])
    }

    if (!businessPageData) return
    setForm({
      ...form,
      coverTitle: businessPageData?.mediaEntrepreneur.coverTitle,
      projectsTitle: businessPageData?.mediaEntrepreneur.projectsTitle,
      formTitle: businessPageData?.mediaEntrepreneur.formTitle,
      formSubtitle: businessPageData?.mediaEntrepreneur.formSubtitle,
      buttonText: businessPageData?.mediaEntrepreneur.buttonText,
      buttonLink: businessPageData?.mediaEntrepreneur.buttonLink,
    })
  }, [stageData, conditionData, businessPageData])

  useEffect(() => {
    setCreatedStages(tempArr.filter((el:any) => el.uuid === undefined).map((el:any) => ({
      title: el.title,
      description: el.description,
      orderNum: el.order,
    })))
    setUpdatedStages(tempArr.filter((el:any) => el.uuid !== undefined).map((el:any) => ({
      uuid: el.uuid,
      title: el.title,
      description: el.description,
      orderNum: el.order,
    })))
    setUpdatedCondititons(tempArrConditions.filter((el:any) => el.uuid !== undefined).map((el:any) => ({
      uuid: el.uuid,
      title: el.title,
      description: el.description,
      orderNum: el.order,
    })))
  }, [tempArr, tempArrConditions])

  const changeCoverText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setForm({ ...form, coverTitle: event.target.value })
  }
  const changeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, formTitle: (event.target.value) })
  }
  const changesubtitle = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setForm({ ...form, formSubtitle: event.target.value })
  }
  const changeButtonText = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, buttonText: event.target.value })
  }
  const changeButtonLink = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, buttonLink: event.target.value })
  }
  const changeProjectTitle = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setForm({ ...form, projectsTitle: event.target.value })
  }

  const chandgeStageTitle = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTempArr(tempArr.map((el:any) => {
      if (el.uniqId === e.target.id) {
        return { ...el, title: e.target.value }
      }
      return el
    }))
  }

  const chandgeStageDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTempArr(tempArr.map((el:any) => {
      if (el.uniqId === e.target.id) {
        return { ...el, description: e.target.value }
      }
      return el
    }))
  }

  const changeConditionTitle = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTempArrConditions(tempArrConditions.map((el:any) => {
      if (el.uniqId === e.target.id) {
        return { ...el, title: e.target.value }
      }
      return el
    }))
  }

  const changeConditionDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTempArrConditions(tempArrConditions.map((el:any) => {
      if (el.uniqId === e.target.id) {
        return { ...el, description: e.target.value }
      }
      return el
    }))
  }

  // добавление этапа
  const addTempArr = () => {
    setTempArr([
      ...tempArr,
      {
        id: new Date().toISOString(),
        description: '',
        title: '',
        order: tempArr.length + 1,
        uniqId: new Date().toISOString(),
      },
    ])
  }

  const deleteTempArr = (e: any) => {
    setTempArr([...tempArr.filter((el: any) => el.uniqId !== e.target.id).map((el: any, index: number) => ({ ...el, order: index + 1 }))])
    // оставляю только тех у кого есть uuid, для мутации удаления
    setDeletedStages([...deletedStages, ...tempArr.filter((el: any) => el.uniqId === e.target.id).filter((el:any) => el.uuid !== undefined)])
  }

  const closePage = () => {
    dispatch(changeIsCreateBroker(false))
    dispatch(changeNav(1))
    setForm({
      avatar: '',
      firstname: '',
      lastname: '',
      sourse: '',
      link: '',
      comment: '',
      datemarker: '',
      review: '',
    })
  }

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>, item: any) => {
    setCurrentItem(item)
  }
  const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    // console.log('e', e)
  }
  const dragEndHandler = (e: React.DragEvent<HTMLDivElement>) => {
    // console.log('e', e)
    // console.log('target', e.target.parentElement)
    // const parentElement = e.currentTarget
    // const childElement = parentElement.querySelector('#child-element')
    // console.log('childElement', childElement)
    // e.target.style.background = 'white'
    // childElement.style.background = 'white'
  }
  const dragOverHandler = (e: any) => {
    e.preventDefault()
  }
  const dropHandler = (e: React.DragEvent<HTMLDivElement>, item: any) => {
    e.preventDefault()
    setTempArr(tempArr.map((el: any) => {
      if (el.uniqId === currentItem.uniqId) {
        return { ...el, order: item.order }
      }
      if (el.uniqId === item.uniqId) {
        return { ...el, order: currentItem.order }
      }
      return el
    }))
  }

  const dragStartHandlerCondition = (e: React.DragEvent<HTMLDivElement>, item: any) => setCurrentItemCondition(item)
  const dropHandlerCondition = (e: React.DragEvent<HTMLDivElement>, item: any) => {
    e.preventDefault()
    setTempArrConditions(tempArrConditions.map((el: any) => {
      if (el.uniqId === currentItemCondition.uniqId) {
        return { ...el, order: item.order }
      }
      if (el.uniqId === item.uniqId) {
        return { ...el, order: currentItemCondition.order }
      }
      return el
    }))
  }

  const sortItems = (a: any, b: any) => {
    if (a.order > b.order) {
      return 1
    }
    return -1
  }

  const handleSaveSent = () => {
    setSenting(true)
    createdStages.forEach((element: any) => {
      createStage({
        variables: {
          data: {
            title: element.title,
            description: element.description,
            orderNum: element.orderNum,
          },
        },
      })
    })
    deletedStages.forEach((element: any) => {
      deleteStage({
        variables: {
          uuid: element.uuid,
        },
      })
    })
    updatedStages.forEach((element: any) => {
      updateStages({
        variables: {
          uuid: element.uuid,
          data: {
            title: element.title,
            description: element.description,
            orderNum: element.orderNum,
          },
        },
      })
    })
    updatedCondititons.forEach((element: any) => {
      updateConditions({
        variables: {
          uuid: element.uuid,
          data: {
            title: element.title,
            description: element.description,
            orderNum: element.orderNum,
          },
        },
      })
    })
    updateBusinessPage({
      variables: {
        data: {
          ...form,
        },
      },
    })
    if (!createStage || errorCreateStage === undefined) {
      setTimeout(() => {
        closePage()
      }, 2000)
    } else {
      setTimeout(() => {
        closePage()
      })
    }
  }

  const closeHandlerPreview = (e: any) => {
    setPreviewToggler(false)
  }

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: '7px' }}>Сохранено</span>
    </>
  )
  return (
    <Container className={s.container}>
      <Title title="Страница Бизнесу" />
      <div className={s.wrapper}>
        <div className={s.first_block}>
          <Panel className={s.info__panel} padding>
            <Title className={s.panel__title} title="Обложка" />
            <Label title="Текст">
              <Textarea
                className={s.description}
                value={form.coverTitle}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => changeCoverText?.(event)}
                rows={5}
              // maxLength={350}
              />
            </Label>
          </Panel>
        </div>
        <div className={s.first_block}>
          <Panel className={s.info__panel} padding>
            <Title className={s.panel__title} title="Этапы работы" />
            {tempArr.sort(sortItems).map((item: any) => (
              <StageItem
                key={item.id}
                id={item.uniqId}
                item={item}
                dragStartHandler={dragStartHandler}
                dragLeaveHandler={dragLeaveHandler}
                dragEndHandler={dragEndHandler}
                dragOverHandler={dragOverHandler}
                dropHandler={dropHandler}
                deleteItem={deleteTempArr}
                chandgeStageTitle={chandgeStageTitle}
                chandgeStageDescription={chandgeStageDescription}
                remove
              />
            ))}
            <AddButton className={s.addButton} onClick={() => addTempArr()}>
              Добавить этап
            </AddButton>
          </Panel>
        </div>
        <div className={s.first_block}>
          <Panel className={s.info__panel} padding>
            <Title className={s.panel__title} title="Анкета" />
            <Label title="Заголовок">
              <Field
                className={s.broker_name}
                value={form.formTitle}
                view="default"
                onChange={(event: ChangeEvent<HTMLInputElement>) => changeTitle?.(event)}
                rows={5}
                maxLength={100}
              />
            </Label>
            <Label title="Подзаголовок">
              <Textarea
                className={s.description}
                value={form.formSubtitle}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => changesubtitle?.(event)}
                rows={5}
              // maxLength={350}
              />
            </Label>
            <div className={s.line}>
              <Label title="Текст кнопки">
                <Field
                  className={s.youTube_link}
                  // placeholder="59 симв. макс. включая пробелы"
                  value={form.buttonText}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => changeButtonText?.(event)}
                  rows={5}
                  maxLength={100}
                />
              </Label>
              <Label title="Ссылка кнопки">
                <Field
                  className={s.link}
                  // placeholder="59 симв. макс. включая пробелы"
                  value={form.buttonLink}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => changeButtonLink?.(event)}
                  rows={5}
                  maxLength={100}
                />
              </Label>
            </div>
          </Panel>
        </div>
        <div className={s.first_block}>
          <Panel className={s.info__panel} padding>
            <Title className={s.panel__title} title="Проекты" />
            <Label title="Текст">
              <Textarea
                className={s.description__project}
                value={form.projectsTitle}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => changeProjectTitle?.(event)}
                rows={5}
              // maxLength={350}
              />
            </Label>
            <Title className={s.panel__title} title="Условия" />
            {tempArrConditions?.sort(sortItems).map((item: any) => (
              <StageItem
                key={item.id}
                id={item.uniqId}
                item={item}
                dragStartHandler={dragStartHandlerCondition}
                dragLeaveHandler={dragLeaveHandler}
                dragEndHandler={dragEndHandler}
                dragOverHandler={dragOverHandler}
                dropHandler={dropHandlerCondition}
                chandgeStageTitle={changeConditionTitle}
                chandgeStageDescription={changeConditionDescription}
              />
            ))}
            {/* <AddButton className={s.addButton} onClick={() => console.log()}>
                Добавить этап
          </AddButton> */}
          </Panel>
        </div>
        <Panel className={s.btns__panel}>
          <Button
            onClick={() => setPreviewToggler(true)}
            // disabled
            className={s.btns__panel_preview}
            icon
            src={preview}
          >
            Предпросмотр
          </Button>
          <Button
            onClick={handleSaveSent}
            className={s.btns__panel_save}
            // className={Math.ceil(sumField) === 100 ? s.btns__panel_save : s.btns__panel_save_disabled}
            theme="dark"
          // disabled={Math.ceil(sumField) < 100}
          // disabled={!broker?.firstname}
          >
            {!senting ? (
              'Сохранить и опубликовать'
            ) : stageLoading || loadingUpdateStage ? (
              <Loader />
            ) : !stageLoading && stageError && !loadingUpdateStage ? (
              'Ошибка'
            ) : (
              sentSaved
            )}
          </Button>
        </Panel>
      </div>
      {previewToggler && (
        <Portal>
          <div className={s.preview} onClick={closeHandlerPreview}>
            <BusinessmanPage startList={tempArr} conditionList={tempArrConditions} form={form} setPreviewToggler={setPreviewToggler} />
          </div>
        </Portal>
      )}
    </Container>
  )
}
