import { gql } from '@apollo/client'

export const CONTRACT_UPDATE = gql`
  mutation UpdateContract($uuid: String!, $data: ContractDataUpdate!) {
    contractUpdate(uuid: $uuid, data: $data) {
      id
      uuid
      number
      signedOn
      status
      client {
        firstName
        middleName
        lastName
      }
      project {
        uuid
        name
      }
    }
  }
`

export const ACT_UPDATE = gql`
  mutation updateAct($uuid: String!, $data: ActDataUpdate!) {
    admActUpdate(uuid: $uuid, data: $data) {
      id
      uuid
      number
      client {
        uuid
        firstName
        lastName
        middleName
      }
      project {
        name
        uuid
      }
      sum
      file
      status
    }
  }
`
export const REPORT_UPDATE = gql`
  mutation updateReport($uuid: String!, $data: ReportDataUpdate!) {
    admReportUpdate(uuid: $uuid, data: $data) {
      id
      uuid
      name
      project {
        name
        uuid
      }
      file
      status
    }
  }
`
