import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { useAppDispatch } from '../../redux/hook'
import { changeNav } from '../../redux/slices/editSiteSlice'
import { DELETE_OUTLET, DELETE_TEXTREVIEW } from '../../apollo/mutation/editSite/mediaPageUpdate'
import { GET_ALL_OUTLETS, GET_ALL_COMMENTS } from '../../apollo/query/editSite/mediaPage'
import {
  Title,
  Container,
  Panel,
  Label,
  Field,
  Textarea,
  Button,
  Loader,
  AddButton,
  Edit,
  Modal,
} from '../../ui'
import s from './MediaItem.module.css'

interface VideoFrameProps {
	item?: any
  index?: number
  avatar?: boolean
}

export const MediaItem: React.FC<VideoFrameProps> = ({ item, index, avatar }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isModal, setIsModal] = useState(false)
  const [firstRandom] = useState(Math.floor(Math.random() * 3))
  const [secondRandom] = useState(Math.floor(Math.random() * 3))
  const colors = ['#e66465', '#9198e5', '#c2a2e8']
  const colors2 = ['#d07b97', '#8d92e3', '#85e1ce']
  const [firstColor] = useState(colors[firstRandom])
  const [secondColor] = useState(colors2[secondRandom])
  const [active, setActive] = useState(false)

  const [deleteOutlet, { data: deleteOutletData }] = useMutation(DELETE_OUTLET, {
    refetchQueries: [
      {
        query: GET_ALL_OUTLETS,
      },
    ],
  })

  const [deleteTextReview, { data: deleteTextReviewData }] = useMutation(DELETE_TEXTREVIEW, {
    refetchQueries: [
      {
        query: GET_ALL_COMMENTS,
      },
    ],
  })

  const removeHandler = () => setIsModal(!isModal)

  return (
    <div>
      <div className={s.grid_item_wrapper} key={item.uuid.concat(index)}>
        <div className={s.grid_item} key={item.uuid} id={item.uuid}>
          <Edit
            className={s.grid_item_edit}
            active={active}
            onClick={() => setActive(!active)}
            onEditClick={() => {
              dispatch(changeNav(!avatar ? 11 : 12))
              navigate(`/editsite/edit/${item.uuid}`)
            }}
            removeDoc={removeHandler}
          />
          <div className={s.grid_item_published}>
            {!avatar && <img className={s.img} src={item.picture} alt="pic" />}
            {avatar && (
              <div className={s.img_wrapper}>
                {item.avatar
                  ? <img className={s.img} src={item.avatar} alt="avatar" />
                  : (
                    <div
                      className={s.img_empty}
                      style={{
                        backgroundImage: `linear-gradient(${firstColor}, ${secondColor})`,
                      }}
                    >
                      {item?.firstname[0]}
                      {item?.lastname[0]}
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
        {avatar && (
          <span className={s.item_capture}>
            {item?.firstname}
          &nbsp;
            {item?.lastname}
          </span>
        )}
        {isModal && (
          <Modal
            contractNumber={item.title}
            background="rgb(250 250 250 / 0.3)"
            question={!avatar ? 'Вы действительно хотите удалить статью ' : `Вы действительно хотите удалить отзыв ${item.firstname} ${item.lastname}`}
          >
            {!avatar && (
              <Button
                className={(s.table__btn)}
                theme="dark"
                onClick={(() => {
                  deleteOutlet({
                    variables: {
                      uuid: item.uuid,
                    },
                  })
                  setIsModal(false)
                })}
              >
                Удалить
              </Button>
            )}
            {avatar && (
              <Button
                className={(s.table__btn)}
                theme="dark"
                onClick={(() => {
                  deleteTextReview({
                    variables: {
                      uuid: item.uuid,
                    },
                  })
                  setIsModal(false)
                })}
              >
                Удалить
              </Button>
            )}
            <Button
              className={s.table__btn}
              theme="outline"
              onClick={() => {
                setIsModal(false)
              }}
            >
              Отмена
            </Button>
          </Modal>
        )}
      </div>
      <span className={s.grid_item_caption}>{item.title}</span>
    </div>
  )
}
