import { gql } from '@apollo/client'

export const CREATE_DOC = gql`
  mutation CreateContract($data: ContractData!) {
    contractCreate(data: $data) {
      id
      uuid
      number
      signedOn
      status
      client {
        firstName
        middleName
        lastName
      }
      project {
        uuid
        name
      }
    }
  }
`

export const CREATE_ACT = gql`
  mutation CreateAct($data: ActData!) {
    admActCreate(data: $data) {
      number
      client {
        id
      }
      id
      uuid
      project {
        uuid
        name
      }
      sum
      date
      file
      status
    }
  }
`
export const CREATE_REPORT = gql`
  mutation CreateReport($data: ReportData!) {
    admReportCreate(data: $data) {
      id
      uuid
      project {
        uuid
        name
      }
      file
      status
      name
    }
  }
`
