import { useState, FC } from 'react'
import { TitleSection } from './titleSection/TitleSection'
import { StartListSection } from './startListSection/StartListSection'
import { FirstStepSection } from './firstStepSection/FirstStepSection'
import { DemandListSection } from './demandListSection/DemandListSection'
import s from './BusinessmanPage.module.css'
import close from '../assets/close_icon.svg'

interface BusinessmanPage {
  setPreviewToggler?: (e: boolean) => void
  startList?: any
  conditionList?: any
  form?: any
}

export const BusinessmanPage: FC<BusinessmanPage> = ({
  setPreviewToggler,
  startList,
  conditionList,
  form,
}) => {
  const [mediaEntrepreneur, setMediaEntrepreneur] = useState({})
  const clientWidth = 1026

  return (
    <div className={s.wrapper} onClick={e => e.stopPropagation()}>
      <button className={s.close} type="button" onClick={() => setPreviewToggler?.(false)}>
        <img src={close} alt="" />
      </button>
      <TitleSection clientWidth={clientWidth} />
      <StartListSection startList={startList} />
      <FirstStepSection mediaEntrepreneur={form} />
      <DemandListSection
        clientWidth={clientWidth}
        mediaEntrepreneur={form}
        conditionList={conditionList}
      />
    </div>
  )
}
