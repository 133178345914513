import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { addClientFields, addInfoField } from '../../redux/slices/clientSlice'
import {
  DataPicker, Field, Label, Panel, ProgressBar, Title
} from '../../ui'
import fullFill from './img/icon-fillfull.svg'
import empty from './img/icon-empty.svg'
import s from './ClientInfo.module.css'

interface ClientInfoProps {
	firstName: string
	setFirstName: ((i: string) => void) | any
	lastName: string
	setLastName: ((i: string) => void) | any
	middleName: string
	setMiddleName: ((i: string) => void) | any
	birthDay: Date | null
	setBirthDay: ((i: string) => void) | any
	phone: string
	setPhone: ((i: string) => void) | any
	email: string
	setEmail: ((i: string) => void) | any
	iis: string
	setIis: ((i: string) => void) | any
	inn: string
	setInn: ((i: string) => void) | any
}

export const ClientInfo = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  middleName,
  setMiddleName,
  birthDay,
  setBirthDay,
  phone,
  setPhone,
  email,
  setEmail,
  iis,
  setIis,
  inn,
  setInn,
}: ClientInfoProps) => {
  const dispatch = useAppDispatch()
  const { clientInfo } = useAppSelector(state => state.clientSlice)
  const [sumField, setSumField] = useState(0)

  const { infoOrg, photo, ...info } = clientInfo

  const arr = Object.values(info)
    .map((e: any) =>
      typeof e === 'object' && e !== null ? Object.values(e) : e
    )
    .flat()

  useEffect(() => {
    setSumField(arr.map(e => (e ? 12.4 : 0)).reduce((a: any, b: any) => a + b))
  }, [clientInfo])

  return (
    <div>
      <Panel padding>
        <Title title="Клиент" className={s.title} />
        <div className={s.blockName}>
          <div>
            <span className={s.star}>Фамилия</span>
            <Label>
              <Field
                type="text"
                view="default"
                value={lastName}
                maxLength={150}
                onChange={(e: any) => {
                  setLastName(e.target.value)
                  dispatch(
                    addClientFields({
                      fieldName: 'lastName',
                      item: e.target.value,
                    })
                  )
                }}
              />
            </Label>
          </div>
          <div>
            <span className={s.star}>Имя</span>
            <Label>
              <Field
                type="text"
                view="default"
                value={firstName}
                maxLength={150}
                onChange={(e: any) => {
                  setFirstName(e.target.value)
                  dispatch(
                    addClientFields({
                      fieldName: 'firstName',
                      item: e.target.value,
                    })
                  )
                }}
              />
            </Label>
          </div>
        </div>
        <div>
          <span className={s.star}>Отчество</span>
          <Label>
            <Field
              type="text"
              view="default"
              value={middleName}
              maxLength={150}
              onChange={(e: any) => {
                setMiddleName(e.target.value)
                dispatch(
                  addClientFields({
                    fieldName: 'middleName',
                    item: e.target.value,
                  })
                )
              }}
            />
          </Label>
        </div>
      </Panel>
      <Panel padding className={s.datas}>
        <Title title="Данные" className={s.title} />
        <Label title="День Рождения" className={s.birthDay}>
          <DataPicker
            className={s.dataPicker}
            startDate={birthDay}
            onChange={date => {
              setBirthDay(date)
              if (date) {
                dispatch(
                  addClientFields({
                    fieldName: 'birthDay',
                    item: date.toISOString(),
                  })
                )
              }
            }}
          />
        </Label>
        <div className={clsx(s.blockData, s.margin)}>
          <div>
            <span className={s.star}>Телефон</span>
            <Label className={s.labelPhone}>
              <Field
                view="default"
                placeholder="79999999999"
                value={phone}
                maxLength={11}
                onChange={(e: any) => {
                  setPhone(e.target.value.replace(/\D/g, ''))
                  dispatch(
                    addClientFields({
                      fieldName: 'phone',
                      item: e.target.value,
                    })
                  )
                }}
              />
            </Label>
          </div>
          <Label title="Email">
            <Field
              view="default"
              // type="email"
              value={email}
              maxLength={30}
              onChange={(e: any) => {
                const emailRegex =
									/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                setEmail(e.target.value.replace(emailRegex, ''))
                dispatch(
                  addInfoField({
                    fieldName: 'email',
                    item: e.target.value.replace(emailRegex, ''),
                  })
                )
              }}
            />
          </Label>
        </div>
        <div className={s.blockData}>
          <Label title="ИИС">
            <Field
              view="default"
              value={iis}
              maxLength={20}
              onChange={(e: any) => {
                setIis(e.target.value.replace(/\D/g, ''))
                dispatch(
                  addInfoField({
                    fieldName: 'iis',
                    item: e.target.value,
                  })
                )
              }}
            />
          </Label>
          <Label title="ИНН">
            <Field
              view="default"
              value={inn}
              maxLength={12}
              onChange={(e: any) => {
                setInn(e.target.value.replace(/\D/g, ''))
                dispatch(
                  addInfoField({
                    fieldName: 'inn',
                    item: e.target.value,
                  })
                )
              }}
            />
          </Label>
        </div>
      </Panel>

      <Panel className={s.panel__progress} padding>
        <ProgressBar className={s.project_create} value={Math.ceil(sumField)} />
        <div className={s.project_indicator}>
          <img
            src={firstName && lastName && middleName ? fullFill : empty}
            alt="empty"
          />
          <p>Клиент</p>
        </div>
        <div className={s.project_indicator}>
          <img
            src={birthDay && phone && email && iis && inn ? fullFill : empty}
            alt="empty"
          />
          <p>Данные</p>
        </div>
      </Panel>
    </div>
  )
}
