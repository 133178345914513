import React, { FC } from 'react'
import s from './FirstScreen.module.css'

interface FirstScreen {
  data?: any
  width: number
}

export const FirstScreen: FC<FirstScreen> = ({ width, data }) => (
  <section className={s.firstscreen}>
    <div className={s.firstscreen__content} id="top">
      <div className={s.firstscreen__content_left_text}>
        <p>ИНВЕСТИЦИИ</p>
        <div>
          <p className={s.firstscreen__content_left_text_hard}>СЛОЖНО</p>
        </div>
      </div>
      <div className={s.firstscreen__content_rihgt_text}>
        <div className={s.firstscreen__content_rihgt_text_up}>
          <p>{data?.heading}</p>
        </div>
        <div>
          <p className={s.firstscreen__content_rihgt_text_down}>НЕ</p>
        </div>
      </div>
    </div>
    <div className={s.firstscreen__content_bottom_content}>
      <div className={s.firstscreen__content_bottom_content_left}>
        <p className={s.firstscreen__content_bottom_content_left_scrolldown}>
          ПРОКРУТИТЕ ВНИЗ
        </p>
      </div>
      <div className={s.firstscreen__text_description}>
        <p>{data?.heading}</p>
      </div>
      <div
        className={s.firstscreen__content_bottom_content_right}
        // style={{
        //   backgroundImage:
        //     width > 1024
        //       ? `url('${data?.page?.photo}')`
        //       : `url('./img/main_img_1024.webp')`,
        // }}
        style={{
          backgroundImage:
              `url('${data?.photo}')`,
        }}
      >
          &nbsp;
      </div>
    </div>
  </section>
)
