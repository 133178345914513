import { gql } from '@apollo/client'

// Мутация для добавления галерии перед созданием проекта
export const CREATE_PROJECT_IMAGES = gql`
  mutation createProjectImages($data: ProjectImageData) {
    admProjectImageCreate(data: $data) {
      uuid
      id
      logo
      bannerApp
      bannerSite
      background
      gallery
    }
  }
`
