export const statusArr = [
  {
    id: 1,
    title: 'Активный',
  },
  {
    id: 2,
    title: 'Черновик',
  },
  {
    id: 3,
    title: 'Архив',
  },
]
