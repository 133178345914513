import { gql } from '@apollo/client'

export const ALL_CLIENTS = gql`
  query ($filter: ClientFilter) {
    clients(filter: $filter) {
      uuid
      firstName
      lastName
      middleName
    }
  }
`

// Запрос клиентов для страницы Клиенты

export const CLIENTS = gql`
  query Clients(
    $slice: Slice
    $filter: ClientFilter
    $sort: ClientSort
    $totalInvestSumFrom: Decimal
    $totalInvestSumTo: Decimal
    $activeInvestSumFrom: Decimal
    $activeInvestSumTo: Decimal
    $totalProjectCountFrom: Int
    $totalProjectCountTo: Int
    $activeProjectCountFrom: Int
    $activeProjectCountTo: Int
    $expireOn: Int
    $projectsUUID: [String]
  ) {
    clients(slice: $slice, filter: $filter, sort: $sort) {
      uuid
      photo
      firstName
      middleName
      lastName
      phone
      birthDay
      profile {
        uuid
      }
      info {
        email
        iis
        inn
      }
      infoOrg {
        name
        inn
        address
        rs
        ks
        bank
        bik
      }
      contracts(
        totalInvestSumFrom: $totalInvestSumFrom
        totalInvestSumTo: $totalInvestSumTo
        activeInvestSumFrom: $activeInvestSumFrom
        activeInvestSumTo: $activeInvestSumTo
        totalProjectCountFrom: $totalProjectCountFrom
        totalProjectCountTo: $totalProjectCountTo
        activeProjectCountFrom: $activeProjectCountFrom
        activeProjectCountTo: $activeProjectCountTo
        expireOn: $expireOn
        projectsUUID: $projectsUUID
      ) {
        uuid
        expireOn
        investSum
        project {
          uuid
          name
        }
      }
      brokers {
        name
      }
    }
  }
`
