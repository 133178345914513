import clsx from 'clsx'
import logo from './img/logo-frontiers.svg'
import s from './Logo.module.css'

type Props = {
  className?: string
  width?: number
  height?: number
}

export const Logo = ({ className, width = 180, height = 30 }: Props) => (
  <div className={clsx(s.logo, className)}>
    <img
      className={s.logo__image}
      src={logo}
      alt="logo"
      width={width}
      height={height}
    />
  </div>
)
