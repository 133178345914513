import { gql } from '@apollo/client'

export const MAINPAGE_UPDATE = gql`
  mutation admMainPageUpdate($data: MainPageDataUpdate!) {
    admMainPageUpdate(data: $data) {
      id
      button
      heading
      headingEn
      photo
      about
      aboutEn
      photoAbout
      title
      titleEn
      quote
      quoteEn
      caption
      captionEn
      leftText
      leftTextEn
    }
  }
`
export const NUMBERS_CREATE = gql`
  mutation create_Numbers($data: MediaNumbersData!) {
    admMediaNumbersCreate(data: $data) {
      year
      investorProfit
      returnOnInvestment
      reinvest
      investorInvested
      transactionsCompleted
      averageInvestorCheck
    }
  }
`
export const NUMBERS_DELETE = gql`
  mutation create_Numbers($uuid: String!) {
    admMediaNumbersDelete(uuid: $uuid)
  }
`

export const NUMBERS_UPDATE = gql`
  mutation update_Numbers($uuid: String!, $data: MediaNumbersDataUpdate!) {
    admMediaNumbersUpdate(uuid: $uuid, data: $data) {
      uuid
      year
      investorProfit
      returnOnInvestment
      reinvest
      investorInvested
      transactionsCompleted
      averageInvestorCheck
    }
  }
`
