import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Button } from '../Button'
import arrowLeft from './img/arrow-left.svg'
import arrowRight from './img/arrow-right.svg'
import s from './SliderUI.module.css'

interface Slides {
	titleSlide: number
	positionTitle: string
	title: any
	text: string
	textBtn: string
	linkBtn: string
	id: number
	color: string
	statusPhoto: string
	photo: string
	colors: any
}

interface SliderProps {
	slides: Slides[]
	btn: number
	next: () => void
	previous: () => void
	slideRef: any
}

export const SliderUI: React.FC<SliderProps> = ({
  slides,
  btn,
  next,
  previous,
  slideRef,
}) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <div>
      <Slider ref={slideRef} {...settings}>
        {slides?.map((elem: Slides) => (
          <div key={elem.id}>
            <div
              className={s.sliderBlock}
              style={
                elem.photo
                  ? {
                    background: `url(${elem.photo}) no-repeat`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
									  }
                  : elem.color ? {
                    background: elem.color,
									  } : { background: '#717171' }
              }
            >
              <div
                className={s.sliderItem}
                style={{
                  justifyContent:
										elem.positionTitle === 'снизу' ? 'flex-end' : 'flex-start',
                }}
              >
                <p className={s.sliderTitle}>{elem.title}</p>
                <p className={s.sliderText}>{elem.text}</p>
              </div>
              {elem.textBtn && <Button className={s.sliderBtn} theme="dark">{elem.textBtn}</Button>}
            </div>
          </div>
        ))}
      </Slider>
      <div className={s.sliderBlockBtn}>
        <div className={s.sliderBtnItem} onClick={previous}>
          <img src={arrowLeft} alt="arrowLeft" />
        </div>
        <p className={s.sliderBtnAmount}>{`${btn}/${slides?.length}`}</p>
        <div className={s.sliderBtnItem} onClick={next}>
          <img src={arrowRight} alt="arrowRight" />
        </div>
      </div>
    </div>
  )
}
