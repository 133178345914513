import clsx from 'clsx'
import { Panel } from '../Panel'
import s from './Tooltip.module.css'

export interface Props {
  className?: string
  onClick?: (event: React.MouseEvent) => void
  removeDoc?: (event: React.MouseEvent) => void
  removeProject?: (event: React.MouseEvent) => void
  archiveProject?: (event: React.MouseEvent) => void
  resumeProject?: (event: React.MouseEvent) => void
  suspendProject?: (event: React.MouseEvent) => void
  activateProject?: (event: React.MouseEvent) => void
  arrSettings?: string[]
  team?: boolean
  teamHandler?: (event: React.MouseEvent) => void
  teamPublished?: boolean
  client?: boolean
}

export const Tooltip = ({
  className,
  onClick,
  removeDoc,
  removeProject,
  archiveProject,
  resumeProject,
  suspendProject,
  activateProject,
  arrSettings = [],
  team,
  teamHandler,
  teamPublished,
  client,
}: Props) => (
  <div className={clsx(s.tooltip, className)}>
    {arrSettings?.length ? (
      <Panel className={s.tooltip__panel}>
        {arrSettings.map((elem: string, i: number) => (
          <button
            key={i}
            className={s.tooltip__btn}
            type="button"
            onClick={
              elem === 'Редактировать'
                ? onClick
                : elem === 'Удалить'
                  ? removeProject
                  : elem === 'Снять с публикации'
                    ? archiveProject
                    : elem === 'Возобновить'
                      ? resumeProject
                      : elem === 'Приостановить'
                        ? suspendProject
                        : activateProject
            }
          >
            {elem}
          </button>
        ))}
      </Panel>
    ) : (
      <Panel className={s.tooltip__panel}>
        <button className={s.tooltip__btn} type="button" onClick={onClick}>
          Редактировать
        </button>
        {team && (
          <button className={s.tooltip__btn} type="button" onClick={teamHandler}>
            {teamPublished ? 'В черновики' : 'Опубликовать'}
          </button>
        )}
        {!client && (
          <button className={s.tooltip__btn} type="button" onClick={removeDoc}>
            Удалить
          </button>
        )}
      </Panel>
    )}
  </div>
)
