import { gql } from '@apollo/client'

export const UPDATE_FAQ = gql`
  mutation admFaqUpdate($uuid: String!, $data: FaqDataUpdate!) {
    admFaqUpdate(uuid: $uuid, data: $data) {
      id
      uuid
      title
      titleEn
      decription
      descriptionEn
      orderNum
    }
  }
`

export const CREATE_FAQ = gql`
  mutation admFaqCreate($data: FaqData!) {
    admFaqCreate(data: $data) {
      id
      uuid
      title
      titleEn
      decription
      descriptionEn
      orderNum
    }
  }
`

export const DELETE_FAQ = gql`
  mutation admFaqDelete($uuid: String!) {
    admFaqDelete(uuid: $uuid)
  }
`
