import React, { FC } from 'react'
import s from './DemandItem.module.css'

interface DemandItem {
  el?: any
  index?: number
  small?: boolean
}

export const DemandItem: FC <DemandItem> = ({ el, index, small }) => (
  <li
    className={
      index === 0
        ? s.demand_item
        : index === 1
          ? s.demand_item2
          : s.demand_item3
    }
  >
    <div className={s.demand_item_number}>{'0'.concat(el.orderNum)}</div>
    <p className={s.demand_item_title}>{el.title}</p>
    {/* <div className={s.demand_item_line}></div> */}
    <p className={s.demand_item_description}>{el.description}</p>
  </li>
)
