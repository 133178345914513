/* eslint-disable*/

import React, { ChangeEvent, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import {
  Title,
  Container,
  Panel,
  Label,
  Field,
  Textarea,
  Button,
  Loader,
  AddButton,
  CloseIcon,
  ProgressBar,
  TypeToggler,
} from "../../ui";
import { Portal } from "../../ui/Portal/Portal";
import { TeamPopup } from "../preview/home/teamSection/teamPopup/TeamPopup";
import { UploadEditPages } from "../UploadEditPages";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
  changeIsCreateBroker,
  changeNav,
} from "../../redux/slices/editSiteSlice";
import { CREATE_PERSONNEL } from "../../apollo/mutation/editSite/teamPageUpdate";
import { GET_TEAM } from "../../apollo/query/editSite/teamPage";
import preview from "./img/icon-eye.png";
import iconSent from "./img/icon-sent.svg";
import iconDraft from "./img/icon-draft.svg";
import fullFill from "./img/icon-fillfull.svg";
import empty from "./img/icon-empty.svg";
import s from "./CreateBroker.module.css";
import { NativeSelect } from "../../ui/nativeSelect";
import { typeArr } from "./data";
import { data } from "../Table/data";

export const CreateBroker = () => {
  const dispatch = useAppDispatch();
  const [sumField, setSumField] = useState(0);
  const [senting, setSenting] = useState(false);
  const [draft, setDraft] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [firstnameEn, setFirstNameEn] = useState("");
  const [slogan, setSlogan] = useState("");
  const [sloganEn, setSloganEn] = useState("");
  const [bio, setBio] = useState("");
  const [bioEn, setBioEn] = useState("");
  const [photoBroker, setPhotoBroker] = useState("");
    const deletePhoto = (e: any) => setPhotoBroker("");
  
  const [role, setRole] = useState("");
  const [roleEn, setRoleEn] = useState("");

  const [numbers, setNumbers] = useState<any>([
    {
      value: "",
      type: "",
      typeEn: "",
      description: "",
      descriptionEn: "",
      uniqId: new Date().toISOString(),
      showType: false,
      num: 0,
    },
  ]);

  const [broker, setBroker] = useState<any>({
    status: "draft",
    employee: "broker",
    firstname: "",
    firstNameEn: "",
    lastname: "",
    patronymic: "",
    personRole: "",
    personRoleEn: "",
    phone: "",
    otherPhone: "",
    slogan: "",
    sloganEn: "",
    photo: "",
    numbers: [],
    // numbersEn: [],
    bio: "",
    bioEn: "",
  });
  const [previewToggler, setPreviewToggler] = useState(false);
  // расчет прогрессбара
  useEffect(() => {
    const tempObj = {
      firstname: broker.firstname.trim() && broker.firstNameEn.trim(),
      personRole: broker.personRole.trim() && broker.personRoleEn.trim(),
      slogan: broker.slogan.trim() && broker.sloganEn.trim(),
      photo: broker.photo,
      numbers:
        broker?.numbers && broker.numbers.length > 0
          ? broker.numbers.every(
              (number: any) =>
                number.value.trim() &&
                number.description.trim() &&
                number.descriptionEn.trim()
            )
            ? 1
            : ""
          : "",
      bio: broker.bio.trim() && broker.bioEn.trim(),
    };

    const values = Object.values(tempObj).map((elem) =>
      elem !== "" ? 16.66 : 0
    );

    setSumField(values.reduce((a: any, b: any) => a + b));
    // setSumField(100)
  }, [broker]); // сложение значений объекта

  const [
    createBroker,
    {
      data: createBrokerData,
      loading: loadingCreateBroker,
      error: errorCreateBroker,
    },
  ] = useMutation(CREATE_PERSONNEL, {
    refetchQueries: [
      {
        query: GET_TEAM,
        variables: {
          filter: {},
        },
      },
    ],
  });

  const closeHandler = (e: any) => {
    e.stopPropagation();
    setPreviewToggler(false);
  };

  // запрет печатать числа
  const typeNoNumbers = (text: string) => text.replace(/\d/g, "");
  // запрет печатать буквы и точку
  const typeNoLеtters = (text: string) => text.replace(/[^0-9,]/g, "");

  const changePhotoBroker = (url: string) => {
    setPhotoBroker(url);
    setBroker({ ...broker, photo: url });
  };
  const changeFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    // setFirstName(event.target.value)
    setFirstName(typeNoNumbers(event.target.value));
    setBroker({ ...broker, firstname: typeNoNumbers(event.target.value) });
  };

  const changeFirstNameEn = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstNameEn(typeNoNumbers(event.target.value));
    setBroker({ ...broker, firstNameEn: typeNoNumbers(event.target.value) });
  };

  const changeRole = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setRole(typeNoNumbers(event.target.value));
    setBroker({ ...broker, personRole: typeNoNumbers(event.target.value) });
  };

  const changeRoleEn = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setRoleEn(typeNoNumbers(event.target.value));
    setBroker({ ...broker, personRoleEn: typeNoNumbers(event.target.value) });
  };

  const changeSlogan = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSlogan(event.target.value);
    setBroker({ ...broker, slogan: event.target.value });
  };

  const changeSloganEn = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSloganEn(event.target.value);
    setBroker({ ...broker, sloganEn: event.target.value });
  };
  const changeBio = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setBio(event.target.value);
    setBroker({ ...broker, bio: event.target.value });
  };

  const changeBioEn = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setBioEn(event.target.value);
    setBroker({ ...broker, bioEn: event.target.value });
  };

  const changeNumber = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      numbers.find((el: any) => el.uniqId === e.target.id).type !== "" &&
      numbers.find((el: any) => el.uniqId === e.target.id).type !==
        "без значения"
    ) {
      numbers.find((el: any) => el.uniqId === e.target.id).value =
        typeNoLеtters(e.target.value);
    } else {
      numbers.find((el: any) => el.uniqId === e.target.id).value =
        e.target.value;
    }
    // numbers.find((el: any) => el.uniqId === e.target.id).value = e.target.value
    setNumbers([...numbers]);
    setBroker({ ...broker, numbers: [...numbers] });
  };

  const changeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    numbers.find((el: any) => el.uniqId === e.target.id).description =
      e.target.value;
    setNumbers([...numbers]);
    setBroker({ ...broker, numbers: [...numbers] });
  };

  const changeDescriptionEn = (e: ChangeEvent<HTMLInputElement>) => {
    numbers.find((el: any) => el.uniqId === e.target.id).descriptionEn =
      e.target.value;
    setNumbers([...numbers]);
    setBroker({ ...broker, numbers: [...numbers] });
  };

  const showTypeHandler = (e: any) => {
    numbers.find((el: any) => el.uniqId === e.target.id).showType = true;
    // setShowType(!showType)
    setNumbers([...numbers]);
    setBroker({ ...broker, numbers: [...numbers] });
  };

  const showTypeHandlerClose = (uniqId: string) => {
    numbers.find((el: any) => el.uniqId === uniqId).showType = false;
    setNumbers([...numbers]);
    setBroker({ ...broker, numbers: [...numbers] });
  };

  const selectTypeHandler = (type: any, uniqId: any) => {
    numbers.find((el: any) => el.uniqId === uniqId).type = type;
    setNumbers([...numbers]);
    setBroker({ ...broker, numbers: [...numbers] });
  };

  const addNumber = () => {
    if (numbers?.length < 6) {
      setNumbers([
        ...numbers,
        {
          value: "",
          type: "",
          typeEn: "",
          description: "",
          descriptionEn: "",
          uniqId: new Date().toISOString(),
        },
      ]);
    }
  };

  const deleteNumber = (e: React.MouseEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;
    if (element.id) {
      setNumbers([...numbers.filter((el: any) => el.uniqId !== element.id)]);
      const heplArr = [
        ...numbers.filter((el: any) => el.uniqId !== element.id),
      ];
      setBroker({ ...broker, numbers: [...heplArr] });
    }
  };

  const closePage = () => {
    dispatch(changeIsCreateBroker(false));
    dispatch(changeNav(2));
    setBroker({
      status: "draft",
      employee: "broker",
      firstname: "",
      firstNameEn: "",
      lastname: "",
      patronymic: "",
      personRole: "",
      personRoleEn: "",
      phone: "",
      otherPhone: "",
      slogan: "",
      sloganEn: "",
      photo: "",
      numbers: "",
      bio: "",
      bioEn: "",
    });
  };

  const handleSaveSent = () => {
    setSenting(true);
    createBroker({
      variables: {
        data: {
          ...broker,
          numbers: JSON.stringify(numbers),
          status: "published",
        },
      },
    });
    if (!loadingCreateBroker || errorCreateBroker === undefined) {
      setTimeout(() => {
        closePage();
      }, 2000);
    } else {
      setTimeout(() => {
        closePage();
      });
    }
  };

  const handleSaveDraft = () => {
    setDraft(true);
    createBroker({
      variables: {
        data: {
          ...broker,
          numbers: JSON.stringify(numbers),
          status: "draft",
        },
      },
    });
    if (!loadingCreateBroker || errorCreateBroker === undefined) {
      setTimeout(() => {
        closePage();
      }, 2000);
    } else {
      setTimeout(() => {
        closePage();
      });
    }
  };

  const clickPlusHandler = (e: any) => {
    const uniqId = e.target.parentElement.id;
    const tempNum = numbers.find((el: any) => el.uniqId === uniqId);

    if (tempNum.num > 4) {
      tempNum.num = 0;
      setNumbers(
        numbers.map((el: any) => {
          if (el.uniqId === uniqId) {
            return { ...el, num: el.num + 1, type: typeArr[tempNum.num].value };
          }
          return el;
        })
      );
    } else {
      setNumbers(
        numbers.map((el: any) => {
          if (el.uniqId === uniqId) {
            return { ...el, num: el.num + 1, type: typeArr[tempNum.num].value };
          }
          return el;
        })
      );
    }
  };
  const clickMinusHandler = (e: any) => {
    const uniqId = e.target.parentElement.id;
    const tempNum = numbers.find((el: any) => el.uniqId === uniqId);
    if (tempNum.num < 0) {
      tempNum.num = 4;
      setNumbers(
        numbers.map((el: any) => {
          if (el.uniqId === uniqId) {
            return { ...el, num: el.num - 1, type: typeArr[tempNum.num].value };
          }
          return el;
        })
      );
    } else {
      setNumbers(
        numbers.map((el: any) => {
          if (el.uniqId === uniqId) {
            return { ...el, num: el.num - 1, type: typeArr[tempNum.num].value };
          }
          return el;
        })
      );
    }
  };

  const draftSaved = (
    <>
      <img src={iconDraft} alt="draft" width="17" height="17" />
      <span style={{ marginLeft: "7px" }}>Черновик сохранен</span>
    </>
  );

  const sentSaved = (
    <>
      <img src={iconSent} alt="sent" width="17" height="17" />
      <span style={{ marginLeft: "7px" }}>Сохранено</span>
    </>
  );

  return (
    <Container className={s.container}>
      <CloseIcon onClick={closePage} />
      {/* <CloseIcon onClick={() => console.log()} /> */}
      <Title title="Добавить сотрудника" />
      <div className={s.wrapper}>
        <div className={s.first_block}>
          <Panel className={s.info__panel} padding>
            <Label title="Имя">
              <div className={s.field_wrapper}>
                <Field
                  className={s.broker_name}
                  value={firstname}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeFirstName?.(event)
                  }
                  maxLength={100}
                />

                <Field
                  className={s.broker_name}
                  eng
                  value={firstnameEn}
                  view="default"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeFirstNameEn?.(event)
                  }
                  maxLength={100}
                />
              </div>
            </Label>

            <Label title="Роль в компании">
              <div className={s.field_wrapper}>
                <Textarea
                  className={s.role}
                  value={role}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    changeRole?.(event)
                  }
                  rows={3}
                  maxLength={100}
                />

                <Textarea
                  className={s.role}
                  eng
                  value={roleEn}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    changeRoleEn?.(event)
                  }
                  maxLength={100}
                  rows={3}
                />
              </div>
            </Label>

            <Label title="Слоган">
              <div className={s.field_wrapper}>
                <Textarea
                  className={s.slogan}
                  value={slogan}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    changeSlogan?.(event)
                  }
                  rows={5}
                />

                <Textarea
                  className={s.slogan}
                  value={sloganEn}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    changeSloganEn?.(event)
                  }
                  eng
                  rows={5}
                />
              </div>
            </Label>
            <Label title="Биография">
              <div className={s.field_wrapper}>
                <Textarea
                  value={bio}
                  className={s.description}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    changeBio?.(event)
                  }
                  rows={5}
                />

                <Textarea
                  value={bioEn}
                  className={s.description}
                  eng
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    changeBioEn?.(event)
                  }
                  rows={5}
                />
              </div>
            </Label>
          </Panel>
        </div>
        <Panel className={s.info__panel} padding>
          <p className={s.number_title}>Цифры</p>
          {numbers.map((el: any, index: number) => (
            <div className={s.form_field_section} key={el.uniqId}>
              <div className={s.number_wrapper}>
                <Label
                  title={`Цифра ${index + 1}`}
                  className={s.form_field_item}
                >
                  <Field
                    className={s.form_field_value}
                    // className={s.form_field_value_small}
                    placeholder="Введите значение"
                    // type={(el.type === '' || el.type === 'без значения') ? '' : 'number'}
                    view="default"
                    id={el.uniqId}
                    value={el.value}
                    // value={
                    //   number
                    //   ? Number(String(number).replace(/\D/g, '')).toLocaleString()
                    //   : number
                    // }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      changeNumber?.(e)
                    }
                    maxLength={5}
                  />
                </Label>
                <TypeToggler
                  className={s.form_field_value_toggler}
                  uniqId={el.uniqId}
                  // fieldName="investorProfit"
                  value={el.type || "Ед.изм"}
                  clickPlusHandler={clickPlusHandler}
                  clickMinusHandler={clickMinusHandler}
                />
                <button
                  className={s.trash}
                  type="button"
                  id={el.uniqId}
                  onClick={deleteNumber}
                >
                  {}
                </button>
              </div>

              <div className={s.desc_wrapper}>
                <Label title="Описание" className={s.form_field_item}>
                  <div className={s.field_wrapper}>
                    <Field
                      className={s.form_field_value}
                      placeholder="Введите описание"
                      view="default"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        changeDescription?.(e)
                      }
                      maxLength={350}
                      id={el.uniqId}
                    />

                    <Field
                      className={s.form_field_value}
                      placeholder="Введите описание"
                      eng
                      view="default"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        changeDescriptionEn?.(e)
                      }
                      maxLength={350}
                      id={el.uniqId}
                    />
                  </div>
                </Label>
              </div>
            </div>
          ))}
          {numbers?.length < 6 && (
            <AddButton className={s.addButton} onClick={addNumber}>
              Добавить год
            </AddButton>
          )}
        </Panel>
        <Panel className={s.info__panel} padding>
          <div className={s.uploads}>
            <UploadEditPages
              title="Загрузить фото"
              titleStatus="Брокер"
              text=""
              text2="Максимальный размер фото 8 Мб. Форматы: jpeg, jpg, png. Оптимальный размер: 707 х 960 px"
              // application=".webp"
              subText1="Загрузить с компьютера"
              subText2="или перетащите сюда"
              uploadId="Broker"
              deletePhoto={deletePhoto}
              changePhotoBroker={changePhotoBroker}
              photoBroker={photoBroker}
              // photoAbout={photoAbout}
              // changePhotoAbout={changePhotoAbout}
            />
          </div>
        </Panel>
        <Panel className={s.btns__panel}>
          {/* <Button
            onClick={() => setPreviewToggler(true)}
            className={s.btns__panel_preview}
            icon
            src={preview}
          >
            Предпросмотр
          </Button> */}

          <Button
            onClick={handleSaveDraft}
            className={s.btns__panel_preview}
            // icon
            // src={preview}
          >
            {!draft ? (
              "Сохранить как черновик"
            ) : loadingCreateBroker ? (
              <Loader />
            ) : !loadingCreateBroker && errorCreateBroker ? (
              "Ошибка"
            ) : (
              draftSaved
            )}
          </Button>

          <Button
            onClick={handleSaveSent}
            className={
              Math.ceil(sumField) === 100
                ? s.btns__panel_save
                : s.btns__panel_save_disabled
            }
            // className={
            //   broker?.firstname
            //     ? s.btns__panel_save
            //     : s.btns__panel_save_disabled
            // }

            theme="dark"
            disabled={Math.ceil(sumField) < 100}
            // disabled={!broker?.firstname}
          >
            {!senting ? (
              "Сохранить и опубликовать"
            ) : loadingCreateBroker ? (
              <Loader />
            ) : !loadingCreateBroker && errorCreateBroker ? (
              "Ошибка"
            ) : (
              sentSaved
            )}
          </Button>
        </Panel>
      </div>
      <Panel className={s.panel__progress} padding>
        <ProgressBar
          className={s.project_create}
          value={Math.ceil(sumField)}
          // value={100}
        />
        <div className={s.project_indicator}>
          <img
            src={firstname.trim() && firstnameEn.trim() ? fullFill : empty}
            alt="empty"
          />
          <p>Имя</p>
        </div>
        <div className={s.project_indicator}>
          <img
            src={role.trim() && roleEn.trim() ? fullFill : empty}
            alt="empty"
          />
          <p>Роль в компании</p>
        </div>
        <div className={s.project_indicator}>
          <img
            src={slogan.trim() && sloganEn.trim() ? fullFill : empty}
            alt="empty"
          />
          <p>Слоган</p>
        </div>
        <div className={s.project_indicator}>
          <img
            src={bio.trim() && bioEn.trim() ? fullFill : empty}
            alt="empty"
          />
          <p>Биография</p>
        </div>
        <div className={s.project_indicator}>
          <img
            src={
              broker?.numbers && broker.numbers.length > 0
                ? broker.numbers.every(
                    (number: any) =>
                      number.value.trim() &&
                      number.description.trim() &&
                      number.descriptionEn.trim()
                  )
                  ? fullFill
                  : empty
                : empty
            }
            alt="empty"
          />
          <p>Цифры</p>
        </div>
        <div className={s.project_indicator}>
          <img src={photoBroker ? fullFill : empty} alt="empty" />
          <p>Фото</p>
        </div>
      </Panel>
      {previewToggler && (
        <Portal>
          <div className={s.preview} onClick={closeHandler}>
            <TeamPopup
              width={1025}
              setPreviewToggler={setPreviewToggler}
              broker={broker}
            />
          </div>
        </Portal>
      )}
    </Container>
  );
};
