import type { ReactNode } from 'react'
import clsx from 'clsx'
import s from './Panel.module.css'

type PanelProps = {
  children: ReactNode
  className?: string
  padding?: boolean
  style?: any
}

export const Panel = ({
  children,
  className,
  padding = false,
  style,
}: PanelProps) => (
  <div
    className={clsx(s.panel, className, {
      [s.padding]: padding,
    })}
    style={style}
  >
    {children}
  </div>
)
