import React, { useMemo } from 'react'
import clsx from 'clsx'
import s from './Avatar.module.css'

interface AvatarType {
  firstName: string | undefined
  lastName: string | undefined
  className?: string
}

export const Avatar: React.FC<AvatarType> = ({
  firstName,
  lastName,
  className,
}) => {
  const randomColor = useMemo(
    () => Math.floor(Math.random() * 16_777_215).toString(16),
    []
  )

  return (
    <div
      className={clsx(s.avatar, className)}
      style={{
        backgroundColor: `#${randomColor}`,
      }}
    >
      {`${firstName === undefined ? '' : firstName} ${
        lastName === undefined ? '' : lastName
      }`}
    </div>
  )
}
