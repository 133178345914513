import React, { useState, FC } from 'react'
import { Link } from 'react-router-dom'
import { SocialLinks } from '../socialLinks/SocialLinks'
import KOJO from './img/kojo_logo.svg'
import close from '../assets/close_icon.svg'
import './Footer.css'

interface Footer {
  footer?: any
  setPreviewToggler?: (arg: any) => void
}

export const Footer: FC<Footer> = ({ setPreviewToggler, footer }) => {
  const [info, setInfo] = useState(footer)

  const formatPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber === undefined) return null
    const cleaned = phoneNumber.replace(/\D/g, '')
    const formatted = `+${cleaned.slice(0, 1)} ${cleaned.slice(1, 4)} ${cleaned.slice(4, 7)} ${cleaned.slice(7)}`
    return formatted
  }

  const replaceCountryCode = (phoneNumber: string) => {
    if (phoneNumber === undefined) return null
    const replaced = phoneNumber.replace(/^\+7/, '8')
    return replaced
  }

  return (
    <div className="wrapper_footer">
      <button className="close" type="button" onClick={() => setPreviewToggler?.(false)}>
        <img src={close} alt="" />
      </button>
      <footer className="lastscreen__footer">
        <div className="lastscreen__footer_wrapper_info">
          <div className="lastscreen__frontiers_group">
            <div
              className="lastscreen__contact_group_frontiers"
            >
              FRONTIERS
            </div>
            <div className="footer_social_links">
              <SocialLinks info={info} />
            </div>
            <div className="lastscreen__footer_text">
              Политика конфиденциальности
            </div>
            <p className="lastscreen__footer_text">
              ©
              {new Date().getFullYear()}
              Frontiers Group
            </p>
          </div>
          <div className="lastscreen__contact_group">
            <p className="lastscreen__contact_group_contact">КОНТАКТЫ</p>
            <address className="lastscreen__footer_text">
              {info?.contactsAddress}
            </address>
            <a
              href={`tel:${replaceCountryCode(info?.contactsPhone)}`}
              className="lastscreen__footer_text"
            >
              {formatPhoneNumber(info.contactsPhone)}
            </a>
            <a
              className="lastscreen__footer_text"
              href={`mailto:${info?.contactsPhonel}`}
            >
              {info?.contactsMail}
            </a>
            <div className="lastscreen__design_dev">
              <p className="lastscreen__design_dev_text">Дизайн и разработка</p>
              <a href="https://kojo.one" target="_blank" rel="noreferrer">
                <div className="kojo__logo_hidden"> KOJO.ONE</div>
                <img
                  className="kojo__logo"
                  src={KOJO}
                  alt="KOJO.ONE"
                />
              </a>
            </div>
          </div>
          <div className="lastscreen__info">
            <div className="lastscreen__footer_info_toplineWrapper">
              <div className="lastscreen__footer_info_img">&nbsp;</div>
              <span className="lastscreen__footer_info_warn">{info?.textHeader}</span>
            </div>
            <textarea className="lastscreen__footer_info_textArea" readOnly rows={12} value={info?.textText} />
          </div>
        </div>
      </footer>
    </div>
  )
}
