import { gql } from '@apollo/client'

export const GET_TEAM = gql`
  query allPersonnel {
    personnel {
      uuid
      status
      employee
      firstname
      firstNameEn
      lastname
      patronymic
      personRole
      personRoleEn
      phone
      otherPhone
      slogan
      sloganEn
      photo
      numbers
      bio
      bioEn
    }
  }
`
export const GET_PERSON = gql`
  query getPerson($uuid: String!) {
    person(uuid: $uuid) {
      uuid
      status
      employee
      firstname
      firstNameEn
      lastname
      patronymic
      personRole
      personRoleEn
      phone
      otherPhone
      slogan
      sloganEn
      photo
      numbers
      bio
      bioEn
    }
  }
`
