import React from 'react'
import clsx from 'clsx'
import s from './TypeToggler.module.css'

interface TypeToggler {
	className?: string
  uniqId?: string
  value?: string
  fieldName?: string
  clickPlusHandler?: (e:any) => void
  clickMinusHandler?: (e:any) => void
}

export const TypeToggler: React.FC<TypeToggler> = ({
  className,
  uniqId,
  value,
  clickPlusHandler,
  clickMinusHandler,
  fieldName,
}) => (
  <div className={clsx(s.form_field_toggler, className)}>
    <div className={s.form_field_toggler_value}>{value}</div>
    <div id={uniqId} className={s.form_field_buttonWrapper}>
      <button className={s.form_field_button} id={fieldName} type="button" onClick={clickPlusHandler}>&nbsp;</button>
      <button className={s.form_field_button} id={fieldName} type="button" onClick={clickMinusHandler}>&nbsp;</button>
    </div>
  </div>
)
