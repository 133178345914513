import { gql } from '@apollo/client'

export const CREATE_STAGE = gql`
  mutation admMediaStageCreate($data: StageData!) {
    admMediaStageCreate(data: $data) {
      id
      uuid
      title
      description
      orderNum
    }
  }
`

export const UPDATE_STAGE = gql`
  mutation admMediaStageUpdate($uuid: String!, $data: StageDataUpdate!) {
    admMediaStageUpdate(uuid: $uuid, data: $data) {
      id
      uuid
      title
      description
      orderNum
    }
  }
`
export const DELETE_STAGE = gql`
  mutation admMediaStageDelete($uuid: String!) {
    admMediaStageDelete(uuid: $uuid)
  }
`

export const CREATE_CONDITION = gql`
  mutation admMediaCondition($data: ConditionData!) {
    admMediaConditionCreate(data: $data) {
      id
      uuid
      title
      description
      orderNum
    }
  }
`

export const UPDATE_CONDITION = gql`
  mutation admMediaConditionUpdate(
    $uuid: String!
    $data: ConditionDataUpdate!
  ) {
    admMediaConditionUpdate(uuid: $uuid, data: $data) {
      id
      uuid
      title
      description
      orderNum
    }
  }
`

export const DELETE_CONDITION = gql`
  mutation admMediaConditionDelete($uuid: String!) {
    admMediaConditionDelete(uuid: $uuid)
  }
`

export const UPDATE_MEDIAENTERPRNEUR = gql`
  mutation admMediaEntrepreneurUpdate($data: MediaEntrepreneurDataUpdate!) {
    admMediaEntrepreneurUpdate(data: $data) {
      coverTitle
      projectsTitle
      formTitle
      formSubtitle
      buttonText
      buttonLink
    }
  }
`
