import React, { useState } from 'react'
import * as tus from 'tus-js-client'
import { InputFile, Panel } from '../../ui'
import s from './UploadStories.module.css'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  changeFieldState,
  changeFirstField,
  deleteImage,
  deleteImageSlide,
} from '../../redux/slices/promotionSlice'
import {
  changeFirstFieldDynamic,
  deleteDynamicImage,
} from '../../redux/slices/promotionDynamicSlice'

interface UploadStoriesProps {
	title?: string
	text: string
	// text2: string
	uploadId: string
	titleStatus: string
	application: string
	subText1: string
	subText2: string
	selectedFile?: boolean
	slideId?: number
	style?: any
	classNameSubText?: string
}

export const UploadStories: React.FC<UploadStoriesProps> = ({
  title,
  text,
  // text2,
  uploadId,
  titleStatus,
  application,
  subText1,
  subText2,
  selectedFile,
  classNameSubText,
  slideId,
  style,
}) => {
  const dispatch = useAppDispatch()
  const { promotionObj } = useAppSelector((state: any) => state.promotionSlice)
  const { promotionDynamicObj } = useAppSelector(
    (state: any) => state.promotionDynamicSlice,
  )

  const upLoad = (e: any, titleStatus: string): void => {
    const file = e.target.files[0]
    console.log('file', file)
    if (
      ((uploadId === 'miniature' && file.size <= 200000)
				|| (file.size <= 8e6 && uploadId !== 'miniature'))
			&& file.type === 'image/png'
    ) {
      // добавить ещё одну проверку
      if (typeof file === 'object') {
        // console.log('file', file)
        const fileType = file.name.slice(-4)
        const upload = new tus.Upload(file, {
          endpoint: process.env.REACT_APP_API_URL,
          retryDelays: [100, 300, 500, 1000, 3000],
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          onProgress(bytesUploaded: number, bytesTotal: number) {
            if (fileType === '.png') {
              // dispatch(statusPng('pending'))
            }
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
            console.log(bytesUploaded, bytesTotal, `${percentage}%`)
          },
          onSuccess() {
            if (slideId === 0 || slideId) {
              dispatch(
                changeFieldState({
                  id: slideId,
                  nameField: 'photo',
                  event: upload.url,
                }),
              )
              // ещё нужны будут статусы
            } else if (uploadId === 'dynamicDesign') {
              dispatch(
                changeFirstFieldDynamic({
                  nameField: 'image',
                  event: upload.url,
                }),
              )
            } else {
              dispatch(
                changeFirstField({
                  nameField: 'image',
                  event: upload.url,
                }),
              )
            }
            console.log(
              'Download %s from %s',
              upload.url?.replace(`${process.env.REACT_APP_API_URL}`, ''),
              file.name.slice(-4),
            )
          },
          onError(error: any) {
            if (fileType === '.png') {
              // dispatch(statusPng('error'))
            }
            console.log(`Failed because: ${error}`)
          },
        })
        // Check if there are any previous uploads to continue.
        upload.findPreviousUploads().then((previousUploads: string | any[]) => {
          // Found previous uploads so we select the first one.
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0])
          }
          // Start the upload
          upload.start()
        })
      }
    } else {
      if (file.type === '.png') {
        // dispatch(statusPng('errorSize'))
      }
      alert('Неверный размер или формат файла')
    }
  }

  if ((slideId === 0 || slideId) && promotionObj?.slides[slideId]?.photo) {
    return (
      <Panel className={s.upload}>
        {title && <p className={s.title}>{title}</p>}
        <p className={s.description}>{text}</p>
        <Panel className={s.upload__file_success}>
          <div className={s.imageRow_wrapper}>
            <div className={s.image_wrapper}>
              <img
                src={
                  (slideId === 0 || slideId)
									&& promotionObj.slides[slideId]?.photo
                }
                alt="logo"
                className={s.image}
              />
              <div
                className={s.image_delete}
                onClick={() => {
                  if (selectedFile) dispatch(deleteImageSlide(slideId))
                }}
              >
                <div className={s.icon_delete} />
              </div>
            </div>
          </div>
          <div className={s.text_wrapper}>
            <InputFile
              className={s.wrapper__succes}
              classNameSpan={s.span__success}
              uploadId={uploadId}
              onChange={e => {
                if (selectedFile || uploadId === 'miniature') {
                  upLoad(e, titleStatus)
                }
              }}
              application={application}
              subText1="Загрузить другой файл"
            />
          </div>
        </Panel>
      </Panel>
    )
  }
  if (promotionObj.image && uploadId === 'miniature') {
    return (
      <Panel className={s.upload}>
        <p className={s.title}>{title}</p>
        <p className={s.description}>{text}</p>
        <Panel className={s.upload__file_success}>
          <div className={s.imageRow_wrapper}>
            <div className={s.image_wrapper}>
              <img src={promotionObj.image} alt="logo" className={s.image} />
              <div
                className={s.image_delete}
                onClick={() => dispatch(deleteImage())}
              >
                <div className={s.icon_delete} />
              </div>
            </div>
          </div>
          <div className={s.text_wrapper}>
            <InputFile
              className={s.wrapper__succes}
              classNameSpan={s.span__success}
              uploadId={uploadId}
              onChange={e => {
                if (selectedFile || uploadId === 'miniature') {
                  upLoad(e, titleStatus)
                }
              }}
              application={application}
              subText1="Загрузить другой файл"
            />
          </div>
        </Panel>
      </Panel>
    )
  }
  if (promotionDynamicObj.image && uploadId === 'dynamicDesign') {
    return (
      <Panel className={s.upload} padding>
        <p className={s.title}>{title}</p>
        <p className={s.description}>{text}</p>
        <Panel className={s.upload__file_success}>
          <div className={s.imageRow_wrapper}>
            <div className={s.image_wrapper}>
              <img
                src={promotionDynamicObj.image}
                alt="logo"
                className={s.image}
              />
              <div
                className={s.image_delete}
                onClick={() => dispatch(deleteDynamicImage())}
              >
                <div className={s.icon_delete} />
              </div>
            </div>
          </div>
          <div className={s.text_wrapper}>
            <InputFile
              className={s.wrapper__succes}
              classNameSpan={s.span__success}
              uploadId={uploadId}
              onChange={e => {
                if (uploadId === 'dynamicDesign') upLoad(e, titleStatus)
              }}
              application={application}
              subText1="Загрузить другой файл"
            />
          </div>
        </Panel>
      </Panel>
    )
  }

  return (
    <Panel style={style} className={s.upload} padding>
      {title && (
        <p style={!title ? { marginBottom: '0px' } : {}} className={s.title}>
          {title}
        </p>
      )}
      <p className={s.description}>{text}</p>
      {/* <p className={s.description}>{text2}</p> */}
      <Panel className={s.upload__file}>
        <InputFile
          uploadId={uploadId}
          onChange={e => {
            if (
              selectedFile
							|| uploadId === 'miniature'
							|| uploadId === 'dynamicDesign'
            ) upLoad(e, titleStatus)
          }}
          application={application}
          subText1={subText1}
          subText2={subText2}
          classNameSubText={classNameSubText}
        />
      </Panel>
    </Panel>
  )
}
